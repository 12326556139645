import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Route } from "@angular/router";
import { PolicyPurchase } from "src/app/models/order-preview/PolicyPurchase";
import { OrderReviewService } from "src/app/services/checkout/order-review.service";

@Component({
  selector: "app-complate-checkout",
  templateUrl: "./complate-checkout.component.html",
  styleUrls: ["./complate-checkout.component.css"],
})
export class ComplateCheckoutComponent implements OnInit {
  constructor(
    private orderService: OrderReviewService,
    private activatedroute: ActivatedRoute
  ) {}
  isSuccess: boolean;
  ngOnInit(): void {
    this.getPayment();
  }

  getPayment() {
    let transactionId = this.activatedroute.snapshot.params["id"];
    this.orderService.getPayment(transactionId).subscribe((res) => {
      this.isSuccess = res.isSuccess;
      // if(this.isSuccess){
      //   console.log(res.data);
      //    this.orderService.policyPurchase(res.data).subscribe((res)=>{
      //       console.log(res);
      //    })
      // }
    });
  }
}
