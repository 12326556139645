import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ProfileComponent } from './profile/profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { PROFILE_ROUTES } from './user.routing';
import { AuthModule } from '../Auth/auth.module';
import { CliamsComponent } from './cliams/cliams.component';
import { AddClaimComponent } from './cliams/add-claim/add-claim.component';
@NgModule({
   declarations: [
      ProfileComponent,
      CliamsComponent,
      AddClaimComponent,
   ],
   imports: [
      CommonModule,
      SharedModule,
      AppRoutingModule,
      ReactiveFormsModule,
      FileUploadModule,
      NgSelectModule,
      AuthModule,
      RouterModule.forChild(PROFILE_ROUTES)
   ],
   exports:[
      AuthModule
   ]
})


export class UserModule { }
