<div class="bg-white my-2">
    <div class="container">
        <div class="faq-section">
            <div class="inner-container">
                <div class="pattern-layer wow slideInRight animated animated animated" data-wow-delay="500ms"
                    data-wow-duration="1500ms" style="background-image: url(assets/images/bg/pattern-9.png)"></div>
                <div class="container">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 inner-column mt-5">
                            <div class="inner-box">
                                <div class="sec-title mb-30">
                                    <h2 class="pb-4">{{ "Footer.service" | translate }}</h2>
                                    <h6 class="p-1" style="justify-content: stretch">
                                        {{ "Footer.ProductLable" | translate }}
                                    </h6>
                                    <h2 class="pb-4 p-1" style="text-justify: kashida">
                                        {{ "Footer.productetails" | translate }}
                                    </h2>
                                </div>
                                <div *ngFor="let item of termsCondition; let i = index" class="">
                                    <ngb-accordion #a="ngbAccordion">
                                        <ngb-panel id="{{ item.id }}">
                                            <ng-template ngbPanelHeader let-opened="opened" class="faq-header">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <button (click)="isCollapsed = !isCollapsed" ngbPanelToggle
                                                        class="m-0" style="font-weight: bold; cursor: pointer">
                                                        {{
                                                        lang === "ar" ? item.questionAr : item.questionEn
                                                        }}
                                                    </button>
                                                    <button ngbPanelToggle *ngIf="!opened"
                                                        class="icon ion-ios-eye-off mx-2"></button>
                                                    <button ngbPanelToggle *ngIf="opened"
                                                        class="icon ion-ios-eye mx-2"></button>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <span [@fadeIn]="isCollapsed ? ':enter' : ':leave'" [innerHTML]="
                              lang === 'ar' ? item.answerAr : item.answerEn
                            ">
                                                </span>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                                <!-- <h6 class="pt-5">{{ "Footer.AboutEnd" | translate }}</h6> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>