import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { Quote } from "src/app/models/quote/Quote";
import { PreviewQuoteResponse } from "src/app/payload/responses/quotes/PreviewQuoteResponse";
import { QuotesListResponse } from "src/app/payload/responses/quotes/QuotesListResponse";
import { AppService } from "src/app/services/app/app.service";
import { ErrorService } from "src/app/services/app/error.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { InsuranceStepsService } from "src/app/services/insurance-steps/insurance-steps.service";
import { ComparisonQuoteService } from "src/app/services/quote/comparison-quote.service";
import { QuoteService } from "src/app/services/quote/quote.service";
import { Benefit } from "../../../models/quote/Benefit";
import { Product } from "../../../models/quote/Product";
import { ErrorPageTypes } from "./../../../models/app/ErrorPageTypes";
import { StatusCodes } from "./../../../models/app/StatusCodes";
import { InsuranceStepsApiService } from "./../../../services/insurance-steps/insurance-steps-api.service";
import AppUtils from "src/app/helpers/utils/AppUtils";
@Component({
  selector: "app-quotes-page",
  templateUrl: "./quotes-page.component.html",
  styleUrls: ["./quotes-page.component.css"],
})
export class QuotesPageComponent implements OnInit, OnDestroy {
  @ViewChild("expiryModal", { static: true }) private expiryModal;

  /* DATA */
  @Input() quote: Quote;
  comparisonProductsBenefits = [];
  comparisonProducts: Product[] = [];
  @Input() quoteProduct?: Product;
  additionalProductBenefits: Benefit[] = [];
  urlQuoteId: any | undefined;
  stepsData;
  lang: string;
  termSearchFn = AppUtils.searchItemTerm;

  /* UI */
  appLang;
  isOnMidScreen;
  isLoadingQuotes;
  isComparisonBarOpen = false;
  isComparisonTableOpen = false;
  isQuotesExpiryModalDisplayed = false;
  hasLoadingQuotesFailed = false;
  @Input() isDataAccordionShown: boolean;
  submitted: boolean = false;
  constructor(
    private insuranceStepServiceApi: InsuranceStepsApiService,
    private insuranceStepsService: InsuranceStepsService,
    private comparisonQuoteService: ComparisonQuoteService,
    private errorService: ErrorService,
    private quoteService: QuoteService,
    private authService: AuthService,
    private appService: AppService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getScreenSize();
    // this.watchQuotesExpiry();
    this.watchComparisonQuotes();
    this.appService.getAppLang().subscribe((lang) => (this.appLang = lang));
    // this.getQuoteUrlId();
  }

  ngOnDestroy() {
    this.clearComparisonList();
  }

  // // Select first deductible (smallest value)
  //Selectfirstdeductible = this.quoteProduct.selectedDeductible = this.quoteProduct.deductibles[0];

  /* -------------------------------------------------------------------------- */
  /*                                  LISTENERS                                 */
  /* -------------------------------------------------------------------------- */

  // Listen for window size changes
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?): void {
    // If browser window is resized below mid screen size width
    window.innerWidth <= 858
      ? (this.isOnMidScreen = true)
      : (this.isOnMidScreen = false);
  }

  private watchComparisonQuotes(): void {
    this.comparisonQuoteService.getComparisonProducts().subscribe((product) => {
      this.comparisonProducts = product;
      if (this.comparisonProducts.length > 0) {
        this.isComparisonBarOpen = true;
      } else {
        this.isComparisonBarOpen = false;
      }
    });

    this.comparisonQuoteService
      .getComparisonProductsBenefits()
      .subscribe(() => {
        this.comparisonProductsBenefits =
          this.comparisonQuoteService.getUniqueProductsBenefitsList();
      });
  }

  private watchQuotesExpiry(): void {
    this.quoteService.getExpiredQuotesState().subscribe((expiryState) => {
      if (expiryState.hasAllQuotesExpired) {
        this.modalService.open(this.expiryModal, { centered: true });
      }
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                               USER INTERFACE                               */
  /* -------------------------------------------------------------------------- */

  removeComparisonProduct(product: Product): void {
    product.isUnderComparison = false;
    this.comparisonQuoteService.removeComparisonProduct(product);
  }

  showComparisonTable(comparisonPopover): void {
    // If there are less comparison quotes than the allowed minimum number on the list
    if (
      this.comparisonQuoteService.getComparisonProducts().value.length <
      ComparisonQuoteService.MIN_COMPARISON_PRODUCTS
    ) {
      // Open the alerting popover
      if (comparisonPopover.isOpen()) {
        comparisonPopover.close();
      } else {
        comparisonPopover.open();
      }
    } else {
      this.isComparisonBarOpen = false;
      this.isComparisonTableOpen = true;
    }
  }

  closeComparisonTable(): void {
    this.isComparisonTableOpen = false;
    this.isComparisonBarOpen = true;
  }

  toggleComparisonTable(): void {
    this.isComparisonTableOpen = !this.isComparisonTableOpen;
  }

  clearComparisonList(): void {
    this.comparisonQuoteService.clearComparisonProducts();
  }

  toggleProductBenefit(product: Product, benefit: Benefit): void {
    if (!benefit.isChecked) {
      // Add the benefit amount to the current total premium
      product.selectedDeductible.policyPremium += benefit.benefitAmount;
      // Add the benefit amount to the previous total premium
      product.previousTotalPremium += benefit.benefitAmount;
      let vatPremiumBreakdown =
        product.selectedDeductible.premiumBreakdowns.filter(
          (pmd) => pmd.breakdownTypeId === 5
        )[0];
      if (vatPremiumBreakdown) {
        vatPremiumBreakdown.breakdownAmount += benefit.benefitVATAmount;
        product.selectedDeductible.policyPremium += benefit.benefitVATAmount;
      }
    } else {
      // Subtract the benefit amount from the current total premium
      product.selectedDeductible.policyPremium -= benefit.benefitAmount;
      // Subtract the benefit amount from the previous total premium
      product.previousTotalPremium -= benefit.benefitAmount;

      // Subtract the benefit VAT amount from the VAT premium breakdown (breakdown with id = 5)
      let vatPremiumBreakdown =
        product.selectedDeductible.premiumBreakdowns.filter(
          (pmd) => pmd.breakdownTypeId === 5
        )[0];
      if (vatPremiumBreakdown) {
        vatPremiumBreakdown.breakdownAmount -= benefit.benefitVATAmount;
        product.selectedDeductible.policyPremium -= benefit.benefitVATAmount;
      }
    }

    var index = this.additionalProductBenefits.indexOf(benefit);

    if (index >= 0) {
      this.additionalProductBenefits.splice(index, 1);
    } else {
      this.additionalProductBenefits.push(benefit);
    }

    this.toggleBenefitCheck(product, benefit);
  }

  // check with muizz
  toggleBenefitCheck(product: Product, benefit: Benefit): void {
    const productBenefits = product.benefits;
    const benefitIdx = productBenefits.indexOf(benefit);
    productBenefits[benefitIdx].isChecked =
      !productBenefits[benefitIdx].isChecked;
  }

  navigateToLanding() {
    // Close modal
    this.modalService.dismissAll();
    // Navigate to landing page
    this.router.navigate([AppRoutes.landing]);
  }

  /* -------------------------------------------------------------------------- */
  /*                                API / SUBMIT                                */
  /* -------------------------------------------------------------------------- */

  orderQuoteProduct(product: Product): void {
    // Get the list of all quotes
    const quotes = this.quoteService.getQuotes().value;

    // Find the product's quote according to its insurance carrier from the quotes list
    const productQuote = quotes.filter(
      (q) => q.insuranceCompany === product.insuranceCarrier
    )[0];

    // Construct a quote which has only the selected product
    const quote = Object.assign({}, productQuote);
    quote.products = [JSON.parse(JSON.stringify(product))];

    // Display loader for selected product from table
    product.isSelected = true;

    /* Extract free benefit */

    // Get benefits which are free
    const freeBenefits = product.benefits.filter(
      (benefit) => benefit.benefitTypeId === 3
    );

    this.additionalProductBenefits.forEach(
      (beneift) => delete beneift.isChecked
    );
    this.additionalProductBenefits.push(freeBenefits[0]);
    quote.benefits = this.additionalProductBenefits;

    // Check if user is logged in
    if (this.authService.getIsLoggedIn().value) {
      // Send the quote select (preview) request
      this.quoteService.setSelectedQuote(quote).subscribe(
        (res: PreviewQuoteResponse) => {
          // Hide loader for product
          product.isSelected = false;

          // Store quote preview response
          localStorage.setItem(
            LocallyStoredItemsKeys.PreviewQuoteResponse,
            JSON.stringify(res)
          );

          // Delete the stored constructed quote preview request
          localStorage.removeItem(LocallyStoredItemsKeys.PreviewQuoteRequest);

          // Increment step number
          //this.insuranceStepService.setCurrentStepNum(7);

          // Close the modal
          this.authService.setIsAuthModalShown(false);

          // Route to the checkout page
          this.router.navigate([AppRoutes.checkout]);
        },
        (err) => {
          // Hide loader for product
          product.isSelected = false;
          this.router.navigate([AppRoutes.error]);
        }
      );
    } else {
      // Stop the comparison
      this.isComparisonBarOpen = false;
      this.isComparisonTableOpen = false;
      this.comparisonQuoteService.clearComparisonProducts();

      // Hide loader for product
      product.isSelected = false;

      // Show the login modal
      this.authService.setIsAuthModalShown(true);
    }
  }

  /**
   * Resends the quotation / quotes fetching request
   *
   * @memberof QuotesPageComponent
   */
  reloadQuotations(): void {
    // Close the quotation reloading dialog modal
    this.modalService.dismissAll();

    // Display loader
    this.isLoadingQuotes = true;

    // Resend the quotations request to reload quotations
    this.insuranceStepServiceApi.getQuotationList().subscribe(
      (res: QuotesListResponse) => {
        // Conceal loader
        this.isLoadingQuotes = false;
        // Check if insurance companies didn't respond with quotations
        if (
          !res.isSuccess &&
          res.statusCode === StatusCodes.QuotationsUnavailable
        ) {
          // Navigate to unavailable quotations error page
          this.errorService.setActiveErrorPageType(
            ErrorPageTypes.QuotationsUnavailable
          );
          this.router.navigate([AppRoutes.error]);
        }
      },
      (err) => {
        // Conceal loader
        this.isLoadingQuotes = false;
        // Navigate to unavailable quotations error page
        this.errorService.setActiveErrorPageType(
          ErrorPageTypes.QuotationsUnavailable
        );
        this.router.navigate([AppRoutes.error]);
      }
    );
  }

  toggleDataAccordion() {
    this.isDataAccordionShown = !this.isDataAccordionShown;
  }
  getQuoteUrlId() {
    this.urlQuoteId = this.activatedRoute.snapshot.params["id"];
    this.insuranceStepServiceApi.getQouteUrlDetails(this.urlQuoteId).subscribe(
      (res: QuotesListResponse) => {
        localStorage.setItem(
          LocallyStoredItemsKeys.UserQuoteResponse,
          JSON.stringify(res)
        );
        this.router.navigate([AppRoutes.Routes.quotes]);
        // Store the retreived quotes on local storage
        localStorage.setItem(
          LocallyStoredItemsKeys.Quotes,
          JSON.stringify(res.quotes)
        );
        this.quoteService.setQuotes(res.quotes);
      },
      (error) => {
        // Conceal loader
        this.isLoadingQuotes = false;
        // Navigate to unavailable quotations error page
        this.errorService.setActiveErrorPageType(
          ErrorPageTypes.QuotationsUnavailable
        );
        this.router.navigate([AppRoutes.error]);
      }
    );
  }
}
