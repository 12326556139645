import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { filter } from "rxjs/operators";
import { LoadingPageTypes } from "src/app/models/app/LoadingPageTypes";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { StatusCodes } from "src/app/models/app/StatusCodes";
import { Driver } from "src/app/models/insurance-steps/Driver";
import { ManagedForms } from "src/app/models/insurance-steps/ManagedFormsTypes";
import { ApiResponse } from "src/app/payload/responses/ApiResponse";
import { AppService } from "src/app/services/app/app.service";
import { InsuranceStepsApiService } from "src/app/services/insurance-steps/insurance-steps-api.service";
import { InsuranceStepsService } from "src/app/services/insurance-steps/insurance-steps.service";
import { ErrorService } from "./../../../services/app/error.service";
import { FormValidationServiceService } from "src/app/services/insurance-steps/form-validation-service.service";
import { MessagesService } from "src/app/services/messages.service";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { DriversService } from "src/app/services/insurance-steps/drivers.service";
import { ErrorPageTypes } from "src/app/models/app/ErrorPageTypes";
import { ProfileService } from "src/app/services/profile/profile.service";
import { Identity, IdentityResponse } from "src/app/models/profile/Identity";
import AppUtils from "src/app/helpers/utils/AppUtils";
@Component({
  selector: "app-quotation-request-page",
  templateUrl: "./quotation-request-page.component.html",
  styleUrls: ["./quotation-request-page.component.css"],
  animations: [
    trigger("step", [
      state(
        "in",
        style({
          opacity: 1,
          transform: "translateX(0)",
        })
      ),
      transition("void => *", [
        style({
          opacity: 0,
          transform: "translateX(-100px)",
        }),
        animate(500),
      ]),
    ]),
  ],
})
export class QuotationRequestPageComponent implements OnInit, OnDestroy {
  /* Data */
  userQuotationDrivers: Driver[] = [];
  isConfirmingIdentity: boolean;
  ManagedForms = ManagedForms;
  validationErrors: string[];
  currentDrivers: Driver[];
  identities: Identity[];
  yakeenMissingData: any;
  errorMessage: string;
  nextRoute: string;
  lang: string;

  /* UI */
  inCompleteDrivingPercentage = false;
  showPercentageAlert = false;
  isErrorAlertVisible = false;
  isLoadingQuotes = false;
  isOnMidScreen: boolean;
  isNewVechile: boolean;
  requestFromProfile: any = false;

  isValidBtn: boolean = false;
  /* Modal */
  @ViewChild("yakeenMissingDataModal") yakeenMissingDataModal: ElementRef;
  @ViewChild("continuationModal") continuationModal: ElementRef;

  constructor(
    private insuranceStepServiceApi: InsuranceStepsApiService,
    private insuranceStepsService: InsuranceStepsService,
    private modalService: NgbModal,
    private appService: AppService,
    private router: Router,
    private messager: MessagesService,
    private formvalidationService: FormValidationServiceService,
    private driversService: DriversService,
    private errorService: ErrorService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    // remove all additional driver if exist
    this.driversService.removeAllDriver();
    this.getQuoteFromProfileValue();
    this.getScreenSize();
    this.insuranceStepServiceApi.getStepsData();
    this.whatchRouteNavigation();
    this.getIsNewVechile();
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
    // this.getIdentities();
    setTimeout(() => {
      this.isValidBtn = true;
    }, 5000);
  }

  /* -------------------------------------------------------------------------- */
  /*                                  LISTENERS                                 */
  /* -------------------------------------------------------------------------- */

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?): void {
    // If browser window is resized below mid screen size width
    window.innerWidth >= 1000
      ? (this.isOnMidScreen = true)
      : (this.isOnMidScreen = false);
  }

  whatchRouteNavigation() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.nextRoute = event.url.replace("/", "");
      });
  }

  /**
   * Check form validity status
   *
   * @private
   * @memberof QuotationRequestPageComponent
   */

  async watchFormsBeforeSendRequest(action: string) {
    // let formsStatus = await this.insuranceStepsService.isValidFormManager();
    let formsStatus: [{ formName: string; isValid: boolean }] = [
      { formName: "", isValid: true },
    ];
    formsStatus.push(
      await this.insuranceStepsService.isMainDriverInsuranceFormValid()
    );
    formsStatus.push(
      await this.insuranceStepsService.isVehicleInsuranceFormValid()
    );
    if (this.requestFromProfile) {
      formsStatus.push(await this.insuranceStepsService.isLandingFormValid());
    }
    this.formvalidationService.setSubmitted(true);
    let valid = true;
    await formsStatus.forEach((formStatus, idx, array) => {
      if (formStatus.isValid === false) {
        valid = false;
        const firstElementWithError = document.querySelector(".ng-invalid");
        if (firstElementWithError)
          firstElementWithError.scrollIntoView({ behavior: "smooth" });
        return;
      }
      // check if we are in last iteration valid and then we send the request
      // if (idx === array.length - 1) {
      //   this.requestNewQuotation(action);
      // }
    });
    if (valid === true) {
      this.requestNewQuotation(action);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                               USER INTERFACE                               */
  /* -------------------------------------------------------------------------- */
  openContinuationModal(modal: any) {
    // Open the modal
    this.modalService.open(modal, {
      size: "md",
      centered: true,
      backdrop: "static",
      beforeDismiss: () => {
        // Close the modal
        return true;
      },
    });
  }

  requestNewQuotation(action: string) {
    this.sendQuotesRequest(action);

    if (action === "Continue" || action === "Previous") {
      this.modalService.dismissAll();
    }
  }

  navigateSavedQuote() {
    this.modalService.dismissAll();
    this.router.navigateByUrl("/user/previous-quotes");
  }

  async scrollToTarget(elId): Promise<any> {
    await this.delay(100);
    const el = document.getElementById(elId);
    if (el == null) {
      return;
    }
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = el.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top - 170;
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  }

  /**
   * Displays an error alert with the retreived error message(s) from an API response
   * @private
   * @param apiResponse The error response that has been received from the API
   * @memberof QuotationRequestPageComponent
   */
  private displayErrorAlert(apiResponse: ApiResponse): void {
    // Set error message
    this.errorMessage = apiResponse.responseMessage;

    // Set validation errors
    if (
      apiResponse.validationErrors &&
      apiResponse.validationErrors.length > 0
    ) {
      // Init empty array
      let errorsArr: string[] = [];

      // Push the errors into the array
      apiResponse.validationErrors.forEach((err) =>
        errorsArr.push(err.description)
      );

      // Set the validation errors
      this.validationErrors = errorsArr;
    } else {
      this.validationErrors = null;
    }

    // Display alert
    // this.isErrorAlertVisible = true;
  }

  /* -------------------------------------------------------------------------- */
  /*                                API / SUBMIT                                */
  /* -------------------------------------------------------------------------- */

  sendQuotesRequest(action?: any): void {
    // this.openContinuationModal(this.continuationModal);

    // close confilrmation email component
    this.isConfirmingIdentity = false;
    // Set active loading type to quotation request/retrieval
    this.appService.setActiveLoadingPageType(LoadingPageTypes.QuotationLoading);

    // Display loading
    this.isLoadingQuotes = true;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Fetch the quotes list
    this.insuranceStepServiceApi.getQuotationList(action).subscribe(
      (res: any) => {
        // Set active loading type to undefine to iniazlize the
        this.appService.setActiveLoadingPageType(undefined);
        // Stop loading
        this.isLoadingQuotes = false;

        // remove quotation request data from local storage
        localStorage.removeItem(
          LocallyStoredItemsKeys.InsuranceCurrentStepData
        );
        localStorage.removeItem(LocallyStoredItemsKeys.UserQuoteRequest);
      },
      (err) => {
        this.appService.setActiveLoadingPageType(undefined);
        // Stop loading
        this.isLoadingQuotes = false;
        if (err.error.statusCode === StatusCodes.QuotatationSavedBefore) {
          this.openContinuationModal(this.continuationModal);
        } else if (
          err.error.statusCode === StatusCodes.DriverNotFound ||
          err.error.statusCode === StatusCodes.AdditionalDriverNotFound ||
          err.error.statusCode === StatusCodes.QueryAccindentFaild ||
          err.error.statusCode === StatusCodes.QueryAdressFaild ||
          err.error.statusCode === StatusCodes.AvoidMultiClientRegistration ||
          err.error.statusCode === StatusCodes.InvalidInputValidation ||
          err.error.statusCode === StatusCodes.YakeenVehicleHaveSequecaseNumber
        ) {
          // Scroll to the top of the page
          this.scrollToTarget("errorAlert");
          this.displayErrorAlert(err.error);
          this.isErrorAlertVisible = true;
          setTimeout(() => (this.isErrorAlertVisible = false), 10000);
          // localStorage.setItem("QuoteFromProfile", "true");
          localStorage.setItem("inputrequestFromProfile", "false");
          this.router.navigate([AppRoutes.insuranceSteps]);
        } else if (err.error.statusCode === StatusCodes.YakeenMissisngData) {
          this.isLoadingQuotes = false;
          this.yakeenMissingData = err.error;
          this.openYakeenMissingDataModal(this.yakeenMissingDataModal);
        } else if (err.error.statusCode === StatusCodes.QuotationsUnavailable) {
          // Navigate to unavailable quotations error page
          this.errorService.setActiveErrorPageType(
            ErrorPageTypes.QuotationsUnavailable
          );
          this.router.navigate([AppRoutes.error]);
        } else {
          let message =
            this.lang === "en"
              ? "Oops, something went wrong, Pleease try again later."
              : "عفوًا، حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا";
          let title = this.lang === "en" ? "Error" : "خطأ";
          this.messager.msgLoadingDataError(title, message);
          // localStorage.setItem("QuoteFromProfile", "true");
          localStorage.setItem("inputrequestFromProfile", "false");
          this.router.navigate([AppRoutes.insuranceSteps]);
          return;
          // this.router.navigate([AppRoutes.error]);
        }
      }
    );
  }

  openYakeenMissingDataModal(yakeenMissingDataModal: any) {
    // Open the modal
    this.modalService.open(yakeenMissingDataModal, {
      size: "md",
      centered: true,
      animation: true,
      backdrop: "static",
      beforeDismiss: () => {
        // Close the modal
        return true;
      },
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  ngOnDestroy() {
    /*  if(this.nextRoute !== AppRoutes.quotes) {
      this.formsManager.clear();
      this.formsManager.unsubscribe();
     } */
  }
  getQuoteFromProfileValue() {
    if (this.requestFromProfile) {
      localStorage.setItem("QuoteFromProfile", "true");
    }
    this.requestFromProfile = localStorage.getItem("QuoteFromProfile");
  }

  getIsNewVechile() {
    this.insuranceStepsService.getIsNewVechile().subscribe((isNewVechile) => {
      this.isNewVechile = isNewVechile;
    });
  }

  // getIdentities() {
  //   this.profileService.getIdentities().subscribe((res: IdentityResponse) => {
  //     this.identities = res.data;
  //   });
  // }
}
