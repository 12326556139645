import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { InsuredInfo } from "src/app/models/quote/InsuredInfo";
import { VehicleInfo } from "src/app/models/quote/VehicleInfo";
import { AppService } from "src/app/services/app/app.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-quotes-order-data",
  templateUrl: "./quotes-order-data.component.html",
  styleUrls: ["./quotes-order-data.component.css"],
})
export class QuotesOrderDataComponent implements OnInit {
  insuredInfo: InsuredInfo;
  vehicleInfo: VehicleInfo;
  vehicleLogoSrc: string;

  @Input() isDataAccordionShown: boolean;
  lang;

  constructor(private appService: AppService) {}

  ngOnInit() {
    this.appService
      .getAppLang()
      .subscribe((appLanguage) => (this.lang = appLanguage));
    this.getUserOrderData();
  }

  getUserOrderData() {
    const userQuotesResponse: any = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.UserQuoteResponse)
    );
    this.insuredInfo = userQuotesResponse.insuredInfo;
    this.vehicleInfo = userQuotesResponse.vehicleInfo;

    // Construct vehicle logo image url
    this.vehicleLogoSrc = environment.apiAssetsUrl + this.vehicleInfo.makerLogo;
  }
}
