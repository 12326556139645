import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DirectAccessGuard } from "src/app/helpers/guards/direct-access.guard";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { CheckoutSuccessComponent } from "./checkout-success/checkout-success.component";
import { OrderReviewComponent } from "./order-review/order-review.component";
import { PaymentStatusComponent } from "./payment-status/payment-status.component";
import { VehicleImagesComponent } from "./vehicle-images/vehicle-images.component";
import { DirectpayComponent } from "./directpay/directpay.component";
import { ComplateCheckoutComponent } from "./complate-checkout/complate-checkout.component";

const Checkout_ROUTES: Routes = [
  {
    path: "",
    canActivate: [DirectAccessGuard],
    component: OrderReviewComponent,
  },
  { path: AppRoutes.Routes.uploadImage, component: VehicleImagesComponent },
  {
    path: AppRoutes.Routes.paymentStatus,
    canActivate: [DirectAccessGuard],
    component: PaymentStatusComponent,
  },
  {
    path: AppRoutes.Routes.checkoutSuccess,
    component: CheckoutSuccessComponent,
  },
  // {
  //   path: AppRoutes.Routes.diractpay,
  //   component: DirectpayComponent,
  // },
  {
    path: AppRoutes.Routes.ComplateCheckout,
    component: ComplateCheckoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(Checkout_ROUTES)],
  exports: [RouterModule],
})
export class CheckoutRoutingModule {}
