import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { ErrorPageTypes } from "src/app/models/app/ErrorPageTypes";
import { QuoteService } from "src/app/services/quote/quote.service";
import { Quote } from "../../../models/quote/Quote";
import { ErrorService } from "./../../../services/app/error.service";

@Component({
  selector: "app-quotes-list",
  templateUrl: "./quotes-list.component.html",
  styleUrls: ["./quotes-list.component.css"],
})
export class QuotesListComponent implements OnInit {
  quotes: Quote[] = [];
  isLoadingQuotes: boolean;
  displayedProductType: number = 0;

  constructor(
    private router: Router,
    private errorService: ErrorService,
    private quoteService: QuoteService
  ) {}

  ngOnInit() {
    this.getQuotes();
    this.watchQuoteFiltering();
  }

  getQuotes() {
    // Get quotes list from local storage
    this.quoteService.getQuotes().subscribe((quotes) => {
      this.isLoadingQuotes = true;
      setTimeout(() => {
        this.isLoadingQuotes = false;
      }, 2000);
      this.quotes = quotes;
      // If there were no quotations
      if (quotes.length === 0) {
        // Navigate to unavailable quotations error page
        this.errorService.setActiveErrorPageType(
          ErrorPageTypes.QuotationsUnavailable
        );
        this.router.navigate([AppRoutes.error]);
      }
    });
  }

  watchQuoteFiltering() {
    this.quoteService.displayedProductType.subscribe((productType) => {
      this.isLoadingQuotes = true;
      setTimeout(() => {
        this.isLoadingQuotes = false;
      }, 2000);
      this.displayedProductType = productType;
    });
  }
}
