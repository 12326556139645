import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { NgFormsManager } from "@ngneat/forms-manager";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
} from "@ngneat/reactive-forms";
import AppUtils from "src/app/helpers/utils/AppUtils";
import { Scroll } from "src/app/helpers/utils/scroll";
import {
  ManagedForms,
  ManagedFormsTypes,
} from "src/app/models/insurance-steps/ManagedFormsTypes";
import { AppService } from "src/app/services/app/app.service";
import { InsuranceStepsService } from "src/app/services/insurance-steps/insurance-steps.service";
import { FormValidationServiceService } from "src/app/services/insurance-steps/form-validation-service.service";

@Component({
  selector: "app-vehicle-info",
  templateUrl: "./vehicle-info.component.html",
  styleUrls: [
    "../quotation-request-page/quotation-request-page.component.css",
    "./vehicle-info.component.css",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleInfoComponent implements OnInit, DoCheck, OnDestroy {
  estimatedVehicleValueMessage: string;
  private validationMesaage = {
    required: "هذا الحقل مطلوب",
    max: "اعلى مبلغ مسموح به هو 500.000",
  };

  /* DATA */
  stepsData;
  stepNumber;
  pass: any;
  choose: any;
  vehicleSpecification: string = "";
  /* UI */
  vehicleUpdated: boolean = true;
  submitted: boolean = false;
  Vehiclepecifications = false;
  lang: string;
  public isCollapsed = true;

  vehicleData = this.insuranceSteps.getSelectVehicle().value.Vehicle;
  termSearchFn = AppUtils.searchItemTerm;
  requestFromProfile: any = true;
  QuoteFromProfileWithIdentity: any;
  /* Form */
  vehicleInfoFormGroup: FormGroup<ManagedFormsTypes["VehicleInsuranceForm"]>;

  constructor(
    private insuranceStepService: InsuranceStepsService,
    private fb: FormBuilder,
    private formsManager: NgFormsManager<ManagedFormsTypes>,
    private scrollOnErrer: Scroll,
    private appService: AppService,
    private insuranceSteps: InsuranceStepsService,
    private formvalidationService: FormValidationServiceService,
    private ref: ChangeDetectorRef
  ) {
    debugger;
  }

  ngDoCheck(): void {
    if (this.submitted == true) {
      // this.watchFormAfterSubmit()
      this.vehicleInfoFormGroup.markAllAsTouched();
    }
  }
  ngOnInit() {
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
      this.pass = this.lang === "en" ? "Ignore" : "تجاوز";
      this.choose = this.lang === "en" ? "Choose" : "إختيار";
    });
    this.getStepData();
    this.initForm();
    this.getPreviousQuotationData();
    this.changeInputFromProfile();
    //TODO:
    this.watchFormAfterSubmit();
    //testt
  }

  initForm(): void {
    this.vehicleInfoFormGroup = this.fb.group({
      vehicleSumInsured: [
        null,
        [
          Validators.required,
          Validators.pattern("^[0-9,]*$"),
          Validators.max(500000),
        ],
      ],
      vehicleUseId: [1, [Validators.required]],
      vehicleTransmissionTypeId: [2, [Validators.required]],
      vehicleParkingLocationId: [2, [Validators.required]],
      isVehicleModified: [false],
      vehicleRepairMethodId: [1, [Validators.required]],
      vehicleModificationDetails: [null],
      vehicleSpecifications: [this.vehicleSpecification],
    });
    const vehicleSumInsured =
      this.vehicleInfoFormGroup.get("vehicleSumInsured");
    vehicleSumInsured.valueChanges.subscribe((value) => {
      this.sendMessage(vehicleSumInsured);
    });
    // Add the form to the managed forms
    this.formsManager.upsert(
      ManagedForms.VehicleInsuranceForm,
      this.vehicleInfoFormGroup,
      { persistState: true }
    );
  }
  sendMessage(c: AbstractControl): void {
    this.estimatedVehicleValueMessage = "";
    if ((c.touched || c.dirty) && c.errors) {
      this.estimatedVehicleValueMessage = Object.keys(c.errors)
        .map(
          (key) =>
            (this.estimatedVehicleValueMessage += this.validationMesaage[key])
        )
        .join("");
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.vehicleInfoFormGroup.controls;
  }

  submitForm() {
    this.submitted = true;
    if (this.vehicleInfoFormGroup.invalid) {
      this.vehicleInfoFormGroup.markAsTouched();
      const firstElementWithError = document.querySelector(".ng-invalid");
      //this.scrollOnErrer.scrollTo(firstElementWithError);
      return;
    }
  }

  addVehicleSpecification(id: number) {
    this.vehicleSpecification = AppUtils.addQuoteCheckBoxs(
      id,
      this.vehicleSpecification
    );
    this.f.vehicleSpecifications.setValue(this.vehicleSpecification);
  }

  getStepData() {
    this.insuranceStepService.getInsuranceStepsData().subscribe((stepsData) => {
      this.stepsData = stepsData;
    });
  }

  vehicleIsUpdated() {
    this.vehicleUpdated = !this.vehicleUpdated;
    this.vehicleInfoFormGroup.get("vehicleModificationDetails").reset();
    if (!this.vehicleUpdated) {
      this.f.vehicleModificationDetails.setValidators([Validators.required]);
      this.f.vehicleModificationDetails.updateValueAndValidity();
    } else {
      this.f.vehicleModificationDetails.clearValidators();
      this.f.vehicleModificationDetails.updateValueAndValidity();
    }
  }

  showVehicleSpecifications() {
    this.Vehiclepecifications = !this.Vehiclepecifications;
    if (!this.vehicleSpecification) {
      this.f.vehicleSpecifications.setValue("");
    }
  }

  restForm() {
    this.vehicleInfoFormGroup.reset();
  }

  /* for landing form */
  getPreviousQuotationData() {
    const fromProfile = localStorage.getItem("QuoteFromProfile");
    if (fromProfile) {
      if (this.vehicleData != null || this.vehicleData !== undefined) {
        this.f.vehicleSumInsured.setValue(this.vehicleData.vehicleSumInsured);
        if (this.vehicleData.vehicleUseId != null) {
          this.f.vehicleUseId.setValue(this.vehicleData.vehicleUseId);
        } else {
          this.f.vehicleUseId.setValue(1);
        }
        if (
          this.vehicleData.isVehicleModified === true ||
          this.vehicleData.isVehicleModified === false
        ) {
          if (this.vehicleData.isVehicleModified != null) {
            this.f.isVehicleModified.setValue(
              this.vehicleData.isVehicleModified
            );
          }
        } else this.f.isVehicleModified.setValue(false);
        if (this.vehicleData.vehicleParkingLocationId != null) {
          this.f.vehicleParkingLocationId.setValue(
            this.vehicleData.vehicleParkingLocationId
          );
        } else {
          this.f.vehicleParkingLocationId.setValue(2);
        }
        if (this.vehicleData.vehicleTransmissionTypeId != null) {
          this.f.vehicleTransmissionTypeId.setValue(
            this.vehicleData.vehicleTransmissionTypeId
          );
        } else {
          this.f.vehicleTransmissionTypeId.setValue(2);
        }
        if (this.vehicleData.vehicleRepairMethodId != null) {
          this.f.vehicleRepairMethodId.setValue(
            this.vehicleData.vehicleRepairMethodId
          );
        } else {
          this.f.vehicleRepairMethodId.setValue(1);
        }
        if (this.vehicleData.vehicleSpecifications != null) {
          this.f.vehicleSpecifications.setValue(
            this.vehicleData.vehicleSpecifications
          );
        } else {
          this.f.vehicleSpecifications.setValue(this.vehicleSpecification);
        }
        if (this.vehicleData.vehicleSumInsured != null) {
          this.f.vehicleSumInsured.setValue(this.vehicleData.vehicleSumInsured);
        }
      }
    }
  }
  changeInputFromProfile() {
    this.requestFromProfile = localStorage.getItem("QuoteFromProfile");
    this.requestFromProfile = !this.requestFromProfile;
  }

  watchFormAfterSubmit() {
    this.formvalidationService.submitForm.subscribe((e) => {
      this.submitted = e;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.vehicleInfoFormGroup.reset();
    this.formvalidationService.setSubmitted(false);
  }
}
