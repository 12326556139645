import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { NgFormsManager } from "@ngneat/forms-manager";
import { FormBuilder, FormGroup } from "@ngneat/reactive-forms";
import AppUtils from "src/app/helpers/utils/AppUtils";
import DropDownValues from "src/app/helpers/utils/DropDownValues";
import { Scroll } from "src/app/helpers/utils/scroll";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import {
  ManagedForms,
  ManagedFormsTypes,
} from "src/app/models/insurance-steps/ManagedFormsTypes";
import { Identity, IdentityResponse } from "src/app/models/profile/Identity";
import { AppService } from "src/app/services/app/app.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { FormValidationServiceService } from "src/app/services/insurance-steps/form-validation-service.service";
import { InsuranceStepsService } from "src/app/services/insurance-steps/insurance-steps.service";
import { ProfileService } from "src/app/services/profile/profile.service";

@Component({
  selector: "app-driver-info",
  templateUrl: "./driver-info.component.html",
  styleUrls: [
    "./driver-info.component.css",
    "../../quotation-request-page/quotation-request-page.component.css",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverInfoComponent implements OnInit, DoCheck, OnDestroy {
  /* Form */
  driverInfoForm: FormGroup<ManagedFormsTypes["MainDriverInsuranceForm"]>;
  /* UI */
  termSearchFn = AppUtils.searchItemTerm;
  showHijriDate: boolean = false;
  showgregoianDate: boolean = false;
  requestFromProfile: any;
  identities: Identity[];

  /* DATA */
  setbirthDate;
  stepsData: any;
  submitted: boolean = false;
  fromZeroToTen = DropDownValues.fromZeroToTen;
  lang: string;
  now = new Date();
  minDate;
  maxDate;
  isLogin = this.authService.getIsLoggedIn().value;
  constructor(
    private insuranceSteps: InsuranceStepsService,
    private fb: FormBuilder,
    private appService: AppService,
    private formsManager: NgFormsManager<ManagedFormsTypes>,
    private cd: ChangeDetectorRef,
    private formvalidationService: FormValidationServiceService,
    private scrollOnErrer: Scroll,
    private profileService: ProfileService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.authService.getIsLoggedIn().value) this.getIdentities();
    let data = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.FormManager)
    );
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
    this.setMaxDate();
    this.setMinDate();
    this.changeInputFromProfile();
    this.watchIdentityNoToshowDate();
    this.setBirthDateToLocalStorage();
    this.getPreviousBirthDate();

    this.getPreviousQuotationData();
    this.watchFormAfterSubmit();
  }
  // ngOnChanges() {
  //   this.cd.detectCh`anges();
  //   this.setBirthDateToLocalStorage()

  //   this.getPreviousBirthDate();

  // }

  ngDoCheck(): void {
    if (this.submitted === true) {
      this.driverInfoForm.markAllAsTouched();
    }

    this.watchFormAfterSubmit();
  }

  initForm() {
    this.driverInfoForm = this.fb.group({
      typeId: [1],
      identityNumber: [null],
      driverId: [1],
      // if showGorgian is true set birthdate to birtDateG
      birthDateG: [null, [Validators.required]],
      birthDateH: [null, [Validators.required]],
      educationLevelId: [4, [Validators.required]],
      drivingPercentageId: [25, [Validators.required]],
      childrenUnder16Years: [3, [Validators.required]],
      policyEffectiveDate: [null, [Validators.required]],
      occupationId: [3, [Validators.required]],
    });
    this.getStepData();
    this.formsManager.upsert(
      ManagedForms.MainDriverInsuranceForm,
      this.driverInfoForm,
      { persistState: true }
    );
  }

  get f() {
    return this.driverInfoForm.controls;
  }

  setMinDate() {
    let minDate = AppUtils.addDays(this.now, 1);
    this.minDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate(),
    };
  }

  setMaxDate() {
    let maxDate = AppUtils.addDays(this.now, 30);
    this.maxDate = {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate(),
    };
  }

  // check() {
  //   const invalid = [];
  //   let _t = this;
  //   for (let [key, val] of Object.entries(_t.f)) {
  //     type ObjectKey = keyof typeof _t.f;
  //     const con = key as ObjectKey;
  //     if (_t.f[con]?.invalid) {
  //       invalid.push(con);
  //     }
  //   }
  // }

  submitForm() {
    this.submitted = true;
    if (this.driverInfoForm.invalid) {
      this.driverInfoForm.markAsTouched();
      const firstElementWithError = document.querySelector(
        ".ng-invalid[formControlName]"
      );
      this.scrollOnErrer.scrollTo(firstElementWithError);

      return;
    }
  }

  getStepData() {
    this.insuranceSteps.getInsuranceStepsData().subscribe((stepsData) => {
      this.stepsData = stepsData;
    });
  }
  /* -------------------------------------------------------------------------- */
  /*                               init Datepicker                              */
  /* -------------------------------------------------------------------------- */
  setBirthDateValue(date: any) {
    if (this.showgregoianDate)
      this.f.birthDateG.setValue(AppUtils.formatDate(date));
    if (this.showHijriDate)
      this.f.birthDateH.setValue(AppUtils.formatDate(date));
  }
  setBirthDateToLocalStorage() {
    let previousQuotationData = this.insuranceSteps.getSelectIdentity().value;

    if (
      this.f.identityNumber?.value !== null &&
      this.f.identityNumber?.value!?.toString()!.startsWith("2")
    ) {
      localStorage.setItem(
        "birthDate",
        //  this.identities[0].birthDate
        previousQuotationData?.Identity?.birthDate
      );
      this.f.birthDateH.disable();
      return;
    } else {
      localStorage.setItem(
        "birthDateH",
        // this.identities[0].birthDateH
        previousQuotationData?.Identity?.birthDateH
      );
      this.f.birthDateG.disable();
      return;
    }
  }

  getPreviousBirthDate(): string {
    this.requestFromProfile = localStorage.getItem("QuoteFromProfile");
    if (this.requestFromProfile) {
      if (this.f.identityNumber?.value!?.toString()!.startsWith("2")) {
        this.showgregoianDate = true;
        this.showHijriDate = false;

        this.f.birthDateG.setValidators([Validators.required]);
        this.f.birthDateG.updateValueAndValidity();
        this.f.birthDateH.clearValidators();
        return localStorage.getItem("birthDate");
      } else {
        this.showHijriDate = true;
        this.showgregoianDate = false;

        this.f.birthDateH.setValidators([Validators.required]);
        this.f.birthDateH.updateValueAndValidity();
        this.f.birthDateG.clearValidators();
        return localStorage.getItem("birthDateH");
      }
    } else {
      localStorage.removeItem("birthDateH");
      let formsData = JSON.parse(
        localStorage.getItem(LocallyStoredItemsKeys.FormManager)
      );
      // set the old value of birthdate after page refresh
      let birthDateG = formsData?.MainDriverInsuranceForm?.value?.birthDateG;
      let birthDate =
        birthDateG == null
          ? formsData.MainDriverInsuranceForm.value.birthDateH
          : formsData.MainDriverInsuranceForm.value.birthDateG;
      return birthDate;
    }
  }
  /* TODO:  */
  watchIdentityNoToshowDate() {
    let formsData = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.FormManager)
    );

    let previousQuotationData = this.insuranceSteps.getSelectIdentity().value;
    let insuredIdentityNumber: number =
      parseInt(formsData.LandingForm.value.insuredIdentityNumber) ||
      previousQuotationData.Identity.identityNumber;

    // let insuredIdentityNumber: number =
    //   previousQuotationData.Identity.identityNumber;

    if (insuredIdentityNumber) {
      this.f.identityNumber.setValue(insuredIdentityNumber);
      if (insuredIdentityNumber?.toString()!.startsWith("2")) {
        this.showgregoianDate = true;
        this.showHijriDate = false;

        this.f.birthDateG.setValidators([Validators.required]);
        this.f.birthDateG.updateValueAndValidity();
        this.f.birthDateH.clearValidators();
      } else {
        this.showHijriDate = true;
        this.showgregoianDate = false;

        this.f.birthDateH.setValidators([Validators.required]);
        this.f.birthDateH.updateValueAndValidity();
        this.f.birthDateG.clearValidators();
      }
    }
    // this.f.identityNumber.setValue(insuredIdentityNumber);
  }

  changeInputFromProfile() {
    this.requestFromProfile = localStorage.getItem("QuoteFromProfile");
    if (this.requestFromProfile) {
      this.requestFromProfile.toLowerCase();
    }
  }
  constructPreviousClientData(identity) {
    this.f.identityNumber = identity.identityNumber;
  }

  getPreviousQuotationData() {
    let previousQuotationData = this.insuranceSteps.getSelectIdentity().value;
    // Object.keys(previousQuotationData).length > 0
    const fromProfile = localStorage.getItem("QuoteFromProfile");
    if (fromProfile) {
      if (
        previousQuotationData.Identity != null ||
        previousQuotationData.Identity !== undefined
      ) {
        this.f.identityNumber.setValue(
          previousQuotationData.Identity.identityNumber
        );
        if (
          previousQuotationData?.Identity?.identityNumber
            ?.toString()
            .startsWith("2")
        ) {
          this.f.birthDateG.setValidators([Validators.required]);
          this.f.birthDateG.updateValueAndValidity();
          this.f.birthDateH.clearValidators();
        } else {
          this.f.birthDateH.setValidators([Validators.required]);
          this.f.birthDateH.updateValueAndValidity();
          this.f.birthDateG.clearValidators();
        }
        this.f.educationLevelId.setValue(
          previousQuotationData.Identity.educationLevelId
        );
        this.f.occupationId.setValue(
          previousQuotationData.Identity.occupationId
        );
        this.f.childrenUnder16Years.setValue(
          previousQuotationData.Identity.childrenUnder16Years
        );
        this.f?.drivingPercentageId.setValue(25);
        this.f.driverId.setValue(1);
        this.f.typeId.setValue(1);
      }
      this.requestFromProfile = true;
      this.f.policyEffectiveDate.setValidators(null);
      this.f.policyEffectiveDate.updateValueAndValidity();
    }
  }

  watchFormAfterSubmit() {
    this.formvalidationService.submitForm.subscribe((e) => {
      this.submitted = e;
      this.cd.detectChanges();
      this.driverInfoForm.markAsTouched();
    });
  }

  getIdentities() {
    this.profileService.getIdentities().subscribe((res: IdentityResponse) => {
      this.identities = res.data;
      // this.setBirthDateToLocalStorage();
    });
  }

  ngOnDestroy(): void {
    this.driverInfoForm.reset();
    this.formvalidationService.setSubmitted(false);
  }

  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any): void {
  //   $event.returnValue = true;
  //   //  message
  //   alert('Data will be deleted. Are you sure you want to leave?');
  // }
}
