import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslationModule } from "../shared/translation.module";
import { AuthenticationPageComponent, CopyDirective } from "./auth.component";
import { AuthRoutingModule } from "./auth.routing";
import { NgOtpInputModule } from "ng-otp-input";
import { GregorianDatepickerComponent } from "../shared/datepicker/gregorian-datepicker/gregorian-datepicker.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [AuthenticationPageComponent, CopyDirective],
  imports: [
    CommonModule,
    TranslationModule,
    NgbModule,
    FormsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    SharedModule,
  ],
  exports: [AuthenticationPageComponent],
})
export class AuthModule {}
