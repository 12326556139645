import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ApiRoutes } from "src/app/models/app/ApiRoutes";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { QuotesListResponse } from "src/app/payload/responses/quotes/QuotesListResponse";
import { environment } from "src/environments/environment";
import { QuoteService } from "../quote/quote.service";
import { InsuranceStepsService } from "./insurance-steps.service";
import { Router } from "@angular/router";
import { Driver } from "src/app/models/insurance-steps/Driver";
import QuoteUtils from "src/app/helpers/utils/QuoteUtils";
import { LandingForm } from "src/app/models/insurance-steps/ManagedFormsTypes";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class InsuranceStepsApiService {
  masterTblApi = environment.apiUrl;
  dataRoutes = [
    ApiRoutes.MasterTable.VehicleIdType,
    ApiRoutes.MasterTable.VehicleRepairMethod,
    ApiRoutes.MasterTable.Cities,
    ApiRoutes.MasterTable.Occupations,
    ApiRoutes.MasterTable.Countries,
    ApiRoutes.MasterTable.VehicleUse,
    ApiRoutes.MasterTable.TransmissionType,
    ApiRoutes.MasterTable.ParkingLocation,
    ApiRoutes.MasterTable.Mileage,
    ApiRoutes.MasterTable.EducationLevel,
    ApiRoutes.MasterTable.DrivingPercentage,
    ApiRoutes.MasterTable.LicenseType,
    ApiRoutes.MasterTable.SocialStatus,
    ApiRoutes.MasterTable.VehicleSpecifications,
    ApiRoutes.MasterTable.Violation,
    ApiRoutes.MasterTable.MedicalCondition,
    ApiRoutes.MasterTable.Relation,
  ];

  yakeenMissingInfoRoutes = [
    ApiRoutes.MasterTable.VehicleMaker,
    // ApiRoutes.MasterTable.VehicleModel
  ];
  constructor(
    private http: HttpClient,
    private insuranceStepService: InsuranceStepsService,
    private router: Router,
    private quoteService: QuoteService,
    private authService: AuthService
  ) {}

  /**
   * Fetches the master tables data
   */
  async getStepsData() {
    // Send requests
    for await (let route of this.dataRoutes) {
      await this.getRouteRequest(route)
        .toPromise()
        .then((res) => {
          /* Convert master/Cities to  Cities */
          //  route = route.substring(7);
          route = route.replace("master/", "");
          this.insuranceStepService.addInsuranceStepsObject(res, route);
        })
        .catch((err) => {});
    }
  }

  /**
   * Fetches the insurance order form data
   */
  async getMissingYakeenStepsData() {
    // Send requests
    for await (let route of this.yakeenMissingInfoRoutes) {
      await this.getRouteRequest(route)
        .toPromise()
        .then((res) => {
          /* Convert master/Cities to  Cities */
          //  route = route.substring(7);
          route = route.replace("master/", "");
          this.insuranceStepService.addInsuranceStepsObject(res, route);
        })
        .catch((err) => {});
    }
  }
  /* get vehicle models by vechile made */
  getVehicleModal(makeId: number) {
    let params = new HttpParams();
    params = params.append("id", makeId.toString());
    return this.http.get<any[]>(
      environment.apiUrl + ApiRoutes.MasterTable.VehicleModel,
      { params: params }
    );
  }

  private getRouteRequest(route: any): Observable<any> {
    return this.http.get(this.masterTblApi + route.valueOf());
  }

  /**
   * Fetches the preliminary quote order and user insurance data
   */
  public getUserQuotePreliminaryData(preliminaryDataReq: any): Observable<any> {
    // Construct the query parameters
    /* const httpParams = new HttpParams({ fromObject: preliminaryDataReq });
      return this.http.get(environment.apiUrl + ApiRoutes.Quotation.InquireQuote, { params: httpParams }); */
    return this.http.post(
      environment.apiUrl + ApiRoutes.Quotation.InquireQuote,
      preliminaryDataReq
    );
  }

  public getQuotationList(action?: string) {
    // Get the constructed user request from local storage
    // const userQuotationRequest = JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.UserQuoteRequest));
    let formsData = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.FormManager)
    );
    let landingInfo: LandingForm = formsData.LandingForm.value;

    // let vehicleOwnerIdentityNumber1 = landingInfo.vehicleOwnerIdentityNumber;
    // let vehicleOwnerIdentityNumber2 = this.insuranceStepService.previousQuotationData.value.Vehicle.vehicleOwnerIdentityNumber;

    // let insuredIdentityNumber= this.insuranceStepService.previousQuotationData.value.Vehicle.insuredIdentityNumber;
    const userQuotationRequest = QuoteUtils.constructQuoteRequest();
    userQuotationRequest.action = action;
    // if (action !== "New") return;

    // if(userQuotationRequest.isVehicleOwnerTransfer && vehicleOwnerIdentityNumber1){
    //   userQuotationRequest.vehicleOwnerIdentityNumber = vehicleOwnerIdentityNumber1
    // }
    // else if(userQuotationRequest.isVehicleOwnerTransfer && vehicleOwnerIdentityNumber2){
    //   userQuotationRequest.vehicleOwnerIdentityNumber = vehicleOwnerIdentityNumber2
    // }
    // else{
    //   userQuotationRequest.vehicleOwnerIdentityNumber =  insuredIdentityNumber
    // }
    // Send the quotes list request
    if (this.authService.getIsLoggedIn().value) {
      return this.http
        .post(
          environment.apiUrl + ApiRoutes.Quotation.RequestQuote,
          userQuotationRequest
        )
        .pipe(
          tap((res: QuotesListResponse) => {
            if (res.isSuccess) {
              // Save the response on local storage
              localStorage.setItem(
                LocallyStoredItemsKeys.UserQuoteResponse,
                JSON.stringify(res)
              );
              // Route to the quotes list page
              this.router.navigate([AppRoutes.quotes]);
              // Store the retreived quotes on local storage
              localStorage.setItem(
                LocallyStoredItemsKeys.Quotes,
                JSON.stringify(res.quotes)
              );
              this.quoteService.setQuotes(res.quotes);
            }
          })
        );
    } else {
      return this.http
        .post(
          environment.apiUrl + ApiRoutes.Quotation.ProspectClientRequest,
          userQuotationRequest
        )
        .pipe(
          tap((res: QuotesListResponse) => {
            if (res.isSuccess) {
              // Save the response on local storage
              localStorage.setItem(
                LocallyStoredItemsKeys.UserQuoteResponse,
                JSON.stringify(res)
              );
              // Route to the quotes list page
              this.router.navigate([AppRoutes.quotes]);
              // Store the retreived quotes on local storage
              localStorage.setItem(
                LocallyStoredItemsKeys.Quotes,
                JSON.stringify(res.quotes)
              );
              this.quoteService.setQuotes(res.quotes);
            }
          })
        );
    }
  }

  getIdFromUrl() {
    const userQuotesResponse: any = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.UserQuoteResponse)
    );
    let quoteId = userQuotesResponse.shareQuoteUrl
      .split("?")[0]
      .split("/")
      .pop();
    localStorage.setItem(LocallyStoredItemsKeys.QuoteId, quoteId);
  }

  getQouteUrlDetails(urlQuoteId: any | undefined) {
    let quoteId: any = localStorage.getItem(LocallyStoredItemsKeys.QuoteId);
    if (urlQuoteId) {
      quoteId = urlQuoteId;
    }
    return this.http.get(
      environment.apiUrl + ApiRoutes.Quotation.quoteDetails + quoteId
    );
  }
}
