/**
 * Contains types of loading scenarios/messages that would be displayed on the loading page to dynamically load its contents
 *
 * @export
 * @enum {number}
 */
export enum LoadingPageTypes {
   /**
    * Switching app language
    */
   LanguageSwitch = 'LanguageSwitch',
   /**
    * Retreiving quotations
    */
   QuotationLoading = 'QuotationLoading'
}
