export default class DropDownValues {
  static fromZeroToTen = [
    { id: 0, name: "0" },
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
  ];

  
static TicketProiority = [
  {id: "High", name: "High"},
  {id: "Normal", name: "Normal"},
  {id: "Low", name: "Low"}

];

static TicketType = [
  {id: "Technical Support", name: "Technical Support"},
  {id: "System Bugs", name: "System Bugs"},
  {id: "Change Request", name: "Change Request"}

];
}

