<div class="container px-0 mt-5 mb-1">
  <div class="row mb-3 mb-md-2">
    <div class="col-lg-8 col-md-8">
      <div class="row ">
        <!-- Policy owner -->
        <div class="col-lg-4 col-md-12 btn-group filter-item" style="color: #007bef">
          <!-- Price Sort Switch -->
          <div (click)="toggleDataAccordion.emit($event)" class="filter">
            <div class="filter-icon-wrapper">
              <i class="ion-md-person secondary-lime"
                [ngClass]="!isDataAccordionShown ? 'ion-md-add' : 'ion-md-remove'"></i>
            </div>
            <div class="filter-value">
              <span class="flex-centered">
                <span>{{ "Quote.HeaderTitle.title" | translate }}</span>
              </span>
            </div>
          </div>
        </div>
        <!-- ----------------------------------------------------------------------- -->
        <!--                       Fliter by ProductType                           -->
        <!-- ----------------------------------------------------------------------- -->
        <!-- #region -->
        <!-- // 1 = Third Party, 2 = Comprehensive , 3 = Others -->
        <div class="col-lg-8 col-md-12 filter-item pl-1 px-0 my-lg-0 my-2 ">
          <div class="btn btn-light comparison-btn"
            style="border-bottom-right-radius: 5px; border-top-right-radius: 5px"
            [class.active]="selectedProductId == 0" (click)="filterProduct(0)">
            <label><span class="filter-number">{{ totalQuotes }} </span> </label>{{ "Quote.insuranceType.all" |
            translate
            }}
          </div>
          <div class="btn btn-light comparison-btn mx-1" [class.active]="selectedProductId == 2"
            (click)="filterProduct(2)">
            <label><span class="filter-number">{{
                comprehensiveProduct
                }}</span></label>
            {{ "Quote.insuranceType.comprehinsive" | translate }}
          </div>
          <div class="btn btn-light comparison-btn" style="border-bottom-left-radius: 5px; border-top-left-radius: 5px"
            [class.active]="selectedProductId == 1" (click)="filterProduct(1)">
            <label><span class="filter-number">{{ thirdPartyProduct }}</span></label>
            {{ "Quote.insuranceType.third-party" | translate }}
          </div>
          <div class="btn btn-light comparison-btn mx-1"
            style="border-bottom-left-radius: 5px; border-top-left-radius: 5px" [class.active]="selectedProductId > 2"
            (click)="filterProduct(3)">
            <label><span class="filter-number">{{ otherProduct }}</span></label>
            {{ "Quote.insuranceType.others" | translate }}
          </div>
        </div>
        <!-- #endregion -->

      </div>
    </div>
    <div class="col-lg-4 d-flex justify-content-lg-end">
      <!-- ----------------------------------------------------------------------- -->
      <!--                            Batch Comparison                             -->
      <!-- ----------------------------------------------------------------------- -->
      <!--Share Quote-->
      <div class="btn-group filter-item " style="color: #007bef">
        <!--  -->
        <div class="filter bouncy noselect" (click)="shareQuote(content)">
          <div class="filter-icon-wrapper">
            <i class="ion-md-share-alt secondary-lime"></i>
          </div>
          <div class="filter-value">
            <span class="flex-centered">
              <span *ngIf="isShareQuoteLoading" class="spinner-border spinner-border-sm me-1" role="status"
                aria-hidden="true">
              </span>
              <span>{{ "Quote.ShareQuote" | translate }}</span>
            </span>
          </div>
        </div>
      </div>

      <!-- #region -->
      <div class="btn-group filter-item ml-1">
        <!-- Price Sort Switch -->
        <div (click)="onCompareAllQuotes()" class="filter bouncy noselect">
          <div class="filter-icon-wrapper">
            <i class="ion-md-git-pull-request secondary-lime mr-1"></i>
          </div>
          <div class="filter-value">
            <span class="flex-centered" style="color: #007bef">
              {{ "Quote.CompareAll" | translate }}
            </span>
          </div>
        </div>
      </div>
      <!-- PRICE FILTER -->
      <div class="btn-group filter-item ml-1">
        <!-- Price Sort Switch -->
        <div (click)="toggleSortType()" class="filter bouncy noselect" [ngClass]="
            selectedSortType.ascending
              ? 'ascending-switched'
              : 'descending-switched'
          ">
          <div class="filter-icon-wrapper" [ngClass]="
              selectedSortType.ascending
                ? 'ascending-switched'
                : 'descending-switched'
            ">
            <i [ngClass]="
                isBtnShown
                  ? 'ion-md-arrow-down secondary-lime'
                  : 'ion-md-arrow-up secondary-lime'
              "></i>
          </div>
          <div class="filter-value">
            <span class="flex-centered">
              {{
              selectedSortType.ascending
              ? ("Quote.Filters.AscendingOrderText" | translate)
              : ("Quote.Filters.DescndingOrderText" | translate)
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Share quote modal-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "Quote.ShareQuote" | translate }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="ion-md-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="shareUrlQouteFormGroup">
      <div class="col px-1">
        <!--Main Url Input-->
        <div>
          <div class="input-group mb-1">
            <input type="text" [value]="shareQuoteUrl" class="form-control" #userurl />
            <div class="input-group-prepend">
              <button class="modal-btn btn" type="button" (click)="copyInputMessage(userurl)">
                {{ "Quote.Copy" | translate }}
              </button>
            </div>
          </div>
          <span class="mt-1" *ngIf="alertMessage">
            <ngb-alert type="success">{{
              "Quote.SuccessMsg" | translate
              }}</ngb-alert>
          </span>
        </div>
        <!-- Email Input To Share Url-->
        <div *ngIf="isEmailSelected" class="">
          <div class="input-group">
            <input class="form-control" formControlName="email" type="email" placeholder="Enter the email" required />
            <div class="input-group-prepend">
              <button class="modal-btn btn" type="submit" (click)="shareUrlToEmail()">
                Send
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                </span>
              </button>
            </div>
          </div>
          <div *ngIf="shareUrlFormControl.email.errors" class="error">
            <span *ngIf="
                (isSubmitting && shareUrlFormControl.email.errors.required) ||
                (shareUrlFormControl.email.touched &&
                  shareUrlFormControl.email.errors.required)
              " class="v-message">
              {{ "Errors.RequiredField" | translate }}
            </span>
            <span *ngIf="
                (isSubmitting && shareUrlFormControl.email.errors.pattern) ||
                (shareUrlFormControl.email.dirty &&
                  shareUrlFormControl.email.errors.pattern)
              " class="v-message">
              {{ "Errors.InvalidEmail" | translate }}
            </span>
          </div>
        </div>
        <div class="mt-2" *ngIf="isSuccess">
          <!-- <ngb-alert type="success">Message sent successfully</ngb-alert> -->
          <ngb-alert type="success">{{ errorMessage }}</ngb-alert>
        </div>
      </div>
    </form>
  </div>
  <div class="px-3">
    <div class="striped">
      <span class="striped-line"></span>
      <span class="striped-text"> أو مشاركة عبر </span>
      <span class="striped-line"></span>
    </div>
    <div class="mb-2">
      <i class="ion-md-mail modal-btn btn mx-1" (click)="toggleEmailInput()"></i>
      <!-- <img class="img-fluid modal-btn btn mx-1" src="assets/images/Icons/SMS.svg" style="width: 50px;" alt="SMS"> -->
      <!-- <img class="img-fluid modal-btn btn mx-1" src="assets/images/Icons/icons8-whatsapp.svg" style="width: 50px;" alt="whatsapp Logo"> -->
    </div>
  </div>
</ng-template>