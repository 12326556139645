export enum InsuranceCompaniesIds {
    Wataniya = 1,
    Walaa = 2,
    TokioMarine = 3,
    Salama = 4,
    GGI = 5,
    Tauwuniya = 6,
    Rajhi = 7,
    Malath = 8,
    Acig = 9,
    MedGulf = 10,
 }