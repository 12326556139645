<div class="loader-container">
  <!-- ----------------------------------------------------------------------- -->
  <!--                                 Loader                                  -->
  <!-- ----------------------------------------------------------------------- -->
  <div style="margin: auto auto 10px auto" class="center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background: #fff; display: block"
      width="211px"
      height="211px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="18" cy="50" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="0s"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="27" cy="61.31370849898476" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.125s"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="36" cy="66" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.25s"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="45" cy="61.31370849898476" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.375s"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="54" cy="50" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.5s"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="63" cy="38.68629150101524" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.625s"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="72" cy="34" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.75s"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="81" cy="38.68629150101523" r="4" fill="#0087C1">
        <animate
          attributeName="cy"
          values="34;66;34"
          times="0;0.5;1"
          dur="1s"
          calcMode="spline"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.875s"
          repeatCount="indefinite"
        ></animate>
      </circle>
    </svg>
  </div>

  <!-- ----------------------------------------------------------------------- -->
  <!--                     Language Switch Loading Message                     -->
  <!-- ----------------------------------------------------------------------- -->
  <span *ngIf="type === 'LanguageSwitch'" class="loading-msg">{{
    "Misc.LanguageSwitchLoadingMessage" | translate
  }}</span>

  <!-- ----------------------------------------------------------------------- -->
  <!--                       Quotations Loading Message                        -->
  <!-- ----------------------------------------------------------------------- -->
  <span *ngIf="type === 'QuotationLoading'" class="loading-msg">{{
    "Misc.QuotationLoadingMessage" | translate
  }}</span>
</div>
