import { WizardStepsWrapperComponent } from "./components/wizard-steps-wrapper/wizard-steps-wrapper.component";
import { AuthGuard } from "./helpers/guards/auth.guard";
import { UnauthGuard } from "./helpers/guards/unauth.guard";
import { AppRoutes } from "./models/app/AppRoutes";

export const ROUTES = [
  {
    path: "",
    component: WizardStepsWrapperComponent,
    children: [
      {
        path: AppRoutes.Routes.insuranceSteps,
        loadChildren: () =>
          import(
            "./components/quotation-request/quotation-request.module"
          ).then((m) => m.QuotationRequestModule),
      },
      {
        path: AppRoutes.Routes.quotes,
        loadChildren: () =>
          import("./components/quotes/quotes.module").then(
            (m) => m.QuotesModule
          ),
      },
      {
        path: AppRoutes.Routes.quotesId,
        loadChildren: () =>
          import("./components/quotes/quotes.module").then(
            (m) => m.QuotesModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: AppRoutes.Routes.checkout,
        loadChildren: () =>
          import("./components/checkout/checkout.module").then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: AppRoutes.Routes.checkoutSuccess,
        canActivate: [AuthGuard],

        loadChildren: () =>
          import("./components/checkout/checkout.module").then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: AppRoutes.Routes.paymentStatus,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./components/checkout/checkout.module").then(
            (m) => m.CheckoutModule
          ),
      },
      // {
      //    path: '**',
      //    redirectTo: AppRoutes.landing
      // }
    ],
  },
];
