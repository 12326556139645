import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { QuoteService } from "src/app/services/quote/quote.service";

@Injectable({
  providedIn: "root",
})
export class DirectAccessGuard implements CanActivate {
  // get Url

  constructor(private router: Router, private quoteService: QuoteService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let identityNumber = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.FormManager)
    ).LandingForm.value.insuredIdentityNumber;
    let quoteFromProfile = localStorage.getItem(
      "QuoteFromProfile".toLowerCase()
    );
    let id: any = localStorage.getItem(LocallyStoredItemsKeys.QuoteId);
    /* landing page => identityNumber
      profile page => ! identityNumber quotaFrom profile
    */
    // switch (state.url) {
    //   case "/quotation":
    //     if (!identityNumber && quoteFromProfile) {
    //       this.router.navigate(["/"]);
    //       return false;
    //     }
    //     break;
    //   case "/quotes":
    //     if (
    //       localStorage.getItem(LocallyStoredItemsKeys.Quotes) === null &&
    //       localStorage.getItem(LocallyStoredItemsKeys.UserQuoteRequest) === null
    //     ) {
    //       this.router.navigate(["/quotation"]);
    //       return false;
    //     }
    //     break;
    //   case "/quotes/" + id:
    //     if (
    //       localStorage.getItem(LocallyStoredItemsKeys.QuoteId) === null &&
    //       localStorage.getItem(LocallyStoredItemsKeys.UserQuoteRequest) === null
    //     ) {
    //       this.router.navigate(["/quotation"]);
    //       return false;
    //     }
    //     break;
    //   case "/checkout":
    //     if (this.quoteService.getPreviewQuote() === null) {
    //       this.router.navigate(["/quotes"]);
    //       return false;
    //     }
    //     break;
    //   case "/checkout/status":
    //     if (
    //       localStorage.getItem(LocallyStoredItemsKeys.purchaseResponse) === null
    //     ) {
    //       this.router.navigate(["/checkout"]);
    //       return false;
    //     }
    //     break;
    //   default:
    //     this.router.navigate(["/"]);
    //     break;
    // }
    return true;
  }
}
