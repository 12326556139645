import { Component, OnInit } from "@angular/core";
import { DashboardResponse } from "src/app/models/order-preview/DashboardResponse";
import { ProfileService } from "src/app/services/profile/profile.service";
import { environment } from "src/environments/environment";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { UserService } from "src/app/services/user/user.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { ClaimList } from "src/app/models/profile/ClaimList";
import { AppService } from "src/app/services/app/app.service";
@Component({
  selector: "app-cliams",
  templateUrl: "./cliams.component.html",
  styleUrls: ["./cliams.component.css"],
})
export class CliamsComponent implements OnInit {
  subscription: Subscription;
  policyId: string;
  isLoading;
  isPolices = false;
  isNewClaim = true;
  lang;
  policies: any;
  isLoadingCliams = false;
  isLoadingPoliciesAnalytics = false;
  policiesAnalytics: DashboardResponse;
  page = 1;
  pageSize = 4;
  claimList: ClaimList = <ClaimList>{};
  claimsList;
  AppRoutes: any = AppRoutes;
  vehicleLogoSrc: string = environment.apiAssetsUrl + "IC/";
  noPolicyError: boolean = false;

  constructor(
    private profileService: ProfileService,
    private userService: UserService,
    private appService: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDashboardData();
    this.getClaimList();
    this.getAppLang();
    this.userService.currentPolicy.subscribe((e) => (this.policyId = e));
    window.scrollTo({
      top: 10,
    });
  }
  getAppLang() {
    this.appService.getAppLang().subscribe((lang) => (this.lang = lang));
  }

  getDashboardData() {
    this.isLoadingPoliciesAnalytics = true;
    this.profileService.getDashboardData().subscribe((policiesAnalytics) => {
      this.policiesAnalytics = policiesAnalytics;
      this.policies = policiesAnalytics.policies;
      this.isLoadingPoliciesAnalytics = false;
    });
  }

  policyData(p) {
    let id = p.id;
    this.userService.policy.next(id);
    this.router.navigateByUrl("/claims/Addclaims");
  }
  // Get Claims List
  getClaimList() {
    this.isLoadingCliams = true;
    this.profileService.getClaimList(this.claimList).subscribe(
      (claims: any) => {
        this.claimsList = claims;
      },
      (err) => {
        this.isLoadingCliams = false;
        this.claimsList = [];
      },
      () => {
        this.isLoadingCliams = false;
        window.scroll(0, 0);
      }
    );
  }
  //new claim
  greateNewClaim() {
    this.isPolices = !this.isPolices;
    this.isNewClaim = !this.isNewClaim;
    if (this.policies.length == 0) {
      this.noPolicyError = true;
    }
  }
}
