<div class="login-page-container">
  <div class="flex-centered">

    <ngb-alert type="success" *ngIf="isSuccessAlertVisible" (close)="isSuccessAlertVisible = !isSuccessAlertVisible"
      [ngStyle]="this.lang == 'ar' ? {'right':0 } : {'left': 0}" style="position: fixed; top: 100px;">
      <small>{{ successMessage }}</small>
    </ngb-alert>

    <div class="wrapper">
      <div class="form-container">
        <div *ngIf="isLoginVisible" class="slide-controls">
          <input type="radio"
            (click)="isLoginFormVisible = true; isSiginupFormVisible = false;this.isSubmitting = false;" name="slide"
            id="login" checked />
          <input type="radio"
            (click)="isSiginupFormVisible = true; isLoginFormVisible = false;this.isSubmitting = false;" name="slide"
            id="signup" />
          <label for="login" class="slide login" [ngClass]="{ 'active-radio': isLoginFormVisible }">{{ "Auth.SiginIn" |
            translate }}</label>
          <label for="signup" class="slide signup" [ngClass]="{ 'active-radio': isSiginupFormVisible }">{{ "Auth.SignUp"
            | translate }}</label>
          <!-- <div class="slider-tab"></div> -->
        </div>
        <div *ngIf="isLoginVisible" class="form-inner">
          <!-- ----------------------------------------------------------------------- -->
          <!--                               LOGIN FORM                                -->
          <!-- ----------------------------------------------------------------------- -->
          <form [formGroup]="loginFormGroup" class="login" [ngClass]="{
              'login-form-uncollapse': isLoginFormVisible,
              'login-form-collapse': !isLoginFormVisible
            }">
            <!-- Error(s) Alert -->
            <ngb-alert *ngIf="isErrorAlertVisible" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
              <small>{{ errorMessage }}</small>
              <small *ngIf="validationErrors">
                <ul class="errors-list">
                  <li *ngFor="let error of validationErrors">
                    {{ error }}
                  </li>
                </ul>
              </small>
            </ngb-alert>

            <!-- Success Alert -->
            <ngb-alert *ngIf="isSuccessAlertVisible" type="success"
              (close)="isSuccessAlertVisible = !isSuccessAlertVisible">
              <small>{{ successMessage }}</small>
            </ngb-alert>

            <div class="field">
              <input formControlName="emailCtrl" type="email" [placeholder]="'Auth.EmailText' | translate" required />
            </div>
            <div *ngIf="isSubmitting && loginForm.emailCtrl.errors">
              <div class="v-meassage" *ngIf="loginForm.emailCtrl.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage" *ngIf="loginForm.emailCtrl.errors.pattern">
                {{ "Errors.InvalidEmail" | translate }}
              </div>
            </div>
            <!-- Backend validation errors -->
            <div *ngIf="isSubmitting && loginFormGroup.errors?.Username">
              <div class="v-meassage" *ngFor="let description of loginFormGroup.errors.descriptions">
                <span *ngIf="description.Username">
                  {{ description.Username }}
                </span>
              </div>
            </div>
            <!-- <div class="input-btn-group flex-container" style="width: 100%"> -->
            <div class="field">
              <div class="input-btn-group flex-container" style="width: 100%">
                <input formControlName="passwordCtrl" [placeholder]="'Auth.PasswordText' | translate"
                  [type]="fieldTextType ? 'text' : 'password'" class="verification-code-ipt" style="width: 80%"
                  preventCutCopyPaste />
                <!-- Eye Icon -->
                <div (click)="toggleFieldTextType()" class="ghost reset-code-btn password-view-btn text-center"
                  style="width: 20%">
                  <span class="eye-icon">
                    <i [ngClass]="{
                        'icon ion-md-eye': fieldTextType,
                        'icon ion-md-eye-off': !fieldTextType
                      }"></i>
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="isSubmitting && loginForm.passwordCtrl.errors">
              <div class="v-meassage" *ngIf="loginForm.passwordCtrl.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
            <!-- Backend validation errors -->
            <div *ngIf="isSubmitting && loginFormGroup.errors?.Password">
              <div class="v-meassage" *ngFor="let description of loginFormGroup.errors.descriptions">
                <span *ngIf="description.Password">
                  {{ description.Password }}
                </span>
              </div>
            </div>
            <!-- </div> -->

            <button (click)="login()" class="btn-main mt-3" type="submit">
              <span class="binner">{{ "Auth.SignInText" | translate }}</span>
              <i *ngIf="!isLoading" class="ion-md-log-in"></i>
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm binner" role="status"
                aria-hidden="true"></span>
            </button>
            <div class="text-2 mt-3">
              <a class="text-2" (click)="togglePasswordResetForm()" id="password-reset-link"
                href="javascript:void(0)">{{ "Auth.ForgotPasswordText" | translate }}</a>
            </div>
            <!-- <div class="text-2 mt-3">
              Not a member? <a class="text-2">Signup now</a>
            </div> -->
          </form>

          <!-- ----------------------------------------------------------------------- -->
          <!--                            REGISTRATION FORM                            -->
          <!-- ----------------------------------------------------------------------- -->
          <form [formGroup]="identificationNumberFormGroup" class="flex-centered" autocomplete="off" class="signup"
            [ngClass]="{
              'signup-form-collapse': isLoginFormVisible,
              'signup-form-uncollapse': !isLoginFormVisible
            }">
            <!-- Title -->
            <h4 class="auth-form-header" style="font-size: 1.2rem !important">
              <i class="ion-ios-lock"></i>
              {{ "Auth.identificationNumber" | translate }}
            </h4>
            <!-- Error(s) Alert -->
            <ngb-alert *ngIf="isErrorAlertVisible" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
              <small>{{ errorMessage }}</small>
              <!-- <small *ngIf="validationErrors">
                      <ul class="errors-list">
                        <li *ngFor="let error of validationErrors">{{error}}</li>
                      </ul>
                  </small> -->
            </ngb-alert>
            <div class="field">
              <input formControlName="identificationNumber" [placeholder]="'Auth.identificationNumber' | translate"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text"
                minlength="10" maxlength="10" />
            </div>
            <!-- identificationNumber Validation Messages -->
            <div *ngIf="
                isSubmitting &&
                identificationNumberForm.identificationNumber.errors
              ">
              <div class="v-meassage" *ngIf="
                  identificationNumberForm.identificationNumber.errors?.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage mt-2" *ngIf="
                  identificationNumberForm.identificationNumber.errors
                    ?.minlength
                ">
                {{ "Errors.IncompleteInputField" | translate }}
              </div>
            </div>

            <button (click)="getOTP()" class="verification-code-btn btn-main mt-3" type="submit">
              <span class="binner">{{ "App.ContinueText" | translate }}</span>
              <i *ngIf="!isLoading" class="ion-md-done-all"></i>
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm binner" role="status"
                aria-hidden="true"></span>
            </button>
            <div class="footer d-flex justify-content-center align-items-center my-2">
              <div class="btn-foot">
                <button (click)="goToLogin()" style="color: 7f8087; cursor: pointer" type="button">
                  {{ "Auth.SiginIn" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                           OTP LOGIN container FORM               -->
        <!-- ----------------------------------------------------------------------- -->
        <div *ngIf="isOtpVisibleLogin" class="form-container">
          <form class="flex-centered">
            <!-- Title -->
            <h4 class="auth-form-header" style="font-size: 1.2rem !important">
              <i class="ion-ios-lock"></i>
              {{ "Auth.sendOtp" | translate }}
            </h4>
            <!-- Error(s) Alert -->
            <ngb-alert *ngIf="isErrorAlertVisible" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
              <small>{{ errorMessage }}</small>
            </ngb-alert>
            <div class="otp my-4" style="direction: initial">
              <ng-otp-input style="direction: ltr;" (onInputChange)="onOtpChange($event)"
                [config]="{ length: 6 }"></ng-otp-input>
            </div>

            <!-- ----------------------------------------------------------------------- -->
            <!--                      OTP LOGIN Issue (Continue) Button                   -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="mt-3 timer d-flex justify-content-center align-items-center">
              <button [disabled]="isRequested" (click)="verifyLoginOTP()"
                [ngClass]="isRequested ? 'secondary-btn' : 'primary-btn'" class="verification-code-btn btn-otp mt-3"
                type="submit" style="color: white">
                <span class="binner" style="color: white">{{ "App.ContinueText" | translate }}</span>
                <i *ngIf="!isLoading" class="ion-md-done-all"></i>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm binner" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>

            <div class="mt-3 timer d-flex justify-content-center align-items-center">
              <p class="fs-6">
                {{ "Auth.RESEND_OTP" | translate }}
                <span class="">{{ transform(counter) }}</span>
              </p>
            </div>
            <div class="footer d-flex justify-content-center align-items-center my-2">
              <div class="me-1 foot">{{ "Auth.RESEIVE_CODE" | translate }}</div>
              <div class="btn-foot">
                <button (click)="startTimer()" [disabled]="counter !== 0" [ngStyle]="
                    counter !== 0 ? { color: '#7f8087' } : { cursor: 'pointer' }
                  ">
                  {{ "Auth.SEND_AGAIN" | translate }}
                </button>
              </div>
            </div>
            <div class="footer d-flex justify-content-center align-items-center my-2">
              <div class="btn-foot">
                <button (click)="goToLogin()" style="color: 7f8087; cursor: pointer">
                  {{ "Auth.SiginIn" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>


        <!-- ----------------------------------------------------------------------- -->
        <!--                           OTP REGISTRATION container FORM               -->
        <!-- ----------------------------------------------------------------------- -->
        <div *ngIf="isOtpVisible" class="form-container">
          <form class="flex-centered">
            <!-- Title -->
            <h4 class="auth-form-header" style="font-size: 1.2rem !important">
              <i class="ion-ios-lock"></i>
              {{ "Auth.sendOtp" | translate }}
            </h4>
            <!-- Error(s) Alert -->
            <ngb-alert *ngIf="isErrorAlertVisible" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
              <small>{{ errorMessage }}</small>
              <!-- <small *ngIf="validationErrors">
                  <ul class="errors-list">
                      <li *ngFor="let error of validationErrors">{{error}}</li>
                  </ul>
                </small> -->
            </ngb-alert>
            <div class="otp my-4" style="direction: initial">
              <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 4 }"></ng-otp-input>
            </div>
            <!-- otp Validation Messages -->
            <!-- <div *ngIf="isSubmitting && identificationNumberForm.identificationNumber.errors">
                <div class="v-meassage" *ngIf="identificationNumberForm.identificationNumber.errors.required">
                  {{ 'Errors.EmptyInputField' | translate }}
                </div>
            </div> -->

            <!-- ----------------------------------------------------------------------- -->
            <!--                      otp Issue (Continue) Button                      -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="mt-3 timer d-flex justify-content-center align-items-center">
              <button [disabled]="otp.length !== 4" (click)="verifyOTP()"
                [ngClass]="otp.length !== 4 ? 'secondary-btn' : 'primary-btn'"
                class="verification-code-btn btn-main mt-3" type="submit" style="color: white">
                <span class="binner">{{ "App.ContinueText" | translate }}</span>
                <i *ngIf="!isLoading" class="ion-md-done-all"></i>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm binner" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>

            <div class="mt-3 timer d-flex justify-content-center align-items-center">
              <p class="fs-6">
                {{ "Auth.RESEND_OTP" | translate }}
                <span class="text-dark">{{ transform(counter) }}</span>
              </p>
            </div>
            <div class="footer d-flex justify-content-center align-items-center my-2">
              <div class="me-1 foot">{{ "Auth.RESEIVE_CODE" | translate }}</div>
              <div class="btn-foot">
                <button (click)="startTimer()" [disabled]="counter !== 0" [ngStyle]="
                    counter !== 0 ? { color: '#7f8087' } : { cursor: 'pointer' }
                  ">
                  {{ "Auth.SEND_AGAIN" | translate }}
                </button>
              </div>
            </div>
            <div class="footer d-flex justify-content-center align-items-center my-2">
              <div class="btn-foot">
                <button (click)="goToLogin()" style="color: 7f8087; cursor: pointer">
                  {{ "Auth.SiginIn" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                            REGISTRATION FORM                            -->
        <!-- ----------------------------------------------------------------------- -->
        <div *ngIf="isRegistrationVisible" class="form-inner mt-3">
          <form [formGroup]="registrationFormGroup">
            <!-- Registration Title -->
            <h5 class="auth-form-header">
              <i class="ion-md-lock"></i>
              {{ "Auth.SignUpText" | translate }}
            </h5>

            <div *ngIf="showAlert" class="alert alert-warning" role="alert">
              Our website is under construction, we'll be back soon!
            </div>

            <!-- Error(s) Alert -->
            <ngb-alert *ngIf="isErrorAlertVisible" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
              <small>{{ errorMessage }}</small>
              <small *ngIf="validationErrors">
                <ul class="errors-list">
                  <li *ngFor="let error of validationErrors">{{ error }}</li>
                </ul>
              </small>
            </ngb-alert>

            <!-- Phone Input -->
            <div class="d-flex">
              <label class="m-0">{{ "Auth.PhoneNumberText" | translate }}</label>
            </div>
            <div class="field mt-0">
              <div class="phone-ipt-container">
                <input placeholder="+966" class="phone-key-ipt" type="text" style="width: 25%" disabled />
                <input formControlName="phoneNumberCtrl" [placeholder]="'Auth.PhoneNumberText' | translate"
                  class="phone-ipt" maxlength="9"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" />
              </div>
            </div>
            <!-- Phone Validation Messages -->
            <div *ngIf="isSubmitting && registrationForm.phoneNumberCtrl.errors">
              <div class="v-meassage mt-1" *ngIf="registrationForm.phoneNumberCtrl.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage mt-1" *ngIf="registrationForm.phoneNumberCtrl.errors.minlength">
                {{ "Errors.IncompleteInputField" | translate }}
              </div>
            </div>

            <!-- Email Input -->
            <div class="d-flex mt-3 mt-3">
              <label class="m-0">{{ "Auth.EmailText" | translate }}</label>
            </div>
            <div class="field mt-0">
              <input formControlName="emailCtrl" type="email" [placeholder]="'Auth.EmailText' | translate" />
            </div>
            <div *ngIf="registrationForm.emailCtrl.errors">
              <div class="v-meassage mt-1" *ngIf="
                  isSubmitting && registrationForm.emailCtrl.errors.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage mt-1" *ngIf="
                  registrationForm.emailCtrl.touched &&
                  registrationForm.emailCtrl.errors.pattern
                ">
                {{ "Errors.InvalidEmail" | translate }}
              </div>
            </div>

            <!-- Password Input -->
            <div class="d-flex mt-3">
              <label class="m-0">{{ "Auth.PasswordText" | translate }}</label>
            </div>
            <div class="field mt-0">
              <div class="input-btn-group flex-container" style="width: 100%">
                <input formControlName="passwordCtrl" [placeholder]="'Auth.PasswordText' | translate"
                  [type]="fieldTextType ? 'text' : 'password'" class="verification-code-ipt" style="width: 80%" />
                <!-- Eye Icon -->
                <div (click)="toggleFieldTextType()" class="ghost reset-code-btn password-view-btn text-center"
                  style="width: 20%">
                  <span class="eye-icon">
                    <i [ngClass]="{
                        'icon ion-md-eye': fieldTextType,
                        'icon ion-md-eye-off': !fieldTextType
                      }"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- Password Validation Message -->
            <div *ngIf="registrationForm.passwordCtrl.errors" class="mt-2">
              <div class="v-meassage" *ngIf="
                  isSubmitting && registrationForm.passwordCtrl.errors.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div *ngIf="registrationForm.passwordCtrl.dirty" style="text-align: start">
                <label [ngClass]="
                    registrationForm.passwordCtrl.hasError('required') ||
                    registrationForm.passwordCtrl.hasError('minlength')
                      ? 'text-danger'
                      : 'text-success'
                  ">
                  <i [ngClass]="
                      registrationForm.passwordCtrl.hasError('required') ||
                      registrationForm.passwordCtrl.hasError('minlength')
                        ? 'ion-md-close-circle'
                        : 'ion-md-checkbox'
                    "></i>
                  {{ "Errors.minlength" | translate }}
                </label>
                <label [ngClass]="
                    registrationForm.passwordCtrl.hasError('required') ||
                    registrationForm.passwordCtrl.hasError('hasNumber')
                      ? 'text-danger'
                      : 'text-success'
                  ">
                  <i [ngClass]="
                      registrationForm.passwordCtrl.hasError('required') ||
                      registrationForm.passwordCtrl.hasError('hasNumber')
                        ? 'ion-md-close-circle'
                        : 'ion-md-checkbox'
                    "></i>
                  {{ "Errors.hasNumber" | translate }}
                </label>
                <label [ngClass]="
                    registrationForm.passwordCtrl.hasError('required') ||
                    registrationForm.passwordCtrl.hasError('hasCapitalCase')
                      ? 'text-danger'
                      : 'text-success'
                  ">
                  <i [ngClass]="
                      registrationForm.passwordCtrl.hasError('required') ||
                      registrationForm.passwordCtrl.hasError('hasCapitalCase')
                        ? 'ion-md-close-circle'
                        : 'ion-md-checkbox'
                    "></i>
                  {{ "Errors.hasCapitalCase" | translate }}
                </label>
                <label [ngClass]="
                    registrationForm.passwordCtrl.hasError('required') ||
                    registrationForm.passwordCtrl.hasError('hasSmallCase')
                      ? 'text-danger'
                      : 'text-success'
                  ">
                  <i [ngClass]="
                      registrationForm.passwordCtrl.hasError('required') ||
                      registrationForm.passwordCtrl.hasError('hasSmallCase')
                        ? 'icons ion-md-close-circle '
                        : 'icons ion-md-checkbox'
                    "></i>
                  {{ "Errors.hasSmallCase" | translate }}
                </label>
                <label [ngClass]="
                    registrationForm.passwordCtrl.hasError('required') ||
                    registrationForm.passwordCtrl.hasError(
                      'hasSpecialCharacters'
                    )
                      ? 'text-danger'
                      : 'text-success'
                  ">
                  <i [ngClass]="
                      registrationForm.passwordCtrl.hasError('required') ||
                      registrationForm.passwordCtrl.hasError(
                        'hasSpecialCharacters'
                      )
                        ? 'ion-md-close-circle'
                        : 'ion-md-checkbox'
                    ">
                  </i>
                  {{ "Errors.hasSpecialCharacters" | translate }}
                </label>
              </div>
            </div>

            <!-- Password Confirmation Input -->
            <div class="d-flex mt-3">
              <label class="m-0">{{ "Auth.ConfirmPasswordText" | translate }}</label>
            </div>
            <div class="field mt-0">
              <div class="input-btn-group flex-container" style="width: 100%">
                <input formControlName="confirmPasswordCtrl" [placeholder]="'Auth.ConfirmPasswordText' | translate"
                  [type]="fieldTextType ? 'text' : 'password'" class="verification-code-ipt" style="width: 80%" />
                <!-- Eye Icon -->
                <div (click)="toggleFieldTextType()" class="ghost reset-code-btn password-view-btn text-center"
                  style="width: 20%">
                  <span class="eye-icon">
                    <i [ngClass]="{
                        'icon ion-md-eye': fieldTextType,
                        'icon ion-md-eye-off': !fieldTextType
                      }"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- Password Confirmation Validation Messages -->
            <div *ngIf="registrationForm.confirmPasswordCtrl.errors">
              <div class="v-meassage mt-2" *ngIf="
                  isSubmitting &&
                  registrationForm.confirmPasswordCtrl.errors.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage mt-2" *ngIf="
                  registrationForm.confirmPasswordCtrl.touched &&
                  registrationForm.confirmPasswordCtrl.errors.ConfirmPassword
                ">
                {{ "Errors.PasswordNotMatch" | translate }}
              </div>
            </div>

            <!-- ----------------------------------------------------------------------- -->
            <!--                               date picker                               -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="d-flex mt-3">
              <label class="m-0">{{ "Auth.BirthDate" | translate }}</label>
            </div>
            <div class="field mt-1">
              <app-gregorian-datepicker (onDatePicked)="setBirthDateValue($event)"
                *ngIf="identificationNumber.toString().startsWith('2')" [ngClass]="{'ng-invalid':
                (submitted && showHijriDate) || (submitted && showgregoianDate)}">
              </app-gregorian-datepicker>

              <app-higri-datepicker (onDatePicked)="setBirthDateValue($event)"
                *ngIf="identificationNumber.toString().startsWith('1')" [ngClass]="{'ng-invalid':
                  (submitted && registrationForm.birthDateH?.errors?.required) }">
              </app-higri-datepicker>
              <div *ngIf="
                (submitted && showHijriDate) || (submitted && showgregoianDate)
              ">
                <div *ngIf="showgregoianDate">
                  <div class="v-meassage" *ngIf="registrationForm.birthDateG?.errors?.required">
                    {{ "Errors.EmptyInputField" | translate }}
                  </div>
                </div>
                <div *ngIf="showHijriDate">
                  <div class="v-meassage" *ngIf="registrationForm.birthDateH?.invalid && submitted">
                    {{ "Errors.EmptyInputField" | translate }}
                  </div>
                </div>
              </div>

            </div>


            <div class="text-center my-2">
              {{ "Auth.SiginUpTerm.TermPart1" | translate }}
              <a [routerLink]="'/tremsCondition'" target="_blank" class="anchor">{{ "Footer.Terms" | translate }}</a>
              {{ "Auth.SiginUpTerm.TermPart2" | translate }}
              <a [routerLink]="'/privacy-policy'" target="_blank" class="anchor">{{ "Footer.Privacy" | translate
                }}</a>{{ "Auth.SiginUpTerm.TermPart3" | translate }}
            </div>

            <div class="mt-3 timer d-flex justify-content-center align-items-center">
              <button (click)="register()" class="verification-code-btn btn-main mt-3"
                style="margin-top: 1em; color: white">
                <span class="binner">{{ "Auth.SignUpText" | translate }}</span>
                <i *ngIf="!isLoading" class="ion-md-person-add"></i>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm binner" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>

            <div class="footer d-flex justify-content-center align-items-center my-2">
              <div class="btn-foot">
                <button (click)="goToLogin()" style="color: 7f8087; cursor: pointer">
                  {{ "Auth.SiginIn" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                           PASSWORD RESET FORM                           -->
        <!-- ----------------------------------------------------------------------- -->
        <div *ngIf="isPasswordResetVisible" class="form-inner mt-3">
          <form [formGroup]="passwordResetFormGroup">
            <!-- Title -->
            <h4 class="auth-form-header">
              <i class="ion-ios-lock"></i>
              {{ "Auth.PasswordReset.ResetPasswordText" | translate }}
            </h4>

            <!-- Subtitle -->
            <h6 *ngIf="isRequestingPasswordReset" class="auth-form-header">
              {{ "Auth.PasswordReset.VerificationStatement" | translate }}
            </h6>

            <!-- Error(s) Alert -->
            <ngb-alert *ngIf="isErrorAlertVisible" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
              <small>{{ errorMessage }}</small>
              <small *ngIf="validationErrors">
                <ul class="errors-list">
                  <li *ngFor="let error of validationErrors">{{ error }}</li>
                </ul>
              </small>
            </ngb-alert>

            <!-- Success Message -->
            <ngb-alert *ngIf="isSuccessAlertVisible" type="success"
              (close)="isSuccessAlertVisible = !isSuccessAlertVisible">
              <small>{{
                "Auth.PasswordReset.CodeResendText" | translate
                }}</small>
            </ngb-alert>

            <!-- Email Input -->
            <div class="field" *ngIf="!isRequestingPasswordReset">
              <input formControlName="emailCtrl" [placeholder]="'Auth.EmailText' | translate" type="email" />
            </div>
            <!-- Email Validation Messages -->
            <div *ngIf="
                !isRequestingPasswordReset && passwordResetForm.emailCtrl.errors
              " class="error-group">
              <div class="v-meassage" *ngIf="
                  isSubmitting && passwordResetForm.emailCtrl.errors.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage" *ngIf="
                  passwordResetForm.emailCtrl.touched &&
                  passwordResetForm.emailCtrl.errors.pattern
                ">
                {{ "Errors.InvalidEmail" | translate }}
              </div>
            </div>

            <!-- Last Phone Digits Input -->
            <div class="field" *ngIf="!isRequestingPasswordReset">
              <input formControlName="phoneNumberCtrl" [placeholder]="
                  'Auth.PasswordReset.LastPhoneDigitsText' | translate
                " maxlength="4" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                type="text" />
            </div>
            <!-- Phone Digits Validation Messages -->
            <div *ngIf="
                !isRequestingPasswordReset &&
                isSubmitting &&
                passwordResetForm.phoneNumberCtrl.errors
              " class="error-group">
              <div class="v-meassage" *ngIf="passwordResetForm.phoneNumberCtrl.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage" *ngIf="passwordResetForm.phoneNumberCtrl.errors.minlength">
                {{ "Errors.IncompleteInputField" | translate }}
              </div>
            </div>

            <!-- ----------------------------------------------------------------------- -->
            <!--                               Code Input                                -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="field" *ngIf="isRequestingPasswordReset">
              <div class="flex-container" style="width: 100%">
                <input class="verification-code-ipt" formControlName="resetTokenCtrl"
                  [placeholder]="'Auth.PasswordReset.ResetCode' | translate" maxlength="6"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text"
                  style="width: 57%" />
                <!-- Resend Button -->
                <button (click)="reissuePasswordReset()" class="reset-code-btn verification-code-btn">
                  <span *ngIf="!isResending">{{
                    "App.ResendText" | translate
                    }}</span>
                  <span *ngIf="isResending" class="spinner-border spinner-border-sm centered" role="status"
                    aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <!-- Code Validation Messages -->
            <div *ngIf="
                !isResending &&
                isRequestingPasswordReset &&
                isSubmitting &&
                passwordResetForm.resetTokenCtrl.errors
              " class="error-group">
              <div class="v-meassage" *ngIf="passwordResetForm.resetTokenCtrl.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>

            <!-- ----------------------------------------------------------------------- -->
            <!--                           New Password Input                            -->
            <!-- ----------------------------------------------------------------------- -->

            <div class="field" *ngIf="isRequestingPasswordReset">
              <div class="flex-container" style="width: 100%">
                <input formControlName="passwordCtrl" [placeholder]="
                    'Auth.PasswordReset.NewPasswordText' | translate
                  " [type]="fieldTextType ? 'text' : 'password'" class="verification-code-ipt" style="width: 80%" />
                <!-- Eye Icon -->
                <div (click)="toggleFieldTextType()" class="ghost reset-code-btn password-view-btn text-center"
                  style="width: 20%">
                  <span class="eye-icon">
                    <i [ngClass]="{
                        'icon ion-md-eye': !fieldTextType,
                        'icon ion-md-eye-off': fieldTextType
                      }"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- New Password Validation Messages -->
            <div *ngIf="
                !isResending &&
                isRequestingPasswordReset &&
                passwordResetForm.passwordCtrl.errors
              " class="error-group">
              <div class="v-meassage" *ngIf="
                  isSubmitting && passwordResetForm.passwordCtrl.errors.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage" *ngIf="
                  passwordResetForm.passwordCtrl.touched &&
                  (passwordResetForm.passwordCtrl.errors.passwordStrength ||
                    passwordResetForm.passwordCtrl.errors.minlength)
                ">
                {{ "Errors.ShortPassword" | translate }}
              </div>
            </div>
            <div class="field" *ngIf="isRequestingPasswordReset">
              <div class="flex-container" style="width: 100%">
                <input formControlName="confirmPasswordCtrl" [placeholder]="'Auth.ConfirmPasswordText' | translate"
                  [type]="fieldTextType ? 'text' : 'password'" class="verification-code-ipt" style="width: 80%" />
                <!-- Eye Icon -->
                <div (click)="toggleFieldTextType()" class="ghost reset-code-btn password-view-btn text-center"
                  style="width: 20%">
                  <span class="eye-icon">
                    <i [ngClass]="{
                        'icon ion-md-eye': !fieldTextType,
                        'icon ion-md-eye-off': fieldTextType
                      }"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- Password Confirmation Validation Messages -->
            <div *ngIf="
                !isResending &&
                isRequestingPasswordReset &&
                passwordResetForm.confirmPasswordCtrl.errors
              ">
              <div class="v-meassage" *ngIf="
                  isSubmitting &&
                  passwordResetForm.confirmPasswordCtrl.errors.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage" *ngIf="
                  passwordResetForm.confirmPasswordCtrl.touched &&
                  passwordResetForm.confirmPasswordCtrl.errors.ConfirmPassword
                ">
                {{ "Errors.PasswordNotMatch" | translate }}
              </div>
            </div>
            <!-- ----------------------------------------------------------------------- -->
            <!--                      Reset Issue (Continue) Button                      -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="mt-3 timer d-flex justify-content-center align-items-center" *ngIf="!isRequestingPasswordReset">
              <button (click)="issuePasswordReset()" class="verification-code-btn primary-btn btn-main mt-3"
                type="button">
                <span>{{ "App.ContinueText" | translate }}</span>
                <i *ngIf="!isLoading" class="ion-md-done-all"></i>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>

            <!-- ----------------------------------------------------------------------- -->
            <!--                          Reset (Submit) Button                          -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="mt-3 timer d-flex justify-content-center align-items-center" *ngIf="isRequestingPasswordReset">
              <button (click)="resetPassword()" class="verification-code-btn primary-btn btn-main mt-3" type="button">
                <span>{{ "Auth.PasswordReset.ResetText" | translate }}</span>
                <i *ngIf="!isLoading" class="ion-md-done-all"></i>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>

            <div class="footer d-flex justify-content-center align-items-center my-2">
              <div class="btn-foot">
                <button (click)="goToLogin()" style="color: 7f8087; cursor: pointer">
                  {{ "Auth.SiginIn" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>