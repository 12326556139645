export enum LocallyStoredItemsKeys {
  /* App */
  JWT = "app:jwt",
  phoneNumber = "app:phoneNumber",
  userId = "app:userId",
  AppLanguage = "app:language",
  ActiveErrorType = "app:errorType",
  /* Insurance */
  InsuranceCurrentStepNumber = "insurance:currentStepNumber",
  UserQuoteRequest = "insurance:quoteRequest",
  UserQuoteResponse = "insurance:quoteResponse",
  UserAdditionalDrivers = "insurance:UserAdditionalDrivers",
  DriverToEdit = "driverToEdit",
  InsuranceCurrentStepData = "insurance:currentStepData",
  FormManager = "ngFormsManager",
  policyEffectiveDate = "policyEffectiveDate",
  /* Quotes */
  Quotes = "quote:quotesList",
  SelectedQuote = "quote:selectedQuote",
  AlteredQuotes = "quote:alteredQuotesList",
  AlteredProducts = "quote:alteredQuotesList",
  PreviewQuoteResponse = "quote:previewQuoteResponse",
  PreviewQuoteRequest = "quote:previewQuoteRequest",
  QuoteId = "QuoteId",
  /* User */
  VerificationUserId = "verification:userId",
  VerificationPhoneNumber = "verification:phone",
  VerificationUserEmail = "verification:email",
  ProfileData = "user:profileInfo",
  /* Checkout */
  CheckoutReturnState = "checkout:returnStateData",
  purchaseResponse = "checkout:PreviewpurchaseResponse",
  PurchaseResponseView = "checkout:PurchaseResponseView",
}
