import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@ngneat/reactive-forms";
import { FileUploader } from "ng2-file-upload";
import { WebcamImage, WebcamInitError } from "ngx-webcam";
import { Observable, Subject } from "rxjs";
import { FileHandle } from "src/app/models/order-preview/FileHandle";
import { ApiResponse } from "src/app/payload/responses/ApiResponse";
import { AppService } from "src/app/services/app/app.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { OrderReviewService } from "src/app/services/checkout/order-review.service";
import Swal from "sweetalert2";
@Component({
  selector: "app-vehicle-images",
  templateUrl: "./vehicle-images.component.html",
  styleUrls: ["./vehicle-images.component.css"],
})
export class VehicleImagesComponent implements OnInit {
  /* DATA */
  userId: string;
  requestReferenceId: string;
  token: string;
  myScriptElement: HTMLScriptElement;
  vehicleLogoSrc: string;
  checkoutResponse: any;
  checkoutJsUrl: string;
  imageId: number;
  referenceId: string;
  endDate: string;
  trigger: Subject<void> = new Subject();
  previewImage: string = "";
  webcamImage: WebcamImage;
  /* UI */
  accordionsState = {
    isDriversDisplayed: true,
    isImageUploadDisplayed: true,
    isPaymentDisplayed: true,
    isCardPaymentDisplayed: false,
    isSadadPaymentDisplayed: false,
  };

  /* UI */
  attachmentObj: any = {};
  lang;
  model: any;
  isCaptrueImage = false;
  isCameraNotAllawed: boolean = false;
  isCameraNotSupported: boolean = false;
  stream: MediaStream;
  stream2: MediaStream;
  hasScrolledBanner: boolean = false;
  isContinuationModalDisplayed = false;
  isWebcamModalDisplayed = false;
  isImageCaptured = false;
  isShowCapturedImage = false;
  isSavingOrder = false;
  showErrorHiddenAlert = false;
  hiddedErrorAlert = true;
  clientQuoteId: string;
  checkoutId: string;

  /* validation */
  /* Alert */
  isSaveQuoteError = false;
  isSaveQuoteSuccess = false;
  errorMessage: string;
  successMessage;
  isErrorAlertVisible = false;
  isSuccessAlertVisible;
  validationErrors: string[];
  uploadedImgValid: boolean = true;
  /* file Attachment */

  uploader: FileUploader;
  supportedFile: boolean = true;
  // File Upload
  allowedFileType: string[] = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
  ];
  vehicleImages = ["Front", "Back", "Right", "Left", "Chassis"];
  isFrontVehicleImageIsUploaded: boolean = false;
  isBackVehicleImageIsUploaded: boolean = false;
  isRightVehicleImageIsUploaded: boolean = false;
  isLeftVehicleImageIsUploaded: boolean = false;
  isChassisVehicleImageIsUploaded: boolean = false;
  state = {
    attachment: {
      tempAttachment: [] as FileHandle[],
    },
  };
  //
  isImageLoading: boolean = false;
  isImagesUploadedCom: boolean = false;
  isImagesNotUploaded: boolean = false;
  //
  imageFile: [] = [];
  previewImageApi: any;
  isAttachmentLoading = false;
  isUploadCompelte = false;
  @ViewChild("attacmentUploadingForm", { static: false })
  attacmentUploadingForm: ElementRef;
  hasBaseDropZoneOver: boolean;
  isQuoteGGiCom = true;
  /* FORM */
  checkoutDetailsFormGroup: FormGroup;
  attachmentFormGroup: FormGroup;

  isFilesUploadSuccessfully = false;
  isCaptrueImageFailed = false;
  constructor(
    private appService: AppService,
    private orderReviewService: OrderReviewService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    window.scroll({ top: 0 });
    this.initForms();
    this.initUploader();
    this.appService.getAppLang().subscribe((appLang) => (this.lang = appLang));
    this.userId = this.activatedRoute.snapshot.paramMap.get("id");
    this.requestReferenceId = this.activatedRoute.snapshot.paramMap.get("ref");
    this.getAttachmentPreview();
  }

  /**
   * Initializes the IBAN form
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private initForms() {
    this.attachmentFormGroup = this.formBuilder.group({
      userId: [null, Validators.required],
      requestReferenceId: [null, Validators.required],
      attachments: [null, Validators.required],
    });
  }
  jwtHelper = new JwtHelperService();
  // get decoded reference ID for get attachment preview
  // getDecodedReferenceId() {
  //   return this.jwtHelper.decodeToken(this.requestReferenceId);
  // }
  // get the attachment preview
  getAttachmentPreview() {
    // this.getDecodedReferenceId();
    if (this.requestReferenceId) {
      this.orderReviewService.getAttachment(this.requestReferenceId).subscribe(
        (response) => {
          response.forEach((elm, index) => {
            this.attachmentObj["image" + elm.imageID] = elm.imageMedia;
          });
        },
        (error) => {
          Swal.fire({
            title: this.lang === "ar" ? " خطأ !" : "Error!",
            text:
              this.lang === "ar" ? "لقد حدث خطأ !" : "Something went wrong!",
            icon: "error",
          });
        }
      );
    }
  }
  initUploader() {
    this.uploader = new FileUploader({ url: "", autoUpload: false });
  }

  get $trigger(): Observable<void> {
    return this.trigger.asObservable();
  }

  get attachmentForm(): any {
    return this.attachmentFormGroup.controls;
  }

  snapshot(event: WebcamImage) {
    this.isImageCaptured = true;
    this.isShowCapturedImage = true;
    this.webcamImage = event;
    this.previewImage = event.imageAsDataUrl;
    this.stopCamera();
  }

  // capture user image
  captureImage() {
    this.trigger.next();
  }

  // set image after capturing
  onSetImage() {
    this.isShowCapturedImage = false;
    const file = this.handleCapturedImage(this.webcamImage);
    this.onFileUploaded(file, this.imageId);
    this.modalService.dismissAll();
  }

  handleCapturedImage(webcamImage: WebcamImage): any {
    this.webcamImage = webcamImage;
    const arr = this.webcamImage.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], this.vehicleImages[this.imageId - 1], {
      type: "image/png",
    });
    return file;
  }

  // recapture image
  onReCaptureImage() {
    this.isImageCaptured = false;
    this.isShowCapturedImage = false;
  }

  // capture webcam image
  onCaptureImage(modal: any, id: number) {
    this.imageId = id;
    this.isImageCaptured = false;

    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { min: 330, ideal: 1920, max: 440 },
          height: { min: 220, ideal: 1080, max: 260 },
        },
      })
      .then((res) => {
        this.stream = res;
        this.stream2 = res;
        this.openWebcamModal(modal);
      })
      .catch((err: WebcamInitError) => {
        this.isCaptrueImageFailed = true;
        setTimeout(() => (this.isCaptrueImageFailed = false), 10000);
        if (err) {
          this.isCameraNotAllawed = true;
          this.errorMessage =
            this.lang === "ar"
              ? "عفوآ، الكاميرا غير متاحة"
              : "Camera device not available";
        }
      });
  }

  openWebcamModal(modal: any) {
    // If closed
    if (!this.isWebcamModalDisplayed) {
      // Open the modal
      this.modalService.open(modal, {
        size: "lg",
        centered: true,
        beforeDismiss: () => {
          this.stopCamera();
          // Switch flag when dissmissed to uncheck the checkbox
          this.isWebcamModalDisplayed = false;
          // Close the modal
          return true;
        },
      });
      // Set flag to check the checkbox
      this.isWebcamModalDisplayed = true;
    }
  }

  /**
   * Displays an alert with the retreived message(s) from an API response
   * @private
   * @param {('ErrorAlert' | 'SuccessAlert')} alertType Specifies the alert type of Success or Error
   * @param {string} message The message to be displayed on the alert
   * @memberof AuthenticationPageComponent
   */

  private displayErrorAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    // Set error message
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.errorMessage = apiResponse.responseMessage;

        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.validationErrors = errorsArr;
        } else {
          this.validationErrors = null;
        }

        break;
      case "SuccessAlert":
        // Set the success message
        this.successMessage = apiResponse.responseMessage;
        break;
      default:
        break;
    }
  }

  // stopping camera
  stopCamera() {
    this.stream.getVideoTracks().forEach((track) => {
      track.stop();
    });
  }

  onFileSelected(event, id) {
    const file: any = event.target.files[0];
    const imgType = event.target.dataset.type;
    let inputsList = document.querySelectorAll("input[type=file]");
    let upLoadedImage: string = "";
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        let imgElem = document.querySelector(`#imagePreview-${imgType}`);
        upLoadedImage = `imagePreview-${imgType}`;
        imgElem.setAttribute("src", `${e.target.result}`);

        document.querySelector(`#add-${imgType}`).className = "d-none";
        document.querySelector(`#remove-${imgType}`).className = "d-block";
      };
      reader.readAsDataURL(file);
    }

    this.imageFile = file;
    let result = this.renameUploadedImg(file, id);

    // let blob = file.slice(0 , file.size , file.type)
    // let newfile = new File([blob], id +'_'+ file.name ,{type: 'image/jpg'});

    // Patch form value
    this.attachmentFormGroup.patchValue({
      userId: this.userId,
      requestReferenceId: this.requestReferenceId,
      attachments: result,
    });
    this.attachmentForm.attachments.updateValueAndValidity();

    const BreakError = {};
    this.state.attachment.tempAttachment = [];
    try {
      inputsList.forEach((input: any) => {
        let selectedFile = input.files[0];

        if (input.files.length !== 0) {
          // Check if uploaded img not expired after 24H //
          // let imgDate = new Date(selectedFile.lastModifiedDate);
          // let cDate = new Date();
          // let diffTime = (cDate.getTime() - imgDate.getTime()) / 1000;
          // diffTime /= 60 * 60; // to get Hours
          // let diffHours = Math.abs(Math.round(diffTime)) // get closet valid Hours

          // Preview Images
          const fileHandle: FileHandle = {
            file: selectedFile,
            url: this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(selectedFile)
            ),
          };
          this.state.attachment.tempAttachment.push(fileHandle);
          // if (diffHours > 24)
          // {
          //   this.uploadedImgValid = false
          //   throw BreakError;
          // }
          this.uploadedImgValid = true;
        }
      }); // end forEach
    } catch (err) {
      if (err !== BreakError) throw err;
    }

    this.isAttachmentLoading = true;
    var formData = new FormData();
    formData.append("userId", this.attachmentForm.userId.value);
    formData.append(
      "requestReferenceId",
      this.attachmentForm.requestReferenceId.value
    );
    formData.append("attachments", this.attachmentForm.attachments.value);
    // success case
    this.orderReviewService.previewAttachment(formData).subscribe(
      (res: any) => {
        this.isAttachmentLoading = false;
        this.isImageLoading = true;
        this.isFilesUploadSuccessfully = true;
        this.uploader.clearQueue();
        setTimeout(() => (this.isFilesUploadSuccessfully = false), 6000);
        if (this.checkUploadedImages()) {
          let message =
            this.lang === "en"
              ? "images uploaded seccessfully"
              : "تم رفع الصور بنجاح";
          res.responseMessage = message;
          this.displayErrorAlert("SuccessAlert", res);
        } else {
          this.displayErrorAlert("SuccessAlert", res);
        }
        if (upLoadedImage == "imagePreview-Vin")
          this.isChassisVehicleImageIsUploaded = true;
      },
      // Error Case
      (error) => {
        this.isAttachmentLoading = false;
        this.isCaptrueImageFailed = true;
        this.isImageCaptured = false;
        setTimeout(() => (this.isCaptrueImageFailed = false), 10000);
        let apiResponse: ApiResponse = {
          responseMessage:
            this.lang === "en"
              ? "sorry, some error occurred. Please try again later"
              : "عفوآ, حدث خطا اثناء تحميل الصورة الرجاء المحاولة لاحقآ",
          validationErrors: null,
        };
        this.displayErrorAlert("ErrorAlert", apiResponse);
      },
      () => {
        if (
          this.isFrontVehicleImageIsUploaded &&
          this.isBackVehicleImageIsUploaded &&
          this.isLeftVehicleImageIsUploaded &&
          this.isRightVehicleImageIsUploaded &&
          this.isChassisVehicleImageIsUploaded
        ) {
          this.isUploadCompelte = true;
        }
      }
    );

    // Check if the product was COMP and shuld upolad only 5 pic
    if (
      this.isFrontVehicleImageIsUploaded &&
      this.isBackVehicleImageIsUploaded &&
      this.isLeftVehicleImageIsUploaded &&
      this.isRightVehicleImageIsUploaded &&
      this.isChassisVehicleImageIsUploaded
    ) {
      this.isAttachmentLoading = false;
      this.isImagesUploadedCom = true;
      this.isImageCaptured = false;
      setTimeout(() => (this.isImagesUploadedCom = false), 4000);
      return;
    }
    // Check if the product was TPL and shuld upolad only 0 pic
    // if (this.quoteProduct.productTypeId === 1) {
    //   this.isAttachmentLoading = false;
    //   this.isImagesUploadedCom = true;
    //   this.isUploadCompelte = true;
    //   // setTimeout(() => (this.isImagesUploadedCom = false), 1000);
    //   return;
    // }
  }

  onFileUploaded(image: File, id: number) {
    const file: any = image;
    const imgType = image.type;
    const vehicleImageSelector = ["front", "back", "right", "left", "Vin"];
    let inputsList = document.querySelectorAll("input[type=file]");
    let upLoadedImage: string = "";

    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        let imgElem = document.querySelector(
          `#imagePreview-${vehicleImageSelector[id - 1]}`
        );
        upLoadedImage = vehicleImageSelector[id - 1];
        imgElem.setAttribute("src", `${e.target.result}`);

        document.querySelector(
          `#add-${vehicleImageSelector[id - 1]}`
        ).className = "d-none";
        document.querySelector(
          `#remove-${vehicleImageSelector[id - 1]}`
        ).className = "d-block";
      };
      reader.readAsDataURL(file);
    }

    this.imageFile = file;
    let result = this.renameUploadedImg(file, id);

    // let blob = file.slice(0 , file.size , file.type)
    // let newfile = new File([blob], id +'_'+ file.name ,{type: 'image/jpg'});

    // Patch form value
    this.attachmentFormGroup.patchValue({
      userId: this.userId,
      requestReferenceId: this.requestReferenceId,
      attachments: result,
    });
    this.attachmentForm.attachments.updateValueAndValidity();

    const BreakError = {};
    this.state.attachment.tempAttachment = [];
    try {
      let selectedFile = image;
      if (image != null || image !== undefined) {
        // Preview Images
        const fileHandle: FileHandle = {
          file: selectedFile,
          url: this.sanitizer.bypassSecurityTrustUrl(
            window.URL.createObjectURL(selectedFile)
          ),
        };
        this.state.attachment.tempAttachment.push(fileHandle);
        // if (diffHours > 24)
        // {
        //   this.uploadedImgValid = false
        //   throw BreakError;
        // }
        this.uploadedImgValid = true;
      }
    } catch (err) {
      if (err !== BreakError) throw err;
    }

    this.isAttachmentLoading = true;
    var formData = new FormData();
    formData.append("userId", this.attachmentForm.userId.value);
    formData.append(
      "requestReferenceId",
      this.attachmentForm.requestReferenceId.value
    );
    formData.append("attachments", this.attachmentForm.attachments.value);
    // success case
    if (formData != null || formData !== undefined) {
      this.orderReviewService.previewAttachment(formData).subscribe(
        (res: any) => {
          this.isAttachmentLoading = false;
          this.isImageLoading = true;
          this.isFilesUploadSuccessfully = true;
          if (upLoadedImage == "front")
            this.isFrontVehicleImageIsUploaded = true;
          else if (upLoadedImage == "back")
            this.isBackVehicleImageIsUploaded = true;
          else if (upLoadedImage == "right")
            this.isRightVehicleImageIsUploaded = true;
          else if (upLoadedImage == "left")
            this.isLeftVehicleImageIsUploaded = true;
          else if (upLoadedImage == "Vin")
            this.isChassisVehicleImageIsUploaded = true;
          this.uploader.clearQueue();
          setTimeout(() => (this.isFilesUploadSuccessfully = false), 6000);
          if (this.checkUploadedImages()) {
            let message =
              this.lang === "en"
                ? "images uploaded seccessfully"
                : "تم رفع الصور بنجاح";
            res.responseMessage = message;
            this.displayErrorAlert("SuccessAlert", res);
          } else {
            this.displayErrorAlert("SuccessAlert", res);
          }
        },
        // Error Case
        (error) => {
          this.isAttachmentLoading = false;
          this.isCaptrueImageFailed = true;
          this.isImageCaptured = false;
          setTimeout(() => (this.isCaptrueImageFailed = false), 10000);
          let apiResponse: ApiResponse = {
            responseMessage:
              this.lang === "en"
                ? "sorry, some error occurred. Please try again later"
                : "عفوآ, حدث خطا اثناء تحميل الصورة الرجاء المحاولة لاحقآ",
            validationErrors: null,
          };
          this.displayErrorAlert("ErrorAlert", apiResponse);
        },
        () => {
          if (
            this.isFrontVehicleImageIsUploaded &&
            this.isBackVehicleImageIsUploaded &&
            this.isLeftVehicleImageIsUploaded &&
            this.isRightVehicleImageIsUploaded &&
            this.isChassisVehicleImageIsUploaded
          ) {
            this.isUploadCompelte = true;
          }
        }
      );
    }

    // Check if the product was COMP and shuld upolad only 5 pic
    if (
      this.isFrontVehicleImageIsUploaded &&
      this.isBackVehicleImageIsUploaded &&
      this.isLeftVehicleImageIsUploaded &&
      this.isRightVehicleImageIsUploaded &&
      this.isChassisVehicleImageIsUploaded
    ) {
      this.isAttachmentLoading = false;
      this.isImagesUploadedCom = true;
      this.isImageCaptured = false;
      setTimeout(() => (this.isImagesUploadedCom = false), 4000);
      return;
    }
    // Check if the product was TPL and shuld upolad only 0 pic
    // if (this.quoteProduct.productTypeId === 1) {
    //   this.isAttachmentLoading = false;
    //   this.isImagesUploadedCom = true;
    //   this.isUploadCompelte = true;
    //   // setTimeout(() => (this.isImagesUploadedCom = false), 1000);
    //   return;
    // }
  }

  // check if all images is uploaded
  checkUploadedImages(): boolean {
    if (
      this.isFrontVehicleImageIsUploaded &&
      this.isBackVehicleImageIsUploaded &&
      this.isLeftVehicleImageIsUploaded &&
      this.isRightVehicleImageIsUploaded &&
      this.isChassisVehicleImageIsUploaded
    )
      return true;
    else return false;
  }

  renameUploadedImg(file, id) {
    const myNewFile = new File([file], `${id}_${file.name}.png`, {
      type: file.type,
    });

    return myNewFile;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
}
