import { Validators } from "@angular/forms";
import { PurchaseResponse } from "./../../../models/order-preview/PurchaseResponse";
import { Component, OnInit } from "@angular/core";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { PolicyPurchase } from "src/app/models/order-preview/PolicyPurchase";
import { AppService } from "src/app/services/app/app.service";
import { InvoiceService } from "src/app/services/checkout/invoice.service";
import { OrderReviewService } from "src/app/services/checkout/order-review.service";
import { environment } from "src/environments/environment";
import { ApiResponse } from "src/app/payload/responses/ApiResponse";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Email } from "src/app/models/master-table/email";
import { QuoteService } from "src/app/services/quote/quote.service";
import { FormBuilder, FormGroup } from "@ngneat/reactive-forms";
import { ActivatedRoute } from "@angular/router";
import { Identity, IdentityResponse } from "src/app/models/profile/Identity";
import { ProfileService } from "src/app/services/profile/profile.service";
declare var require: any;
const FileSaver = require("file-saver");

@Component({
  selector: "app-payment-status",
  templateUrl: "./payment-status.component.html",
  styleUrls: ["./payment-status.component.css"],
})
export class PaymentStatusComponent implements OnInit {
  lang;
  purchaseRespone: PurchaseResponse;
  loaclStoredPurchaseResponse: PolicyPurchase;
  purchaseRespones: PurchaseResponse;
  transactionId = this.activatedroute.snapshot.params["id"];
  /*UI*/
  isPurchaseRespone;
  errorMessage: string;
  validationErrors: string[];
  successMessage;
  isErrorAlertVisible = false;
  isLoading = true;
  sharePolicyFormGroup: FormGroup<Email>;
  alertMessage = "";
  isPolicySelected;
  isSubmitting: boolean = false;
  isSuccess;
  clientId: string;
  policyUrl: string;
  identities: Identity[];
  isSharePolicyLoading: boolean = false;
  constructor(
    private appService: AppService,
    private invoiceService: InvoiceService,
    private orderReviewService: OrderReviewService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private quoteService: QuoteService,
    private activatedroute: ActivatedRoute,
    private profileService: ProfileService
  ) {}
  ngOnInit() {
    this.InquiryCall();
    this.getPurchaseResponse();
    window.scroll({ top: 0 });
    // Get the checkout ID from URL and check the payment status
    this.appService.getAppLang().subscribe((appLang) => (this.lang = appLang));
    //this.PaymentResponseCallBack();
    this.sharePolicyFormGroup = this.fb.group({
      //phoneNumber: [null, [Validators.required, Validators.pattern(/^5[0-9]*$/), Validators.minLength(9), Validators.maxLength(9)]],
      email: [
        null,
        [
          Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
          Validators.required,
        ],
      ],
      message: [null, Validators.required],
    });
  }
  //download Invoice
  downloadPdf(): any {
    this.invoiceService
      .download(this.purchaseRespone.documents[1].url)
      .subscribe((blob) => {
        const a = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        a.href = objectUrl;
        // a.download = 'Plicy.zip';
        a.download = this.purchaseRespone?.documents[1].name; // to download with name of document
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  getCompanyLogo(): string {
    return (
      environment.apiAssetsUrl +
      "IC/" +
      this.purchaseRespone?.insuranceCompanyLogo
    );
  }

  getIdentities() {
    this.profileService.getIdentities().subscribe((res: IdentityResponse) => {
      this.identities = res.data;
      if (this.identities.length > 0) {
        this.clientId = this.identities[0].clientId;
      }
    });
  }

  InquiryCall() {
    const Jwt = localStorage.getItem(LocallyStoredItemsKeys.JWT);
    if (Jwt) {
      this.getIdentities();
      let data = {
        clientId: this.clientId,
      };
      this.orderReviewService.Inquiry(data).subscribe((res) => {
        if (res.isSuccess === true) {
          this.displayErrorAlert("SuccessAlert", res);
        } else this.displayErrorAlert("ErrorAlert", res);
      });
    }
  }

    PaymentResponseCallBack() {
      console.log("inside")
      let purchaseRequest: PolicyPurchase = {};
      purchaseRequest.clientPaymentId = this.loaclStoredPurchaseResponse.clientPaymentId;
      purchaseRequest.clientQuoteId = this.loaclStoredPurchaseResponse.clientQuoteId;
      purchaseRequest.pgReferenceId = this.loaclStoredPurchaseResponse.data.pgReferenceId;

      console.log("purchaseRequest",purchaseRequest)
      this.orderReviewService.policyPurchase(purchaseRequest).subscribe((res: any) => {
        console.log("RESSS",res)
        this.purchaseRespone = res.data;
      },
         erro => {
         }
      )
   }

  // getPurchaseResponse() {
  //   let purchaseRequest=JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.purchaseResponse))
  //   console.log("The purchaseRequest is", purchaseRequest)
  //   this.orderReviewService.policyPurchase(purchaseRequest).subscribe((res: any) => {
  //     console.log("RESSS",res)
  //     this.purchaseRespone = res.data;
  //    },
  //    err => {
  //     // this.alert = true
  //     // this.isLoading = false;
  //     console.log("Error in get Purchase Response",err.error);
  //  }
  //    )
  // }
  private displayErrorAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    // Set error message
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.errorMessage = apiResponse.responseMessage;
        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];
          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );
          // Set the validation errors
          this.validationErrors = errorsArr;
        } else {
          this.validationErrors = null;
        }
        break;
      case "SuccessAlert":
        // Set the success message
        this.successMessage = apiResponse.responseMessage;
        break;
      default:
        break;
    }
  }

  //  Display purchase Warnings
  private displayWarning(apiResponse: ApiResponse): void {
    // Set error message
    this.errorMessage = apiResponse.responseMessage;

    // Set validation errors
    if (
      apiResponse.validationErrors &&
      apiResponse.validationErrors.length > 0
    ) {
      // Init empty array
      let errorsArr: string[] = [];
      // Push the errors into the array
      apiResponse.validationErrors.forEach((err) =>
        errorsArr.push(err.description)
      );
      // Set the validation errors
      this.validationErrors = errorsArr;
    } else {
      this.validationErrors = null;
    }
    //Display Warning
    this.isErrorAlertVisible = true;
    // setTimeout(() => this.isWarningVisible = false, 20000);
  }

  // get purchase response
  getPurchaseResponse() {
    let purchaseRequest = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.purchaseResponse)
    );
    this.orderReviewService.policyPurchase(purchaseRequest).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.isPurchaseRespone = true;
        localStorage.setItem(
          LocallyStoredItemsKeys.PurchaseResponseView,
          JSON.stringify(res.data)
        );
        this.purchaseRespone = res.data;
        this.policyUrl = res.data?.documents[0].url;
      },
      (error) => {
        this.isLoading = false;
        this.isPurchaseRespone = false;
        this.displayWarning(error.error);
      }
    );
  }

  // get form controls
  get sharePolicyFormControl() {
    return this.sharePolicyFormGroup.controls;
  }
  // share modal
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  //show email input
  toggleEmailInput() {
    this.isPolicySelected = true;
  }
  //post modal data to email
  shareUrlToEmail() {
    this.isSharePolicyLoading = true;
    this.isSubmitting = true;
    // Validate form
    // if (this.shareUrlQouteFormGroup.invalid) {
    //    this.isLoading = false;
    //    return;
    // }
    const Share: Email = {
      // phoneNumber: this.SharebyFormGroup.get('phoneNumber').value,
      email: this.sharePolicyFormGroup.get("email").value,
      message: this.policyUrl,
    };
    this.quoteService.PostUrlToEmail(Share).subscribe(
      (response) => {
        this.isSuccess = true;
        this.displayWarning(response);
        setTimeout(() => (this.isSuccess = false), 5000);
        this.isSharePolicyLoading = false;
        this.isSubmitting = false;
        this.sharePolicyFormGroup.reset();
      },
      (error) => {
        // console.warn(error.responseText)
        this.isSharePolicyLoading = false;
      }
    );
  }
}
