<div class="input-group">
  <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="date" (ngModelChange)="pickedDate($event)" name="dp"
    ngbDatepicker [minDate]="{year: startDateYear, month: 1, day: 1}"
    [maxDate]="{year: maxDateYear,  month: 12, day: 31}" #d="ngbDatepicker" (click)="d.open()" [disabled]="disabled ">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary calendar-icon" (click)="d.open()" type="button" [disabled]="disabled">
      <i class="icon ion-md-calendar"></i>
    </button>
  </div>
</div>
