import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { FormBuilder, FormControl, FormGroup } from "@ngneat/reactive-forms";
import { Email } from "src/app/models/master-table/email";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { Product } from "src/app/models/quote/Product";
import { Quote } from "src/app/models/quote/Quote";
import { ApiResponse } from "src/app/payload/responses/ApiResponse";
import { AppService } from "src/app/services/app/app.service";
import { ComparisonQuoteService } from "src/app/services/quote/comparison-quote.service";
import { QuoteService } from "src/app/services/quote/quote.service";

@Component({
  selector: "app-quotes-filters",
  templateUrl: "./quotes-filters.component.html",
  styleUrls: ["./quotes-filters.component.css"],
})
export class QuotesFiltersComponent implements AfterViewInit, OnInit {
  // UI
  isLoading: Boolean = false;
  isBtnShown = false;
  isShareQuoteLoading: boolean = false;
  isDataAccordionShown = false;
  totalQuotes: number;
  thirdPartyProductCounterTa: number = 0;
  GGi: number = 0;
  GGithirdParty: number = 0;
  alertMessage: boolean = false;
  thirdPartyProduct: number;
  comprehensiveProduct: number;
  otherProduct: number = 0;
  other: boolean = false;
  @Input() quoteProduct?: Product;
  @Output() toggleDataAccordion = new EventEmitter<any>();
  shareQuoteUrl: string = "";
  url: string;
  selectedProductId: number;
  lang: string;
  closeResult = "";
  isEmailSelected: boolean = false;
  shareUrlQouteFormGroup: FormGroup<Email>;
  isSubmitting: boolean = false;
  isFormValid = false;
  //warning msg
  isSuccess = false;
  errorMessage: string;
  validationErrors: string[];
  isErrorAlertVisible = false;
  /* Data */
  selectedSortType: { ascending: boolean; descending: boolean } = {
    ascending: true,
    descending: false,
  };
  constructor(
    private comparisonQuoteService: ComparisonQuoteService,
    private quoteService: QuoteService,
    private appService: AppService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    // this.filterProducts = this.products;
  }
  ngOnInit(): void {
    this.getLenghtOfProductType(),
      this.appService.getAppLang().subscribe((language) => {
        this.lang = language;
      });
    this.checkQuoteProductTypes();
    this.shareUrlQouteFormGroup = this.fb.group({
      //phoneNumber: [null, [Validators.required, Validators.pattern(/^5[0-9]*$/), Validators.minLength(9), Validators.maxLength(9)]],
      email: [
        null,
        [
          Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
          Validators.required,
        ],
      ],
      message: new FormControl(null, Validators.required),
    });
  }

  // product = this.quoteService.sortByPriceDescending;

  ngAfterViewInit() {
    // this.initSortingOrder();
  }

  checkQuoteProductTypes(): boolean {
    this.quoteService.getQuotes().value.forEach((quote) => {});
    return true;
  }

  /************************************************************
   *   INITIALIZATION
   ************************************************************/

  /**
   * Initializes quotes sorting order
   */
  initSortingOrder() {
    // this.quoteService.sortByPriceAscending();
  }

  toggleSortType() {
    this.isBtnShown = !this.isBtnShown;
    this.selectedSortType = {
      ascending: !this.selectedSortType.ascending,
      descending: !this.selectedSortType.descending,
    };

    if (this.selectedSortType.ascending)
      this.quoteService.sortByPriceAscending();
    else this.quoteService.sortByPriceDescending();
  }

  filterProduct(productTypeId?) {
    this.selectedProductId = productTypeId;
    this.quoteService.displayedProductType.next(productTypeId);
  }

  resetFilters() {
    this.quoteService.resetQuotes();
    this.selectedSortType = { ascending: true, descending: false };
  }
  /**
   * Adds all current quotes to comparison list
   *
   * @memberof QuotesFiltersComponent
   */
  onCompareAllQuotes(): void {
    this.quoteService.compareAllQuotes();
  }

  getLenghtOfProductType() {
    let quotes = this.quoteService.getQuotes().value;
    let x = 0;
    quotes.forEach((quote) => {
      if (quote.products.length > 1) {
        x++;
      }
    });
    this.totalQuotes = quotes.length;

    /* for count the  third Party Product or comprehensive Product */
    for (var y = 0; y < quotes.length; y++) {
      if (quotes[y].products.length > 1) {
        if (
          quotes[y].products[1].productTypeId === 5 ||
          quotes[y].products[1].productTypeId === 3
        ) {
          this.thirdPartyProductCounterTa = this.thirdPartyProductCounterTa + 2;
        } else if (quotes[y].products[0].productTypeId === 1) {
          this.GGi = this.GGi + 1;
        } else {
          this.GGithirdParty = this.GGithirdParty + 1;
        }
      }
    }
    this.thirdPartyProduct = quotes.filter(
      (item) =>
        item.products[0].productTypeId == 1 ||
        item.products[0].productTypeId == 5
    ).length;

    this.comprehensiveProduct = quotes.filter(
      (item) =>
        item.products[0].productTypeId == 2 ||
        item.products[0].productTypeId == 6
    ).length;

    this.otherProduct = quotes.filter(
      (item) =>
        item.products[0].productTypeId != 1 &&
        item.products[0].productTypeId != 2
    ).length;

    // quotes.filter((item) => {
    //   item.products.filter((product) => {
    //     if (
    //       product.name != "Comprehensive Insurance" &&
    //       product.name != "Third-Party Insurance (TPL)"
    //     ) {
    //       ++this.otherProduct;
    //     }
    //   });
    // });
  }
  //  Display Warnings msg
  private displayWarning(apiResponse: ApiResponse): void {
    // Set error message
    this.errorMessage = apiResponse.responseMessage;

    // Set validation errors
    if (
      apiResponse.validationErrors &&
      apiResponse.validationErrors.length > 0
    ) {
      // Init empty array
      let errorsArr: string[] = [];
      // Push the errors into the array
      apiResponse.validationErrors.forEach((err) =>
        errorsArr.push(err.description)
      );
      // Set the validation errors
      this.validationErrors = errorsArr;
    } else {
      this.validationErrors = null;
    }
    //Display Warning
    this.isErrorAlertVisible = true;
    // setTimeout(() => this.isWarningVisible = false, 20000);
  }

  //Get quote url value from localstorage
  getQuoteUrl() {
    const userQuotesResponse: any = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.UserQuoteResponse)
    );
    this.shareQuoteUrl = userQuotesResponse.shareQuoteUrl;
    // let quoteId = this.shareQuoteUrl.split('?')[0].split('/').pop();
    //     localStorage.setItem(LocallyStoredItemsKeys.QuoteId, quoteId);
  }

  // share modal
  shareQuote(content) {
    this.isShareQuoteLoading = true;
    let quoteReqId = this.quoteService.getQuotes().value[0].quotationReqtId;
    let request = {
      quoteReqId: quoteReqId,
    };
    if (this.shareQuoteUrl === "" || this.shareQuoteUrl === null) {
      this.quoteService.getQuoteUrl(request).subscribe((res) => {
        if (res.isSuccess) {
          this.shareQuoteUrl = res.shortenUrl;
          this.isShareQuoteLoading = false;
          if (this.shareQuoteUrl !== "" || this.shareQuoteUrl !== null) {
            this.modalService
              .open(content, { ariaLabelledBy: "modal-basic-title" })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(
                    reason
                  )}`;
                }
              );
          }
        }
      });
    } else {
      this.isShareQuoteLoading = false;
      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  //copy URL from input
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
    if (inputElement) {
      this.alertMessage = true;
      setTimeout(() => (this.alertMessage = false), 2000);
    }
  }

  get shareUrlFormControl() {
    return this.shareUrlQouteFormGroup.controls;
  }

  //post modal data to email
  shareUrlToEmail() {
    this.isLoading = true;
    this.isSubmitting = true;
    // Validate form
    // if (this.shareUrlQouteFormGroup.invalid) {
    //    this.isLoading = false;
    //    return;
    // }
    const Share: Email = {
      // phoneNumber: this.SharebyFormGroup.get('phoneNumber').value,
      email: this.shareUrlQouteFormGroup.get("email").value,
      message: this.shareQuoteUrl,
    };
    this.quoteService.PostUrlToEmail(Share).subscribe(
      (response) => {
        this.isSuccess = true;
        this.displayWarning(response);
        setTimeout(() => (this.isSuccess = false), 5000);
        this.isLoading = false;
        this.isSubmitting = false;
        //this.restForm();
      },
      (error) => {
        // console.warn(error.responseText)
        this.isLoading = false;
      }
    );
  }

  //show email input
  toggleEmailInput() {
    this.isEmailSelected = true;
  }
}
