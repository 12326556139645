<div *ngIf="isLoadingQuotes" class="loader-card boxed-item mb-3">
  <div class="content-container loader-wrapper flex-centered flex-container">
    <div class="lds-facebook flex-centered">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>

<div *ngIf="!isLoadingQuotes">
  <div *ngFor="let quote of quotes">
    <ul style="margin: auto;">
      <li class="quote-item" *ngFor="let product of quote.products">
        <app-quote *ngIf=" displayedProductType == 0" [quote]="quote" [quoteProduct]="product"></app-quote>
        <app-quote
          *ngIf="((product.productTypeId == displayedProductType || product.productTypeId == 2 || product.productTypeId == 6) && displayedProductType ==2)"
          [quote]="quote" [quoteProduct]="product"></app-quote>
        <app-quote *ngIf="(( product.productTypeId == 1 ||product.productTypeId == 5 ) && displayedProductType == 1)"
          [quote]="quote" [quoteProduct]="product"></app-quote>
        <app-quote *ngIf="(( product.productTypeId == 3) && displayedProductType == 3)" [quote]="quote"
          [quoteProduct]="product"></app-quote>
      </li>
    </ul>
  </div>
</div>