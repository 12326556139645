import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/services/app/app.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.css"],
})
export class PrivacyPolicyComponent implements OnInit {
  isCollapsed: boolean = false;
  lang: string;
  finalJson: any;
  PrivacyPolicy: any[] = [
    {
      id: "q1",
      questionAr: "التعريفات",
      questionEn: "Definitions",
      answerAr:
        '(-) "نحن" "الخاصة بنا" "إلينا": موقع وازن <a href="https://www.wazen.com" target="_blank">www.wazen.com</a> أو تطبيق وازن يتم تشغيل هذا الموقع من قبل وازن؛ توجد تفاصيل إضافية عن خدمة وازن في بنود استخدام الموقع الخاص بنا. <br>' +
        '(-) "أنت" "الخاصة بك": مستخدم موقع أو تطبيق وازن<br>' +
        '(-) "شركات التأمين": الشركات المصرح لها ممارسة نشاط التأمين من قبل هيئة التأمين والمتعاقدة معنا لإظهار عروضها خلال موقعنا وتطبيقنا.<br>',
      answerEn:
        '(-) "We" "Our" "Our": Wazen website <a href="https://www.wazen.com" target="_blank">www.wazen.com</a> or Wazen application This website is operated by Wazen; Additional details about the Wazen service are available in our website terms of use.<br>' +
        '(-) "You" "Your": the user of the Wazen website or application.<br>' +
        '(-) "Insurance companies": companies authorized to practice insurance activity by the Insurance authority and contracted with us to display their offers through our website and application.<br>',
    },
    {
      id: "q2",
      questionAr: "نطاق السياسة",
      questionEn: "Scope of the policy",
      answerAr:
        "يرجى العلم بأن سياسة الخصوصية هذه تنطبق فقط على هذا الموقع. ففي هذا الموقع، قد ترى روابط أو مراجع لمواقع ويب أخرى لا تغطيها هذه السياسة، ومن المرجح أن يكون لها ممارسات ومعلومات مختلفة. ولذلك، فإننا نشجعك على قراءة سياسة الخصوصية لكل موقع ويب تزوره. ونحن نقدم هذه المراجع والروابط لراحتك، ولا ينبغي أن تفسر ذلك على اننا نؤيد هذه المواقع، ومحتواها، أو ممارساتها المعلوماتية. ولذلك فأنت وحدك المسؤول عن أي تفاعلات قد تقوم بها مع مثل هذه المواقع.",
      answerEn:
        "Please note that this Privacy Policy applies only to this site. On this site, you may see links or references to other websites that are not covered by this policy and are likely to have different practices and information. Therefore, we encourage you to read the privacy policy of every website you visit. We provide these references and links for your convenience, and this should not be construed as an endorsement of these sites, their content, or their information practices. Therefore, you are solely responsible for any interactions you may have with such sites.",
    },
    {
      id: "q3",
      questionAr: "عدم الضمان",
      questionEn: "No warranty",
      answerAr:
        "(-) نظرًا لطبيعية الإنترنت، لا تضمن خدمة وازن خلو هذا الموقع أو جهاز الخادم الذي يتيحه من الفيروسات أو العناصر الضارة الأخرى أو عدم إرسال الأشياء المفسدة أو المتلفة الأخرى أو عدم حدوث أي ضرر لجهاز الحاسوب الخاص بك. وتتحمل المسئولية وحدك عن الحماية الملائمة والنسخ الاحتياطي للمعلومات و/أو الأجهزة وعن اتخاذ الاحتياطات المعقولة والملائمة لفحص جهاز الحاسوب للتأكد من خلوه من الفيروسات أو الأشياء المتلفة الأخرى. ويتحمل المستخدم كامل تكلفة جميع عمليات الصيانة والإصلاح والتصحيح الضرورية.<br>" +
        "(-) إننا لا نقدم أي إقرارات، أو ضمانات بخصوص دقة، أو تشغيل، أو أداء أي برنامج خاص للغير قد يتم استخدامه فيما يتعلق بالموقع.<br>" +
        '(-) يتم توفير المعلومات على هذا الموقع على أساس "ما هو متاح". وتستثنى جميع الضمانات الصريحة أو الضمنية من أي نوع (بما في ذلك الضمانات القابلة للتسويق، وملاءمة الغرض، وعدم التعدي على الملكية الفكرية لدى الغير) إلى أقصى حد يسمح به القانون المعمول به. ونحن لا نقدم أي ضمان ضد توقف الخدمة أو تقديم خدمة خالية من الأخطاء، أو أن الموقع سوف يوفر أي نتائج بحث محددة. وقد نقوم بتغيير المعلومات من وقت لآخر دون سابق إنذار ودون أي التزام بالحفاظ على المعلومات محدثة، أو أي إقرار، أو ضمان بخصوص دقة، أو اكتمال المعلومات.<br>' +
        "(-) نحن لسنا مسؤولين عن أفعال، أو تقاعس أي عملاء، أو مستخدمين آخرين للموقع، أو المعلنين على الموقع، أو الغير بالموقع، أو الأحداث، أو الخدمات، أو المنتجات المقدمة من أي من منظمي الفعاليات، أو مكان الفعاليات، أو المروج، أو المنتج، أو الغير فيما يتعلق بأي حدث أو منتج يباع على الموقع.</br>" +
        "(-) ويتم التحكم في الموقع وتشغيله من قبل مكاتبنا في المملكة العربية السعودية. ونحن لا نقر أو نضمن أن المعلومات مناسبة أو متاحة للاستخدام في ولايات قضائية تابعة لدول اخرى سواء في الخليج العربي او غيرها. يمكنك استخدام هذا الموقع فقط لأغراض قانونية ووفقا للشروط والأحكام [رابط يؤدي الضغط عليه الوصول الى الشروط والأحكام الخاصة بوازن].</br>",
      answerEn:
        "(-) Due to the nature of the Internet, Wazen does not guarantee that this site or the server that makes it available is free of viruses or other harmful elements, that contaminating or other destructive objects will not be sent, or that no damage will occur to your computer. You are solely responsible for adequately protecting and backing up information and/or equipment and for taking reasonable and appropriate precautions to scan your computer for viruses or other destructive things. The user bears the full cost of all necessary maintenance, repair, and correction.<br>" +
        "(-) We make no representations or warranties about the accuracy, operation, or performance of any third-party software that may be used in connection with the Site.<br>" +
        '(-) The information on this website is provided on an "as available" basis. All express or implied warranties of any kind (including the warranties of merchantability, fitness for purpose, and non-infringement of third-party intellectual property) are excluded to the fullest extent permitted by applicable law. We make no warranty that the Service will be interrupted that the Service will be error-free, or that the Site will provide any specific search results. We may change the information from time to time without notice and without any obligation to keep the information up to date, or any representation or warranty as to the accuracy or completeness of the information.<br>' +
        "(-) We are not responsible for the actions or inaction of any customers, other users of the Site, advertisers on the Site, third parties on the Site, or the events, services, or products provided by any event organizer, event venue, promoter, producer, or others in connection with any event or product sold on the Site.</br>" +
        '(-) The Site is controlled and operated by our offices in the Kingdom of Saudi Arabia. We do not represent or guarantee that the information is appropriate or available for use in the jurisdictions of other countries, whether in the Arabian Gulf or elsewhere. You may use this website only for lawful purposes and in accordance with the terms and conditions [clicking on a link lead to Wazen"s terms and conditions].',
    },
    {
      id: "q4",
      questionAr: "الدفع عبر الإنترنت",
      questionEn: "Payment online",
      answerAr:
        "(-) إذا كنت قد اخترت إجراء الدفع عبر الإنترنت مقابل وثيقة التأمين الخاصة بك عن طريق بطاقة الائتمان، سوف يطلب منك إدخال تفاصيل بطاقة الائتمان الخاصة بك على الانترنت، وسوف نسعى للحصول على إذن لسحب المبلغ المحدد من بطاقة الائتمان الخاصة بك.<br>" +
        '(-) يتم تشفير المعلومات الشخصية التي تقوم بإدخالها باستخدام بروتوكول طبقة المقابس الآمنة (SSL). وما يعنيه ذلك هو أن جميع المعلومات الخاصة بك يتم إرسالها من خلال "مسار" آمن بينك وبين موقع وازن مما يجعلها غير مقروءة عندما يتم نقلها.<br>',
      answerEn:
        "(-) If you have chosen to make an online payment for your insurance policy by credit card, you will be asked to enter your credit card details online and we will seek your authorization to debit the specified amount from your credit card.<br>" +
        '(-) The personal information you enter is encrypted using the Secure Socket Layer (SSL) protocol. What this means is that all your information is sent through a secure "path" between you and the Wazen website, making it unreadable when it is transmitted.<br>',
    },
    {
      id: "q5",
      questionAr: "كيف نستخدم معلوماتك ومع من نشاركها",
      questionEn: "How we use your information and with whom we share it",
      answerAr:
        "خصوصيتك مهمة جدًا بالنسبة لنا وقد أعددنا بيان سياسة الخصوصية هذا بهدف الحفاظ على معلوماتك والاحتفاظ بثقتك واحترام حقك في حماية معلوماتك الشخصية، وسوف نستخدم معلوماتك لإدارة التأمين الخاص بك لدينا، بما في ذلك ضمان التأمين، معالجة المطالبات والتحليل الإحصائي. وقد يشتمل ذلك على الكشف عن معلوماتك داخل خدمة وازن إلى الجهات ذات العلاقة، والوكلاء، وشركات التأمين المصرح لهم من قبل الجهات الرسمية. إننا لا نكشف عن معلوماتك لأي شخص خارج خدمة وازن، إلا في الحالات التالية: </br> " +
        "إذا حصلنا على إذن منك بذلك؛ أو</br>" +
        "إذا طُلب منا أو سُمح لنا ذلك بموجب القانون؛ أو</br>" +
        "إلى الشركات الأخرى المصرحة من قبل الجهات الرسمية التي تقدم خدمة لنا أو لك؛ أو</br>" +
        "إذا جاز لنا نقل الحقوق والالتزامات بموجب بيان سياسة الخصوصية هذا.</br>",
      answerEn:
        'Your privacy is very important to us, and we have prepared this Privacy Policy Statement with the aim of protecting your information, retaining your trust, and respecting your right to protect your personal information. We will use your information to administer your insurance with us, including insurance underwriting, claims processing, and statistical analysis. And he may be distracted This involves disclosing your information within the insurance service to the relevant authorities, agents, and insurance companies authorized by the official authorities. We don"t reveal.Disclose your information to anyone outside of my insurance service, except in the following cases:</br>' +
        "If we have your permission to do so; or</br>" +
        "If we are required or permitted to do so by law; or</br>" +
        "To other companies authorized by official authorities that provide a service to us or to you; or</br>" +
        "If we may transfer rights and obligations under this Privacy Policy Statement.</br>",
    },
    {
      id: "q6",
      questionAr: "جمع المعلومات",
      questionEn: "Collecting information",
      answerAr:
        "(-) ستكون معظم البيانات التي نعالجها بيانات نجمعها منك مباشرة عند طلب عرض الأسعار. سنطرح عليك أسئلة مختلفة لجمع البيانات التي نحتاجها لغرض تقديم عروض الأسعار ومعلومات المقارنة. لن نتمكن من تقديم عرض أسعار إلا إذا أجبت على الأسئلة الإلزامية. وإننا نبذل قصارى جهدنا لضمان دقة وحداثة تلك المعلومات، ولذلك يُرجى مساعدتنا في القيام بذلك عن طريق إعلامنا بأية تغييرات في أقرب وقت ممكن.</br>" +
        "(-) ونحن نسعى باستمرار لتحسين جودة خدماتنا وكفاءة أنظمتنا ولذلك فإننا قد نستخدم المعلومات الشخصية الخاصة بك، من وقت لآخر، خلال تأهيل الموظفين و/ أو اختبار النظام.",
      answerEn:
        "(-) Most of the data we process will be data we collect directly from you when you request a quote. We will ask you different questions to collect the data we need for the purpose of providing quotes and comparison information. We will not be able to provide a quote unless you answer the mandatory questions. We do our best to ensure that this information is accurate and up to date, so please help us do this by notifying us of any changes as soon as possible.</br>" +
        "(-) We constantly strive to improve the quality of our services and the efficiency of our systems and therefore we may use your personal information, from time to time, during employee qualification and/or system testing.",
    },
    {
      id: "q7",
      questionAr: "تبادل المعلومات",
      questionEn: "Exchanging information",
      answerAr:
        "سنتبادل، في أوقات مختلفة، معلوماتك الشخصية مع بعض الجهات الأخرى المعتمدة من قبل الجهات الرسمية لأغراض ضمان التأمين ومكافحة الاحتيال وقد تقدم تلك المعلومات لآخرين إذا كان ذلك مطلوبًا أو مسموحًا بموجب القانون. وسيتم أيضًا أحيانًا استخدام معلوماتك الشخصية وتحليلها من قبلنا وشركات أخرى مصرح لها من قبل جهات رسمية للأغراض التالية:</br>" +
        "(-) إدارة شئون المنتجات والخدمات التي نقدمها – في حالة منتجات التأمين، يشتمل ذلك على أغراض ضمان التأمين، إعادة التأمين ومعالجة المطالبات وقد تشتمل على إفشاء معلوماتك لشركات تأمين، و/أوشركات إعادة تأمين أخرى و/أو الهيئات التنظيمية و/أو إلى وكلائنا الذين يقدمون الخدمات نيابة عنا؛</br>" +
        "(-) دراسة أي طلبات قد تقدمها لشراء المنتجات والمساعدة في اتخاذ القرارات المتعلقة بالائتمان الخاصة بك حيثما يكون ملائمًا؛</br>" +
        "(-) المساعدة في اتخاذ القرارات بخصوص طلبات التأمين، المنتجات الأخرى وأي مطالبات تقوم أنت بتقديمها، و/أو أفراد أسرتك، و/او آخرين ممن لهم علاقة بطلبات التأمين والمطالبات الخاصة بك؛ </br>" +
        "(-)  و تمكين خدمة وازن لتطوير منتجات وخدمات جديدة وإبداعية.</br>" +
        "(-) قد نرسل المعلومات المحتفظ بها لدينا في منتج خدمة وازن، للمعالجة إلى شركات أخرى (أو شركات تعمل بناءً على تعليماتها). ويُعد إدخالك لتفاصيلك في موقعنا موافقة منك على هذا الاستخدام لمعلوماتك الشخصية.</br>" +
        "لن يتم إفشاء معلوماتك إلى طرف آخر خارج خدمة وازن، إلا إذا وافق على الحفاظ على سرية معلوماتك الشخصية بصرامة واستخدامها فقط للغرض المحدد الذي من أجله قمنا بتزويده بها وفي الحالات التالية:</br>" +
        " إذا كانت ضرورية لتنفيذ أي اتفاقية منتجات / خدمات نبرمها معك</br>" +
        " إذا أعطيت موافقتك على هذا الإفشاء؛</br>" +
        "إذا كان هذا الإفشاء مطلوبًا أو مسموحًا بموجب القانون؛</br>" +
        "إذا كان لدينا الحق لنقل الحقوق و/أو الالتزامات حسبما هو منصوص عليه بموجب بنود أي وثيقة قد تشتريها؛</br>" +
        "إذا كان الطرف الآخر يقدم منافع أو خدمات لك بموجب أو فيما يتعلق بأي اتفاقية منتجات / خدمات تم إبرامها معك مباشرة        </br>" +
        "قد تسفر عمليات التحسين في التكنولوجيا عن حدوث تغييرات في طريقة عرض وتسجيل واستخدام المعلومات الشخصية الخاصة بك. إذا اعتقدنا بأن التغييرات يمكن أن تكون مضرة لك، فسنقوم بإبلاغك، عن طريق البريد الإلكتروني والرسائل النصية [تطبيقاً للقواعد المنظمة لأعمال وساطة التأمين الإلكتروني.] وبإرسال إشعار بالتعديل في الموقع، وإذا استخدمت الموقع بعد هذا الإشعار، فسنفترض أنك وافقت على تلك التغييرات تلقائيا من دون الحاجة لتقديم اشعار خطي منك بذلك.</br>",
      answerEn:
        "We will, at various times, exchange your personal information with certain third parties approved by official authorities for the purposes of insurance assurance and anti-fraud and may provide that information to others if this is required or permitted by law. Your personal information will also sometimes be used and analyzed by us, and other companies authorized by authorities for the following purposes:</br>" +
        "(-) Managing the affairs of the products and services we offer – in the case of insurance products, this includes underwriting, reinsurance, and claims processing purposes and may include disclosing your information to insurance companies, other reinsurers and/or regulatory bodies and/or to our agents who provide the services on our behalf.</br>" +
        "(-) Consider any requests you may make to purchase products and assist in making your credit decisions where appropriate.</br>" +
        "(-) Assist in making decisions regarding applications for insurance, other products, and any claims submitted by you, your family members, and/or others related to your insurance applications and claims; And Empowering the insurance service to develop new and creative products and services.</br>" +
        "(-) We may send the information held by us in an insurance service product for processing to other companies (or companies acting on their instructions). By entering your details on our site, you consent to the use of your personal information.</br>" +
        "Your information will not be disclosed to another party outside the insurance service unless they agree to keep your personal information strictly confidential and use it only for the specific purpose for which we provided it to them and in the following cases:</br>" +
        "If it is necessary for the performance of any products/services agreement, we enter with you.</br>" +
        "If you give your consent to such disclosure.</br>" +
        "If such disclosure is required or permitted by law. </br>" +
        "If we have the right to transfer rights and/or obligations as provided under the terms of any document, you may purchase.</br>" +
        "If the other party is providing benefits or services to you under or in connection with any products/services agreement entered directly with you</br>" +
        "Improvements in technology may result in changes in the way your personal information is displayed, recorded, and used. If we believe that the changes could be harmful to you, we will notify you, via email and text messages, in accordance with the rules governing electronic insurance brokerage businesses.] By sending notice of the amendment to the site, and if you use the site after this notice, we will assume that you have automatically agreed to those changes without the need to provide written notice from you.</br>",
    },
    {
      id: "q8",
      questionAr: "استخدام المعلومات الشخصية في الأغراض التسويقية",
      questionEn: "Use personal information for marketing purposes",
      answerAr:
        "(-) قد نستخدم المعلومات التي تقدمها لنا، أو قد يستخدمها أعضاء آخرون أو شركات أخرى يتم اختيارها بعناية، وذلك لأغراض بحثية أو لإعلامك بمنتجات أو خدمات أخرى قد تمثل لك أهمية، وذلك ما لم تنصحنا بخلاف ذلك، وفي كل مناسبة نقوم فيها بالتواصل معك بخصوص تلك الأغراض، سُيتاح لك دائمًا خيار الانسحاب.<br/>" +
        "(-) وفي حالة تزويدك لنا بمعلومات الاتصال أو كنت تفضل عدم تلقي معلومات تسويقية أو المشاركة في البحث، يرجى الاتصال بنا وإبلاغنا بذلك.",
      answerEn:
        "(-) We may use the information you provide to us, or it may be used by other members or carefully selected companies, for research purposes or to inform you about other products or services that may be of interest to you, unless you advise us otherwise, and on each occasion, we contact you regarding those purposes. You will always have the option to opt-out.<br/>" +
        "(-) If you have provided us with contact information or would prefer not to receive marketing information or participate in research, please contact us, and let us know.",
    },
    {
      id: "q9",
      questionAr: "التعامل مع الآخرين",
      questionEn: "Dealing with others",
      answerAr:
        "(-) إذا قام زوجك/زوجتك أو أحد أفراد اسرتك بالاتصال بنا نيابة عنك، بسبب حماية المعلومات، لن يكون بمقدورنا الإجابة على استفساراتهم.<br/>" +
        "(-) لن نكشف عن أي أرقام خاصة ببطاقة الائتمان أو الخصم لأي شخص بخلاف صاحب الوثيقة.",
      answerEn:
        "(-) If your spouse or family member contacts us on your behalf, due to the protection of information, we will not be able to answer their inquiries.<br/>" +
        "(-) We will not disclose any credit or debit card numbers to anyone other than the policyholder.",
    },
    {
      id: "q10",
      questionAr: "أمن البيانات",
      questionEn: "Data security",
      answerAr:
        "على الرغم من أننا نتخذ خطوات معقولة لحماية المعلومات الشخصية التي نوفرها عبر هذا الموقع، إلا أنه لا يوجد نظام آمن أو خال من الأخطاء بنسبة 100٪. لذلك، لأننا لا نقر ولا نضمن أمن أو دقة المعلومات التي نجمعها أو نقدمها عبر هذا الموقع كما أنك تقر بمعرفتك وموافقتك على ذلك من دون أدني مسؤولية علينا.",
      answerEn:
        "Although we take reasonable steps to protect the personal information, we make available through this Site, no system is 100% secure or error-free. Therefore, because we do not represent or guarantee the security or accuracy of the information that we collect or provide through this site, you also acknowledge your knowledge and agreement to this without any responsibility on us.",
    },
    {
      id: "q11",
      questionAr: "حق النشر والعلامات التجارية",
      questionEn: "Copyright and Trademarks",
      answerAr:
        "(-) تمتلك خدمة وازن العلامات التجارية، الشعارات وعلامات الخدمة التي تظهر في هذا الموقع. ولا يجوز استخدام هذه العلامات دون موافقة كتابية مسبقة من قبلنا قبل الاستخدام مع تحملك طائلة المسؤولية القانونية في حال قيامك بأي نوع من الاستخدام الخاطئ والمنافي للشروط والاحكام المذكورة هنا.<br/>" +
        "(-) كما ان لوائح هيئة التأمين وسياسة وازن تمنع منعاً باتاً استخدام موقع وازن من قبل وسطاء التأمين أو المندوبين، إلا للأغراض المصرح لها القيام بها من خلال المنصة. وان موقع وازن هو للاستخدام الشخصي فقط وهو حصري للفرد صاحب الشأن وغير قابل للتحويل الى أي طرف ثالث.<br/>" +
        "(-) بالإضافة إلى ان المواد الموجودة في هذا الموقع محمية بحق النشر ولا يجوز تعديل أي جزء من هذه المواد أو إعادة إنتاجه أو تخزينه في أي نظام استرداد، أو نقله، أو نسخه، أو توزيعه، أو استخدامه بأي طريقة أخرى لأغراض تجارية أو عامة دون موافقة كتابية مسبقة من خدمة منتج وازن.",
      answerEn:
        "(-) Wazen Service owns the trademarks, logos, and service marks that appear on this site. It is not permissible to use these marks without our prior written consent before use, and you will bear all legal responsibility if you make any type of incorrect use that violates the terms and conditions mentioned here.<br/>" +
        "(-) Also, the regulations of the Insurance authority and the insurance policy strictly prohibit the use of the insurance website by insurance brokers or representatives, except for the purposes authorized to be carried out through the platform. The Wazen website is for personal use only and is exclusive to the individual concerned and is not transferable to any third party.<br/>" +
        "(-) In addition, the materials on this site are protected by copyright, and no part of these materials may be modified, reproduced, stored in any retrieval system, transmitted, copied, distributed, or used in any other way for commercial or public purposes without the prior written consent of the Service. Insurance product.",
    },
    {
      id: "q12",
      questionAr: "التعديلات",
      questionEn: "Modifications",
      answerAr:
        'يجوز لن تغيير سياسة الخصوصية هذه في أي وقت دون إشعار مسبق، وتعتبر أنت مسؤولا عن الاطلاع على كل ما هو جديد من خلال قراءة سياسة الخصوصية هذه قبل الوصول إلى الموقع وأنت توافق على أن النسخة الحالية من سياسة الخصوصية تسري على استخدامك للموقع <a href="https://www.wazen.com" target="_blank">www.wazen.com</a>',
      answerEn:
        'May not This privacy policy may be changed at any time without prior notice. You are responsible for keeping up to date with everything new by reading this privacy policy before accessing the site, and you agree that the current version of the privacy policy applies to your use of the site <a href="https://www.wazen.com" target="_blank">www.wazen.com</a>',
    },
    {
      id: "q13",
      questionAr: "الاستفسارات",
      questionEn: "Inquiries",
      answerAr:
        'أي استفسارات أو تعليقات حول مواقع أو سياسة الخصوصية ينبغي أن توجه عبر البريد الإلكتروني باستخدام النموذج الموجود في صفحة "تواصل معنا" على موقع <a href="https://www.wazen.com" target="_blank">www.wazen.com</a>.',
      answerEn:
        'Any inquiries or comments about the Sites or the Privacy Policy should be directed via email using the form on the "Contact Us" page on <a href="https://www.wazen.com" target="_blank">www.wazen.com</a>.',
    },
    {
      id: "q14",
      questionAr: "القانون الواجب التطبيق والمحاكم المختصة",
      questionEn: "The applicable law and the competent courts",
      answerAr:
        "تخضع شروط الاستخدام هذه وتفسر وفقاً لقانون المملكة العربية السعودية. تخضع المنازعات الناشئة ذات العلاقة بشروط الاستخدام للاختصاص القضائي الحصري لمحاكم الرياض لدى المملكة العربية السعودية.",
      answerEn:
        "These Terms of Use shall be governed by and construed in accordance with the law of the Kingdom of Saudi Arabia. Disputes arising in connection with these Terms of Use are subject to the exclusive jurisdiction of the courts of Riyadh in the Kingdom of Saudi Arabia.",
    },
  ];

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
  }
}
