<!--Contact Icon-->
<!-- <div id="contact-icon" *ngIf="currentRoute != '/contact'">
  <button type="button" class="feedback" [routerLink]="'/contact'">
    <i
      class="ion-md-mail"
      [ngbTooltip]="'App.ContactToolTibText' | translate"
    ></i>
  </button>
</div> -->
<footer class="footer-area footer-style-two footer-style-three">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-6 px-0">
        <div class="footer-widget d-flex justify-content-center">
          <div class="footer-logo">
            <img height="100" width="150px" src="{{ 'Header.logoUrl' | translate }}" alt="Wazen" />
          </div>
          <!-- <p>A mobile application, also referred to as a mobile app or simply an app.</p> -->
          <!--  <div class="footer-social">
                        <a target="_blank" href="#"><i class="icon ion-logo-facebook"></i></a>
                        <a target="_blank" href="#"><i class="icon ion-logo-twitter"></i></a>
                        <a target="_blank" href="#"><i class="icon ion-logo-linkedin"></i></a>
                        <a target="_blank" href="#"><i class="icon ion-logo-instagram"></i></a>
                    </div> -->
        </div>
      </div>

      <div class="col-lg-10 col-md-9 col-sm-12">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="footer-widget pl-90">
              <h3>{{ "Footer.Company" | translate }}</h3>

              <ul>
                <li>
                  <a href="https://wazen.com/" target="_blank"><i class="icon ion-ios-arrow-back"></i>{{ "Footer.About"
                    | translate }}</a>
                </li>
                <li>
                  <a [routerLink]="'/products'" (click)="scrollToTop()"><i class="icon ion-ios-arrow-back"></i>{{
                    "Footer.Product" | translate}}</a>
                </li>
                <li>
                  <a [routerLink]="'/service'" (click)="scrollToTop()"><i class="icon ion-ios-arrow-back"></i>{{
                    "Footer.Services" | translate}}</a>
                </li>
                <!--  <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Bonus</a></li>
                            <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Contact</a></li> -->
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="footer-widget pl-90">
              <h3>{{ "Footer.Links" | translate }}</h3>

              <ul>
                <li>
                  <a [routerLink]="'/tremsCondition'" (click)="scrollToTop()"><i class="icon ion-ios-arrow-back"></i>{{
                    "Footer.Terms" |translate }}</a>
                </li>
                <li>
                  <a [routerLink]="'/privacy-policy'" (click)="scrollToTop()"><i class="icon ion-ios-arrow-back"></i>{{
                    "Footer.Privacy" | translate }}</a>
                </li>
                <li>
                  <a [routerLink]="'/Insurance-Authority'" (click)="scrollToTop()"><i
                      class="icon ion-ios-arrow-back"></i>{{
                    "Footer.InsuranceAuthority" | translate }}</a>
                </li>

                <!--  <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Screenshots</a></li>
                            <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Pricing</a></li>
                            <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Testimonial</a></li> -->
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="footer-widget pl-90">
              <h3>{{ "Footer.Support" | translate }}</h3>

              <ul>
                <li>
                  <a [routerLink]="'/faq'" (click)="scrollToTop()"><i class="icon ion-ios-arrow-back"></i>{{
                    "Footer.FAQ" | translate }}</a>
                </li>
                <li>
                  <a [routerLink]="'/help-center'" (click)="scrollToTop()"><i class="icon ion-ios-arrow-back"></i>{{
                    "Footer.HelpCenter" | translate }}</a>
                </li>
                <li>
                  <a [routerLink]="'/channels'" (click)="scrollToTop()"><i class="icon ion-ios-arrow-back"></i>{{
                    "Footer.ChannelsTitle" |translate }}</a>
                </li>

                <!--  <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Screenshots</a></li>
                            <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Pricing</a></li>
                            <li><a href="#"><i class="icon ion-ios-arrow-back"></i>Testimonial</a></li> -->
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="footer-widget pl-90">
              <h3>{{ "Footer.Contact" | translate }}</h3>

              <ul>
                <li>
                  <div>
                    <i class="ion-md-mail"></i>
                    <a class="phone-text"> email </a>
                  </div>
                </li>

                <li>
                  <div>
                    <i class="ion-ios-call"></i>
                    <a class="phone-text">
                      <p style="direction: ltr">+966</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="social-links pt-3 justify-content-center">
              <a href="https://www.linkedin.com/company/wazen/about/" target="_blank">
                <i class="ion-logo-linkedin"></i></a>
              <a href="https://twitter.com/@WazenKSA" target="_blank">
                <i class="ion-logo-twitter"></i></a>
              <a id="lastChild"> <i class="ion-ios-globe"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-4 col-sm-12 lh-1">
          <img src="assets/images/payment-brands/Visa.png" style="width: 70px" alt="visa card" />
          <!-- <img
            src="assets/images/payment-brands/Mada.png"
            style="width: 70px"
            alt="american-express"
          /> -->
          <img src="assets/images/payment-brands/Sadad.png" style="width: 70px" alt="discover" />
          <img src="assets/images/payment-brands/MasterCard.png" style="width: 70px" alt="master card" />
          <!-- <img
            src="assets/images/payment-brands/ApplePay.png"
            style="width: 70px"
            alt="apple pay"
          /> -->
        </div>

        <div class="col-lg-6 col-md-8 lh-1 d-flex justify-content-end">
          <p>
            © Wazan {{ currentDate | date : "y" }}
            {{ "Footer.CopyRights" | translate }}
            <!-- <a href="https://portal.wazen-api.oasisoft.net/"
                            target="_blank">HiBootstrap.com</a></p> -->
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>