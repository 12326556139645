<div class="profile-page-container">
  <div class="profile-container">
    <!-- MENU -->
    <div class="menu-container">
      <ul class="menu-list flex-column">
        <!-- User Dashboard Button -->
        <li class="policy-purchase-btn-wrapper">
          <div
            class="policy-purchase-btn d-flex align-items-center justify-content-center"
          >
            <i class="icon ion-ios-contact"></i>
            <span class="ms-1" style="margin-right: 5px">{{
              "Profile.Menu.UserDashboardText" | translate
            }}</span>
          </div>
        </li>
        <!-- Policy Purchase Button -->
        <li
          [routerLink]="AppRoutes.controlPanel.newQuotation.main"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="icon ion-ios-add"></i>
          <span>{{ "Profile.Menu.NewInsurancePolicyText" | translate }}</span>
        </li>
        <!-- Policies -->
        <li
          [routerLink]="AppRoutes.controlPanel.policies.main"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="icon ion-ios-document"></i>
          <span>{{ "Profile.Menu.PoliciesText" | translate }}</span>
        </li>

        <!-- Vehicles List-->
        <li
          [routerLink]="AppRoutes.controlPanel.vehiclesList"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="ion-ios-car"></i>
          <span>{{ "Profile.Menu.RegisteredVehiclesText" | translate }}</span>
        </li>
        <!-- Previous Policies -->
        <li
          [routerLink]="AppRoutes.controlPanel.quotes"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="ion-ios-albums"></i>
          <span>{{ "Profile.Menu.SavedQuotationText" | translate }}</span>
        </li>

        <!-- Invoices -->
        <li
          [routerLink]="AppRoutes.controlPanel.invoices"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="ion-ios-paper"></i>
          <span>{{ "Profile.Menu.InvoicesText" | translate }}</span>
        </li>
        <!-- Claims -->
        <!-- <li
          [routerLink]="AppRoutes.controlPanel.claims"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="ion-ios-cash"></i>
          <span>{{ "Profile.Menu.Claims" | translate }}</span>
        </li> -->
        <!-- Technical Support -->
        <li
          [routerLink]="['support']"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="ion-ios-help-circle-outline"></i>
          <span>{{ "Profile.Menu.TechnicalSupportText" | translate }}</span>
          <!-- <span *ngIf="unReadFollowup != 0" class="badge badge-pill badge-danger"
                  style="font-size: 15px;">{{unReadFollowup}}</span> -->
          <span *ngIf="total !== 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white" >
            {{total ? total : 0}}
          </span>
        </li>
        <!-- Settings -->
        <li
          [routerLink]="['account']"
          class="menu-item"
          routerLinkActive="active-l"
        >
          <i class="ion-ios-settings"></i>
          <span>{{ "Profile.Menu.AccountSettingsText" | translate }}</span>
        </li>
      </ul>
    </div>

    <!-- PANEL -->
    <div class="user-panel-box flex-column">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
