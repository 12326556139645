import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/services/app/app.service";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"],
})
export class ServicesComponent implements OnInit {
  isCollapsed: boolean = false;
  lang: string;
  finalJson: any;
  termsCondition: any[] = [
    {
      id: "q1",
      questionAr: "عصر جديد في سرعة الحصول على تأمين للمركبات",
      questionEn: "A New Era of Insurance Agility",
      answerAr:
        "انس كل ما تعرفه عن طريقة الحصول على تأمين المركبات لأن وازن الرقمية (Wazen) تغير كل شيء. منصتنا هي طاقم البحث الرقمي الخاص بك" +
        "، وهي مجهزة بأحدث الأدوات لتعيدك إلى المسار الصحيح مع التغطية المناسبة، بشكل فوري! ",
      answerEn:
        "Forget everything you know about shopping for car insurance because Wazen is changing the game. Our platform is your digital pit crew, " +
        " equipped with state-of-the-art tools to get you back on track with the right coverage, pronto! ",
    },
    {
      id: "q2",
      questionAr: " ميزة منصة وازن الرقمية (Wazen)",
      questionEn: "The Wazen Advantager",
      answerAr:
        "(1)- المقارنات في الوقت الفعلي: توفر لك منصتنا مجموعة من خيارات التأمين المختلفة، وتعرض لك الأسعار والتغطية الأكثر تنافسية في الوقت الفعلي.<br>" +
        "(2)-  تبسيط عملية اعرف عميلك: نحن نقدر وقتك. عملية 'اعرف عميلك' الخاصة بنا سريعة ومباشرة – بدون خطوات إضافية أو تأخيرات. <br>" +
        "(3)- بوابة دفع آمنة: بفضل التشفير المتطور، يكون دفعك آمنًا بشكل تام.<br>" +
        "(4)- السياسات المخصصة: يجب أن يكون تأمينك فريدًا مثل أسلوب قيادتك. في منصة وازن الرقمية (Wazen)، نقوم بتصميم السياسات لتناسب احتياجاتك الفردية.<br>",
      answerEn:
        "(1)- Real-Time Comparisons: Just like a dashboard GPS, our platform guides you through a landscape of insurance options, displaying the most competitive rates and coverage in real-time.<br>" +
        "(2)- Streamlined KYC Process: We value the importance of your time. Our KYC process is swift and straightforward – no detours, no delays.<br>" +
        "(3)- Secure Payment Gateway: With fortified encryption, your payment is as secure as a seatbelt on this journey.<br>" +
        "(4)- Customized Policies: Your insurance should be as unique as your driving style. At Wazen, we tailor policies to fit your individual needs like a glove.<br>",
    },
  ];
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
  }
}
