<div class="data-accordion-container">
   <!-- HEADER -->
   <!--    <div class="data-header-container">
      <div class="flex-container">
         <div (click)="toggleDataAccordion()" id="user-data-wrapper" class="bouncy noselect">
            <i class="ion-md-contact secondary-lime"></i>
            <span *ngIf="lang === 'ar'">{{ insuredInfo.firstNameAr }}  {{ insuredInfo.middleNameAr }} {{ insuredInfo.lastNameAr }}</span>
            <span *ngIf="!(lang === 'ar')">{{ insuredInfo.firstName }}  {{ insuredInfo.middleName }} {{ insuredInfo.lastName }}</span>
         </div>
      </div>
      <div class="flex-container">
         <a (click)="toggleDataAccordion()" class="flex-centered body-view-link secondary-lime" href="javascript:void(0)">
            <i [ngClass]="isDataAccordionShown ? 'ion-md-remove-circle' : 'ion-md-add-circle'"></i>
         </a>
      </div>
   </div> -->


   <!-- BODY -->
   <div class="data-body" [ngClass]="isDataAccordionShown ? 'visible-body' : 'hidden-body'">

      <div class="data-body-container">

         <!-- ----------------------------------------------------------------------- -->
         <!--                               Client Info                               -->
         <!-- ----------------------------------------------------------------------- -->
         <div class="insured-info-container container">
            <table class="order-data-table mt-3">
               <tr>
                  <th >
                     {{ 'Policy.NationalID' | translate }}:
                  </th>
                  <td>
                     {{insuredInfo.identityNumber}}
                  </td>
               </tr>
               <tr>
                  <th >
                     {{ 'Policy.FullName' | translate }}:
                  </th>
                  <td>
                     <span *ngIf="lang === 'ar'">{{ insuredInfo.firstNameAr }} {{ insuredInfo.middleNameAr }} {{
                        insuredInfo.lastNameAr }}</span>
                     <span *ngIf="!(lang === 'ar')">{{ insuredInfo.firstName }} {{ insuredInfo.middleName }} {{
                        insuredInfo.lastName }}</span>
                  </td>
               </tr>
               <tr>
                  <th >
                     {{ 'Policy.NationalAddress' | translate }}:
                  </th>
                  <td>
                     {{insuredInfo.nationalAddress}}
                  </td>
               </tr>
            </table>
         </div>




         <!-- ----------------------------------------------------------------------- -->
         <!--                              Vehicle Info                               -->
         <!-- ----------------------------------------------------------------------- -->
         <div class="container">
            <table class="order-data-table mt-3 ml-2">
               <tr *ngIf="vehicleInfo.vehicleIdTypeId ==1">
                  <th > {{ 'Checkout.PolicyDetails.SequenceNum' | translate}}:</th>
                  <td>{{ vehicleInfo.vehicleId }}</td>
               </tr>
               <tr *ngIf="vehicleInfo.vehicleIdTypeId ==2">
                  <th > {{ 'Landing.CustomCardText' | translate}}:</th>
                  <td>{{ vehicleInfo.vehicleId }}</td>
               </tr>
               <tr>
                  <th >
                     {{ 'Policy.VehicleData.Description' | translate }}:
                  </th>
                  <td>
                     <span>({{vehicleInfo.maker}},{{vehicleInfo.model}},{{vehicleInfo.modelYear}}) </span>
                  </td>
               </tr>
               <tr>
                  <th >
                     {{ 'Policy.VehicleData.VehicleOwnerIdentityNumber' | translate }}:
                  </th>
                  <td>
                     <span>{{vehicleInfo.vehicleId}}</span>
                  </td>
               </tr>

               <!-- <tr>
                  <th>
                      {{ 'Policy.VehicleData.ModelYear' | translate }}:
                  </th>
                  <td>
                     {{vehicleInfo.modelYear}} 
                  </td>
               </tr> -->


            </table>
         </div>

         <!--  -->
         <div class="container">
            <table class="order-data-table mt-3">
               <tr *ngIf="vehicleInfo.vehicleIdTypeId == 1">
                  <th >
                     {{ 'Policy.VehicleData.PlateNumber' | translate }}:
                  </th>
                  <td>
                     <span *ngIf="lang === 'en'">
                        {{ vehicleInfo.plateFirstLetter }}
                        {{ vehicleInfo.plateSecondLetter }}
                        {{ vehicleInfo.plateThirdLetter }}
                     </span>

                     <span *ngIf="lang === 'ar'">
                        {{ vehicleInfo.plateFirstLetterAr }}
                        {{ vehicleInfo.plateSecondLetterAr }}
                        {{ vehicleInfo.plateThirdLetterAr }}
                     </span>
                     <span>
                        {{ vehicleInfo.plateNumber }}
                     </span>
                  </td>
               </tr>
               <tr>
                  <th >
                     {{ 'Policy.VehicleData.RepairsLocation' | translate }}:
                  </th>
                  <td>
                     <span *ngIf="lang === 'ar'">{{vehicleInfo.repairMethodAr}}</span>
                     <span *ngIf="lang === 'en'">{{vehicleInfo.repairMethod}}</span>
                  </td>
               </tr>
               <tr>
                  <th >
                     {{ 'Policy.VehicleData.SumInsured' | translate }}:
                  </th>
                  <td>
                     {{vehicleInfo.sumInsured | number}}
                     <small>{{ 'App.Currency' | translate }}</small>
                  </td>
               </tr>
            </table>
         </div>
         <!-- ----------------------------------------------------------------------- -->
         <!--                              Vehicle Plate                              -->
         <!-- ----------------------------------------------------------------------- -->
         <div class="vehicle-logo-contianer flex-container">
            <img [src]="vehicleLogoSrc" class="flex-centered" alt="Vehicle Model Logo">
            <!-- <div class="vehicle-plate flex-centered">
               <div class="plate-num-ar">
                  {{vehicleInfo.plateNumber}}
               </div>
               <div class="plate-mid">
                  <img src="https://cf4.s3.souqcdn.com/item/2017/11/18/28/12/64/63/item_L_28126463_71924704.jpg" alt="">
                  <span>السعودية</span>
                  <span>KSA</span>
               </div>
               <div class="plate-txt-ar">
                  {{vehicleInfo.plateFirstLetter}} {{vehicleInfo.plateSecondLetter}} {{vehicleInfo.plateThirdLetter}}
               </div>
               <div class="plate-num-en">
                  {{vehicleInfo.plateNumber}}
               </div>
               <div class="plate-txt-en">
                  {{vehicleInfo.plateFirstLetter}} {{vehicleInfo.plateSecondLetter}} {{vehicleInfo.plateThirdLetter}}
               </div>
            </div> -->
         </div>

      </div>

   </div>

</div>
<!-- <div class="col-12 d-flex justify-content-center">
   <a class="btn  info-btn">
      <i class="ion-md-contact secondary-lime"></i>
      {{ 'Quote.HeaderTitle.title' | translate }}
    <i [ngClass]="isDataAccordionShown ? 'ion-md-arrow-up' : 'ion-md-arrow-down'"></i>
   </a>
</div> -->