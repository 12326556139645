import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { AuthenticationPageComponent } from "./auth.component";

const Auth_ROUTING: Routes = [
    { path: '', component: AuthenticationPageComponent, children:[
      { path: AppRoutes.verification, component: AuthenticationPageComponent },
      { path: AppRoutes.passwordReset, component: AuthenticationPageComponent },
    ] },
];
@NgModule({
    imports: [RouterModule.forChild(Auth_ROUTING)],
    exports: [RouterModule]
  })
export class AuthRoutingModule {}