/**
 * Contains the client side application routes
 */
export const AppRoutes = {
  landing: "",
  FAQ: "faq",
  Partners: "Partners",
  HelpCenter: "help-center",
  error: "error",
  footerContent: {
    contents: "",
    contact: "contact",
    tremsCondition: "tremsCondition",
    privacyPolicy: "privacy-policy",
    InsuranceAuthority: "Insurance-Authority",
    products: "products",
    Service: "service",
    channels: "channels",
  },
  Routes: {
    diractpay: "diractpay",
    main: "",
    quotes: "quotes",
    quotesId: "quotes/:id",
    insuranceSteps: "quotation",
    checkout: "checkout",
    paymentStatus: "status",
    checkoutSuccess: "checkout-success",
    ComplateCheckout: "ComplateCheckout/:id",
    uploadImage: "checkout/vehicle-images/:id/:ref",
  },
  quotes: "quotes",
  insuranceSteps: "quotation",
  checkout: "checkout",
  paymentStatus: "checkout/status",
  authentication: "account/login",
  verification: "account/verify",
  passwordReset: "account/reset",
  controlPanel: {
    main: "user",
    quotes: "previous-quotes",
    policies: {
      uri: "policies",
      main: "policies",
      bought: "bought",
      expiring: "expiring",
      expired: "expired",
      support: "support",
    },
    claims: "claims",
    Addcliams: "Addclaims",
    vehicles: "new-quote/select-vehicle",
    newQuotation: {
      main: "new-quotation",
      vehicle: "select-vehicle",
      identity: "",
    },
    vehiclesList: "vehicles",
    invoices: "invoices",
    accountSettings: {
      uri: "user/account",
      main: "account",
      email: {
        main: "user/account/email",
        verify: "user/account/email/verify",
      },
      password: {
        main: "user/account/password",
        verify: "user/account/password/verify",
      },
      phone: {
        main: "user/account/phone",
        verify: "user/account/phone/verify",
      },
      misc: "user/account/miscellaneous",
    },
  },
  //    profile: {
  //       profileMain: 'user',
  //       newPolicy:{
  //        main: 'newPolicy',
  //        vehicle:'vehicle',
  //        addVehicle:'add-vehicle'
  //       },
  //       policies: 'policies',
  //       vehicles: 'vehicles',
  //       savedQuote: 'saved-quote',
  //       invoice: 'invoices',
  //       support : {
  //         main: 'support',
  //         add: 'add',
  //         details:'details'
  //       },
  //       account:{
  //        main: 'account',
  //        email:'email',
  //        password: 'password',
  //        language:'language',
  //        phone:'phone'
  //      },
  //       claim: {
  //         main:'claims',
  //         new:'new',
  //         details:'details'
  //       },

  //   }
};
