import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from "@angular/core";
import { DriverPage } from "src/app/models/insurance-steps/DriverPage";
import { DriversService } from "src/app/services/insurance-steps/drivers.service";

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: "app-drivers",
  templateUrl: "./drivers.component.html",
  styleUrls: ["./drivers.component.css"],
})
export class DriversComponent implements OnInit {
  /* UI */
  showDriverPage: DriverPage;
  currentActiveDriverPage = DriverPage;
  constructor(private driverService: DriversService) {}

  ngOnInit(): void {
    this.getCurrentDriver();
  }

  getCurrentDriver() {
    let currDrivers = this.driverService.getUserQuoteReqDrivers().value;
    if (currDrivers?.length > 1) {
      this.driverService.setDriverPage(DriverPage.additonalDriverList);
    } else {
      this.driverService.setDriverPage(
        this.driverService.getDriverPage().value
      );
    }

    this.driverService.getDriverPage().subscribe((currPage) => {
      this.showDriverPage = currPage;
    });
  }
}
