import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import Swal, { SweetAlertIcon, SweetAlertResult } from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  constructor(private router: Router) {}
  confirm(
    btnTitle: string = "Sure!",
    message: string = "Message",
    btn: string = "primary",
    icon: SweetAlertIcon = "question"
  ): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${message}`,
      icon: icon,
      showCancelButton: true,
      background: "var(--vz-modal-bg)",
      customClass: {
        confirmButton: `btn btn-${btn} btn-sm w-xs me-2 mt-2`,
        cancelButton: "btn btn-ghost-danger btn-sm w-xs mt-2",
      },
      confirmButtonText: `Yes, ${btnTitle}`,
      buttonsStyling: false,
      showCloseButton: true,
    });
  }

  popup(title: string, message: string, icon: SweetAlertIcon = "info") {
    return Swal.fire({
      title: title,
      text: message,
      icon: icon,
      confirmButtonText: "Ok",
    });
  }

  async success(msg: string) {
    const res2 = await Swal.fire({
      icon: "warning",
      title: msg,
      showDenyButton: true,
      confirmButtonText: "Yes",
    });
    if (res2.isDenied) {
    } else {
      const res = await Swal.fire({
        title: '"! طلبك قيد التنفيذ"',
        text: "سوف نبلغك",
        icon: "success",
        confirmButtonText: "Ok",
      });
    }
  }

  toast(message: string, icon: SweetAlertIcon = "info") {
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: message,
    });
  }

  msgCreatedToast(name: string) {
    this.toast(`(${name}) was added!`, "success");
  }

  msgUpdatedConfirm(oldName: string, newName: string) {
    return this.confirm("Update", `(${oldName}) updated to (${newName})`);
  }
  msgUpdatedToast(oldName: string, newName: string) {
    this.toast(`(${oldName}) updated to (${newName})`, "success");
  }

  msgCancelPolicyToast(title: string, message: string) {
    this.confirm(title, message);
  }

  msgDeleteConfirm(name: string) {
    return this.confirm("delete", `delete (${name}) `);
  }
  msgDeletedToast(name: string) {
    return this.toast(`(${name}) was deleted successfully`, "success");
  }

  msgLoadingDataError(title: string, msg: string) {
    this.popup(title, msg, "error");
  }
}
