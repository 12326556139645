import { ClaimList } from "./../../models/profile/ClaimList";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiRoutes } from "src/app/models/app/ApiRoutes";
import { UserPoliciesDaily } from "src/app/models/app/UserPoliciesDaily";
import { Invoice } from "src/app/models/checkout/Invoice";
import {
  DashboardResponse,
  Policies,
} from "src/app/models/order-preview/DashboardResponse";
import { ClientVehicles } from "src/app/models/profile/ClientVehicles";
import { TicketsParms } from "src/app/models/profile/Tickets";
import { UserInvoice } from "src/app/models/profile/UserInvoice";
import { UserPolicesCount } from "src/app/models/profile/UserPolicesCount";
import { VehicleInfo } from "src/app/models/quote/VehicleInfo";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  apiUrl: string = environment.apiUrl;
  invoicesInfo;

  policiesCountToday: UserPoliciesDaily = {
    maximumDailyPolicies: 0,
    totalPoliciesPurchasedToday: 0,
  };

  private policiesToday: BehaviorSubject<UserPoliciesDaily> =
    new BehaviorSubject<UserPoliciesDaily>(this.policiesCountToday);

  constructor(private http: HttpClient) {}

  getIdentities() {
    return this.http.get(this.apiUrl + ApiRoutes.Profile.GetUserIdentities);
  }

  getVehiclesList(filter?: string) {
    if (filter === undefined) filter = "";
    return this.http.get<ClientVehicles>(
      this.apiUrl + ApiRoutes.Profile.GetVehiclesList + "?q=" + filter
    );
  }

  getUserPoliciesCount(): Observable<UserPolicesCount> {
    return this.http.get<UserPolicesCount>(
      environment.apiUrl + ApiRoutes.Profile.PoliciesCount
    );
  }
  getClientVehicles(clientId: string) {
    return this.http.get(this.apiUrl + ApiRoutes.Profile.GetVehicles);
  }

  /* get all saved Quote */
  getActiveQuotes() {
    return this.http.get(environment.apiUrl + ApiRoutes.Profile.UserQuote);
  }

  /* get single saved Quote */

  getSavedQuote(id: string) {
    // const params = new HttpParams().set(id);
    return this.http.get(
      environment.apiUrl + ApiRoutes.Profile.UserQuotes + "/" + id
    );
  }

  getDashboardData(filter?: string) {
    if (filter === undefined) filter = "";
    return this.http.get<DashboardResponse>(
      environment.apiUrl + ApiRoutes.Profile.Dashboard + "?q=" + filter
    );
  }

  getDashboardPolicies(status: string) {
    return this.http.get<Policies[]>(
      environment.apiUrl + ApiRoutes.Profile.Policy + status
    );
  }

  getInvoices(filter?: string) {
    if (filter === undefined) filter = "";
    return this.http.get<UserInvoice>(
      environment.apiUrl + ApiRoutes.Profile.Invoices + "?q=" + filter
    );
  }

  printInvoice(id: string) {
    return this.http.get<Invoice>(
      environment.apiUrl + ApiRoutes.Profile.PrintInvoice + id
    );
  }
  /*-------------------------------------------------------------------------------
                                Download Policy
  ------------------------------------------------------------------------------*/
  downloadPolicy(id: string): Observable<Blob> {
    return this.http.get(
      environment.apiUrl + ApiRoutes.Profile.DownloadPolicy + id,
      {
        responseType: "blob",
        reportProgress: true,
      }
    );
  }

  /*-------------------------------------------------------------------------------
                                Print Policy
  ------------------------------------------------------------------------------*/
  printPolicy(id: string): Observable<Blob> {
    return this.http.get(
      environment.apiUrl + ApiRoutes.Profile.PrintInvoice + id,
      {
        responseType: "blob",
      }
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Tickets                                  */
  /* -------------------------------------------------------------------------- */
  getTicketsList(tickets?: TicketsParms) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Tickets.ticketsList,
      tickets
    );
  }

  getTicketsTypes() {
    return this.http.get(environment.apiUrl + ApiRoutes.Tickets.ticketsTypes);
  }

  createTicket(ticketFormData: FormData) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Tickets.ticketsCreate,
      ticketFormData
    );
  }

  getTicket(ticketId: string) {
    let params = new HttpParams().set("ticketId", ticketId);
    return this.http.get(environment.apiUrl + ApiRoutes.Tickets.ticketDetails, {
      params: params,
    });
  }

  sendfollowUp(message: { message: string; ticketId: string }) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Tickets.ticketFollowUp,
      message
    );
  }

  getTicketStatus() {
    return this.http.get(environment.apiUrl + ApiRoutes.Tickets.ticketStatus);
  }

  getUnReadFollowup() {
    return this.http.get(
      environment.apiUrl + ApiRoutes.Tickets.totalUnReadFollowup
    );
  }
  changeStatus(status) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Tickets.changeStatus,
      status
    );
  }
  //Get Claim List
  getClaimList(ClaimList?: ClaimList) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Claims.claimList,
      ClaimList
    );
  }

  setPoliciesCountToday(policiesTody: UserPoliciesDaily) {
    this.policiesToday.next(policiesTody);
  }
  getPoliciesCountToday(): BehaviorSubject<UserPoliciesDaily> {
    return this.policiesToday;
  }
}
