import { registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import localeAr from "@angular/common/locales/ar";
import localeEn from "@angular/common/locales/en";
import { Injector, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CarouselModule } from "ngx-owl-carousel-o";
import { Observable, of } from "rxjs";
import * as arContent from "../assets/i18n/ar.json";
import * as enContent from "../assets/i18n/en.json";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./components/shared/shared.module";
import { UserModule } from "./components/user/user.module";
import { JwtInterceptor } from "./helpers/interceptors/JwtInterceptor";
import { LanguageInterceptor } from "./helpers/interceptors/LanguageInterceptor";
import { SecureAppInterceptor } from "./helpers/interceptors/SecureAppInterceptor";
import { TenantInterceptor } from "./helpers/interceptors/TenantInterceptor";
import { MotorModule } from "./insurance.module";
import { AuthService } from "./services/auth/auth.service";
import { DriversService } from "./services/insurance-steps/drivers.service";
import { ErrorInterceptor } from "./helpers/interceptors/ErrorInterceptor";
import { ProductsComponent } from "./components/shared/layout/products/products.component";
import { ServicesComponent } from "./components/shared/layout/service/services.component";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

// import {NgxPaginationModule} from 'ngx-pagination';

/* -------------------------------------------------------------------------- */
/*                                 Translation                                */
/* -------------------------------------------------------------------------- */

registerLocaleData(localeAr, "ar");
registerLocaleData(localeEn, "en");

const TRANSLATIONS = { ar: arContent, en: enContent };

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "assets/i18n/", ".json");
}

export class TranslateUniversalLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang].default);
  }
}

/* -------------------------------------------------------------------------- */
/*                                    Misc                                    */
/* -------------------------------------------------------------------------- */

export let InjectorInstance: Injector;

@NgModule({
  declarations: [AppComponent, ProductsComponent, ServicesComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MotorModule,
    NgbModule,
    SharedModule,
    UserModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useClass: TranslateUniversalLoader, // Used in production to enable translation
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SecureAppInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private key = sessionStorage.getItem("isPrompted");
  private secretKey = "1234";
  constructor(private injector: Injector) {
    if (!this.key) {
      let confirmation = "";
      while (confirmation !== this.secretKey) {
        confirmation = prompt("Please enter the private key to continue");
      }
      sessionStorage.setItem("isPrompted", btoa(confirmation));
    }

    InjectorInstance = this.injector;
    AuthService.injector = injector;
    DriversService.injector = injector;
  }
}
