import { CheckoutRequest } from "src/app/models/order-preview/checkoutRequest";
import { ApiRoutes } from "src/app/models/app/ApiRoutes";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PolicyPurchase } from "src/app/models/order-preview/PolicyPurchase";
import { SavedQuoteRequest } from "src/app/models/order-preview/SavedQuoteRequest";
import {
  IbanVerificationRequest,
  PreviewPageRequest,
} from "src/app/models/order-preview/PreviewPageRequest";
import { Observable, of } from "rxjs";
import { SendUploadImagesLinkRequest } from "src/app/models/order-preview/SendUploadImagesLinkRequest";
import { ApiResponse } from "src/app/payload/responses/ApiResponse";
import { IbanVerificationsResponse } from "src/app/payload/responses/order-preview/IbanVerificationsResponse";
import { delay, mergeMap, retryWhen, takeWhile } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class OrderReviewService {
  constructor(private http: HttpClient) {}

  // send req attachment/preview and get attachment response
  getAttachment(attachmentPreviewRequest: any): Observable<any> {
    return this.http.post(
      environment.apiUrl + ApiRoutes.checkout.attachmentPreview,
      {},
      { params: { ReferenceId: attachmentPreviewRequest } }
    );
  }
  // get iban
  getIban(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + ApiRoutes.LeanTec.iban, data);
  }

  getPayment(data: any): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}${ApiRoutes.Directpay.PurchasePolicyRequest}?transactionId=${data}`
    );
  }
  // get data From diractpay
  getDiractpay(data: any): Observable<any> {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Directpay.GetSecureHash,
      data
    );
  }

  Inquiry(data: any): Observable<any> {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Directpay.Inquiry,
      data
    );
  }

  post(data: any) {
    return this.http.post(
      "https://paytest.directpay.sa/SmartRoutePaymentWeb/SRPayMsgHandler",
      data
    );
  }

  // verify iban
  verifyIban(
    verifyIbanRequest: IbanVerificationRequest
  ): Observable<IbanVerificationsResponse> {
    return this.http.post<IbanVerificationsResponse>(
      environment.apiUrl + ApiRoutes.LeanTec.ibanVerifications,
      verifyIbanRequest
    );
  }

  //  lean tech customer add
  getDetailsForLean(data: any): Observable<any> {
    return this.http.post(
      environment.apiUrl + ApiRoutes.LeanTec.leanTech,
      data
    );
  }
  saveQuote(saveOrderRequest: PreviewPageRequest) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Quotation.saveOrder,
      saveOrderRequest
    );
  }
  applyCoupon(applyCoupon: any): Observable<any> {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Policy.applyCoupon,
      applyCoupon
    );
  }

  public getPaymentCard(CheckoutRequest: CheckoutRequest) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Policy.checkout,
      CheckoutRequest
    );
  }

  public getCheckoutStatus(checkoutId: string) {
    return this.http.get(
      environment.apiUrl + ApiRoutes.Policy.issue + "/" + checkoutId
    );
  }
  public policyPurchase(purchaseRequest: PolicyPurchase) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Policy.policyPurchase,
      purchaseRequest
    );
  }

  saveOrder(saveOrderRequest: SavedQuoteRequest) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Quotation.saveOrder,
      saveOrderRequest
    );
  }

  previewAttachment(previewAttachment: FormData) {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Quotation.PreviewAttachment,
      previewAttachment
    );
  }

  attachmentPreview(referenceNo: string) {
    return this.http.get(
      environment.apiUrl +
        ApiRoutes.Quotation.PreviewAttachment +
        "?ReferenceId=" +
        referenceNo
    );
  }

  sendUploadImageLink(
    request: SendUploadImagesLinkRequest
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.apiUrl + ApiRoutes.Quotation.SendUploadImagesLink,
      request
    );
  }

  checkingImagePreview(request: string): Observable<any> {
    return this.http
      .post(
        environment.apiUrl + ApiRoutes.checkout.attachmentPreview,
        {},
        { params: { ReferenceId: request } }
      )
      .pipe(
        mergeMap((data: any) => {
          if (data.length < 5) {
            // If the data length is less than 5, throw an error to trigger retry
            throw data;
          }
          return of(data);
        }),
        retryWhen((errors) =>
          errors.pipe(
            delay(5000), // Wait for 1 minute before retrying
            mergeMap((errorData) => {
              if (errorData.length < 5) {
                // If the data length is still less than 5, retry
                return of(errorData);
              }
              // If the data length is 5 or more, stop retrying
              throw errorData;
            }),
            takeWhile((errorData) => errorData.length < 5, true) // Continue retrying until the data length is 5 or more
          )
        )
      );
  }
}
