<div class="">
  <div class="row">
    <!-- Title -->
    <div class="col-6 d-flex justify-content-start">
      <h6 class="policies-type-header">
        <i class="ion-md-apps"></i>
        {{ "Profile.Menu.Claims" | translate }}
      </h6>
    </div>
    <!-- New Claim Button -->
    <div class="col-6 d-flex justify-content-end">
      <button
        *ngIf="isNewClaim"
        (click)="greateNewClaim()"
        class="policies-type-header btn btn-sm btn-info"
        style="font-weight: unset"
      >
        <i class="icon ion-ios-add-circle-outline"></i>
        {{ "Claims.newClaim" | translate }}
      </button>
    </div>
  </div>
</div>
<!-- Loader -->
<div *ngIf="isLoadingCliams" class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- Claims -->
<div class="" *ngIf="!isPolices">
  <div class="row">
    <div class="col-lg-12">
      <div class="card" *ngIf="claimsList?.data.length > 0 && !isLoadingCliams">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <!-- <th>Name</th> -->
                  <th>claim Reference Id</th>
                  <th>accident Date</th>
                  <th>total Un Read Claims</th>
                  <th>claim Details</th>
                  <th>Created On</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let claim of claimsList.data">
                  <!-- <td></td> -->
                  <td>{{ claim.claimReferenceId }}</td>
                  <td>{{ claim.accidentDate | date }}</td>
                  <td>{{ claim.totalUnReadClaims }}</td>
                  <td>{{ claim.claimDetails }}</td>
                  <td>{{ claim.createdDate | date }}</td>
                  <td>
                    <label
                      class="badge text-light"
                      [ngClass]="{
                        'badge-secondary': claim.status == 'Pending',
                        'badge-warning': claim.status == 'Under Proccess',
                        'badge-success': claim.status == 'Closed',
                        'badge-danger': claim.status == 'Rejected'
                      }"
                    >
                      {{ claim.status }}
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ALL POLICIES LIST -->
<div *ngIf="isPolices">
  <div
    class="policies-list-container card py-3"
    *ngFor="
      let policy of policies | slice : (page - 1) * pageSize : page * pageSize
    "
  >
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 flex-container px-3">
        <!--- Company Logo --------------------------------------------------->
        <!-------------------------------------------------------------------->
        <!-- <img class="flex-centered company-log"
                    src="assets/images/quote/QuoteCompanyPlaceholder.svg"> -->
        <img
          class="img-fluid d-block mx-auto"
          [src]="vehicleLogoSrc + policy.insurCompLogo"
          alt=""
          style="width: 150px"
        />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 right-border px-3">
        <tr>
          <th>
            {{ "Profile.PanelItems.Policies.PolicyNumber" | translate }} :
          </th>
          <td>{{ policy.policyNumber }}</td>
        </tr>

        <tr>
          <th>
            {{
              "Profile.PanelItems.Policies.PolicyInitiationDate" | translate
            }}
            :
          </th>
          <td>{{ policy.policyEffectiveDate | date : "dd-MM-yyy" }}</td>
          <!-- {{ policy.policyEffectiveDate }} {{policy.policyEffectiveDate | date:'d'}} {{ policy.policyEffectiveDate | date:'MMMM'}}
                    {{ policy.policyEffectiveDate | date:'yyyy'}} -->
        </tr>

        <tr>
          <th>
            {{ "Profile.PanelItems.Policies.PolicyStartDate" | translate }} :
          </th>
          <td>{{ policy.policyIssueDate | date : "dd-MM-yyy" }}</td>
        </tr>

        <tr>
          <th>
            {{ "Profile.PanelItems.Policies.PolicyEndDate" | translate }} :
          </th>
          <td>{{ policy.policyExpiryDate | date : "dd-MM-yyy" }}</td>
        </tr>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 right-border px-3">
        <!-- <tr>
                <th>{{ 'Checkout.PolicyDetails.SequenceNum' | translate }} :</th>
                <td>{{ policy.vehicleId }}</td>
            </tr> -->

        <tr>
          <th>
            {{ "Checkout.PolicyDetails.VehicleDescription" | translate }} :
          </th>
          <td>
            ({{ policy.maker }} {{ policy.model }} {{ policy.modelYear }})
          </td>
        </tr>

        <tr>
          <th>
            {{ "Checkout.PolicyDetails.VehicleMajorColor" | translate }} :
          </th>
          <td *ngIf="lang == 'ar'">{{ policy.colorAr }}</td>
          <td *ngIf="lang == 'en'">{{ policy.colorAr }}</td>
        </tr>

        <tr *ngIf="policy.vehicleIdTypeId == '1'">
          <th>{{ "Profile.PanelItems.Policies.PlateNumber" | translate }}:</th>
          <td *ngIf="lang == 'ar'">
            {{ policy.plateFirstLetterAr }} {{ policy.plateSecondLetterAr }}
            {{ policy.plateThirdLetterAr }} {{ policy.plateNumber }}
          </td>
          <td *ngIf="lang == 'en'">
            {{ policy.plateFirstLetter }} {{ policy.plateSecondLetter }}
            {{ policy.plateThirdLetter }} {{ policy.plateNumber }}
          </td>
        </tr>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 right-border px-3">
        <div class="container mt-5">
          <!-- <a class="identity-btn text-white btn-block mt-3 text-center py-1"  (click)="printPolicy(policy.id)">
                    <i class="icon ion-md-print" placement="top"
                        [ngbTooltip]="('QuotationRequestForms.Tooltips.PrintBtn' | translate)" aria-hidden="true"
                       ></i>[routerLink]="'Addclaims'" [state]="policy.id"
                    Print
                </a> -->
          <a
            class="identity-btn text-white btn-block text-center py-1"
            target="_blank"
            (click)="policyData(policy)"
          >
            {{ "Claims.newClaim" | translate }}
          </a>
        </div>
        <i
          *ngIf="policy.status == 1 || policy.status == 2"
          class="icon ion-md-refresh"
          placement="top"
          [ngbTooltip]="'QuotationRequestForms.Tooltips.Renew' | translate"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3" *ngIf="policies.length > 0">
    <ngb-pagination
      [(page)]="page"
      [pageSize]="pageSize"
      [collectionSize]="policies.length"
    ></ngb-pagination>
  </div>
</div>
<!-- Empty Alert -->
<div
  *ngIf="claimsList?.data.length === 0 && !isLoadingCliams"
  class="empty-policies-container"
>
  <h6>
    <i class="empty-header-icon ion-ios-alert"></i>
    <!-- {{'Claims.noClaim'|translate}} -->
    {{
      noPolicyError
        ? ("Claims.noPolicyError" | translate)
        : ("Claims.noClaim" | translate)
    }}
  </h6>
</div>
