import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Invoice } from "src/app/models/checkout/Invoice";
import { InvoiceGeneration } from "src/app/models/checkout/InvoiceGeneration";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  activeGeneratedInvoice: BehaviorSubject<InvoiceGeneration> =
    new BehaviorSubject<InvoiceGeneration>(undefined);

  constructor(private http: HttpClient) {}
  download(url: string): Observable<Blob> {
    return this.http.get("https://wazen-api.oasisoft.net/" + url, {
      responseType: "blob",
    });
  }

  setActiveGeneratedInvoice(invoiceGenerationData: InvoiceGeneration) {
    this.activeGeneratedInvoice.next(invoiceGenerationData);
  }

  getActiveGeneratedInvoice(): BehaviorSubject<InvoiceGeneration> {
    return this.activeGeneratedInvoice;
  }
}
