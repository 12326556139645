import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { ErrorComponent } from "./pages/error/error.component";
import { LoadingComponent } from "./pages/loading/loading.component";
import { ProgressComponent } from "./layout/progress/progress.component";
import { HijriDatePipe } from "./../../helpers/pipes/HijriDate.pipe";
import { SortByPipe } from "./../../helpers/pipes/SortBy.pipe";
import { InvoiceComponent } from "./pages/invoice/invoice.component";
import { HigriDatepickerComponent } from "./datepicker/higri-datepicker/higri-datepicker.component";
import { GregorianDatepickerComponent } from "./datepicker/gregorian-datepicker/gregorian-datepicker.component";
import { AnQrcodeModule } from "an-qrcode";
import { TranslationModule } from "./translation.module";
import { WizardStepsWrapperComponent } from "../wizard-steps-wrapper/wizard-steps-wrapper.component";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { ContactUsComponent } from "../Portal/help-center/contact-us/contact-us.component";
import { TermsConditionsComponent } from "./layout/terms-conditions/terms-conditions.component";
import { SharedRoutingModule } from "./shared.routes";
import { NgxMaskModule } from "ngx-mask";
import { PrivacyPolicyComponent } from "./layout/privacy-policy/privacy-policy.component";
import { InsuranceAuthorityComponent } from "./insurance-authority/insurance-authority.component";
import { ChannelsComponent } from "./layout/channels/channels.component";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    ErrorComponent,
    InsuranceAuthorityComponent,
    ProgressComponent,
    InvoiceComponent,
    HigriDatepickerComponent,
    GregorianDatepickerComponent,
    HijriDatePipe,
    SortByPipe,
    WizardStepsWrapperComponent,
    // ContactUsComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ChannelsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    AnQrcodeModule,
    TranslationModule,
    NgxMaskModule.forRoot(),
    SharedRoutingModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    InvoiceComponent,
    LoadingComponent,
    ErrorComponent,
    HijriDatePipe,
    SortByPipe,
    HigriDatepickerComponent,
    TranslationModule,
    GregorianDatepickerComponent,
  ],
})
export class SharedModule {}
