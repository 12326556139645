import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class FormValidationServiceService {
  submitted: boolean = false;
  submitForm = new BehaviorSubject<boolean>(false);
  // submit$ = this.submitForm.asObservable();
  constructor() {}

  formsStatus: BehaviorSubject<{ formName: string; isInvalid: boolean }[]> =
    new BehaviorSubject<{ formName: string; isInvalid: boolean }[]>([]);

  public setSubmitted(state: boolean) {
    this.submitForm.next(state);
  }

  public submitState(): Observable<boolean> {
    return this.submitForm.asObservable();
  }
}
