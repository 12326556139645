<div class="row" id="add-driver">
  <div class="col-lg-2"></div>
  <div class="col-lg-7 col-md-7 col-sm-7">
    <label class="question-lable">{{ "QuotationRequestForms.AdditionalDriver.AddDriver" | translate }}
      <i class="icon ion-md-help-circle tooltip-icon" placement="top" [ngbTooltip]="
          'QuotationRequestForms.Tooltips.AdditionalDriver' | translate
        " aria-hidden="true" style="width: 20">
      </i></label>
  </div>
  <div class="col-lg-2 col-md-5 col-sm-5">
    <input type="checkbox" id="question1" class="question-checkbox" [checked]="showAdditionalDriverForm"
      (change)="showAdditionalDriversForm()" /><label for="question1"><span class="ui"></span></label>
  </div>
  <div class="col-lg-1"></div>
</div>
<div class="row" *ngIf="showAdditionalDriverForm">
  <div class="col-sm-2 col-md-2 col-lg-2 flex-container">
    <div class="flex-centered mt-0">
      <div class="round round-lg hollow color" style="margin: 10px auto">
        <span>4</span>
      </div>
      <div class="title">
        <h6>
          {{ "QuotationRequestForms.StepsTitles.AddDriverInfo" | translate }}
        </h6>
        <img src="assets/images/icons8-driver-80.png" class="logo" />
      </div>
    </div>
  </div>

  <div class="col-sm-9 col-md-9 col-lg-9 border-right border-left">
    <!-- ----------------------------------------------------------------------- -->
    <!--                              Errors Alert                               -->
    <!-- ----------------------------------------------------------------------- -->
    <div class="row alert-box" *ngIf="isErrorAlertVisible" id="errorAlert">
      <ngb-alert class="alert col-12" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
        <small>
          <i class="icon ion-md-alert"></i>
          {{ errorMessage }}
        </small>
        <!--  <small *ngIf="validationErrors">
              <ul class="errors-list">
                  <li *ngFor="let error of validationErrors">
                      {{error}}
                  </li>
              </ul>
          </small> -->
      </ngb-alert>
    </div>

    <form [formGroup]="additionalDriverInfoForm">
      <div class="row">
        <!-- ----------------------------------------------------------------------- -->
        <!--                             identityNumber                              -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <label>
            {{
            "QuotationRequestForms.AdditionalDriver.NationalIDLabel"
            | translate
            }}</label>
          <div class="form-group">
            <input formControlName="identityNumber"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10"
              autocomplete="on" name="identityNumber" class="form-control" type="text" />
            <!-- Validation Errors -->
            <div *ngIf="f.identityNumber.errors">
              <div *ngIf="
                  f.identityNumber.touched && f.identityNumber.errors.required
                " class="v-meassage">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div *ngIf="
                  f.identityNumber.touched &&
                  !f.identityNumber.errors.isValidNationalId &&
                  !f.identityNumber.errors.duplicate
                " class="v-meassage">
                {{ "Errors.InvalidNationalID" | translate }}
              </div>
              <div *ngIf="
                  f.identityNumber.touched && f.identityNumber.errors.minlength
                " class="v-meassage">
                {{ "Errors.IncompleteInputField" | translate }}
              </div>

              <div *ngIf="
                  f.identityNumber.dirty && f.identityNumber.errors.duplicate
                " class="v-meassage">
                {{ "Errors.DriverExist" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                               date picker                               -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4" *ngIf="showgregoianDate || showHijriDate">
          <label>
            {{ "QuotationRequestForms.DriverInfo.Birth" | translate }}
          </label>
          <!--  [previousQuoteDriverBirthDate]="previousQuoteDriverBirthDate" -->
          <app-gregorian-datepicker (onDatePicked)="setBirthDateValue($event)" *ngIf="showgregoianDate">
          </app-gregorian-datepicker>
          <app-higri-datepicker (onDatePicked)="setBirthDateValue($event)" *ngIf="showHijriDate">
          </app-higri-datepicker>
          <div *ngIf="additionalDriverInfoFormSubmitted && f.birthDateG.errors">
            <div class="v-meassage" *ngIf="f.birthDateG.errors.required">
              {{ "Errors.EmptyInputField" | translate }}
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                          childrenUnder16Years                           -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>{{
              "QuotationRequestForms.DriverInfo.Children" | translate
              }}</label>
            <ng-select [items]="fromZeroToTen" formControlName="childrenUnder16Years" bindLabel="name" bindValue="id">
            </ng-select>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                            educationLevelId                             -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>{{
              "QuotationRequestForms.DriverInfo.Education" | translate
              }}</label>
            <ng-select [items]="stepsData.EducationLevels" bindValue="id" [searchFn]="termSearchFn"
              [loading]="!stepsData.EducationLevels" formControlName="educationLevelId" [ngClass]="{
                'ng-invalid':
                  additionalDriverInfoFormSubmitted && f.educationLevelId.errors
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="
                additionalDriverInfoFormSubmitted && f.educationLevelId.errors
              ">
              <div class="v-meassage" *ngIf="f.educationLevelId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                           drivingPercentageId?                           -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>
              {{
              "QuotationRequestForms.AdditionalDriver.Percentage" | translate
              }}</label>
            <ng-select [items]="stepsData.DrivingPercentages" formControlName="drivingPercentageId"
              [loading]="!stepsData.DrivingPercentages" bindValue="id" [ngClass]="{
                'ng-invalid':
                  additionalDriverInfoFormSubmitted &&
                  f?.drivingPercentageId?.errors
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>

            <div *ngIf="f?.drivingPercentageId?.errors">
              <div class="v-meassage" *ngIf="
                  additionalDriverInfoFormSubmitted &&
                  f?.drivingPercentageId?.errors.required
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div class="v-meassage" *ngIf="
                  f?.drivingPercentageId?.dirty &&
                  f?.drivingPercentageId?.errors.drivingPercentageComplete
                ">
                {{ "Errors.InCompletePercentage" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                               relationId                                -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>
              {{
              "QuotationRequestForms.DriverInfo.Relation" | translate
              }}</label>
            <ng-select [items]="stepsData.relations" formControlName="relationId" [searchFn]="termSearchFn"
              [loading]="!stepsData.relations" bindValue="id" [ngClass]="{
                'ng-invalid':
                  additionalDriverInfoFormSubmitted && f.relationId.errors
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="additionalDriverInfoFormSubmitted && f.relationId.errors">
              <div class="v-meassage" *ngIf="f.relationId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                               Occupation                                -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>
              {{ "QuotationRequestForms.DriverInfo.Occupation" | translate }}
              <i class="fa fa-question-circle tooltip-icon" placement="top" ngbTooltip="Tooltip on top"
                aria-hidden="true">
              </i>
            </label>
            <ng-select [items]="stepsData?.Occupations" bindValue="id" formControlName="occupationId"
              [loading]="!stepsData?.EducationLevels" [searchFn]="termSearchFn" [virtualScroll]="true" [ngClass]="{
                'ng-invalid':
                  additionalDriverInfoFormSubmitted && f.occupationId.errors
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="additionalDriverInfoFormSubmitted && f.occupationId.errors">
              <div class="v-meassage" *ngIf="f.occupationId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <!----->
      <!-- Address -->
      <div class="row">
        <!-- <div class="col-lg-10 col-md-8 col-sm-9">
               <label class="question-lable">{{ 'QuotationRequestForms.AdditionalDriver.Address' | translate }}</label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3">
               <input type="checkbox" id="driver-adress" class="question-checkbox"  formControlName="isSameInsuredAddress" [checked]="true"/><label for="driver-adress"><span class="ui"></span></label>
            </div> -->
        <div class="col">
          <input class="cbx" type="checkbox" id="driver-adress" style="display: none"
            formControlName="isSameInsuredAddress" [checked]="true" />
          <label for="driver-adress" class="check pt-2 pb-2">
            <svg width="18px" height="18px" viewBox="0 0 20 18">
              <path
                d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
              </path>
              <polyline points="1 9 7 14 15 4"></polyline>
            </svg>
            {{ "QuotationRequestForms.AdditionalDriver.Address" | translate }}
          </label>
        </div>
      </div>
      <hr />
      <!-- ----------------------------------------------------------------------- -->
      <!--                       Medical condition Question                        -->
      <!-- ----------------------------------------------------------------------- -->

      <div class="row">
        <div class="col-lg-10 col-md-8 col-sm-9">
          <label class="question-lable">{{
            "QuotationRequestForms.AdditionalDriver.Conditions" | translate
            }}</label>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-3">
          <div class="tg-list">
            <div class="tg-list-item">
              <!-- <input class="tgl tgl-flip" id="cb4" type="checkbox" (change)="showMedicalCondition()" />
              <label [attr.data-tg-off]="!showDriverMedical ? choose : choose"
                [attr.data-tg-on]="showDriverMedical ? pass : pass" class="tgl-btn" for="cb4"></label> -->
              <button type="button" class="btn btn-sm" [class.btn-primary]="!isCollapsed"
                [class.btn-outline-primary]="isCollapsed" (click)="collapse.toggle()"
                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                {{choose}}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- ----------------------------------------------------------------------- -->
      <!--                 MedicalConditon is Empty Error Message                  -->
      <!-- ----------------------------------------------------------------------- -->
      <!--
         <div *ngIf="isMedicalCheckboxEmpty" class="v-meassage  pt-2 pb-2">
            {{ 'Errors.MedicalConditionInvalid' | translate }}
         </div> -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                       MedicalConditon Checkboxes                        -->
      <!-- ----------------------------------------------------------------------- -->

      <div class="row" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <!-- spinner -->
        <div *ngIf="!stepsData.MedicalConditions" class="loader-card col d-flex justify-content-center">
          <div class="content-container loader-wrapper flex-centered flex-container">
            <div class="lds-facebook flex-centered">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4" *ngFor="let medical of stepsData.MedicalConditions; let i = index">
          <input class="cbx" type="checkbox" [id]="medical?.name + i + 'a'" style="display: none"
            [checked]="medical.checked" (change)="addMedicalCondition(medical.id)" />
          <label for="{{ medical.name + i + 'a' }}" class="check pt-2 pb-2">
            <svg width="18px" height="18px" viewBox="0 0 20 18">
              <path
                d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
              </path>
              <polyline points="1 9 7 14 15 4"></polyline>
            </svg>
            <span> {{ lang === "ar" ? medical.nameAr : medical.name }} </span>
          </label>
        </div>
      </div>
      <hr />
      <!-- ----------------------------------------------------------------------- -->
      <!--                       Violations condition Question                        -->
      <!-- ----------------------------------------------------------------------- -->
      <!--   <div class="row">
            <div class="col-lg-10 col-md-8 col-sm-9">
               <label class="question-lable">{{ 'QuotationRequestForms.AdditionalDriver.Violations' | translate }}</label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3">
               <input type="checkbox" id="driverViolation" class="question-checkbox"
                  (change)="showViolations()" /><label for="driverViolation"><span class="ui"></span></label>
            </div>
         </div> -->
      <!-- ----------------------------------------------------------------------- -->
      <!--                 violations is Empty Error Message                  -->
      <!-- ----------------------------------------------------------------------- -->
      <!--  <div *ngIf="isViolationCheckboxEmpty" class="v-meassage  pt-2 pb-2">
            {{ 'Errors.ViolationInvalid' | translate }}
         </div> -->
      <!-- ----------------------------------------------------------------------- -->
      <!--                       violations Checkboxes                        -->
      <!-- ----------------------------------------------------------------------- -->
      <!--  <div *ngIf="showViolation">

            <div class="row">
               <div class="spinner-border" role="status" *ngIf="!stepsData.violations">
                  <span class="sr-only">Loading...</span>
               </div>
               <div class="col-sm-6 col-md-4 col-lg-4" *ngFor="let violation of stepsData.violations; let i = index;">
                  <input class="cbx" type="checkbox" [id]="violation.name + i + 'a'" style="display: none;" [checked]="violation.checked"
                     (change)="addViolation(violation.id)">
                  <label for="{{violation.name + i + 'a'}}" class="check pt-2 pb-2">
                     <svg width="18px" height="18px" viewBox="0 0 20 18">
                        <path
                           d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
                        </path>
                        <polyline points="1 9 7 14 15 4"></polyline>
                     </svg>
                     <span> {{ lang === 'ar' ? violation.nameAr : violation.name }}</span>
                  </label>
               </div>
            </div>
         </div> -->
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button type="button" class="btn btn-save bouncy" (click)="submitForm()" [disabled]="isSavingDriver">
            <span *ngIf="isSavingDriver" class="spinner-border spinner-border-sm text-white" role="status"
              aria-hidden="true"></span>
            <i class="icon ion-ios-save" *ngIf="!isSavingDriver"></i>
            {{ "QuotationRequestForms.AdditionalDriver.SaveBtn" | translate }}
            <!-- <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
          </button>

          <button type="button" class="btn btn-danger" (click)="cancelAddingDriver()">
            <i class="icon ion-ios-close"></i>
            {{ "QuotationRequestForms.AdditionalDriver.CancelBtn" | translate }}
          </button>
        </div>
      </div>
    </form>
    <hr />

    <!-- Driver list-->
  </div>
  <div class="col-sm-1 col-md-1 col-lg-1">
    <div [ngClass]="{
        'check-icon': additionalDriverInfoForm.valid,
        'check-icon-invalid': additionalDriverInfoForm.invalid
      }">
      <i class="icon ion-md-checkmark-circle"></i>
    </div>
  </div>
</div>