<form [formGroup]="submitPayment" (submit)="getPaymentValue()" #form
  action="https://paytest.directpay.sa/SmartRoutePaymentWeb/SRPayMsgHandler" method="post" name="redirectForm">
  <input formControlName="Amount" name="Amount" hidden />
  <input formControlName="MerchantID" name="MerchantID" hidden />
  <input formControlName="CurrencyISOCode" name="CurrencyISOCode" hidden />
  <input formControlName="Language" name="Language" hidden />
  <input formControlName="MessageID" name="MessageID" hidden />
  <input formControlName="PaymentDescription" name="PaymentDescription" hidden />
  <input formControlName="PaymentMethod" name="PaymentMethod" hidden />
  <input formControlName="TransactionID" name="TransactionID" hidden />
  <input formControlName="ResponseBackURL" name="ResponseBackURL" hidden />
  <input formControlName="Quantity" name="Quantity" hidden />
  <input formControlName="Channel" name="Channel" hidden />
  <input formControlName="Version" name="Version" hidden />
  <input formControlName="SecureHash" name="SecureHash" hidden />
  <!--<div class="container mt-4 d-flex justify-content-center main">
   <div class="card card py-4">
      <div class="card-body p-2">
          <div class="text-center mb-4">
            <h3>Payment</h3>
            <img _ngcontent-wnc-c115="" src="../../../../assets/images/payment-cards.png" style="width: 40%;"
              alt="visa" />
          </div>
        </div> -->
  <!-- <div class="px-3 pt-2">
          <label for="card number" class="form-label">CARD NUMBER</label>
          <input type="text" [ngClass]="{'is-invalid': isSubmit && submitPayment.controls.CardNumber.errors}"
            formControlName="CardNumber" name="CardNumber" class="form-control" placeholder="8881 2545 2545 2245" />
        </div> -->

  <!-- <div class="px-3 pt-2">
          <label for="card Name" class="exptxt"> Card Name </label>
          <input type="text" formControlName="CardHolderName" [ngClass]="{
                    'is-invalid':
                      isSubmit && submitPayment.controls.CardHolderName.errors
                  }" name="CardHolderName" class="form-control" placeholder="Card Name" />
        </div> -->
  <!-- <div class="d-flex justify-content-between px-3 pt-3">
          <div>
            <label for="date" class="exptxt">Expiry Date Year </label>
            <input type="text" formControlName="ExpiryDateYear" [ngClass]="{
                      'is-invalid':
                        isSubmit && submitPayment.controls.ExpiryDateYear.errors
                    }" name="ExpiryDateYear" class="form-control" placeholder="YY" />
          </div>
          <div>
            <label for="date" class="exptxt">Expiry Date Month </label>
            <input type="text" [ngClass]="{
                      'is-invalid':
                        isSubmit && submitPayment.controls.ExpiryDateMonth.errors
                    }" formControlName="ExpiryDateMonth" name="ExpiryDateMonth" class="form-control"
              placeholder="MM" />
          </div>
          <div>
            <label for="cvv" class="cvvtxt">CVV /CVC </label>
            <input type="text" [ngClass]="{
                      'is-invalid':
                        isSubmit && submitPayment.controls.SecurityCode.errors
                    }" formControlName="SecurityCode" name="SecurityCode" class="form-control" placeholder="123" />
          </div>
        </div>

        <button type="submit" (click)="getPaymentValue()" class="btn btn-primary mt-4 px-3 pt-2">
          Pay
        </button>


    </div>
  </div>-->
</form>