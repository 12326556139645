import { Bank } from "./Bank";

export default class BanksIds {
  static banks: Bank[] = [
    {
      id: 26,
      code: 100,
      nameAr: "بنك باركلاس",
      name: "barclays",
    },
    {
      id: 1,
      code: 5,
      nameAr: "مصرف الإنماء",
      name: "Alinma Bank",
    },
    {
      id: 2,
      code: 10,
      nameAr: "البنك الأهلي التجاري",
      name: "The National Commercial Bank",
    },
    {
      id: 3,
      code: 15,
      nameAr: "بنك البلاد",
      name: "Bank AlBilad",
    },
    {
      id: 4,
      code: 20,
      nameAr: "بنك الرياض",
      name: "Riyad Bank",
    },
    {
      id: 5,
      code: 30,
      nameAr: "البنك العربي الوطني",
      name: "Arab National Bank",
    },
    {
      id: 6,
      code: 40,
      nameAr: "(مجموعة سامبا المالية (سامبا",
      name: "Samba Financial Group (Samba)",
    },
    {
      id: 7,
      code: 45,
      nameAr: "البنك السعودي البريطاني",
      name: "The Saudi British Bank (SABB)",
    },
    {
      id: 8,
      code: 50,
      nameAr: "البنك الأول",
      name: "Alawwal Bank",
    },
    {
      id: 9,
      code: 55,
      nameAr: "البنك السعودي الفرنسي",
      name: "Banque Saudi Fransi",
    },
    {
      id: 10,
      code: 60,
      nameAr: "بنك الجزيرة",
      name: "Bank AlJazira",
    },
    {
      id: 11,
      code: 65,
      nameAr: "البنك السعودي للاستثمار",
      name: "Saudi Investment Bank",
    },
    {
      id: 12,
      code: 80,
      nameAr: "مصرف الراجحي",
      name: "Al Rajhi Bank",
    },
    {
      id: 13,
      code: 71,
      nameAr: "بنك البحرين الوطني",
      name: "National Bank of Bahrain (NBB)",
    },
    {
      id: 14,
      code: 75,
      nameAr: "بنك الكويت الوطني",
      name: "National Bank of Kuwait (NBK)",
    },
    {
      id: 15,
      code: 76,
      nameAr: "بنك مسقط",
      name: "Muscat Bank",
    },
    {
      id: 16,
      code: 81,
      nameAr: "دويتشه بنك",
      name: "Deutsche Bank",
    },
    {
      id: 17,
      code: 82,
      nameAr: "بنك باكستان الوطني",
      name: "National Bank Of Pakistan (NBP)",
    },
    {
      id: 18,
      code: 83,
      nameAr: "ستيت بنك أوف إنديا",
      name: "State Bank of India(SBI)",
    },
    {
      id: 19,
      code: 84,
      nameAr: "بنك تي سي زراعات بانكاسي",
      name: "T.C.ZIRAAT BANKASI A.S.",
    },
    {
      id: 20,
      code: 85,
      nameAr: "بي إن بي باريباس",
      name: "BNP Paribas",
    },
    {
      id: 21,
      code: 86,
      nameAr: "جي بي مورقان تشيز إن أيه",
      name: "J.P. Morgan Chase N.A",
    },
    {
      id: 22,
      code: 90,
      nameAr: "بنك الخليج الدولي",
      name: " Gulf International Bank(GIB)",
    },
    {
      id: 23,
      code: 95,
      nameAr: "بنك الإمارات دبي الوطني",
      name: "Emirates (NBD)",
    },
  ];
}
