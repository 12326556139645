import { Driver } from "./Driver";
import { DriverLicense } from "./DriverLicense";

export enum ManagedForms {
  LandingForm = "LandingForm",
  VehicleInsuranceForm = "VehicleInsuranceForm",
  MainDriverInsuranceForm = "MainDriverInsuranceForm",
  AdditionalDriverInsuranceForm = "AdditionalDriverInsuranceForm",
  DriversMoreDetails = "DriversMoreDetails",
  // End form to scroll
  FinalStep = " FinalStep",
}
export interface ManagedFormsTypes {
  LandingForm: LandingForm;
  VehicleInsuranceForm: VehicleForm;
  MainDriverInsuranceForm: Driver;
  DriversMoreDetails: DriversMoreDetails;
}

export interface LandingForm {
  isVehicleOwnerTransfer?: boolean;
  insuredIdentityNumber?: any;
  vehicleId?: string;
  policyEffectiveDate?: any;
  vehicleIdTypeId?: number;
  vehicleOwnerIdentityNumber?: any;
  vehicleModelYear?: string;
  insuredIdentityTypeId?: number;
  captcha?: any;
}

export interface VehicleForm {
  vehicleSumInsured?: number;
  vehicleUseId?: number;
  vehicleModelYear?: number;
  vehicleTransmissionTypeId?: number;
  vehicleParkingLocationId?: number;
  isVehicleModified?: boolean;
  vehicleModificationDetails?: string;
  isVehicleOwnerTransfer?: boolean;
  vehicleRepairMethodId?: number;
  vehicleOwnerIdentityNumber?: number;
  vehicleSpecifications?: string;
}

export interface DriversMoreDetails {
  TypeId: number;
  MedicalConditions: string;
  DriverViolations: string;
  DriverLicenses: DriverLicense;
}
