import { Injectable } from "@angular/core";
import { NgFormsManager } from "@ngneat/forms-manager";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import {
  ManagedForms,
  ManagedFormsTypes,
} from "src/app/models/insurance-steps/ManagedFormsTypes";
import { PreviousQuotationData } from "src/app/models/insurance-steps/PreviousQuotationData";
import { InsuranceStepsData } from "src/app/models/master-table/InsuranceStepsData";
import { Vehicles } from "src/app/models/profile/ClientVehicles";

@Injectable({
  providedIn: "root",
})
export class InsuranceStepsService {
  vehicles: Vehicles[] = [];

  insuranceStepsData: BehaviorSubject<InsuranceStepsData> =
    new BehaviorSubject<InsuranceStepsData>({} as InsuranceStepsData);
  //TODO: Create interface for the previousQuotation Data
  previousQuotationData: BehaviorSubject<PreviousQuotationData> =
    new BehaviorSubject<PreviousQuotationData>({} as PreviousQuotationData);
  previousQuotationDataAction = this.previousQuotationData.asObservable();

  public isNewVehicles = new BehaviorSubject<boolean>(true);
  isNewVehiclesAction = this.isNewVehicles.asObservable();

  public insuranceStepNumber: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  insuranceStepNumberAction = this.isNewVehicles.asObservable();

  constructor(private formsManager: NgFormsManager<ManagedFormsTypes>) {}

  /**
   * Set the insurance steps data master tables data
   * @param insuranceStepsData Steps data
   */
  public setInsuranceStepsData(insuranceStepsData: InsuranceStepsData): void {
    this.insuranceStepsData.next(insuranceStepsData);

    localStorage.setItem(
      LocallyStoredItemsKeys.InsuranceCurrentStepData,
      JSON.stringify(insuranceStepsData)
    );
  }

  /**
   * Returns the current insurance data
   */
  public getInsuranceStepsData(): BehaviorSubject<InsuranceStepsData> {
    // Get from local storage
    // const storedStepsData = localStorage.getItem(LocallyStoredItemsKeys.InsuranceCurrentStepData);
    // // If exists in local storage then set it's value on the subject
    // if (storedStepsData && storedStepsData.length > 0) {
    //    this.setInsuranceStepsData(JSON.parse(storedStepsData));
    // }
    return this.insuranceStepsData;
  }

  public addInsuranceStepsObject(res: any, field: string): void {
    // Get current steps
    const tempStepsData = this.getInsuranceStepsData().value;
    // Add the object
    tempStepsData[field] = res;
    // Set the steps
    this.setInsuranceStepsData(tempStepsData);
  }

  async isMainDriverInsuranceFormValid() {
    const formStatus: { formName: string; isValid: boolean } = {
      formName: "",
      isValid: false,
    };
    await this.formsManager
      .validityChanges(ManagedForms.MainDriverInsuranceForm)
      .pipe(first())
      .subscribe((isValid) => {
        formStatus.formName = ManagedForms.MainDriverInsuranceForm;
        formStatus.isValid = isValid;
        if (!formStatus.isValid) {
          this.formsManager.markAllAsTouched(
            ManagedForms.MainDriverInsuranceForm
          );
        }
      });
    return formStatus;
  }

  async isLandingFormValid() {
    const formStatus: { formName: string; isValid: boolean } = {
      formName: "",
      isValid: false,
    };
    await this.formsManager
      .validityChanges(ManagedForms.LandingForm)
      .pipe(first())
      .subscribe((isValid) => {
        formStatus.formName = ManagedForms.LandingForm;
        formStatus.isValid = isValid;
        if (!formStatus.isValid) {
          this.formsManager.markAllAsTouched(ManagedForms.LandingForm);
        }
      });
    return formStatus;
  }

  async isVehicleInsuranceFormValid() {
    const formStatus: { formName: string; isValid: boolean } = {
      formName: "",
      isValid: false,
    };
    await this.formsManager
      .validityChanges(ManagedForms.VehicleInsuranceForm)
      .pipe(first())
      .subscribe((isValid) => {
        formStatus.formName = ManagedForms.VehicleInsuranceForm;
        formStatus.isValid = isValid;
        if (!formStatus.isValid) {
          this.formsManager.markAllAsTouched(ManagedForms.VehicleInsuranceForm);
        }
      });
    return formStatus;
  }
  // check form validitiy
  public isValidFormManager() {
    let formStatus: { formName: string; isValid: boolean } = {
      formName: "",
      isValid: false,
    };
    let formsStatus: [{ formName: string; isValid: boolean }] = [
      { formName: "", isValid: false },
    ];
    this.formsManager
      .validityChanges(ManagedForms.MainDriverInsuranceForm)
      .pipe(first())
      .subscribe((isValid) => {
        formStatus.formName = ManagedForms.MainDriverInsuranceForm;
        formStatus.isValid = isValid;
        if (formStatus.isValid) {
          formsStatus.push(formStatus);
        } else {
          this.formsManager.markAllAsTouched(
            ManagedForms.MainDriverInsuranceForm
          );
          formsStatus.push(formStatus);
        }
      });

    this.formsManager
      .validityChanges(ManagedForms.VehicleInsuranceForm)
      .pipe(first())
      .subscribe((isValid) => {
        formStatus.formName = ManagedForms.VehicleInsuranceForm;
        formStatus.isValid = isValid;
        if (formStatus.isValid) {
          formsStatus.push(formStatus);
        } else {
          this.formsManager.markAllAsTouched(ManagedForms.VehicleInsuranceForm);
          formsStatus.push(formStatus);
        }
      });

    this.formsManager
      .validityChanges(ManagedForms.LandingForm)
      .pipe(first())
      .subscribe((isValid) => {
        formStatus.formName = ManagedForms.LandingForm;
        formStatus.isValid = isValid;
        if (formStatus.isValid) {
          formsStatus.push(formStatus);
        } else {
          this.formsManager.markAllAsTouched(ManagedForms.LandingForm);
          formsStatus.push(formStatus);
        }
      });

    return formsStatus;
  }

  /* -------------------------------------------------------------------------- */
  /*                             Getters and Setters                            */
  /* -------------------------------------------------------------------------- */

  setSelectVehicle(PreviousQuotationData): void {
    let tempPreviousQuotationData = this.previousQuotationData.value;
    tempPreviousQuotationData.Vehicle = PreviousQuotationData;
    this.previousQuotationData.next(tempPreviousQuotationData);
  }

  setSelectIdentity(PreviousQuotationData): void {
    let tempPreviousQuotationData = this.previousQuotationData.value;
    tempPreviousQuotationData.Identity = PreviousQuotationData;
    this.previousQuotationData.next(tempPreviousQuotationData);
  }

  getSelectIdentity(): BehaviorSubject<PreviousQuotationData> {
    return this.previousQuotationData;
  }

  getSelectVehicle(): BehaviorSubject<PreviousQuotationData> {
    return this.previousQuotationData;
  }

  setIsVehicleOwnerTransfer(): void {
    let tempPreviousQuotationData = this.previousQuotationData.value;
    tempPreviousQuotationData.Vehicle.isVehicleOwnerTransfer = false;
    this.previousQuotationData.next(tempPreviousQuotationData);
  }

  clearSelectVehicle() {
    let tempPreviousQuotationData = this.previousQuotationData.value;
    tempPreviousQuotationData.Vehicle = Object.assign({});
    this.previousQuotationData.next(tempPreviousQuotationData);
  }

  public setIsNewVechile() {
    this.isNewVehicles.next(!this.isNewVehicles.value);
  }

  public getIsNewVechile() {
    return this.isNewVehicles;
  }

  public setStepNumber(step: number) {
    this.insuranceStepNumber.next(step);
  }

  public getStepNumber() {
    return this.insuranceStepNumber;
  }
}
