<div class="row">
  <div class="col-sm-12 col-md-2 col-lg-2 flex-container center">
    <div class="flex-centered mt-12">
      <div class="round round-lg hollow color" style="margin: 10px auto">
        <div></div>
        <span>1</span>
      </div>
      <div class="logo-wrapper">
        <h6>
          {{ "QuotationRequestForms.StepsTitles.InsuranceInfo" | translate }}
        </h6>
        <img src="assets/images/detaila-insurance.svg" class="logo img-fluid d-flex mx-auto" style="height: 80px" />
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-9 col-lg-9 border-right border-left border-bottom">
    <!-- ----------------------------------------------------------------------- -->
    <!--                             Insurance Form                              -->
    <!-- ----------------------------------------------------------------------- -->
    <div class="form-section flex-container flex-centered">
      <div class="insurance-form" id="insurance-form">
        <form [formGroup]="landingFormGroup" class="rounded-box insurance-form-container">
          <!-- ----------------------------------------------------------------------- -->
          <!--                               Form Inputs                               -->
          <!-- ----------------------------------------------------------------------- -->
          <div class="row">
            <!-- ----------------------------------------------------------------------- -->
            <!--                      Insurance Type Radio Buttons                       -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="form-group col-12">
              <label>{{
                "QuotationRequestForms.InsuranceInfo.InsurancePurpose"
                | translate
                }}</label>
              <br />
              <label class="form-radio registration-by-form-radio">
                <input type="radio" class="option-input radio" formControlName="isVehicleOwnerTransfer" [value]="false"
                  (change)="isTransfer()" />
                <span class="radio-text">
                  {{
                  "QuotationRequestForms.InsuranceInfo.NewInsurance"
                  | translate
                  }}
                </span>
              </label>
              <label class="form-radio registration-by-form-radio">
                <input type="radio" class="option-input radio" (change)="isTransfer()"
                  formControlName="isVehicleOwnerTransfer" [value]="true" />
                <span class="radio-text">
                  {{
                  "QuotationRequestForms.InsuranceInfo.NewVehicleOwner"
                  | translate
                  }}
                </span>
              </label>
            </div>
            <!-- ----------------------------------------------------------------------- -->
            <!--                               National ID                               -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label for="insuredIdentityNumber">{{
                "Landing.NationalIDLabel" | translate
                }}</label>
              <input formControlName="insuredIdentityNumber"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                [placeholder]="'Landing.NationalIDLabel' | translate" maxlength="10" autocomplete="off"
                name="insuredIdentityNumber" class="form-control" type="text"
                [attr.disabled]="requestFromProfileIdentity" />
              <!-- Validation Errors -->
              <div *ngIf="f.insuredIdentityNumber.errors">
                <div *ngIf="
                    (submitted || f.insuredIdentityNumber.touched) &&
                    f.insuredIdentityNumber.errors.required
                  " class="v-meassage">
                  {{ "Errors.EmptyInputField" | translate }}
                </div>
                <div *ngIf="
                    submitted &&
                    f.insuredIdentityNumber.dirty &&
                    !f.insuredIdentityNumber.errors
                      .isValidNationalId &&
                    !f.insuredIdentityNumber.errors.required
                  " class="v-meassage">
                  {{ "Errors.InvalidNationalID" | translate }}
                </div>
                <div *ngIf="
                    submitted &&
                    f.insuredIdentityNumber.dirty &&
                    f.insuredIdentityNumber.errors.minlength
                  " class="v-meassage">
                  {{ "Errors.IncompleteInputField" | translate }}
                </div>
              </div>
            </div>
            <!-- --------------------- vehicleOwnerIdentityNumber Input ---------------------- -->
            <div class="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngIf="isVehicleTrasfer">
              <label for="vehicleOwnerIdentityNumber">{{
                "QuotationRequestForms.InsuranceInfo.VehicleIdentificationNumber"
                | translate
                }}</label>
              <input formControlName="vehicleOwnerIdentityNumber"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="{{
                  'QuotationRequestForms.InsuranceInfo.VehicleIdentificationNumber'
                    | translate
                }}" autocomplete="off" name="vehicleOwnerIdentityNumber" class="form-control" type="text" />
              <!-- [attr.disabled]="requestFromProfile" -->
              <!-- Validation Errors -->
              <div *ngIf="f.vehicleOwnerIdentityNumber.errors">
                <div *ngIf="
                    f.vehicleOwnerIdentityNumber.touched &&
                    f.vehicleOwnerIdentityNumber.errors.required
                  " class="v-meassage">
                  {{ "Errors.EmptyInputField" | translate }}
                </div>
                <div *ngIf="
                    f.vehicleOwnerIdentityNumber.dirty &&
                    !f.vehicleOwnerIdentityNumber.errors
                      .isValidNationalId &&
                    !f.vehicleOwnerIdentityNumber.errors.required
                  " class="v-meassage">
                  {{ "Errors.InvalidNationalID" | translate }}
                </div>
                <div *ngIf="
                    f.vehicleOwnerIdentityNumber.dirty &&
                    f.vehicleOwnerIdentityNumber.errors.minlength
                  " class="v-meassage">
                  {{ "Errors.IncompleteInputField" | translate }}
                </div>
              </div>
            </div>
            <!-- ----------------------------------------------------------------------- -->
            <!--                             VehicleIdTypes                              -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngIf="!isVehicleTrasfer">
              <label for="vehicleIdTypeId">{{
                "Landing.RegistrationTypeText" | translate
                }}</label>
              <ng-select [items]="vehicleIdTypes" [loading]="!vehicleIdTypes" [searchFn]="termSearchFn"
                formControlName="vehicleIdTypeId" (change)="selectVehicleType($event)"
                [placeholder]="'Landing.RegistrationTypeText' | translate" bindValue="id"
                [ngClass]="{ 'is-invalid': f.vehicleIdTypeId.errors }">
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                  <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
                </ng-template>
              </ng-select>
              <div *ngIf="f.vehicleIdTypeId.errors">
                <div class="v-meassage" *ngIf="
                    f.vehicleIdTypeId.errors.required &&
                    f.vehicleIdTypeId.touched
                  ">
                  {{ "Errors.EmptyInputField" | translate }}
                </div>
              </div>
            </div>
            <!-- ----------------------------------------------------------------------- -->
            <!--                             Sequence Number                             -->
            <!-- ----------------------------------------------------------------------- -->
            <div *ngIf="
                selectedVehicleIdTypes == 1 ||
                selectedVehicleIdTypes == 2 ||
                selectedVehicleIdTypes == undefined
              " class="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label *ngIf="selectedVehicleIdTypes == 1" for="vehicleId">{{
                "Landing.SequenceNumText" | translate
                }}</label>
              <!-- [attr.disabled]="requestFromProfile" -->
              <input *ngIf="selectedVehicleIdTypes == 1" formControlName="vehicleId"
                [placeholder]="'Landing.SequenceNumText' | translate"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" name="vehicleId"
                autocomplete="off" class="form-control" maxlength="10" type="text" [ngClass]="{
                  'is-invalid': f.vehicleId.errors && submitted
                }" />
              <!-- Card  Number -->
              <label *ngIf="selectedVehicleIdTypes == 2" for="vehicleId">{{
                "Landing.CardNo" | translate
                }}</label>
              <!-- [attr.disabled]="requestFromProfile" -->
              <input *ngIf="selectedVehicleIdTypes == 2" formControlName="vehicleId"
                [placeholder]="'Landing.CardNo' | translate"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" name="vehicleId"
                autocomplete="off" class="form-control" maxlength="10" type="text" [ngClass]="{
                  'is-invalid': f.vehicleId.errors && submitted
                }" />
              <div *ngIf="submitted && f.vehicleId.errors">
                <div class="v-meassage" *ngIf="f.vehicleId.errors.required">
                  {{ "Errors.EmptyInputField" | translate }}
                </div>
              </div>
            </div>
            <!-- ----------------------------------------------------------------------- -->
            <!--                       Vehicle Manufacturing Date                        -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="form-group col-lg-4 col-md-4 col-sm-6 col-xs-6" *ngIf="selectedVehicleIdTypes == 2">
              <label for="vehicleModelYear">{{
                "Landing.manufacturingYear" | translate
                }}</label>
              <input formControlName="vehicleModelYear" [placeholder]="'Landing.manufacturingYear' | translate"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                name="vehicleModelYear" autocomplete="off" class="form-control" maxlength="4" type="text" [ngClass]="{
                  'is-invalid': f.vehicleModelYear.errors && submitted
                }" />
              <div *ngIf="submitted && f.vehicleModelYear.errors">
                <div class="v-meassage" *ngIf="f.vehicleModelYear.errors.required">
                  {{ "Errors.EmptyInputField" | translate }}
                </div>
              </div>
            </div>
            <!-- ----------------------------------------------------------------------- -->
            <!--                          Policy Effective Date                          -->
            <!-- ----------------------------------------------------------------------- -->
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label class="input-group" for="policyEffectiveDate">{{
                  "QuotationRequestForms.InsuranceInfo.PolicyEffectiveDate"
                  | translate
                  }}</label>
                <div class="input-group">
                  <input class="form-control" formControlName="policyEffectiveDate" [placeholder]="
                      'QuotationRequestForms.InsuranceInfo.PolicyEffectiveDate'
                        | translate" [minDate]="minDate" [maxDate]="maxDate" name="dp" ngbDatepicker #d="ngbDatepicker"
                    (click)="d.open()" autocomplete="off" [ngClass]="{
                      'is-invalid':
                        f.policyEffectiveDate.errors && submitted
                    }" />
                  <div class="input-group-append calendar-btn-contianer">
                    <button class="btn btn-outline-secondary calendar-icon" (click)="d.open()" type="button">
                      <i class="icon ion-md-calendar"></i>
                    </button>
                  </div>

                  <!-- <app-gregorian-datepicker [previousQuoteDriverBirthDate]="{
                    driverTypeId: 1,
                    birthDate: getPreviousBirthDate()
                  }" (onDatePicked)="setBirthDateValue($event)" *ngIf="showgregoianDate" [ngClass]="{
                    'ng-invalid':
                      (submitted && showHijriDate) || (submitted && showgregoianDate)
                  }"></app-gregorian-datepicker>
                   -->
                </div>
                <div *ngIf="submitted && f.policyEffectiveDate.errors">
                  <div class="v-meassage" *ngIf="f.policyEffectiveDate.errors.required">
                    {{ "Errors.EmptyInputField" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----------------------------------------------------------------------- -->
          <!--                          Error Messages Alert                           -->
          <!-- ----------------------------------------------------------------------- -->
          <div (click)="errorMessage = undefined" *ngIf="errorMessage && errorMessage.length > 0"
            class="form-item error-msg bouncy">
            <i class="ion-ios-alert"></i>
            <span>{{ errorMessage }}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- ----------------------------------------------------------------------- -->
  <!--                               Check icon                                -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="flex-centered mt-12 col-md-1">
    <div class="col-sm-12 col-md-1 col-lg-1">
      <div [ngClass]="{
          'check-icon': landingFormGroup.valid,
          'check-icon-invalid': landingFormGroup.invalid
        }">
        <i class="icon ion-md-checkmark-circle"></i>
      </div>
    </div>
  </div>
</div>