import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AccessToken } from "src/app/models/app/AccessToken";
import { ApiRoutes } from "src/app/models/app/ApiRoutes";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { UserLogin } from "src/app/payload/requests/auth/UserLogin";
import { UserRegistration } from "src/app/payload/requests/auth/UserRegistration";
import { LoginResponse } from "src/app/payload/responses/auth/LoginResponse";
import { RegistrationResponse } from "src/app/payload/responses/auth/RegistrationResponse";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  static injector: Injector;
  apiUrl: string = environment.apiUrl;
  isLoggedIn = new BehaviorSubject<boolean>(this.isTokenAvailable());
  isAuthModalShown = new BehaviorSubject<boolean>(false);
  jwtHelper = new JwtHelperService();

  constructor(private router: Router, private http: HttpClient) {}
  // send identificationNumber and get otp sms to mobile
  getOtp(identificationNumberObj: any): Observable<any> {
    return this.http.post(
      this.apiUrl + ApiRoutes.Account.getOtp,
      identificationNumberObj
    );
  }

  // verify otp code
  verifyOtp(data: any): Observable<any> {
    return this.http.post(this.apiUrl + ApiRoutes.Account.verifyOtp, data);
  }
  /* Authentication */
  headers: Headers;
  login(userLogin: UserLogin) {
    return this.http
      .post(this.apiUrl + ApiRoutes.Account.Login, userLogin)
      .pipe(
        tap((res: LoginResponse) => {
          if (res.isSuccess) {
            window.localStorage.isMySessionActive = "true";
          }
        })
      );
  }

  // verify Login otp code
  verifyLoginOTP(data: any): Observable<any> {
    return this.http
      .post(this.apiUrl + ApiRoutes.Account.VerifyPhone, data)
      .pipe(
        tap((res: LoginResponse) => {
          if (res.isSuccess) {
            localStorage.setItem(LocallyStoredItemsKeys.JWT, res.accessToken);
            this.setIsLoggedIn(true);
          }
        })
      );
  }

  async logout(): Promise<any> {
    let appLanguage = localStorage.getItem(LocallyStoredItemsKeys.AppLanguage);
    // Clear JWT from localstorage
    await localStorage.removeItem(LocallyStoredItemsKeys.JWT);
    await localStorage.removeItem(
      LocallyStoredItemsKeys.InsuranceCurrentStepData
    );
    await localStorage.removeItem(LocallyStoredItemsKeys.FormManager);
    await localStorage.removeItem(LocallyStoredItemsKeys.ProfileData);
    await localStorage.removeItem(LocallyStoredItemsKeys.phoneNumber);
    await localStorage.removeItem(LocallyStoredItemsKeys.userId);
    // Update logged in status
    this.setIsLoggedIn(false);
    localStorage.setItem(LocallyStoredItemsKeys.AppLanguage, appLanguage);
    // Navigate user back to home page
    await this.router.navigate([AppRoutes.landing]);
  }

  register(userRegistration: UserRegistration) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Account.Register,
      userRegistration
    );
  }

  /* verfication */
  sendVerificationCode(verificationObj: any) {
    return this.http
      .post(this.apiUrl + ApiRoutes.Account.VerifyPhone, verificationObj)
      .pipe(
        tap((res: LoginResponse) => {
          if (res.isSuccess) {
            localStorage.setItem(LocallyStoredItemsKeys.JWT, res.accessToken);
            this.setIsLoggedIn(true);
          }
        })
      );
  }

  resendVerficationCode(UserVerificationData: RegistrationResponse) {
    // Send the verification request
    return this.http.post(
      this.apiUrl + ApiRoutes.Account.ResendVerfication,
      UserVerificationData
    );
  }
  GetPaymentCode(UserVerificationData: RegistrationResponse) {
    // Send the verification request
    return this.http.post(
      this.apiUrl + ApiRoutes.Account.GetPaymentCode,
      UserVerificationData
    );
  }
  /* Access Token */

  public getDecodedToken(): AccessToken {
    const token = localStorage.getItem(LocallyStoredItemsKeys.JWT);
    return this.jwtHelper.decodeToken(token);
  }

  private isTokenAvailable(): boolean {
    return !!localStorage.getItem(LocallyStoredItemsKeys.JWT);
  }

  /* Access Token Claims */

  public getTokenUserId(): string {
    return this.getDecodedToken().jti;
  }

  public getTokenClientId(): string {
    return this.getDecodedToken().cid;
  }

  public getTokenClientSub(): string {
    return this.getDecodedToken().sub;
  }
  public getTokenUserPhoneNum(): string {
    return this.getDecodedToken().tel;
  }

  public getTokenUserLang(): string {
    return this.getDecodedToken().lng;
  }

  /* Getters and Setters  */

  setIsLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn.next(isLoggedIn);
  }

  getIsLoggedIn(): BehaviorSubject<boolean> {
    return this.isLoggedIn;
  }

  setIsAuthModalShown(isAuthModalShown: boolean): void {
    this.isAuthModalShown.next(isAuthModalShown);
  }

  getIsAuthModalShown(): BehaviorSubject<boolean> {
    return this.isAuthModalShown;
  }

  async AccountEditLogout(): Promise<any> {
    await localStorage.removeItem(LocallyStoredItemsKeys.JWT);

    this.setIsLoggedIn(false);

    await window.location.replace("account/login");

    // await this.router.navigate([AppRoutes.authentication])
    this.setIsLoggedIn(false);
    await this.router.navigate([AppRoutes.authentication]);
  }
}
