import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { NumberCommaDirective } from "src/app/helpers/utils/number-comma.directive";
import { SharedModule } from "../shared/shared.module";
import { AdditionalDriverComponent } from "./drivers/additional-driver/additional-driver.component";
import { DriverInfoComponent } from "./drivers/driver-info/driver-info.component";
import { DriverListComponent } from "./drivers/driver-list/driver-list.component";
import { DriversComponent } from "./drivers/drivers.component";
import { MoreDetailsComponent } from "./more-details/more-details.component";
import { QuotationRequestPageComponent } from "./quotation-request-page/quotation-request-page.component";
import { QUOTATION_RQQUEST_ROUTES } from "./quotation-request.routes";
import { UpdateClientInfoComponent } from "./update-client-info/update-client-info.component";
import { VehicleInfoComponent } from "./vehicle-info/vehicle-info.component";
import { YakeenFormComponent } from "./yakeen-form/yakeen-form.component";
import { InsuranceProfileFormComponent } from "./insurance-profile-form/insurance-profile-form.component";

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
  declarations: [
    DriverInfoComponent,
    VehicleInfoComponent,
    MoreDetailsComponent,
    AdditionalDriverComponent,
    QuotationRequestPageComponent,
    DriverListComponent,
    DriversComponent,
    NumberCommaDirective,
    UpdateClientInfoComponent,
    YakeenFormComponent,
    InsuranceProfileFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    NgbModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    RouterModule.forChild(QUOTATION_RQQUEST_ROUTES),
  ],
  providers: [NgbActiveModal],
})
export class QuotationRequestModule {}
