import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { FormBuilder, FormControl, FormGroup } from "@ngneat/reactive-forms";
import { FileUploader } from "ng2-file-upload";
import AppUtils from "src/app/helpers/utils/AppUtils";
import { AppService } from "src/app/services/app/app.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { UserService } from "src/app/services/user/user.service";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Identity, IdentityResponse } from "src/app/models/profile/Identity";
@Component({
  selector: "app-add-claim",
  templateUrl: "./add-claim.component.html",
  styleUrls: ["./add-claim.component.css"],
})
export class AddClaimComponent implements OnInit {
  @Input() policyId?: any;
  identities: Identity[];
  successAlert: boolean;
  errorAlert: boolean;
  responseMessage: string;
  submitted: boolean;
  minDate;
  maxDate;
  now = new Date();
  uploader: FileUploader;
  supportedFile: boolean = true;
  hasBaseDropZoneOver: boolean;
  isUploading;
  allowedFileType: string[] = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
    "application/vnd.ms-excel",
    "application/pdf",
  ];
  lang: string;
  PolicyId: string = "";

  subscription: Subscription;
  claimForm: FormGroup<any>;
  @ViewChild("claims", { static: false })
  claims: ElementRef;
  constructor(
    private appService: AppService,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDAta();
    this.initUploader();
    this.getAppLang();
    this.getIdentities();
    this.initForm();
    this.noPolicy();
  }

  getDAta() {
    this.userService.currentPolicy.subscribe((e) => {
      this.policyId = e;
    });
  }

  initUploader() {
    this.uploader = new FileUploader({ url: "", autoUpload: false });
  }

  noPolicy() {
    if (!this.policyId) {
      this.router.navigateByUrl("claims");
      this.claimForm.reset();
    }
  }

  getAppLang() {
    this.appService.getAppLang().subscribe((lang) => (this.lang = lang));
  }

  getIdentities() {
    this.profileService.getIdentities().subscribe(
      (res: IdentityResponse) => {
        this.identities = res.data;
      },
      (err) => {}
    );
  }

  setMinDate() {
    let minDate = AppUtils.addDays(this.now, 1);
    this.minDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate(),
    };
  }

  setMaxDate() {
    let maxDate = AppUtils.addDays(this.now, 30);
    this.maxDate = {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate(),
    };
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  onFileSelected(event) {
    const uploadItems: any[] = this.uploader.queue; // Uploader's uploaded items
    uploadItems.forEach((file) => {
      if (this.allowedFileType.indexOf(file.file.type) > -1) {
      } else {
        setTimeout(() => {
          this.supportedFile = true;
        }, 2000);
        this.supportedFile = false;
        file.remove();
      }
    });
  }

  initForm(): void {
    this.claimForm = this.fb.group({
      ClientId: [null, [Validators.required]],
      policyId: [this.policyId],
      AccidentDate: [null, [Validators.required]],
      cost: [null, Validators.required],
      ClaimDetails: [null, [Validators.required]],
    });
  }

  createClaim(formdata: any) {
    this.submitted = true;

    const uploadItems: any[] = this.uploader.getNotUploadedItems(); // Uploader's uploaded items
    let date = `${this.form.AccidentDate.value.year}-${this.form.AccidentDate.value.month}-${this.form.AccidentDate.value.day}`;
    const formData = new FormData(); // Holds the files and data
    let ClientId = this.identities[0].clientId;

    let full = `${date}T00:00:00Z`;

    uploadItems.forEach((item) => {
      formData.append("files", item._file);
    });

    formData.set("AccidentDate", full);
    formData.set("ClientId", ClientId);

    formData.append("Amount", this.claimForm.get("cost")?.value);
    formData.append("ClaimDetails", this.claimForm.get("ClaimDetails")?.value);
    formData.append("PolicyId", this.claimForm.get("policyId")?.value);
    const myData = Object.entries(formData);

    this.userService.addNewClaim(formData).subscribe(
      (res: any) => {
        if (res.isSuccess) {
          this.responseMessage = res.responseMessage;
          this.successAlert = true;
          this.windowMotion();
          setTimeout(() => {
            this.successAlert = false;
          }, 4000);
        }
      },
      (err) => {
        this.responseMessage = err.error.responseMessage;
        this.windowMotion();
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 4000);
        //
      }
    );
  }

  windowMotion() {
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
  }

  // initForm() {
  //   return this.claimForm = this.fb.group({
  //     AccidentDate: [null, Validators.required],
  //     cost: [null, Validators.required],
  //     details: [null, Validators.required],
  //     policyId: [this.policyId],
  //     clientId: [this.identities[0].clientId]
  //   })
  // }

  cancel() {
    window.location.replace("/cliams");
    this.claimForm.reset();
  }

  get form() {
    return this.claimForm.controls;
  }

  errAlertAction() {
    document.getElementById("Ealert").style.display = "none";
    this.errorAlert = false;
  }

  successAlertAction() {
    document.getElementById("Salert").style.display = "none";
    this.successAlert = false;
  }
}
