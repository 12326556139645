<div class="row">
  <div class="container mt-4">
    <!--Loader-->
    <div *ngIf="isLoading" class="card loader-card mb-3">
      <div class="content-container loader-wrapper flex-centered flex-container mt-5 py-3">
        <div class="lds-facebook flex-centered">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <!--In Success Case-->
    <div class="card mb-3" *ngIf="isPurchaseRespone && !isLoading">
      <div class="card-body">
        <div class="row mb-4">
          <!-- Download and prind Buttons -->
          <div class="col-lg-12 px-0">
            <div class="">
              <div class="col-lg-12">
                <div class="dummy-positioning d-flex">
                  <div class="success-icon">
                    <!--(click)="downloadPdf()-->
                    <div class="success-icon__tip"></div>
                    <div class="success-icon__long"></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 offset-lg-3 mt-3">
                <h2 class="text-center">
                  {{ "PaymentStatus.Success.SuceessTitle" | translate }}
                </h2>
              </div>
              <div class="d-flex justify-content-center align-item-center my-4">
                <a class="download-btn mx-2" target="_blank" [href]="purchaseRespone?.documents[0].url">
                  <i class="ion-md-cloud-download text-light mx-1"></i>{{ "PaymentStatus.Success.DownloadPolicyLink" |
                  translate }}
                </a>
                <a class="download-btn mx-2" (click)="downloadPdf()">
                  <i class="ion-md-print mx-1"></i>
                  {{ "PaymentStatus.Success.PrintInvoice" | translate }}
                </a>
                <a class="download-btn mx-2" (click)="open(content)">
                  <i class="ion-md-share-alt mx-1"></i>
                  {{ "PaymentStatus.Success.Share" | translate }}
                </a>
                <!-- <a class="download-btn mx-2" ><i class="ion-md-cloud-download mx-1"></i> Download </a>
                                <a class="download-btn mx-2" ><i class="ion-md-mail mx-1"></i> Mail</a>
                                <a class="download-btn mx-2" ><i class="ion-md-chatbubbles mx-1"></i> sms</a> -->
                <!-- <div class="hidden-input">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="my-addon">Text</span>
                                        </div>
                                        <input class="form-control" type="text" name="" placeholder="Recipient's text" aria-label="Recipient's " aria-describedby="my-addon">
                                    </div>

                                </div>

                        </div>
                    </div>
                </div> -->
                <!-- End -->
              </div>
              <div class="card">
                <div class="card-header">
                  {{ "Checkout.PolicyDetails.PolicyDetailsTitle" | translate }}
                </div>
                <div class="table-responsive-sm">
                  <div class="row">
                    <!-- Policy Details -->
                    <div class="col-lg-5">
                      <table class="table">
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.CompanyName" | translate
                            }}:
                          </th>
                          <td>
                            {{
                            lang === "ar"
                            ? purchaseRespone?.insuranceCompanyNameAr
                            : purchaseRespone?.insuranceCompanyName
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.ProductType" | translate
                            }}:
                          </th>
                          <td>
                            {{
                            lang === "ar"
                            ? purchaseRespone?.productNameAr
                            : purchaseRespone?.productName
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.PolicyNumber" | translate
                            }}:
                          </th>
                          <td>{{ purchaseRespone?.policyNumber }}</td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.PolicyIssueDate"
                            | translate
                            }}:
                          </th>
                          <td>
                            {{
                            purchaseRespone?.policyIssueDate
                            | date : "dd-MM-yyyy"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.PolicyStart" | translate
                            }}:
                          </th>
                          <td>
                            {{
                            purchaseRespone?.policyEffectiveDate
                            | date : "dd-MM-yyyy"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.PolicyExpiryDate"
                            | translate
                            }}:
                          </th>
                          <td>
                            {{
                            purchaseRespone?.policyExpiryDate
                            | date : "dd-MM-yyyy"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.TransactionRef"
                            | translate
                            }}:
                          </th>
                          <td>
                            {{transactionId}}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!-- End of Policy Details -->
                    <div class="col-lg-5">
                      <!-- Vehicle Details -->
                      <table class="table table-striped">
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.SequenceNum" | translate
                            }}:
                          </th>
                          <td>{{ purchaseRespone?.vehicleId }}</td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.VehicleDescription"
                            | translate
                            }}:
                          </th>
                          <td>{{ purchaseRespone?.vehicleDescription }}</td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.VehicleMajorColor"
                            | translate
                            }}:
                          </th>
                          <td>{{ purchaseRespone?.vehicleColor }}</td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.vehicleChassieNo"
                            | translate
                            }}:
                          </th>
                          <td>{{ purchaseRespone?.vehicleChassieNo }}</td>
                        </tr>
                        <tr>
                          <th>
                            {{ "Policy.VehicleData.SumInsured" | translate }}:
                          </th>
                          <td>
                            <span class="text-style">{{
                              purchaseRespone?.vehicleSumInsured
                              | number : "1.2-2"
                              }}
                              <small class="text-style">{{
                                "App.Currency" | translate
                                }}</small>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {{
                            "Checkout.PolicyDetails.RepairsLocation"
                            | translate
                            }}:
                          </th>
                          <td>{{ purchaseRespone?.vehicleRepairMethod }}</td>
                        </tr>
                      </table>
                      <!-- End of Vehicle Details -->
                    </div>
                    <!-- Vehicle IC Logo -->
                    <div class="col-lg-2 d-flex align-items-center">
                      <img class="company-image mx-auto mr-2" [src]="getCompanyLogo()" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <!-- Policy Premium -->
                <div class="col-lg-4 ml-auto">
                  <div class="card">
                    <div class="card-header">
                      <span>{{ "Checkout.CostsDetails" | translate }}</span>
                    </div>
                    <table class="table table-clear">
                      <tr>
                        <th>
                          {{
                          "Checkout.netPremiumWithoutBenefits" | translate
                          }}:
                        </th>
                        <td>
                          <span class="text-style">{{
                            purchaseRespone?.netPremiumWithoutBenefits
                            | number : "1.2-2"
                            }}
                            <small class="text-style">{{
                              "App.Currency" | translate
                              }}</small></span>
                        </td>
                      </tr>
                      <tr>
                        <th>{{ "Checkout.VAT" | translate }}:</th>
                        <td>
                          <span class="text-style">{{
                            purchaseRespone?.totalVATAmount | number : "1.2-2"
                            }}
                            <small class="text-style">{{
                              "App.Currency" | translate
                              }}</small></span>
                        </td>
                      </tr>
                      <tr>
                        <th>{{ "Quote.ExtraBenefitsTitle" | translate }}:</th>
                        <td>
                          <span class="text-style">{{
                            purchaseRespone?.totalAdditionalBenefits
                            | number : "1.2-2"
                            }}
                            <small class="text-style">{{
                              "App.Currency" | translate
                              }}</small></span>
                        </td>
                      </tr>
                      <tr>
                        <th>{{ "App.TotalPriceText" | translate }}:</th>
                        <td>
                          <span class="text-style">{{
                            purchaseRespone?.policyPremium | number : "1.2-2"
                            }}
                            <small class="text-style">{{
                              "App.Currency" | translate
                              }}</small>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- End of Policy Premium -->
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--In Error Case-->
    <div class="card mt-1 mb-3" *ngIf="!isPurchaseRespone && !isLoading">
      <div *ngIf="isErrorAlertVisible" class="p-5 text-center">
        <!-- <h1 class="dispaly-1 text-center">Opps!</h1> -->
        <!-- <img class="img-fluid img d-block mx-auto" src="assets/images/warning-sign-svgrepo-com.svg" alt=""> -->
        <i class="ion-md-warning mx-1 warning-icon"></i>
        <h3 class="text-muted">
          {{ errorMessage }}
        </h3>
      </div>
    </div>
  </div>
</div>

<!--Share quote modal-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "Quote.ShareQuote" | translate }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="ion-md-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="sharePolicyFormGroup">
      <div class="col px-1">
        <!--Main Url Input-->
        <div>
          <div class="input-group mb-1">
            <input type="text" [value]="policyUrl" class="form-control" #userurl />
            <!-- <div class="input-group-prepend">
                      <button class="modal-btn btn" type="button">{{'Quote.Copy' |
                         translate}}</button>
                   </div> -->
          </div>
          <span class="mt-1" *ngIf="alertMessage">
            <ngb-alert type="success">{{
              "Quote.SuccessMsg" | translate
              }}</ngb-alert>
          </span>
        </div>
        <!-- Email Input To Share Url-->
        <div *ngIf="isPolicySelected" class="">
          <div class="input-group">
            <input class="form-control" formControlName="email" type="email" placeholder="Enter the email" required />
            <div class="input-group-prepend">
              <button class="modal-btn btn" type="submit" (click)="shareUrlToEmail()">
                {{ "PaymentStatus.Send" | translate }}
                <span *ngIf="isSharePolicyLoading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true">
                </span>
              </button>
            </div>
          </div>
          <div *ngIf="sharePolicyFormControl.email.errors" class="error">
            <span *ngIf="
                (isSubmitting &&
                  sharePolicyFormControl.email.errors.required) ||
                (sharePolicyFormControl.email.touched &&
                  sharePolicyFormControl.email.errors.required)
              " class="v-message">
              {{ "Errors.RequiredField" | translate }}
            </span>
            <span *ngIf="
                (isSubmitting && sharePolicyFormControl.email.errors.pattern) ||
                (sharePolicyFormControl.email.dirty &&
                  sharePolicyFormControl.email.errors.pattern)
              " class="v-message">
              {{ "Errors.InvalidEmail" | translate }}
            </span>
          </div>
        </div>
        <div class="mt-2" *ngIf="isSuccess">
          <!-- <ngb-alert type="success">Message sent successfully</ngb-alert> -->
          <ngb-alert type="success">{{ errorMessage }}</ngb-alert>
        </div>
      </div>
    </form>
  </div>
  <div class="px-3">
    <div class="striped">
      <span class="striped-line"></span>
      <span class="striped-text">{{ "PaymentStatus.ShareVia" }}</span>
      <span class="striped-line"></span>
    </div>
    <div class="mb-2">
      <i class="ion-md-mail modal-btn btn mx-1" (click)="toggleEmailInput()"></i>
      <!-- <img class="img-fluid modal-btn btn mx-1" src="assets/images/Icons/SMS.svg" style="width: 50px;" alt="SMS"> -->
      <!-- <img class="img-fluid modal-btn btn mx-1" src="assets/images/Icons/icons8-whatsapp.svg" style="width: 50px;" alt="whatsapp Logo"> -->
    </div>
  </div>
</ng-template>
