<!-- ----------------------------------------------------------------------- -->
<!--                               APP LAYOUT                                -->
<!-- ----------------------------------------------------------------------- -->
<div [ngClass]="{'invoice-container': isPrintingInvoice}">
  <div class="app-container">
    <!-- Success Alert -->
    <ngb-alert *ngIf="isSuccessAlertVisible" type="success" (close)="isSuccessAlertVisible = !isSuccessAlertVisible"
      style="position: fixed;z-index: 1001;width: 30%;">
      <small style="font-size: 14px;">{{ successMessage }}</small>
    </ngb-alert>

    <ngb-alert *ngIf="isErrorAlertVisible" type="success" (close)="isErrorAlertVisible = !isErrorAlertVisible"
      style="position: fixed;z-index: 1001;width: 30%;">
      <small style="font-size: 14px;">{{ errorMessage }}</small>
    </ngb-alert>

    <div class="header" style="display: none;">
      <app-header></app-header>
    </div>
    <div class="header">
      <app-header></app-header>
    </div>
    <div [class.contents]="!isAtLanding">

      <router-outlet></router-outlet>
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
  <!-- <div *ngIf="isPrintingInvoice" class="invoice-wrapper flex-container">
        <div class="invoice">
            <app-invoice></app-invoice>
        </div>
    </div> -->
</div>




<!-- ----------------------------------------------------------------------- -->
<!--                               LOGIN MODAL                               -->
<!-- ----------------------------------------------------------------------- -->
<div *ngIf="isAuthModalVisible" class="modal-container">

  <!-- Back Button -->
  <button (click)="isAuthModalVisible = false" class="modal-close-btn primary-btn white-ghost">
    <i class="ion-md-undo"></i>
    <span>{{ 'App.BackText' | translate }}</span>
  </button>

  <!-- Auth Card -->
  <app-auth [authSource]="'forms'"></app-auth>
</div>