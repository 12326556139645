<!-- ----------------------------------------------------------------------- -->
<!--                                 Loading                                 -->
<!-- ----------------------------------------------------------------------- -->
<div *ngIf="isLoadingQuotes" class="loader-card boxed-item mb-3">
  <div class="content-container loader-wrapper flex-centered flex-container">
    <div class="row">
      <div class="col-12" style="text-align: center">
        <div class="lds-facebook flex-centered">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="col-12 centered" style="text-align: center">
        <span class="loading-msg">{{
          "Misc.QuotationLoadingMessage" | translate
          }}</span>
      </div>
    </div>
  </div>
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                           Continuation Modal                            -->
<!-- ----------------------------------------------------------------------- -->
<ng-template #continuationModal let-modal id="continuationModal">
  <!-- Header -->
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      {{
      "QuotationRequestForms.ClientInfoModal.PreviousQuoteTitle" | translate
      }}
    </h5>
  </div>

  <!-- Body -->
  <div class="modal-body cont-modal-body flex-column">
    <h6 class="flex-centered">
      {{ "QuotationRequestForms.ClientInfoModal.ReqNewQuote" | translate }}
    </h6>
  </div>

  <!-- Footer -->
  <div class="modal-footer flex-centered">
    <button class="primary-btn" (click)="requestNewQuotation('Continue')">
      {{ "QuotationRequestForms.ClientInfoModal.ReqNewQuoteBtn" | translate }}
    </button>
    <button class="btn btn-warning text-white" (click)="requestNewQuotation('Previous')">
      {{ "QuotationRequestForms.ClientInfoModal.SavedBtn" | translate }}
    </button>
  </div>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                          Yakeen Missing Data Form                            -->
<!-- ----------------------------------------------------------------------- -->
<ng-template #yakeenMissingDataModal let-modal id="yakeenMissingDataModal">
  <!-- Body -->
  <div class="modal-body cont-modal-body flex-column">
    <app-yakeen-form (yakeenFormSubmitted)="sendQuotesRequest($event)" [yakeenMissingData]="yakeenMissingData">
    </app-yakeen-form>
  </div>

  <!-- Footer -->
  <div class="modal-footer flex-centered">
    <!--  <button class="primary-btn" >
            {{ 'QuotationRequestForms.ClientInfoModal.ReqNewQuoteBtn' | translate}}
        </button>
        <button class="btn btn-warning text-white">
            {{ 'QuotationRequestForms.ClientInfoModal.SavedBtn' | translate}}
        </button> -->
  </div>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                    (sendQuotesRequest) (backToForms)  Event sent from
                        update-client info component                  -->
<!-- ----------------------------------------------------------------------- -->

<div *ngIf="isConfirmingIdentity">
  <app-update-client-info (sendQuotesRequest)="sendQuotesRequest()" (backToForms)="isConfirmingIdentity = false">
  </app-update-client-info>
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                                  Steps                                  -->
<!-- ----------------------------------------------------------------------- -->
<div [class.not-displayed]="isLoadingQuotes || isConfirmingIdentity" style="padding-top: 2em" [ngClass]="{
    'container px-0': isOnMidScreen,
    'mobile-container': !isOnMidScreen
  }">
  <!-- ----------------------------------------------------------------------- -->
  <!--                              Errors Alert                               -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="row alert-box p-0 m-0" *ngIf="isErrorAlertVisible" id="errorAlert">
    <ngb-alert class="alert col-12" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
      <strong style="font-weight: bold">
        <i class="icon ion-md-alert"></i>
        {{ errorMessage }}
      </strong>
      <strong *ngIf="validationErrors" style="font-weight: bold">
        <ul class="errors-list">
          <li *ngFor="let error of validationErrors">
            {{ error }}
          </li>
        </ul>
      </strong>
    </ngb-alert>
  </div>

  <!-- ----------------------------------------------------------------------- -->
  <!--                            Insurance Profile Form                             -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="row boxed-item striped-top mt-1 mx-0" [id]="ManagedForms.LandingForm" *ngIf="requestFromProfile">
    <div class="col">
      <app-insurance-profile-form [@step]></app-insurance-profile-form>
    </div>
  </div>
  <!-- ----------------------------------------------------------------------- -->
  <!--                            Main Driver Info                             -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="row boxed-item striped-top mt-1 mx-0" [id]="ManagedForms.MainDriverInsuranceForm">
    <div class="col">
      <app-driver-info [@step]></app-driver-info>
    </div>
  </div>
  <!-- ----------------------------------------------------------------------- -->
  <!--                              Vehicle Info                               -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="row boxed-item striped-top mt-4 mx-0" [id]="ManagedForms.VehicleInsuranceForm">
    <div class="col">
      <app-vehicle-info [@step]></app-vehicle-info>
    </div>
  </div>

  <!-- ----------------------------------------------------------------------- -->
  <!--                           Additional Drivers                            -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="row boxed-item striped-top mt-4 mx-0" id="additionalDriver">
    <div class="col">
      <app-drivers [@step]></app-drivers>
    </div>
  </div>

  <!-- ----------------------------------------------------------------------- -->
  <!--                      inComplete Driving Percentage                      -->
  <!-- ----------------------------------------------------------------------- -->

  <div class="row mt-4 mx-0" *ngIf="inCompleteDrivingPercentage">
    <ngb-alert class="alert col-12" type="danger" *ngIf="showPercentageAlert" (close)="showPercentageAlert = false">
      {{ "Errors.InCompletePercentage" | translate }}</ngb-alert>
  </div>

  <!-- ----------------------------------------------------------------------- -->
  <!--                             Request Button                              -->
  <!-- ----------------------------------------------------------------------- -->
  <!--  <div  class="row padded d-flex justify-content-end">
        <button  (click)="openContinuationModal(continuationModal)" type="button"
            class="btn send-btn bouncy">
            <i class="icon ion-ios-list"></i>
            {{ 'QuotationRequestForms.StepsTitles.ShowQuoteBtn' | translate }}
        </button>
    </div> -->
  <div class="row my-3 mx-0 d-flex justify-content-end">
    <button (click)="watchFormsBeforeSendRequest('New')" type="button"
      class="btn send-btn bouncy d-flex align-items-center" [disabled]="!isValidBtn">
      <i class="icon ion-ios-list"></i>
      {{ "QuotationRequestForms.StepsTitles.ShowQuoteBtn" | translate }}
      <span *ngIf="!isValidBtn" class="spinner-border spinner-border-sm binner mx-2" role="status"
        aria-hidden="true"></span>
    </button>
  </div>
</div>