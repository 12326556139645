import { NavigationEnd, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: [
    "./footer.component.css",
    "../../../../../assets/styles/home-page-styles/style.css",
    "../../../../../assets/styles/home-page-styles/rtl.css",
    "../../../../../assets/styles/home-page-styles/responsive.css",
  ],
})
export class FooterComponent implements OnInit {
  currentDate: Date;
  Router: string;
  currentRoute: string;
  constructor(private router: Router) {
    // router.url='/contact';
    //this.Router = router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        //Do something with the NavigationEnd event object.
        this.currentRoute = event.url;
      });
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }
  ngOnInit() {
    this.currentDate = new Date();
  }
}
