import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutModule } from './components/checkout/checkout.module';
import { QuotationRequestModule } from './components/quotation-request/quotation-request.module';
import { QuotesModule } from './components/quotes/quotes.module';
import { HotToastModule } from '@ngneat/hot-toast';
@NgModule({
  declarations: [],
  imports: [
    RouterModule,
    CheckoutModule,
    QuotesModule,
    QuotationRequestModule,
    NgbModule,
    HotToastModule.forRoot({
      position: 'top-right'
    })
  ]
})
export class MotorModule {}
