import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InsuranceStepsService } from "src/app/services/insurance-steps/insurance-steps.service";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { ProfileService } from "src/app/services/profile/profile.service";
import { TicketsParms } from "src/app/models/profile/Tickets";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  [x: string]: any;
  AppRoutes: any = AppRoutes;
  // unReadFollowup: number;
  isLoadingTickets: boolean = false;
  total:number= 0;
  TicketsParms: TicketsParms = <TicketsParms>{};
  constructor(
    private router: Router,
    private insuranceStepsService: InsuranceStepsService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.getAppLang();
    this.getTicketsList();
    // this.getUnReadFollowup();
  }

  getAppLang() {
    this.appService?.getAppLang().subscribe((lang: string) => {
      this.lang = lang;
    });
  }

  navigateToPolicyForms() {
    // Navigate to the policy forms
    this.router.navigate([AppRoutes.controlPanel.vehicles]);
  }

  // getUnReadFollowup() {
  //   this.profileService
  //     .getUnReadFollowup()
  //     .subscribe((unReadFollowup: number) => {
  //       this.unReadFollowup = unReadFollowup;
  //     });
  // }
  getTicketsList() {
    this.profileService.getTicketsList(this.TicketsParms).subscribe(
      (ticket: any) => {
        this.total = ticket.data.reduce((total,currentValue)=>{
          return total + currentValue.totalUnReadMessages
        },0)       
      },
    );
}
}
