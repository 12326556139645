<div class="container  px-0" [class.no-padding]="isLoadingQuotes" style="overflow: hidden;">

   <!-- ----------------------------------------------------------------------- -->
   <!--                                 LOADER                                  -->
   <!-- ----------------------------------------------------------------------- -->
   <app-loading *ngIf="isLoadingQuotes"></app-loading>


   <!-- ----------------------------------------------------------------------- -->
   <!--                           QUOTES EXPIRY MODAL                           -->
   <!-- ----------------------------------------------------------------------- -->
   <!-- <ng-template #expiryModal let-modal>
      <div class="modal-header">
         <h4 class="modal-title" id="modal-basic-title">{{ 'Quote.ExpiryModal.OffersExpiry' | translate }}</h4>
      </div> -->
   <!-- <div class="modal-body">
         {{ 'Quote.ExpiryModal.GetNewOffersQuestion' | translate }}
      </div>
      <div class="modal-footer"> -->
   <!-- Reload Button -->
   <!-- <button (click)="reloadQuotations()" class="primary-btn">
            <span>{{ 'Quote.ExpiryModal.GetNewOffers' | translate }}</span>
         </button> -->
   <!-- Cancel Button -->
   <!-- <button (click)="navigateToLanding()" class="primary-btn danger">
            <span>{{ 'App.CancelText' | translate }}</span>
         </button>
      </div> -->
   <!-- </ng-template> -->


   <!-- ------------------------ VehicleRepairMethods Input ------------------------- -->




   <!-- ----------------------------------------------------------------------- -->
   <!--                          QUOTES ORDER DETAILS                           -->
   <!-- ----------------------------------------------------------------------- -->
   <app-quotes-order-data [isDataAccordionShown]="isDataAccordionShown"></app-quotes-order-data>


   <!-- ----------------------------------------------------------------------- -->
   <!--                             QUOTES FILTERS                              -->
   <!-- ----------------------------------------------------------------------- -->
   <app-quotes-filters (toggleDataAccordion)="toggleDataAccordion()"></app-quotes-filters>


   <!-- ----------------------------------------------------------------------- -->
   <!--                               QUOTES LIST                               -->
   <!-- ----------------------------------------------------------------------- -->
   <app-quotes-list></app-quotes-list>


   <!-- ----------------------------------------------------------------------- -->
   <!--                         PRODUCTS COMPARISON BAR                         -->
   <!-- ----------------------------------------------------------------------- -->
   <div *ngIf="!isOnMidScreen" class="comparison-bar row"
      [ngClass]="isComparisonBarOpen ? 'shown-comparison-bar' : 'hidden-comparison-bar'">

      <!-- Clear Button -->
      <a (click)="clearComparisonList()" class="comparison-clear-btn" href="javascript:void(0)">X</a>

      <!-- Compare Table Button -->
      <div class="comparison-btn-container col-3">
         <h3>{{ 'Quote.QuoteComparison.ComparisonMenuTitle' | translate }}</h3>
         <a (click)="showComparisonTable(popoverEl)" #popoverEl="ngbPopover" triggers="manual" [ngbPopover]="popContent"
            class="quote-compare-btn" href="javascript:void(0)">
            <i class="fa fa-balance-scale"></i>
            {{ 'Quote.QuoteComparison.CompareButtonText' | translate }}
         </a>
         <ng-template #popContent>
            <span class="comparison-alert-popover">{{ 'Quote.QuoteComparison.MinComparisonAlertText' | translate
               }}</span>
         </ng-template>
      </div>


      <!-- Mini Quotes Products -->
      <div class="comparison-list-view col-9">
         <div *ngFor="let product of comparisonProducts" class="quotionBox">
            <span (click)="removeComparisonProduct(product)" id="quote-removal-btn" class="bouncy">X</span>
            <div class="logo text-center">
               <img *ngIf="product?.insuranceCarrier.logo" [src]="('https://wazen-api.oasisoft.net/logo/IC/' +
               product?.insuranceCarrier.logo)" alt="IC Logo">
            </div>
            <div class="itemPrice">
               <span class="comparison-price secondary-lime">{{ product.selectedDeductible.policyPremium |
                  number:'1.2-2' }}</span>
               <small class="currency-txt">{{ 'App.Currency' | translate }}</small>
            </div>
         </div>
      </div>

   </div>


   <!-- ----------------------------------------------------------------------- -->
   <!--                        PRODUCTS COMPARISON TABLE                        -->
   <!-- ----------------------------------------------------------------------- -->
   <div class="comparison-table-container"
      [ngClass]="isComparisonTableOpen ? 'shown-comparison-table' : 'hidden-comparison-table'">

      <!-- Back button -->
      <button (click)="closeComparisonTable()" class="comparison-back-btn primary-btn white-ghost">
         <i class="ion-md-undo"></i>
         <span>{{ 'App.BackText' | translate }}</span>
      </button>


      <!-- Table -->
      <table class="comparison-table table">

         <!-- Products Headers (company + price + buy) -->
         <thead>
            <tr>
               <th id="transparent-header"></th>
               <th *ngFor="let product of comparisonProducts" id="company-header">
                  <div class="company-header-container">
                     <img *ngIf="product?.insuranceCarrier.logo" [src]="('https://wazen-api.oasisoft.net/logo/IC/' +
                   product?.insuranceCarrier.logo)" alt="IC Logo">
                     <div>
                        <span class="comparison-price secondary-lime">{{ product.selectedDeductible.policyPremium |
                           number:'1.2-2' }}</span>
                        <small class="currency-txt">{{ 'App.Currency' | translate }}</small>
                     </div>
                     <button (click)="orderQuoteProduct(product)" class="primary-btn" [disabled]="isLoadingQuotes">
                        <span style="margin: auto;" class="bouncy">
                           <span *ngIf="product.isSelected" class="spinner-border spinner-border-sm" role="status"
                              aria-hidden="true" style="margin: 5px;"></span>
                           {{ 'App.BuyText' | translate }}
                        </span>
                     </button>
                  </div>
               </th>
            </tr>
         </thead>

         <!-- Products benefits -->
         <tbody>
            <tr>
               <th class="comparison-benefit-title">
                  {{ 'Checkout.PolicyDetails.ProductType' | translate }}
               </th>
               <td *ngFor="let product of comparisonProducts;">
                  {{ appLang === 'ar' ? product.nameAr : product.name }}
               </td>
            </tr>
            <tr>
               <th class="comparison-benefit-title">
                  {{ 'Quote.LiabilityCostText' | translate }}
               </th>
               <td *ngFor="let product of comparisonProducts; let i = index">
                  <div *ngIf="product.productTypeId == 2">
                     <div *ngFor="let productDeductibles of product.deductibles; let j = index; let first = first">
                        <div *ngIf="first">
                           {{productDeductibles.deductibleValue}}
                        </div>
                     </div>
                  </div>
               </td>
            </tr>

            <tr *ngFor="let benefit of comparisonProductsBenefits; let i = index">
               <th class="comparison-benefit-title">
                  {{ appLang === 'ar' ? benefit.nameAr : benefit.name }}
               </th>
               <td *ngFor="let product of comparisonProducts;">
                  <div *ngFor="let productBenefit of product.benefits; let j = index">
                     <div *ngIf="productBenefit.name === benefit.name">
                        <!-- Ticked check Icon for free benefits (without checkbox)  -->
                        <div *ngIf="(productBenefit.benefitTypeId === 2)">
                           <i class="comparison-check ion-md-checkmark secondary-lime"></i>
                        </div>
                        <!-- Checkbox for paid benefits -->
                        <div *ngIf="!(productBenefit.benefitTypeId === 2)">
                           <div class="quote-detail-item-title">
                              <input
                                 [checked]="productBenefit.benefitTypeId == 2 || productBenefit.benefitTypeId == 3 || productBenefit.isChecked"
                                 (change)="toggleProductBenefit(product, productBenefit)"
                                 [disabled]="productBenefit.benefitTypeId == 2 || productBenefit.benefitTypeId == 3"
                                 class="cbx" type="checkbox"
                                 [id]="product.name + productBenefit.name + product.selectedDeductible.policyPremium + i + j"
                                 style="display: none;">
                              <label
                                 [for]="product.name + productBenefit.name + product.selectedDeductible.policyPremium + i + j"
                                 class="check noselect"><svg width="18px" height="18px" viewBox="0 0 18 18">
                                    <path
                                       d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
                                    </path>
                                    <polyline points="1 9 7 14 15 4"></polyline>
                                 </svg>
                                 <span *ngIf="!(productBenefit.benefitTypeId == 2)" class="secondary-lime"
                                    style="font-weight: bold;">{{ productBenefit.benefitAmount | number:'1.2-2'
                                    }}</span>
                                 <small *ngIf="!(productBenefit.benefitTypeId == 2)" class="currency-txt">{{
                                    'App.Currency' | translate }}</small>
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>
               </td>

            </tr>

         </tbody>

      </table>

   </div>


   <!-- ----------------------------------------------------------------------- -->
   <!--                   PRODUCTS COMPARISON FLOATING BUTTON                   -->
   <!-- ----------------------------------------------------------------------- -->
   <div (click)="toggleComparisonTable()" *ngIf="isOnMidScreen && comparisonProducts.length > 0" class="floating-btn">
      <span class="comparison-counter">
         {{ isComparisonTableOpen ? 'X' : comparisonProducts.length }}
      </span>
   </div>
</div>