import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { ErrorPageTypes } from "src/app/models/app/ErrorPageTypes";
import { ErrorService } from "src/app/services/app/error.service";

/**
 * Intercepts and handles API errors / error related HTTP status codes
 *
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService
  ) {}

  intercept(
    request: HttpRequest<any>,
    handler: HttpHandler
  ): Observable<HttpEvent<any>> {
    return handler
      .handle(request)
      .pipe(catchError((err) => this.handleErrors(err)));
  }

  handleErrors(error: HttpErrorResponse) {
    switch (error.status) {
      /**
       * DNS/Certificate/Hosting/Server Errors
       */
      case 0:
        this.errorService.setActiveErrorPageType(ErrorPageTypes.ServerError);
        this.router.navigate([AppRoutes.error]);
        break;

      /**
       * Server Error
       */
      case 500:
        this.errorService.setActiveErrorPageType(ErrorPageTypes.ServerError);
        this.router.navigate([AppRoutes.error]);
        break;

      /**
       * Unauthorized
       */
      case 401:
        // TODO: Ask Omran if we're going to use Refresh Token or whether we should keep navigating user to login page
        this.authService.logout();

        break;

      /**
       * Other
       */
      default:
        break;
    }

    return throwError(error);
  }
}
