<div class="container-fluid my-5 bg-white py-3">
    <div class="row">
        <div class="col-md-12 text-center">
            <h2>{{ "Footer.Rules" | translate }}</h2>
        </div>

        <div class="col-11 mx-auto">
            <div class="card">
                <div class="card-body p-4">
                    <div class="table-responsive border-0">
                        <table class="table align-middle p-4 mb-0 table-hover table-shrink">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col" class="h6 border-0 rounded-start">{{ "Footer.Title" | translate }}
                                    </th>
                                    <th scope="col" class="border-0">{{ "Footer.PublishedDate" | translate }}</th>
                                    <th scope="col" class="border-0 text-end">{{ "Footer.Download" | translate }}</th>
                                </tr>
                            </thead>


                            <tbody class="border-top-0">
                                <tr>
                                    <td>
                                        <h6 class="mb-0">{{ "Footer.insurancePolicy" | translate }}</h6>
                                    </td>
                                    <td>
                                        <h6 class="mb-0">17/01/2023</h6>
                                    </td>
                                    <td class="text-end"><a target="_blank"
                                            href="https://www.ia.gov.sa/documents/Rules/The_Unified_Compulsory_Motor_Insurance_Policy-AR.pdf">
                                            <i class="ion ion-md-document larger-icon"></i>

                                        </a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6 class="mb-0">{{ "Footer.Comprehensive" | translate }}</h6>
                                    </td>
                                    <td>
                                        <h6 class="mb-0">06/11/2022</h6>
                                    </td>
                                    <td class="text-end"><a target="_blank"
                                            href="https://www.ia.gov.sa/documents/Rules/en/Comprehensive_Motor_Insurance_Rules.pdf">
                                            <i class="ion ion-md-document larger-icon"></i>
                                        </a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6 class="mb-0">{{ "Footer.Governing" | translate }}</h6>
                                    </td>
                                    <td>
                                        <h6 class="mb-0">19/02/2020</h6>
                                    </td>
                                    <td class="text-end"><a target="_blank"
                                            href="https://www.ia.gov.sa/documents/Rules/Rules_Governing_Insurance_Aggregation_Activities.pdf">
                                            <i class="ion ion-md-document larger-icon"></i>
                                        </a></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>