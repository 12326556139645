import { AppRoutes } from "./models/app/AppRoutes";
import { ROUTES } from "./insurance.routes";
import { PROFILE_ROUTES } from "./components/user/user.routing";
import { ErrorComponent } from "./components/shared/pages/error/error.component";

export const APP_ROUTES = [
  {
    path: AppRoutes.landing,
    loadChildren: () =>
      import("./components/home-page/home-page.module").then(
        (m) => m.HomePageModule
      ),
  },

  {
    path: AppRoutes.authentication,
    loadChildren: () =>
      import("./components/Auth/auth.routing").then((m) => m.AuthRoutingModule),
  },

  {
    path: AppRoutes.footerContent.contents,
    loadChildren: () =>
      import("./components/shared/shared.routes").then(
        (m) => m.SharedRoutingModule
      ),
  },

  { path: AppRoutes.error, component: ErrorComponent },

  { path: AppRoutes.controlPanel.main, children: PROFILE_ROUTES },
  { path: AppRoutes.Routes.main, children: ROUTES },
];
