import { HotToastService } from "@ngneat/hot-toast";
import { AuthService } from "./../../services/auth/auth.service";
import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { LoadingPageTypes } from "src/app/models/app/LoadingPageTypes";
import { AppService } from "src/app/services/app/app.service";
import { InsuranceStepsService } from "src/app/services/insurance-steps/insurance-steps.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-wizard-steps-wrapper",
  templateUrl: "./wizard-steps-wrapper.component.html",
  styleUrls: ["./wizard-steps-wrapper.component.css"],
})
export class WizardStepsWrapperComponent implements OnInit {
  activeStep: any = {
    quotation: true,
    quotes: false,
    checkout: false,
    status: false,
  };
  hasScrolledBanner: boolean = false;
  isLoadingQuotes: boolean;
  isLogin: boolean;
  appLang: string;
  step: number;
  isClearQuoteModalDisplayed: boolean;

  constructor(
    private router: Router,
    private insuranceStepsService: InsuranceStepsService,
    private appService: AppService,
    private authService: AuthService,
    private toast: HotToastService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.watchNavigation();
    this.switchActivePage(this.router.url.replace("/", ""));
    this.watchLoader();
    this.appService
      .getAppLang()
      .subscribe((appLangResp) => (this.appLang = appLangResp));

    this.authService.isLoggedIn.subscribe((isLoginResp) => {
      this.isLogin = isLoginResp;
    });
  }

  watchLoader() {
    this.appService.getActiveLoadingPageType().subscribe((loader) => {
      loader === LoadingPageTypes.QuotationLoading
        ? (this.isLoadingQuotes = true)
        : (this.isLoadingQuotes = false);
    });
  }
  watchNavigation() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        event.url = event.url.replace("/", "");
        this.switchActivePage(event.url);
      });
  }

  ClearQuote() {
    this.clearQuoteLocalstorage();
    this.modalService.dismissAll();
    this.router.navigate(["/quotation"]);
  }

  switchActivePage(url: string) {
    if (url === AppRoutes.insuranceSteps) {
      this.activeStep.quotation = true;
      this.activeStep.quotes = false;
      this.activeStep.checkout = false;
      this.activeStep.status = false;
    } else if (url === AppRoutes.quotes) {
      this.activeStep.quotation = false;
      this.activeStep.quotes = true;
      this.activeStep.checkout = false;
      this.activeStep.status = false;
    } else if (url === AppRoutes.checkout) {
      this.activeStep.quotation = false;
      this.activeStep.quotes = false;
      this.activeStep.status = false;
      this.activeStep.checkout = true;
    } else {
      this.activeStep.quote = false;
      this.activeStep.quotation = false;
      this.activeStep.checkout = false;
      this.activeStep.status = true;
    }
  }

  openClearQuoteModal(modal: any): boolean {
    let confirmClear = false;
    // If closed
    if (!this.isClearQuoteModalDisplayed) {
      // Open the modal
      this.modalService.open(modal, {
        size: "lg",
        centered: true,
        beforeDismiss: () => {
          // Switch flag when dissmissed to uncheck the checkbox
          this.isClearQuoteModalDisplayed = false;
          // Close the modal
          confirmClear = true;
          return true;
        },
      });
      // Set flag to check the checkbox
      this.isClearQuoteModalDisplayed = true;
    }
    return confirmClear;
  }

  clearQuoteLocalstorage() {
    localStorage.removeItem("quote:quotesList");
    localStorage.removeItem("quote:previewQuoteResponse");
    localStorage.removeItem("quote:alteredQuotesList");
    localStorage.removeItem("insurance:quoteResponse");
  }

  enterToStep(stepName: string, modal: any) {
    // this.getStepNumber();
    if (stepName === this.router.url) return;
    if (stepName === "/quotation") {
      if (!this.isLogin) {
        this.isLoginToaster();
        return;
      } else if (this.router.url === "/quotes") {
        // let isClear = this.openClearQuoteModal(modal);
        // if (isClear) {
        //   this.ClearQuote();
        // }
        this.router.navigate(["/quotation"]);
      }
    }
    if (stepName === "/quotes") {
      if (this.router.url === "/checkout") {
        this.router.navigate(["/quotes"]);
      }
    }
    if (stepName === "/checkout") {
      if (this.router.url === "/checkout/status") {
        this.router.navigate(["/checkout"]);
      }
    }
    if (stepName === "/checkout/status") return;
  }

  // Window scroll events
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    if (window.pageYOffset > 100) this.hasScrolledBanner = true;
    else this.hasScrolledBanner = false;
  }

  isLoginToaster() {
    this.toast.info(
      this.appLang == "en"
        ? "You Have To Login First!"
        : "يجب ان تسجل الدخول اولا!",
      {
        dismissible: true,
      }
    );
  }
  
}
