import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { Route, Router } from "@angular/router";
import { FormControl, FormGroup } from "@ngneat/reactive-forms";
import { Payment } from "src/app/models/checkout/payment";
import { dataPayment } from "src/app/models/order-preview/CheckoutReturnState";
import { OrderReviewService } from "src/app/services/checkout/order-review.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-directpay",
  templateUrl: "./directpay.component.html",
  styleUrls: ["./directpay.component.css"],
})
export class DirectpayComponent implements OnInit {
  @Input() dataPayment: dataPayment;
  @Output() formSubmitted = new EventEmitter<any>();
  @ViewChild("form") form!: ElementRef<HTMLFormElement>;
  submitPayment: FormGroup;
  isSubmit = false;
  constructor(
    private orderService: OrderReviewService,
    private render: Renderer2
  ) {}
  get formFroup() {
    return this.submitPayment.controls;
  }

  getPaymentValue() {
    this.isSubmit = true;
    var cardVisa = {
      PaymentMethod: 1,
      Language: "En",
      ResponseBackURL: `${environment.apiUrl}Request/CallBack`,
      PaymentDescription: `Thank you for choosing Wazen Insurance Brokerage. The payment process for ${Math.round(
        this.dataPayment.totleAmount
      )} SAR is now in progress`,
      Amount: Math.round(this.dataPayment.totleAmount) * 100,
      ClientId: this.dataPayment.clientId,
      ClientQuotationId: this.dataPayment.clientQuoteId,
      
      
      // cardNumber: this.formFroup.CardNumber.value,
      // cardHolderName: this.formFroup.CardHolderName.value,
      // expiryDateMonth: this.formFroup.ExpiryDateMonth.value,
      // expiryDateYear: this.formFroup.ExpiryDateYear.value,
      // securityCode: this.formFroup.SecurityCode.value,
    };
    this.orderService.getDiractpay(cardVisa).subscribe(
      (response) => {
        
        this.submitPayment
          .get("CurrencyISOCode")
          .patchValue(response.currencyISOCode);
        this.submitPayment.get("Amount").patchValue(response.amount);
        this.submitPayment.get("Language").patchValue(response.language);
        this.submitPayment
          .get("PaymentMethod")
          .patchValue(response.paymentMethod);
        this.submitPayment.get("MerchantID").patchValue(response.merchantID);
        this.submitPayment
          .get("TransactionID")
          .patchValue(response.transactionID);
        this.submitPayment.get("MessageID").patchValue(response.messageID);
        this.submitPayment
          .get("PaymentDescription")
          .patchValue(response.paymentDescription);
        this.submitPayment.get("SecureHash").patchValue(response.secureHash);
        this.submitPayment.get("Channel").patchValue(response.channel);
        this.submitPayment.get("Quantity").patchValue(response.quantity);
        this.submitPayment
          .get("ResponseBackURL")
          .patchValue(response.responseBackURL);
        this.form.nativeElement.submit();
        setTimeout(() => this.form.nativeElement.submit(), 20000);
      },
      (error) => {}
    );
  }

  ngOnInit(): void {
    this.initForm();
  }
  initForm(): void {
    this.submitPayment = new FormGroup({
      Amount: new FormControl(),
      CurrencyISOCode: new FormControl(),
      MerchantID: new FormControl(),
      Language: new FormControl(),
      MessageID: new FormControl(),
      PaymentDescription: new FormControl(),
      TransactionID: new FormControl(),
      ResponseBackURL: new FormControl(),
      Quantity: new FormControl(),
      Channel: new FormControl(),
      Version: new FormControl("1.0"),
      SecureHash: new FormControl(),
      PaymentMethod: new FormControl(),
      // CardHolderName: new FormControl("", [
      //   Validators.required,
      //   Validators.minLength(3),
      //   Validators.maxLength(30),
      //   Validators.pattern(/[a-zA-Z]/),
      // ]),
      // CardNumber: new FormControl("", [
      //   Validators.required,
      //   Validators.minLength(16),
      //   Validators.maxLength(16),
      // ]),
      // ExpiryDateMonth: new FormControl("", [
      //   Validators.required,
      //   Validators.pattern(/0[1-9]|1[0-2]/gm),
      // ]),
      // ExpiryDateYear: new FormControl("", [
      //   Validators.required,
      //   Validators.pattern(/[0-9]{2}/gm),
      // ]),
      // SecurityCode: new FormControl("", [
      //   Validators.required,
      //   Validators.max(999),
      //   Validators.min(100),
      // ]),
    });
  }
}
