<div class="row">
  <div class="col-sm-12 col-md-2 col-lg-2 flex-container">
    <div class="flex-centered mt-12">
      <div class="round round-lg hollow color" style="margin: 10px auto">
        <span>{{ requestFromProfile ? "2" : "1" }} </span>
      </div>
      <div class="logo-wrapper">
        <h6>
          {{ "QuotationRequestForms.StepsTitles.DriverInfo" | translate }}
        </h6>
        <img src="assets/images/icons8-user-80.png" class="logo" />
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-9 col-lg-9 border-right border-left border-bottom">
    <form [formGroup]="driverInfoForm">
      <div class="row">
        <!-- ----------------------------------------------------------------------- -->
        <!--                             identityNumber                              -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="!requestFromProfile">
          <label> {{ "Landing.NationalIDLabel" | translate }} </label>
          <div class="form-group">
            <input formControlName="identityNumber"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10"
              autocomplete="on" name="identityNumber" class="form-control" type="text" readonly />
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                               date picker                               -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-4 col-lg-4">
          <label>
            {{ "QuotationRequestForms.DriverInfo.Birth" | translate }}
          </label>
          <app-gregorian-datepicker [previousQuoteDriverBirthDate]="{
              driverTypeId: 1,
              birthDate: getPreviousBirthDate()
            }" (onDatePicked)="setBirthDateValue($event)" *ngIf="showgregoianDate" [ngClass]="{
              'ng-invalid':
                (submitted && showHijriDate) || (submitted && showgregoianDate)
            }" [disabled]="isLogin"></app-gregorian-datepicker>
          <app-higri-datepicker [previousQuoteDriverBirthDate]="{
              driverTypeId: 1,
              birthDate: getPreviousBirthDate()
            }" (onDatePicked)="setBirthDateValue($event)" *ngIf="showHijriDate" [ngClass]="{
              'ng-invalid':
                (submitted && showHijriDate) || (submitted && showgregoianDate)
            }" [disabled]="isLogin"></app-higri-datepicker>
          <div *ngIf="
              (submitted && showHijriDate) || (submitted && showgregoianDate)
            ">
            <div *ngIf="showgregoianDate">
              <div class="v-meassage" *ngIf="f.birthDateG?.errors?.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
            <div *ngIf="showHijriDate">
              <div class="v-meassage" *ngIf="f.birthDateH?.errors?.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                          Policy Effective Date                          -->
        <!-- ----------------------------------------------------------------------- -->
        <div *ngIf="!requestFromProfile" class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="form-group">
            <label class="input-group" for="policyEffectiveDate">{{
              "QuotationRequestForms.InsuranceInfo.PolicyEffectiveDate"
              | translate
              }}</label>
            <div class="input-group">
              <input class="form-control" formControlName="policyEffectiveDate" [placeholder]="
                  'QuotationRequestForms.InsuranceInfo.PolicyEffectiveDate'
                    | translate
                " [minDate]="minDate" [maxDate]="maxDate" name="dp" ngbDatepicker #d="ngbDatepicker" (click)="d.open()"
                autocomplete="off" [ngClass]="{
                  'is-invalid':
                    (f.policyEffectiveDate.errors && submitted) ||
                    (f.policyEffectiveDate.touched &&
                      f.policyEffectiveDate.errors)
                }" />
              <div class="input-group-append calendar-btn-contianer">
                <button class="btn btn-outline-secondary calendar-icon" (click)="d.open()" type="button">
                  <i class="icon ion-md-calendar"></i>
                </button>
              </div>
            </div>
            <div *ngIf="
                (f.policyEffectiveDate.errors && submitted) ||
                (f.policyEffectiveDate.touched && f.policyEffectiveDate.errors)
              ">
              <div class="v-meassage" *ngIf="f.policyEffectiveDate.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                             Education Levels input                         -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <!-- <button (click)="check()">check</button> -->
          <div class="form-group">
            <label>
              {{ "QuotationRequestForms.DriverInfo.Education" | translate }}
              <i class="fa fa-question-circle tooltip-icon" placement="top" ngbTooltip="Tooltip on top"
                aria-hidden="true">
              </i>
            </label>
            <ng-select [items]="stepsData?.EducationLevels" bindValue="id" formControlName="educationLevelId"
              [loading]="!stepsData?.EducationLevels" [searchFn]="termSearchFn" [ngClass]="{
                'ng-invalid':
                  (submitted && f.educationLevelId.errors) ||
                  (f.educationLevelId.touched && f.educationLevelId.errors)
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="
                (submitted && f.educationLevelId.errors) ||
                (f.educationLevelId.touched && f.educationLevelId.errors)
              ">
              <div class="v-meassage" *ngIf="f.educationLevelId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                               Occupation                                -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>
              {{ "QuotationRequestForms.DriverInfo.Occupation" | translate }}
              <i class="fa fa-question-circle tooltip-icon" placement="top" ngbTooltip="Tooltip on top"
                aria-hidden="true">
              </i>
            </label>
            <ng-select [items]="stepsData?.Occupations" bindValue="id" formControlName="occupationId"
              [loading]="!stepsData?.EducationLevels" [searchFn]="termSearchFn" [virtualScroll]="true" [ngClass]="{
                'ng-invalid':
                  (submitted && f.occupationId.errors) ||
                  (f.occupationId.touched && f.occupationId.errors)
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="
                (submitted && f.occupationId.errors) ||
                (f.occupationId.touched && f.occupationId.errors)
              ">
              <div class="v-meassage" *ngIf="f.occupationId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                          childrenUnder16Years                           -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>{{ "QuotationRequestForms.DriverInfo.Children" | translate }}
              <i class="fa fa-question-circle tooltip-icon" placement="top" ngbTooltip="Tooltip on top"
                aria-hidden="true">
              </i>
            </label>
            <ng-select [items]="fromZeroToTen" bindLabel="name" bindValue="id" [searchFn]="termSearchFn"
              formControlName="childrenUnder16Years" [ngClass]="{
                'ng-invalid':
                  (submitted && f.childrenUnder16Years.errors) ||
                  (f.childrenUnder16Years.touched &&
                    f.childrenUnder16Years.errors)
              }">
            </ng-select>
            <div *ngIf="submitted && f.childrenUnder16Years.errors">
              <div class="v-meassage" *ngIf="f.childrenUnder16Years.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- ----------------------------------------------------------------------- -->
    <!--                           More Driver Details                           -->
    <!-- ----------------------------------------------------------------------- -->

    <div>
      <app-more-details></app-more-details>
    </div>
  </div>
  <!-- ----------------------------------------------------------------------- -->
  <!--                               Check icon                                -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="flex-centered mt-12 col-md-1">
    <div class="col-sm-12 col-md-1 col-lg-1">
      <div [ngClass]="{
          'check-icon': driverInfoForm.valid,
          'check-icon-invalid': driverInfoForm.invalid
        }">
        <i class="icon ion-md-checkmark-circle"></i>
      </div>
    </div>
  </div>
</div>
