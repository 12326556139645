import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/services/app/app.service";

@Component({
  selector: "app-terms-conditions",
  templateUrl: "./terms-conditions.component.html",
  styleUrls: ["./terms-conditions.component.css"],
})
export class TermsConditionsComponent implements OnInit {
  isCollapsed: boolean = false;
  lang: string;
  finalJson: any;
  termsCondition: any[] = [
    {
      id: "q1",
      questionAr: "تعريفات",
      questionEn: "Definitions",
      answerAr:
        "(-) 'أنت' وجميع التعبيرات المشابهة تشير إلى مستخدم موقع وازن.<br>" +
        "(-) 'نحن' وجميع التعبيرات المشابهة تعني 'شركة وازن لوساطة التأمين الإلكترونية'.<br>" +
        "(-) يشير 'موقع وازن' إلى التعبير المحدد أعلاه.<br>" +
        "(-) 'معلومات المستخدم' تعني التفاصيل الشخصية التي تقدمها لنا عبر موقع وازن.<br>" +
        "(-) 'المستخدمون' يعني مستخدمي موقع وازن بشكل عام كما يشير السياق.<br>" +
        "(-) 'موقع الويب' يعني موقع الويب الموجود على شبكة الويب العالمية.<br>" +
        "(-) 'المحتوى' يعني أي مراجعات أو تعليقات أو رسائل أو بيانات أو معلومات أو نص أو صورة أو صوت أو رسم أو فيديو أو رمز أو غيرها من المواد المعروضة أو المنشورة على 'موقع وازن'.<br>" +
        "(-) 'الشروط والأحكام' تعني تلك الشروط والأحكام التي قد تخضع للتعديل والتغيير من وقت لآخر.<br>",
      answerEn:
        "(-) 'You' and all similar expressions refer to the user of the Wazen website.<br>" +
        "(-) 'We' and all similar expressions mean ‘Wazen Electronic Insurance Broker Company’.<br>" +
        "(-) 'Wazen Website' refers to the above-defined expression.<br>" +
        "(-) 'User’s information' means the personal details you provide us with via the Wazen website..<br>" +
        "(-) 'Users' means the users of the Wazen website in general as the context refers.<br>" +
        "(-) 'Website' means the website on the world wide web.<br>" +
        "(-) 'Content' means any reviews, feedback, messages, data, information, text, photo, voice, drawing, video, symbol, or other materials shown or published on the 'Wazen website'.<br>" +
        "(-) 'Terms and conditions' mean those terms and conditions that might be subject to amendment and change from time to time.",
    },
    {
      id: "q2",
      questionAr: "الاستخدام المشروع",
      questionEn: " Legitimate Use",
      answerAr:
        "(1)- لتسجيل الدخول إلى موقع وازن، يجب أن يكون عمرك 18 عامًا على الأقل (حسب التقويم الميلادي) ويجب أن تتمتع بالأهلية القانونية الكاملة.<br>" +
        "(2)- أي شخص أقل من 18 سنة (حسب التقويم الميلادي) يرغب في الدخول إلى موقع وازن يجب عليه الحصول على موافقة والديه أو ولي أمره.<br>" +
        "(3)- تقر بأن لديك الحق والأهلية القانونية لاستخدام موقع Wazen بموجب الشروط والأحكام المنصوص عليها هنا.",
      answerEn:
        "(1)- To log in to the Wazen website, you must be at least 18 years old (as per the Gregorian calendar) and must have a full legal capacity.<br>" +
        "(2)- Any person under 18 years old (as per the Gregorian calendar) who would like to log in to the Wazen website must obtain the approval of his parents or guardian.<br>" +
        "(3)- You shall acknowledge that you have the right and the legal capacity to use the Wazen website under the terms and conditions set forth herein.",
    },
    {
      id: "q3",
      questionAr: "المسؤولية",
      questionEn: "Responsibility",
      answerAr:
        "(1)- لا نتعهد بأن محتوى موقع وازن أو أي وظائف يحتوي عليها أو أي من خوادمه سوف تعمل دون انقطاع أو تأخير أو خطأ، أو أنها خالية من الفيروسات أو الأخطاء البرمجية، أو أنها متوافقة مع أي برنامج أو مادة أخرى.<br>" +
        "(2)- لن نتحمل المسؤولية أمامك أو أمام أي شخص آخر فيما يتعلق بأي خسارة ناتجة عن أمر خارج عن إرادتنا. ويشمل ذلك خلل المعدات الإلكترونية والميكانيكية وخطوط الاتصالات (بما في ذلك الهاتف والكابلات والإنترنت)، والوصول غير القانوني، والرموز الضارة، والسرقة، وأخطاء التشغيل، والظروف الجوية القاسية أو غير الطبيعية (بما في ذلك الفيضانات والزلازل أو غيرها من أعمال القضاء والقدر)، حريق، حرب، عصيان، أعمال إرهابية، أعمال شغب، نزاعات عمالية، خلافات عمالية أخرى، حوادث، حالات طوارئ، تأخيرات، حجوزات زائدة أو ملغاة، وفاة حاكم، أو أي عمل من قبل الحكومة.",
      answerEn:
        "(1)- We do not promise that the content of the Wazen website, any employments it contains, or any of its servers shall work without interruption, delay, or error, that they are free from viruses or programming errors, or that they are compatible with any other program or material.<br>" +
        "(2)- We shall not be held responsible before you or any other person regarding any loss resulting from a matter out of our control. This includes the malfunction of the electronic and mechanical equipment and telecommunication lines (including telephone, cables, and internet), illegal access, harmful codes, theft, operating errors, harsh or abnormal weather conditions (including floods, earthquakes, or other acts of God), fire, war, disobedience, terror acts, riot, labor disputes, other labor differences, accidents, emergencies, delays, excess or canceled bookings, death of a ruler, or any act by the government. <br>",
    },
    {
      id: "q4",
      questionAr: " التعويضات",
      questionEn: "Indemnities",
      answerAr:
        "(1)- يجب أن تقر بأننا شركة تأمين فقط ونقطة عرض وليس من مسؤوليتنا تعويض أي مطالبات أو تقديم أي تعويضات بسبب تقصير أي طرف ثالث (شركات التأمين).<br>" +
        "(2)- تقر وتوافق على تعويضنا، وأي جهة ذات صلة بنا، وموظفينا، ومسؤولينا فورًا وعند الطلب عن جميع المطالبات والالتزامات والخسائر والتكاليف بما في ذلك الرسوم القانونية الناتجة عن أي خرق أو مخالفة لهذه الشروط والشروط منك أو أي أضرار أخرى تنتج عن استخدامك لموقع وازن.<br>",
      answerEn:
        "(1)- You shall acknowledge that we are an insurer only and a point of display and it is not our responsibility to compensate any claims or make any compensations on account of the default of any third party (insurers).<br>" +
        "(2)- You shall acknowledge and agree to compensate us, any authority related to us, our employees, and officers immediately and on request for all the claims, obligations, losses, and costs including the legal fees resulting from any breach or violation of these conditions and terms by you or any other damages resulting from your using Wazen website. ",
    },
    {
      id: "q5",
      questionAr: "الآنهاء ",
      questionEn: "Termination",
      answerAr:
        "نحتفظ بحقنا في إنهاء استخدامك لموقع وازن فورًا في حالة انتهاكك لأي بند من هذه الشروط والأحكام أو إذا اعتقدنا، لأسباب معقولة، أنك قد تنتهك بنود هذه الشروط والأحكام أو إذا كنت تتصرف بطريقة نعتبرها، وفقًا لتقديرنا الخاص، غير مقبولة.",
      answerEn:
        "We reserve our right to immediately terminate your use of the Wazen website in the event you violate any item of these terms and conditions or if we believe, for reasonable reasons, that you might violate the items of these terms and conditions or if you act in a way we consider, at our sole discretion, unacceptable.",
    },
    {
      id: "q6",
      questionAr: "التواصل",
      questionEn: "Communication",
      answerAr:
        "إذا كنت تريد أن تقدم لنا أي تعليقات حول موقع وازن أو الاستفسار عنه، فلا تتردد في الاتصال بنا على الأرقام المتاحة على موقع وازن.",
      answerEn:
        "If you want to give us any comments about the Wazen website or inquire about it, please feel free to contact us on the numbers available on the Wazen website.",
    },
    {
      id: "q7",
      questionAr: "صلاحية الموقع",
      questionEn: "Validity of the Website",
      answerAr:
        "(1)- نظرًا لأن الخدمات الإلكترونية معرضة للتوقف أو التعطل، فإن الوصول إلى موقع Wazen متاح 'كما هو' حاليًا.<br>" +
        "(2)- يجوز لنا، وفقًا لتقديرنا الخاص، فرض قيود وحدود على استخدامك لموقع Wazen الإلكتروني، بالإضافة إلى ذلك، يجوز لنا، لأسباب أمنية وأسباب فنية، أو صيانة، أو قانونية، أو نتيجة لأي انتهاك لهذه الشروط والأحكام، أو سحب موقع Wazen أو دخولك إلى موقع Wazen في أي وقت دون إشعار مسبق من جانبنا.<br>",
      answerEn:
        "(1)- As electronic services are prone to disruption or breakdown, access to the Wazen website is available 'as it is' currently. <br>" +
        "(2)- We may, at our sole discretion, impose restrictions and limitations on your use of the Wazen website, and, additionally, we may, for security purposes and technical, maintenance, or legal reasons, or as a result of any violation of these terms and conditions, withdraw Wazen website or your access to Wazen website at any time without prior notice from our side.",
    },
    {
      id: "q8",
      questionAr: "عام",
      questionEn: "General",
      answerAr:
        "(1)- في حال قررت أي محكمة أو مشروع أن أي بند من هذه الشروط والأحكام غير صالح أو غير قابل للتطبيق بأي شكل من الأشكال، فسيتم إلغاؤه وشطبه وتكون البنود المتبقية من الشروط والأحكام سارية بالكامل.<br>" +
        "(2)- تتم تسوية المنازعات عن طريق المحاكم المختصة أو لجان تسوية المنازعات ومخالفات التأمين في المملكة العربية السعودية",
      answerEn:
        "(1)- In the event any court or project decides that any item of these terms and conditions is invalid or inapplicable in any way, the same shall be canceled and stricken out and the remaining items of the terms and conditions shall be fully valid.<br>" +
        "(2)- Disputes shall be settled by the competent courts or the disputes and insurance violations resolution commissions in the Kingdom of Saudi Arabia.",
    },
    {
      id: "q9",
      questionAr: "ملف الشركة",
      questionEn: "Company’s Profile",
      answerAr: "شركة وازن لوساطة التأمين الإلكتروني",
      answerEn: "Wazen Electronic Insurance Broker Company",
    },
  ];
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
  }
}
