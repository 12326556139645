import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgFormsManager } from "@ngneat/forms-manager";
import { FormBuilder, FormGroup } from "@ngneat/reactive-forms";
import AppUtils from "src/app/helpers/utils/AppUtils";
import { Scroll } from "src/app/helpers/utils/scroll";
import { NationalIdValidator } from "src/app/helpers/validators/NationalIdValidator";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import {
  LandingForm,
  ManagedForms,
  ManagedFormsTypes,
} from "src/app/models/insurance-steps/ManagedFormsTypes";
import { VehicleIdTypes } from "src/app/models/master-table/VehicleIdTypeId";
import { Identity, IdentityResponse } from "src/app/models/profile/Identity";
import { AppService } from "src/app/services/app/app.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { FormValidationServiceService } from "src/app/services/insurance-steps/form-validation-service.service";
import { InsuranceStepsService } from "src/app/services/insurance-steps/insurance-steps.service";
import { ProfileService } from "src/app/services/profile/profile.service";
@Component({
  selector: "app-insurance-profile-form",
  templateUrl: "./insurance-profile-form.component.html",
  styleUrls: ["./insurance-profile-form.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceProfileFormComponent
  implements OnInit, OnDestroy, DoCheck
{
  /* Form */
  landingFormGroup: FormGroup<LandingForm>;
  termSearchFn = AppUtils.searchItemTerm;
  isLoading = false;
  isFormValid = false;
  errorMessage: string;
  isAgree = false;
  showSequenceNo = true;
  showCardNo: boolean;
  vehicleIdTypes: VehicleIdTypes[] = [];
  selectedVehicleIdTypes: number;
  isVehicleTrasfer: boolean = false;
  now = new Date();
  minDate;
  maxDate;
  model;
  submitted: boolean = false;
  identityData = this.insuranceStepsService.getSelectIdentity().value.Identity;
  vehiclesData = this.insuranceStepsService.getSelectVehicle().value.Vehicle;
  identities: Identity[];

  id: number;

  previousQuotationDataIdentity =
    this.insuranceStepsService.getSelectIdentity().value;
  previousQuotationDatavehicles =
    this.insuranceStepsService.getSelectVehicle().value;

  requestFromProfileIdentity: boolean =
    Object.entries(this.previousQuotationDataIdentity).length === 0
      ? null
      : true;
  requestFromProfile: boolean =
    Object.entries(this.previousQuotationDatavehicles).length === 0
      ? null
      : true;
  lang: string;
  QuoteFromProfileWithIdentity: any;
  insuranceSteps: any;
  constructor(
    private fb: FormBuilder,
    private insuranceStepsService: InsuranceStepsService,
    private authService: AuthService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private formsManager: NgFormsManager<ManagedFormsTypes>,
    private scrollOnErrer: Scroll,
    private formvalidationService: FormValidationServiceService,
    private cd: ChangeDetectorRef,
    private profileService: ProfileService
  ) {}
  ngOnInit(): void {
    if (this.authService.getIsLoggedIn().value) this.getIdentities();
    this.checkExternalAuth();
    this.getStepData();
    this.initForm();
    this.getPreviousQuotationData();
    this.setMinDate();
    this.setMaxDate();
    this.onChangeRegistration();
    // this.watchFormChanges();
    // this.selectedVehicleIdTypes = 1;
    // this.changeInputFromProfile();
    this.watchFormAfterSubmit();

    //  this.inquireService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    // this.formvalidationService.submitForm.forEach((e) => (this.submitted = e));
  }

  ngDoCheck(): void {
    if (this.submitted === true) {
      this.landingFormGroup.markAllAsTouched();
    }

    this.watchFormAfterSubmit();
  }
  initForm(): void {
    this.landingFormGroup = this.fb.group({
      isVehicleOwnerTransfer: [null],
      insuredIdentityNumber: [
        null,
        Validators.compose([
          Validators.required,
          NationalIdValidator,
          Validators.minLength(10),
        ]),
      ],
      vehicleId: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(10),
        ]),
      ],
      policyEffectiveDate: [null, [Validators.required]],
      vehicleIdTypeId: [1, [Validators.required]],
      vehicleOwnerIdentityNumber: [null],
      vehicleModelYear: [null],
      insuredIdentityTypeId: [null],
    });
    // this.initCaptchaContent();
    // Add the form to the managed forms
    this.formsManager.upsert(ManagedForms.LandingForm, this.landingFormGroup, {
      persistState: true,
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.landingFormGroup.controls;
  }

  private async checkExternalAuth() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      const urlAccessToken = params["access"];
      if (urlAccessToken) {
        // Logout from existing accounts
        await localStorage.removeItem(LocallyStoredItemsKeys.JWT);
        this.authService.setIsLoggedIn(false);
        // Login with the access token
        localStorage.setItem(
          LocallyStoredItemsKeys.JWT,
          decodeURI(urlAccessToken)
        );
        this.authService.setIsLoggedIn(true);
      }
    });
  }
  setMinDate() {
    let minDate = AppUtils.addDays(this.now, 1);
    this.minDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate(),
    };
  }
  setMaxDate() {
    let maxDate = AppUtils.addDays(this.now, 30);
    this.maxDate = {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate(),
    };
  }

  isTransfer() {
    this.f.vehicleOwnerIdentityNumber.reset();
    this.isVehicleTrasfer = !this.isVehicleTrasfer;
    // if isVehicle tranfered remove the vehicleTypes dropdown and show seqNumber input
    if (this.isVehicleTrasfer) {
      this.selectedVehicleIdTypes = 1;
    }
    if (this.isVehicleTrasfer) {
      this.f.vehicleOwnerIdentityNumber.setValidators(
        Validators.compose([
          Validators.required,
          NationalIdValidator,
          Validators.minLength(10),
        ])
      );
      this.f.vehicleOwnerIdentityNumber.updateValueAndValidity();
    } else {
      this.f.vehicleOwnerIdentityNumber.clearValidators();
    }
    this.f.vehicleOwnerIdentityNumber.updateValueAndValidity();
  }

  submitForm() {
    this.submitted = true;
    if (this.landingFormGroup.invalid) {
      const firstElementWithError = document.querySelector(
        ".ng-invalid[formControlName]"
      );
      this.scrollOnErrer.scrollTo(firstElementWithError);
      return;
    }
  }
  watchFormChanges() {
    this.landingFormGroup.valueChanges.subscribe((e) => {
      this.validateOrderForm();
    });
  }
  validateOrderForm() {
    if (this.f.insuredIdentityNumber.valid && this.f.vehicleId.valid) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }
  getStepData() {
    // this.insuranceStepsApi.getStepsData();
    this.insuranceStepsService
      .getInsuranceStepsData()
      .subscribe((stepsData) => {
        this.isLoading = false;
        this.vehicleIdTypes = stepsData.VehicleIdTypes;
      });
    /* get master table data */
    /* TODO: Move master table request from quotation request page to landing page */
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
  }
  selectVehicleType(e) {
    this.selectedVehicleIdTypes = e.id;
    if (this.selectedVehicleIdTypes == 2) {
      this.f.vehicleModelYear.setValidators(Validators.required);
    } else {
      this.f.vehicleModelYear.clearValidators();
    }
    this.f.vehicleModelYear.updateValueAndValidity();
  }

  onChangeRegistration() {
    this.f.vehicleIdTypeId.valueChanges.subscribe((vehicleIdTypeId) => {
      if (vehicleIdTypeId == 1) {
        this.showSequenceNo = true;
        this.showCardNo = false;
      } else {
        this.showCardNo = true;
        this.showSequenceNo = false;
      }
    });
  }

  getIdentities() {
    this.profileService.getIdentities().subscribe((res: IdentityResponse) => {
      this.identities = res.data;

      // this.identities.forEach((id) => {
      //   this.id = id.identityNumber;
      // });
      if (this.identities.length > 0) {
        const firstIdentity = this.identities[0];

        this.landingFormGroup.patchValue({
          insuredIdentityNumber: firstIdentity.identityNumber,
        });
      }
    });
  }

  /* for landing form */
  getPreviousQuotationData() {
    this.selectedVehicleIdTypes = this.vehiclesData?.vehicleIdTypeId;
    // this.landingFormGroup.reset();
    // this.f.insuredIdentityNumber.setValue(this.identityData.identityNumber);
    // case add new vehicle
    if (
      JSON.stringify(this.vehiclesData) === "{}" ||
      this.vehiclesData === undefined
    ) {
      this.requestFromProfile = false;
      this.f.vehicleIdTypeId.setValue(1);
      this.selectedVehicleIdTypes = 1;
      this.f.vehicleId.setValue(null);
      this.f.policyEffectiveDate.setValue(null);
      this.f.isVehicleOwnerTransfer.setValue(false);
      this.f.vehicleModelYear.setValue(null);
      this.f.isVehicleOwnerTransfer.setValue(false);
      if (this.identityData != null || this.identityData !== undefined) {
        this.f.insuredIdentityNumber.setValue(this.identityData.identityNumber);
      }
    } else if (Object.keys(this.previousQuotationDatavehicles).length > 0) {
      this.requestFromProfileIdentity = true;
      if (this.vehiclesData.isVehicleOwnerTransfer === true) {
        this.f.isVehicleOwnerTransfer.setValue(
          this.vehiclesData.isVehicleOwnerTransfer
        );
        this.isVehicleTrasfer = true;
      } else this.f.isVehicleOwnerTransfer.setValue(false);
      this.f.insuredIdentityNumber.setValue(this.identityData.identityNumber);

      if (this.vehiclesData === null || this.vehiclesData === undefined) {
        this.f.vehicleIdTypeId.setValue(1);
      } else {
        this.f.vehicleIdTypeId.setValue(this.vehiclesData.vehicleIdTypeId);
      }
      this.f.vehicleId.setValue(this.vehiclesData.vehicleId);
      this.f.vehicleModelYear.setValue(this.vehiclesData.vehicleModelYear);
      this.f.vehicleOwnerIdentityNumber.setValue(
        this.vehiclesData.vehicleOwnerIdentityNumber
      );
    } else if (
      Object.keys(this.previousQuotationDatavehicles).length > 0 &&
      this.QuoteFromProfileWithIdentity == 3
    ) {
      this.f.isVehicleOwnerTransfer.setValue(false);
      this.f.insuredIdentityNumber.setValue(
        this.vehiclesData.insuredIdentityNumber
      );

      this.f.vehicleIdTypeId.setValue(1);
      this.f.vehicleId.setValue(null);
      this.f.vehicleModelYear.setValue(null);
    } else {
      this.f.isVehicleOwnerTransfer.setValue(false);
      this.f.insuredIdentityNumber.setValue(null);
      this.f.vehicleIdTypeId.setValue(1);
      this.f.vehicleId.setValue(null);
      this.f.vehicleModelYear.setValue(null);
    }
  }

  //  to disable input when come from profile with identity and vichele info
  changeInputFromProfile() {
    this.previousQuotationDatavehicles =
      this.insuranceStepsService.getSelectVehicle().value;
    if (Object.keys(this.previousQuotationDatavehicles).length > 0) {
    }
  }

  watchFormAfterSubmit() {
    this.formvalidationService.submitForm.subscribe((e) => {
      this.submitted = e;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.landingFormGroup.reset();
    this.formvalidationService.setSubmitted(false);
  }
}
