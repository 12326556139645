import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { environment } from "src/environments/environment";
import { UserVerification } from "src/app/payload/requests/auth/UserVerification";
import { ApiRoutes } from "src/app/models/app/ApiRoutes";
import { BehaviorSubject, Observable } from "rxjs";
import { ActiveSettingsPanels } from "src/app/models/app/ActiveSettingsPanels";
import { UsernameChangeRequest } from "src/app/payload/requests/user/UsernameChangeReq";
import { UsernameChangeVerificationReq } from "src/app/payload/requests/user/UsernameChangeVerificationReq";
import { PhoneChangeRequest } from "src/app/payload/requests/user/PhoneChangeReq";
import { PhoneChangeVerificationReq } from "src/app/payload/requests/user/PhoneChangeVerificationReq";
import { DefaultLangChangeRequest } from "src/app/payload/requests/user/DefaultLangChangeReq";
import { PasswordResetVerificationReq } from "src/app/payload/requests/user/PasswordResetVerificationReq";
import { PasswordResetReq } from "src/app/payload/requests/user/PasswordResetReq";
import { PasswordChangeRequest } from "src/app/payload/requests/user/PasswordChangeReq";
import { PasswordChangeVerficationReq } from "src/app/payload/requests/user/PasswordChangeVerficationReq";
import {
  UserProfileDataRes,
  UserProfileData,
} from "src/app/payload/responses/user/UserProfileDataRes";
import { RegistrationResponse } from "src/app/payload/responses/auth/RegistrationResponse";
import { tap } from "rxjs/operators";
import { LoginResponse } from "src/app/payload/responses/auth/LoginResponse";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  policy = new BehaviorSubject("");
  currentPolicy = this.policy.asObservable();
  apiUrl: string = environment.apiUrl;

  initialActivePanels = {
    password: false,
    email: false,
    phone: false,
    misc: false,
  };

  private activeSettingsPanel: BehaviorSubject<ActiveSettingsPanels> =
    new BehaviorSubject<ActiveSettingsPanels>(this.initialActivePanels);

  constructor(private http: HttpClient, private authService: AuthService) {}

  sendVerificationCode(verificationCode: number) {
    // Get verification data from local storage
    const verificationUserId = localStorage.getItem(
      LocallyStoredItemsKeys.VerificationUserId
    );
    const verificationPhoneNum = localStorage.getItem(
      LocallyStoredItemsKeys.VerificationPhoneNumber
    );

    // Construct verification request body
    const verificationReq: UserVerification = {
      userId: verificationUserId,
      phoneNumber: verificationPhoneNum,
      code: verificationCode.toString(),
    };

    // Send the verification request
    return this.http
      .post(this.apiUrl + ApiRoutes.Account.VerifyPhone, verificationReq)
      .pipe(
        tap((res: LoginResponse) => {
          if (res.isSuccess) {
            localStorage.setItem(LocallyStoredItemsKeys.JWT, res.accessToken);
            this.authService.setIsLoggedIn(true);
          }
        })
      );
  }
  resendVerficationCode(UserVerificationData: RegistrationResponse) {
    // Send the verification request
    return this.http.post(
      this.apiUrl + ApiRoutes.Account.ResendVerfication,
      UserVerificationData
    );
  }

  requestPasswordChange(passwordChangeRequest: PasswordChangeRequest) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Profile.PasswordChangeRequest,
      passwordChangeRequest
    );
  }

  changePassword(
    passwordChangeVerificationRequest: PasswordChangeVerficationReq
  ) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Profile.PasswordChange,
      passwordChangeVerificationRequest
    );
  }

  requestPasswordReset(passwordResetRequest: PasswordResetReq) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Account.PasswordResetRequest,
      passwordResetRequest
    );
  }

  resetPassword(
    passwordResetVerificationRequest: PasswordResetVerificationReq
  ) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Account.PasswordReset,
      passwordResetVerificationRequest
    );
  }

  requestUsernameChange(usernameChangeRequest: UsernameChangeRequest) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Profile.UsernameChangeRequest,
      usernameChangeRequest
    );
  }

  changeUsername(
    usernameChangeVerificationRequest: UsernameChangeVerificationReq
  ) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Profile.UsernameChange,
      usernameChangeVerificationRequest
    );
  }

  requestPhoneNumberChange(phoneNumberChangeRequest: PhoneChangeRequest) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Profile.PhoneNumberChangeRequest,
      phoneNumberChangeRequest
    );
  }

  changePhoneNumber(
    phoneNumberChangeVerificationRequest: PhoneChangeVerificationReq
  ) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Profile.PhoneNumberChange,
      phoneNumberChangeVerificationRequest
    );
  }

  changeDefaultLanguage(languageChangeRequest: DefaultLangChangeRequest) {
    return this.http.post(
      this.apiUrl + ApiRoutes.Profile.ChangeLanguage,
      languageChangeRequest
    );
  }

  fetchUserProfileData(): Observable<UserProfileDataRes> {
    let url = this.apiUrl + ApiRoutes.Profile.GetAccountinfo;
    return this.http.get<UserProfileDataRes>(
      this.apiUrl + ApiRoutes.Profile.GetAccountinfo
    );
  }

  /* Setters and Getters */

  getActiveSettingsPanel(): BehaviorSubject<ActiveSettingsPanels> {
    return this.activeSettingsPanel;
  }

  setActiveSettingsPanel(activePanelsObj: ActiveSettingsPanels) {
    this.activeSettingsPanel.next(activePanelsObj);
  }

  resetActiveSettingsPanel() {
    this.activeSettingsPanel.next({
      password: false,
      email: false,
      phone: false,
      misc: false,
    });
  }

  getUserProfileData(): UserProfileData {
    return JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.ProfileData));
  }

  setUserProfileData(userProfileData: UserProfileData) {
    // localStorage.removeItem(LocallyStoredItemsKeys.ProfileData);
    localStorage.setItem(
      LocallyStoredItemsKeys.ProfileData,
      JSON.stringify(userProfileData)
    );
  }

  /*add claim */
  addNewClaim(claim: any) {
    return this.http.post(this.apiUrl + "Claims/create", claim);
  }
}
