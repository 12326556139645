<!-- ----------------------------------------------------------------------- -->
<!--                           Webcam Modal                                  -->
<!-- ----------------------------------------------------------------------- -->
<!-- Webcam Modal -->
<ng-template #webcamModal let-modal>
  <!-- Header -->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "Checkout.WebcamModal.CapturesImage" | translate }}
    </h4>
    <!-- <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss()"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
  </div>
  <!-- Body -->
  <div class="modal-body cont-modal-body flex-column" style="align-items: center; text-align: center">
    <!--  <i class="ion-md-alert flex-centered" style="color: red;"></i> -->
    <div *ngIf="!isImageCaptured">
      <webcam [trigger]="$trigger" (imageCapture)="snapshot($event)" [height]="220" [width]="330"></webcam>
    </div>
    <img *ngIf="isShowCapturedImage" [src]="previewImage" [height]="220" [width]="330" alt="vehicle image" />
  </div>
  <!-- Footer -->
  <div class="modal-footer">
    <button *ngIf="!isShowCapturedImage" class="primary-btn" (click)="captureImage()">
      <span>{{ "Checkout.WebcamModal.CaptureBtnLable" | translate }}</span>
    </button>
    <button *ngIf="isShowCapturedImage" class="primary-btn" (click)="onSetImage()">
      <span>{{ "Checkout.WebcamModal.SetImageBtn" | translate }}</span>
    </button>
    <button *ngIf="isShowCapturedImage" class="primary-btn" (click)="onReCaptureImage()">
      <span>{{ "Checkout.WebcamModal.ReCaptureBtnLable" | translate }}</span>
    </button>
    <button *ngIf="!isShowCapturedImage" (click)="modal.dismiss()" class="primary-btn danger">
      <span>{{ "App.CancelText" | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- Webcam Modal /-->

<div class="container px-0">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <!-- ----------------------------------------------------------------------- -->
      <!--                           Vehicle  Image Form                           -->
      <!-- ----------------------------------------------------------------------- -->
      <form [formGroup]="attacmentUploadingForm">
        <div class="form-group" *ngIf="!supportedFile">
          <label class="control-label col-sm-10 mt-2 v-meassage" style="color: red">
            {{ "Checkout.Attachments.FileNotSupport" | translate }}</label>
        </div>
        <div class="boxed-item mt-3 mb-3" *ngIf="isQuoteGGiCom">
          <h5 class="card-header">{{ "Checkout.VehicleImage" | translate }}</h5>
          <div class="card-body">
            <!-- Imgs Numbers Alert -->
            <div class="alert-box p-1 m-0" *ngIf="isImagesNotUploaded" id="errorAlert">
              <ngb-alert class="alert col-12" type="warning">
                <!-- <ul
                  style="list-style-type: square"
                  *ngIf="quoteProduct.productTypeId === 1"
                >
                  <h6 style="font-size: 0.9rem">
                    {{ "Checkout.Attachments.TPLImgs.Msg" | translate }}
                  </h6>
                  <li>{{ "Checkout.Attachments.TPLImgs.ID" | translate }}</li>
                  <li>
                    {{ "Checkout.Attachments.TPLImgs.Istemara" | translate }}
                  </li>
                </ul> -->

                <ul style="list-style-type: square">
                  <h6 style="font-size: 0.9rem">
                    {{ "Checkout.Attachments.COMImgs.Msg" | translate }}
                  </h6>
                  <li *ngIf="!isFrontVehicleImageIsUploaded">
                    {{ "Checkout.Attachments.COMImgs.FrontSide" | translate }}
                  </li>
                  <li *ngIf="!isBackVehicleImageIsUploaded">
                    {{ "Checkout.Attachments.COMImgs.BackSide" | translate }}
                  </li>
                  <li *ngIf="!isLeftVehicleImageIsUploaded">
                    {{ "Checkout.Attachments.COMImgs.LeftSide" | translate }}
                  </li>
                  <li *ngIf="!isRightVehicleImageIsUploaded">
                    {{ "Checkout.Attachments.COMImgs.RightSide" | translate }}
                  </li>
                  <!-- <li>{{ "Checkout.Attachments.COMImgs.ID" | translate }}</li>
                  <li>
                    {{ "Checkout.Attachments.COMImgs.Istemara" | translate }}
                  </li>
                  <li>
                    {{
                      "Checkout.Attachments.COMImgs.DrivingLicense" | translate
                    }}
                  </li> -->
                  <li *ngIf="!isChassisVehicleImageIsUploaded">
                    {{ "Checkout.Attachments.COMImgs.Vin" | translate }}
                  </li>
                </ul>
              </ngb-alert>
            </div>
            <!-- Success Alert -->
            <div class="container">
              <div class="alert-box p-0 m-0" *ngIf="isFilesUploadSuccessfully" id="errorAlert">
                <ngb-alert class="alert col-12" type="success" (close)="
                    isFilesUploadSuccessfully = !isFilesUploadSuccessfully
                  ">
                  <small style="font-size: 15px">
                    <i class="icon ion-md-alert pl-1"></i>
                    {{ lang == "ar" ? "تم رفع الصورة بنجاح" : successMessage }}
                  </small>
                </ngb-alert>
              </div>
              <div class="alert-box p-1 m-0" *ngIf="isImagesUploadedCom" id="errorAlert">
                <ngb-alert class="alert col-12" type="warning">
                  <small style="font-size: 15px" style="color: #856404">
                    <i class="icon ion-md-alert p-1" style="color: #856404"></i>
                    <span>You reached the allowed limit of pictures </span>
                  </small>
                </ngb-alert>
              </div>
            </div>
            <!-- Error Alert -->
            <div class="container">
              <div class="row alert-box p-0 m-0" *ngIf="isCaptrueImageFailed" id="errorAlert">
                <ngb-alert class="alert col-12" type="danger" (close)="isCaptrueImageFailed = !isCaptrueImageFailed">
                  <small style="font-size: 15px">
                    <i class="icon ion-md-alert pl-1"></i>
                    {{ errorMessage }}
                    <a *ngIf="isCameraNotAllawed" href="#">{{
                      lang === "ar" ? "" : ""
                      }}</a>
                    <a *ngIf="isCameraNotSupported" href="https://econcord.com/user/previous-quotes">{{ lang === "ar" ?
                      "" : "" }}</a>
                  </small>
                </ngb-alert>
              </div>
            </div>
            <!-- Img Validation -->
            <div class="container">
              <div class="row alert-box p-0 m-0" *ngIf="!uploadedImgValid" id="errorAlert">
                <ngb-alert class="alert col-12" type="danger">
                  <small style="font-size: 15px">
                    <i class="icon ion-md-alert pl-1"></i>
                    {{ "Checkout.Attachments.ImgValid" | translate }}
                  </small>
                </ngb-alert>
              </div>
            </div>

            <!--  Select Vehcile Imgs (COM Type) -->
            <div class="row">
              <!-- front side car -->
              <div class="col-md-3 col-sm-12">
                <div id="attachFilesNewProjectLabel" (click)="onCaptureImage(webcamModal, 1)"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable">
                  <div class="dz-message my-3" [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }">
                    <img id="imagePreview-front" class="avatar avatar-xl avatar-4x3 mb-3" [src]="
                        attachmentObj?.image1
                          ? 'data:image/png;base64,' + attachmentObj.image1
                          : 'assets/images/Icons/front.jpg'
                      " alt="Image Description" data-hs-theme-appearance="default" />
                    <span id="add-front" class="d-block" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span id="remove-front" class="d-none" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{
                        "Checkout.Attachments.COMImgs.FrontSide" | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- back side car -->
              <div class="col-md-3 col-sm-12">
                <div id="attachFilesNewProjectLabel" (click)="onCaptureImage(webcamModal, 2)"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable">
                  <div class="dz-message my-3" [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }">
                    <img id="imagePreview-back" class="avatar avatar-xl avatar-4x3 mb-3" [src]="
                        attachmentObj?.image2
                          ? 'data:image/png;base64,' + attachmentObj.image2
                          : 'assets/images/Icons/back.jpg'
                      " alt="Image Description" data-hs-theme-appearance="default" />
                    <span id="add-back" class="d-block" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span id="remove-back" class="d-none" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{
                        "Checkout.Attachments.COMImgs.BackSide" | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- right side car -->
              <div class="col-md-3 col-sm-12">
                <div id="attachFilesNewProjectLabel" (click)="onCaptureImage(webcamModal, 3)"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable">
                  <div class="dz-message my-3" [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }">
                    <img id="imagePreview-right" class="avatar avatar-xl avatar-4x3 mb-3" [src]="
                        attachmentObj?.image3
                          ? 'data:image/png;base64,' + attachmentObj.image3
                          : 'assets/images/Icons/right.jpg'
                      " alt="Image Description" data-hs-theme-appearance="default" />

                    <div style="display: none">
                      <div class="upload-btn-wrapper">
                        <button type="button" class="btn btn-primary">
                          Upload Files
                        </button>
                        <input type="file" value="select" #selectFile3 data-type="right"
                          (change)="onFileSelected($event, 3)" />
                      </div>
                    </div>
                    <span id="add-right" class="d-block" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span id="remove-right" class="d-none" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{
                        "Checkout.Attachments.COMImgs.RightSide" | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- left side car -->
              <div class="col-md-3 col-sm-12">
                <div id="attachFilesNewProjectLabel" (click)="onCaptureImage(webcamModal, 4)"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable">
                  <div class="dz-message my-3" [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }">
                    <img id="imagePreview-left" class="avatar avatar-xl avatar-4x3 mb-3" [src]="
                        attachmentObj?.image4
                          ? 'data:image/png;base64,' + attachmentObj.image4
                          : 'assets/images/Icons/left.jpg'
                      " alt="Image Description" data-hs-theme-appearance="default" />
                    <span id="add-left" class="d-block" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span id="remove-left" class="d-none" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{
                        "Checkout.Attachments.COMImgs.LeftSide" | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ID Picture -->
              <!-- <div class="col-md-3">
                <div
                  id="attachFilesNewProjectLabel"
                  (click)="selectFile5.click()"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                >
                  <div
                    class="dz-message my-3"
                    ng2FileDrop
                    [uploader]="uploader"
                    (fileOver)="fileOverBase($event)"
                    [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
                  >
                    <img
                      id="imagePreview-IDPicture"
                      class="avatar avatar-xl avatar-4x3 mb-3"
                      src="assets/images/Icons/id-photo.jpg"
                      alt="Image Description"
                      data-hs-theme-appearance="default"
                    />

                    <div style="display: none">
                      <div class="upload-btn-wrapper">
                        <button type="button" class="btn btn-primary">
                          Upload Files
                        </button>
                        <input
                          type="file"
                          value="select"
                          #selectFile5
                          data-type="IDPicture"
                          (change)="onFileSelected($event, 5)"
                        />
                      </div>
                    </div>
                    <span
                      id="add-IDPicture"
                      class="d-block"
                      [ngbTooltip]="'App.AddBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span
                      id="remove-IDPicture"
                      class="d-none"
                      [ngbTooltip]="'App.EditBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>{{ "Checkout.Attachments.COMImgs.ID" | translate }}</p>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- Istemara Picture -->
              <!-- <div class="col-md-3">
                <div
                  id="attachFilesNewProjectLabel"
                  (click)="selectFile6.click()"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                >
                  <div
                    class="dz-message my-3"
                    ng2FileDrop
                    [uploader]="uploader"
                    (fileOver)="fileOverBase($event)"
                    [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
                  >
                    <img
                      id="imagePreview-Istemara"
                      class="avatar avatar-xl avatar-4x3 mb-3"
                      src="assets/images/Icons/Istemara.jpg"
                      alt="Image Description"
                      data-hs-theme-appearance="default"
                    />

                    <div style="display: none">
                      <div class="upload-btn-wrapper">
                        <button type="button" class="btn btn-primary">
                          Upload Files
                        </button>
                        <input
                          type="file"
                          value="select"
                          #selectFile6
                          data-type="Istemara"
                          (change)="onFileSelected($event, 6)"
                        />
                      </div>
                    </div>
                    <span
                      id="add-Istemara"
                      class="d-block"
                      [ngbTooltip]="'App.AddBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span
                      id="remove-Istemara"
                      class="d-none"
                      [ngbTooltip]="'App.EditBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{
                          "Checkout.Attachments.COMImgs.Istemara" | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- Driving License Picture -->
              <!-- <div class="col-md-3">
                <div
                  id="attachFilesNewProjectLabel"
                  (click)="selectFile7.click()"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                >
                  <div
                    class="dz-message my-3"
                    ng2FileDrop
                    [uploader]="uploader"
                    (fileOver)="fileOverBase($event)"
                    [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
                  >
                    <img
                      id="imagePreview-DrivingLicense"
                      class="avatar avatar-xl avatar-4x3 mb-3"
                      src="assets/images/Icons/driverlicense.jpg"
                      alt="Image Description"
                      data-hs-theme-appearance="default"
                    />

                    <div style="display: none">
                      <div class="upload-btn-wrapper">
                        <button type="button" class="btn btn-primary">
                          Upload Files
                        </button>
                        <input
                          type="file"
                          value="select"
                          #selectFile7
                          data-type="DrivingLicense"
                          (change)="onFileSelected($event, 7)"
                        />
                      </div>
                    </div>
                    <span
                      id="add-DrivingLicense"
                      class="d-block"
                      [ngbTooltip]="'App.AddBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span
                      id="remove-DrivingLicense"
                      class="d-none"
                      [ngbTooltip]="'App.EditBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{
                          "Checkout.Attachments.COMImgs.DrivingLicense"
                            | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- Vin Picture -->
              <div class="col-md-3 col-sm-12">
                <div id="attachFilesNewProjectLabel" (click)="onCaptureImage(webcamModal, 5)"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable">
                  <div class="dz-message my-3" [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }">
                    <img id="imagePreview-Vin" class="avatar avatar-xl avatar-4x3 mb-3" [src]="
                            attachmentObj?.image5
                              ? 'data:image/png;base64,' + attachmentObj.image5
                              : 'assets/images/Icons/MicrosoftTeams-image.png'
                          " alt="Image Description" data-hs-theme-appearance="default" />
                    <span id="add-Vin" class="d-block" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span id="remove-Vin" class="d-none" placement="top" tooltipClass="tooltip">
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{ "Checkout.Attachments.COMImgs.Vin" | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!--  Select Vehcile Imgs (TPL Type) -->
            <!-- <div class="row" *ngIf="quoteProduct.productTypeId === 1">
              <div class="col-md-6">
                <div
                  id="attachFilesNewProjectLabel"
                  (click)="selectFile5.click()"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                >
                  <div
                    class="dz-message my-3"
                    ng2FileDrop
                    [uploader]="uploader"
                    (fileOver)="fileOverBase($event)"
                    [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
                  >
                    <img
                      id="imagePreview-IDPicture"
                      class="avatar avatar-xl avatar-4x3 mb-3"
                      src="assets/images/Icons/id-photo.jpg"
                      alt="Image Description"
                      data-hs-theme-appearance="default"
                    />

                    <div style="display: none">
                      <div class="upload-btn-wrapper">
                        <button type="button" class="btn btn-primary">
                          Upload Files
                        </button>
                        <input
                          type="file"
                          value="select"
                          #selectFile5
                          data-type="IDPicture"
                          (change)="onFileSelected($event, 1)"
                        />
                      </div>
                    </div>
                    <span
                      id="add-IDPicture"
                      class="d-block"
                      [ngbTooltip]="'App.AddBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span
                      id="remove-IDPicture"
                      class="d-none"
                      [ngbTooltip]="'App.EditBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>{{ "Checkout.Attachments.COMImgs.ID" | translate }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  id="attachFilesNewProjectLabel"
                  (click)="selectFile6.click()"
                  class="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                >
                  <div
                    class="dz-message my-3"
                    ng2FileDrop
                    [uploader]="uploader"
                    (fileOver)="fileOverBase($event)"
                    [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
                  >
                    <img
                      id="imagePreview-Istemara"
                      class="avatar avatar-xl avatar-4x3 mb-3"
                      src="assets/images/Icons/Istemara.jpg"
                      alt="Image Description"
                      data-hs-theme-appearance="default"
                    />

                    <div style="display: none">
                      <div class="upload-btn-wrapper">
                        <button type="button" class="btn btn-primary">
                          Upload Files
                        </button>
                        <input
                          type="file"
                          value="select"
                          #selectFile6
                          data-type="Istemara"
                          (change)="onFileSelected($event, 2)"
                        />
                      </div>
                    </div>
                    <span
                      id="add-Istemara"
                      class="d-block"
                      [ngbTooltip]="'App.AddBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-add-circle-outline"></i>
                    </span>
                    <span
                      id="remove-Istemara"
                      class="d-none"
                      [ngbTooltip]="'App.EditBtn' | translate"
                      placement="top"
                      tooltipClass="tooltip"
                    >
                      <i class="ion-md-remove-circle-outline text-danger"></i>
                    </span>
                    <div class="sideMsg">
                      <p>
                        {{
                          "Checkout.Attachments.COMImgs.Istemara" | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </form>
      <!--    Vehicle  Image  -->
    </div>
  </div>
</div>