import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { AuthService } from "src/app/services/auth/auth.service";
import { DriversService } from "src/app/services/insurance-steps/drivers.service";
import { QuoteRequest } from "src/app/models/insurance-steps/QuoteRequest";
import { Driver } from "src/app/models/insurance-steps/Driver";
import {
  LandingForm,
  VehicleForm,
} from "src/app/models/insurance-steps/ManagedFormsTypes";

export default class QuoteUtils {
  /**
   * Construct Quotation Request
   */
  public static constructQuoteRequest(): QuoteRequest {
    let authService: AuthService = AuthService.injector.get(AuthService);
    let driverService: DriversService =
      DriversService.injector.get(DriversService);

    let formsData = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.FormManager)
    );
    let landingInfo: LandingForm = formsData.LandingForm?.value;
    let vehicleInfo: VehicleForm = formsData.VehicleInsuranceForm?.value;
    if (
      landingInfo.policyEffectiveDate == null ||
      landingInfo.policyEffectiveDate === undefined
    ) {
      landingInfo.policyEffectiveDate =
        formsData.MainDriverInsuranceForm.value.policyEffectiveDate;
    }
    // let vehicleInfo: VehicleForm = formsData.VehicleInsuranceForm.value;
    let drivers: Driver[] = driverService.getUserQuoteReqDrivers().value;

    let mainDriver: Driver = drivers.filter((driver) => driver.typeId === 1)[0];

    mainDriver.drivingPercentageId = driverService.getCalculatedPercentage();
    let mainDriverMoreDetails: any = formsData.DriversMoreDetails?.value;
    mainDriver.medicalConditions = mainDriverMoreDetails?.MedicalConditions;
    mainDriver.driverViolations = mainDriverMoreDetails?.DriverViolations;
    mainDriver.driverLicenses = null;

    // mainDriver = {
    //   ...mainDriver,
    //   drivingPercentageId: driverService.getCalculatedPercentage(),
    //   medicalConditions: mainDriverMoreDetails?.MedicalConditions,
    //   driverViolations: mainDriverMoreDetails?.DriverViolations,
    //   driverLicenses: null,
    // };

    let isVehicleTrasfer = landingInfo.vehicleOwnerIdentityNumber;
    let quotationReq: QuoteRequest = Object.assign({
      // email: authService.getDecodedToken().sub,
      // phoneNumber: authService.getTokenUserPhoneNum(),
      vehicleSumInsured: vehicleInfo.vehicleSumInsured,
      // vehicleInfo.vehicleSumInsured == null
      //   ? parseInt(
      //       vehicleInfo.vehicleSumInsured?.toString().replace(/,/g, "")
      //     )
      //   : undefined,
      vehicleUseId: vehicleInfo.vehicleUseId,
      vehicleTransmissionTypeId: vehicleInfo.vehicleTransmissionTypeId,
      vehicleParkingLocationId: vehicleInfo.vehicleParkingLocationId,
      isVehicleModified: vehicleInfo.isVehicleModified,
      vehicleRepairMethodId: vehicleInfo.vehicleRepairMethodId,
      vehicleModificationDetails: vehicleInfo.vehicleModificationDetails,
      vehicleCurrentMileage: 1000,
      insuredIdentityTypeId: parseInt(
        landingInfo.insuredIdentityNumber.toString()[0]
      ),
      insuredIdentityNumber: parseInt(landingInfo.insuredIdentityNumber),
      vehicleOwnerIdentityNumber: isVehicleTrasfer
        ? parseInt(landingInfo.vehicleOwnerIdentityNumber)
        : parseInt(landingInfo.insuredIdentityNumber),
      occupationId: mainDriver.occupationId,
      vehicleIdTypeId: landingInfo.vehicleIdTypeId,
      vehicleId: parseInt(landingInfo.vehicleId),
      vehicleModelYear: parseInt(landingInfo.vehicleModelYear),
      policyEffectiveDate: this.formatDate(landingInfo.policyEffectiveDate),
      nationalityId: 0,

      InsuredEducationLevelId: mainDriver.educationLevelId,
      childrenUnder16Years: mainDriver.childrenUnder16Years,
      promoCode: null,
      isVehicleOwnerTransfer: landingInfo.isVehicleOwnerTransfer,
      vehicleSpecifications: vehicleInfo.vehicleSpecifications,
    });

    if (mainDriver.identityNumber.toString().startsWith("2")) {
      quotationReq.insuredBirthDate = mainDriver.birthDateG;
    } else {
      quotationReq.insuredBirthDate = mainDriver.birthDateH;
    }
    drivers.forEach((driver) => delete driver.isSaudi);
    quotationReq.drivers = drivers;
    quotationReq.missingInfo = JSON.parse(
      localStorage.getItem("yakeenInfoForm")
    );
    localStorage.removeItem("yakeenInfoForm");
    return quotationReq;
  }
  // Construct the policy effective date format
  static formatDate(date: any) {
    const day = date.day.toString().padStart(2, "0");
    const month = date.month.toString().padStart(2, "0");
    const year = date.year.toString();
    return `${year}-${month}-${day}`;
  }
}
