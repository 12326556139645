import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { QuoteComponent } from './quote/quote.component';
import { QuotesListComponent } from './quotes-list/quotes-list.component';
import { QuotesPageComponent } from './quotes-page/quotes-page.component';
import { QuotesFiltersComponent } from './quotes-filters/quotes-filters.component';
import { QuotesOrderDataComponent } from './quotes-order-data/quotes-order-data.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { QUOTES_PAGE_ROUTES } from './quotes.routes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';


@NgModule({
   declarations: [
      QuoteComponent,
      QuotesListComponent,
      QuotesPageComponent,
      QuotesFiltersComponent,
      QuotesOrderDataComponent,
   ],
   imports: [
      CommonModule,
      SharedModule,
      FormsModule,
      NgbModule,
      ReactiveFormsModule,
      CarouselModule,
      RouterModule.forChild(QUOTES_PAGE_ROUTES),
   ],
   exports: [
      QuoteComponent
   ]
})


export class QuotesModule { }
