<div class="mt-4" [class.container]="!isLoadingQuotes">
  <div class="row" *ngIf="!isLoadingQuotes">
    <div class="col-lg-12 mx-0 mt-5">
      <div class="wizard-container" [class.is-sticky]="hasScrolledBanner" [ngStyle]="{
          'box-shadow': hasScrolledBanner
            ? 'unset'
            : '0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15)'
        }">
        <div class="tabs container">
          <input type="radio" id="radio-1" class="input" name="tabs"
            (click)="enterToStep('/quotation', ClearQuoteModal)" [class.active-step]="activeStep.quotation" />
          <label class="tab" for="radio-1">
            <span class="notification">1</span>{{ "Quote.wizard-steps.step3" | translate }}</label>

          <input type="radio" id="radio-2" class="input" name="tabs" (click)="enterToStep('/quotes', ClearQuoteModal)"
            [class.active-step]="activeStep.quotes" />
          <label class="tab" for="radio-2"><span class="notification">2</span>{{ "Quote.wizard-steps.step2" | translate
            }}</label>

          <input type="radio" id="radio-3" class="input" name="tabs" (click)="enterToStep('/checkout', ClearQuoteModal)"
            [class.active-step]="activeStep.checkout" />
          <label class="tab" for="radio-3"><span class="notification">3</span>{{ "Quote.wizard-steps.step1" | translate
            }}</label>

          <input type="radio" id="radio-4" class="input" name="tabs"
            (click)="enterToStep('/checkout/status', ClearQuoteModal)" [class.active-step]="activeStep.status" />
          <label class="tab" for="radio-4"><span class="notification">4</span>{{ "Quote.wizard-steps.step4" | translate
            }}</label>
          <span class="glider"></span>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                           ClearQuote Modal                            -->
<!-- ----------------------------------------------------------------------- -->
<ng-template #ClearQuoteModal let-modal>
  <!-- Header -->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{
      appLang == "en"
      ? "Would you need to go back to the previous step?"
      : "هل أنت بحاجة للعودة إلى الخطوة السابقة؟"
      }}
    </h4>
    <!-- <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button> -->
  </div>
  <!-- Body -->
  <div class="modal-body cont-modal-body flex-column">
    <!--  <i class="ion-md-alert flex-centered" style="color: red;"></i> -->
    <hr />
    <p class="flex-centered">
      <b>{{
        appLang == "ar"
        ? "لن يكون الطلب الحالي متاحًا بعد الآن إذا عدت إلى الخطوة السابقة."
        : "The current quotes will no longer be available if you go back to theprevious step. "
        }}</b>
    </p>
  </div>
  <!-- Footer -->
  <div class="modal-footer">
    <button class="primary-btn" (click)="ClearQuote()">
      <span>{{ appLang == "en" ? "Ok" : "نعم" }}</span>
    </button>
    <button (click)="modal.dismiss()" class="primary-btn danger">
      <span>{{ "App.CancelText" | translate }}</span>
    </button>
  </div>
</ng-template>