import { TermsConditionsComponent } from "./layout/terms-conditions/terms-conditions.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { PrivacyPolicyComponent } from "./layout/privacy-policy/privacy-policy.component";
import { InsuranceAuthorityComponent } from "./insurance-authority/insurance-authority.component";
import { ProductsComponent } from "./layout/products/products.component";
import { ServicesComponent } from "./layout/service/services.component";
import { ChannelsComponent } from "./layout/channels/channels.component";

const routes: Routes = [
  // { path: AppRoutes.footerContent.contact, component: ContactUsComponent },
  {
    path: AppRoutes.footerContent.tremsCondition,
    component: TermsConditionsComponent,
  },
  {
    path: AppRoutes.footerContent.privacyPolicy,
    component: PrivacyPolicyComponent,
  },
  {
    path: AppRoutes.footerContent.InsuranceAuthority,
    component: InsuranceAuthorityComponent,
  },
  {
    path: AppRoutes.footerContent.products,
    component: ProductsComponent,
  },
  {
    path: AppRoutes.footerContent.Service,
    component: ServicesComponent,
  },
  {
    path: AppRoutes.footerContent.channels,
    component: ChannelsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
