import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import AppUtils from "src/app/helpers/utils/AppUtils";
import { AppLanguage } from "src/app/models/app/AppLanguage";
import { LoadingPageTypes } from "src/app/models/app/LoadingPageTypes";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { UserPoliciesDaily } from "src/app/models/app/UserPoliciesDaily";
import { AppService } from "src/app/services/app/app.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { ProfileService } from "src/app/services/profile/profile.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: [
    "./header.component.css",
    "../../../../../assets/styles/home-page-styles/style.css",
    "../../../../../assets/styles/home-page-styles/rtl.css",
    "../../../../../assets/styles/home-page-styles/responsive.css",
  ],
})
export class HeaderComponent implements OnInit {
  appLanguage;
  isAr;

  isNavbarCollapsed: boolean = false;
  isChangingLang: boolean = false;
  isNavbarOpen: boolean = false;
  isOnMidScreen: boolean = false;
  isLoggedIn: boolean;
  jwtHelper = new JwtHelperService();

  userEmail;
  userFullName;
  userFullNameAr;

  isUserMenuOpen;

  isAtLanding: boolean = false;
  hasScrolledBanner: boolean = false;
  policesCount: UserPoliciesDaily;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.checkIfAtLanding();
    this.getScreenSize();
    this.getCurrentAppLanguage();
    this.watchLoginState();
    this.initUserData();
    this.getUserPolicesToday();
    this.profileService.getPoliciesCountToday().subscribe((data) => {
      this.policesCount = data;
    });
  }

  getUserPolicesToday() {
    this.profileService.getUserPoliciesCount().subscribe((res) => {
      this.policesCount.maximumDailyPolicies = res.maximumDailyPolicies;
      this.policesCount.totalPoliciesPurchasedToday =
        res.totalPoliciesPurchasedToday;
      this.profileService.setPoliciesCountToday(this.policesCount);
    });
  }

  private initUserData(): void {
    // Watch for login
    this.authService.getIsLoggedIn().subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        const token = localStorage.getItem(LocallyStoredItemsKeys.JWT);
        const decoded = this.jwtHelper.decodeToken(token);
        
        // this.userEmail = decoded.sub;
        this.userFullName = decoded.name;
        this.userFullNameAr = decoded.nameAr;

        this.isLoggedIn = isLoggedIn;
      } else {
        this.userEmail = null;
        this.isLoggedIn = false;
      }
    });
  }

  private watchLoginState(): void {
    // Watch authentication status changes
    this.authService.getIsLoggedIn().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  private getCurrentAppLanguage(): void {
    // Get current language from local storage
    this.appLanguage = localStorage.getItem(LocallyStoredItemsKeys.AppLanguage);
    // Get it from the observable if doesn't exist on local storage
    if (!this.appLanguage) {
      this.appLanguage = this.appService.getAppLang().value;
    }
    // Set the header lang link
    this.isAr = this.appLanguage === "ar" ? true : false;
    // Watch for language changes
    this.appService.getAppLang().subscribe((lang) => (this.appLanguage = lang));
  }

  async switchLang() {
    // Set the active loader type to language switch
    this.appService.setActiveLoadingPageType(LoadingPageTypes.LanguageSwitch);
    // Show loader
    this.isChangingLang = !this.isChangingLang;
    // Delay until animation ends, also switch document direction
    await AppUtils.delay(2800);
    // Switch the language
    if (!this.appLanguage || this.appLanguage === AppLanguage.ENGLISH) {
      this.appService.setLanguage(AppLanguage.ARABIC);
      this.isAr = true;
      this.isChangingLang = !this.isChangingLang;
    } else {
      this.appService.setLanguage(AppLanguage.ENGLISH);
      this.isAr = false;
      this.isChangingLang = !this.isChangingLang;
    }
  }

  logout(): void {
    this.userEmail = null;
    this.isLoggedIn = false;
    this.authService.logout();
  }

  private checkIfAtLanding(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isAtLanding = event.url === "/";
      }
    });
  }

  // Listen for window size changes
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?): void {
    // If browser window is resized below mid screen size width
    window.innerWidth <= 858
      ? (this.isOnMidScreen = true)
      : (this.isOnMidScreen = false);
  }

  // Window scroll events
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    if (window.pageYOffset > 100) this.hasScrolledBanner = true;
    else this.hasScrolledBanner = false;
  }

  onNavLinkClick() {
    // Close the navbar
    this.isNavbarOpen = false;
  }
}
