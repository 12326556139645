import {
  Component,
  Directive,
  ElementRef,
  Renderer2,
  Input,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmPasswordValidator,
  PasswordStrengthValidator,
} from "src/app/helpers/validators/ConfirmPasswordValidator";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { AuthSource } from "src/app/models/app/AuthSource";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import { PasswordResetReq } from "src/app/payload/requests/user/PasswordResetReq";
import { ApiResponse } from "src/app/payload/responses/ApiResponse";
import { LoginResponse } from "src/app/payload/responses/auth/LoginResponse";
import { RegistrationResponse } from "src/app/payload/responses/auth/RegistrationResponse";
import { AppService } from "src/app/services/app/app.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { UserService } from "src/app/services/user/user.service";
import { UserLogin } from "../../payload/requests/auth/UserLogin";
import { UserRegistration } from "../../payload/requests/auth/UserRegistration";
import { StatusCodes } from "./../../models/app/StatusCodes";
import { CustomValidators } from "./custom-validators";
import { UserVerifyOtp } from "src/app/payload/requests/auth/UserVerifyOtp";
import { UserIdentityNumber } from "src/app/payload/requests/auth/UserIdentityNumber";
import { Subscription, timer } from "rxjs";
import { take } from "rxjs/operators";
import AppUtils from "src/app/helpers/utils/AppUtils";
import { UserVerification } from "src/app/payload/requests/auth/UserVerification";
import { QuoteService } from "src/app/services/quote/quote.service";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"],
})
export class AuthenticationPageComponent implements OnInit {
  @Input("authSource") authSource: AuthSource;

  /* UI */
  isLoginFormVisible: boolean = true;
  isSiginupFormVisible: boolean = false;
  isRightPanelActive;
  isLoading = false;
  isResending = false;
  isSubmitting = false;
  isRememberMeChecked;
  isRequestingPasswordReset = false;
  fieldTextType: boolean;
  selectedField: number;
  otp: string = "";
  countDown!: Subscription;
  display: any;
  counter = 90;
  tick = 1000;
  inOtp: boolean = false;
  /* Forms */
  loginFormGroup: FormGroup;
  registrationFormGroup: FormGroup;
  passwordResetFormGroup: FormGroup;
  identificationNumberFormGroup: FormGroup;
  /* Alert */
  errorMessage;
  successMessage;
  isErrorAlertVisible;
  showAlert;
  isSuccessAlertVisible;
  validationErrors: string[];

  /* Verification */
  verificationCode: number;
  // absherId: number;

  /* Subpages */
  isRegistrationVisible = false;
  isLoginVisible = true;
  isVerificationVisible = false;
  isPasswordResetVisible = false;
  identificationNumberVisible = false;
  isOtpVisible = false;
  isOtpVisibleLogin = false;
  isOtpSent: boolean = false;
  /* Data */
  userPasswordResetData: PasswordResetReq;
  returnUrl: string;
  lang: string;
  requestFromProfile: string;
  showgregoianDate: boolean;
  showHijriDate: boolean = true;
  insuranceSteps: any;
  submitted: any;
  identificationNumber: any;
  private subscription: Subscription;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private quoteService: QuoteService
  ) {}

  ngOnInit() {
    this.initForms();
    this.initActiveFormState();
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
  }

  // start timer counter
  countDwon() {
    if (this.countDown) this.countDown.unsubscribe();
    this.counter = 90;
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        // console.log(this.counter);
        if (this.counter < 1) {
          this.countDown.unsubscribe();
          // this.isOtpSent = true;
        }
      });
  }
  // display timer UI
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                               INITIALIZATION                               */
  /* -------------------------------------------------------------------------- */

  /**
   * Initializes form groups and the controls validations
   * @memberof AuthenticationPageComponent
   */
  initForms(): void {
    /* Login form initialization */
    this.loginFormGroup = this.formBuilder.group({
      emailCtrl: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/
          ),
        ]),
      ],
      passwordCtrl: ["", Validators.required],
      rememberMeCtrl: [""],
    });

    /* identificationNumber form initialization */
    this.identificationNumberFormGroup = this.formBuilder.group({
      identificationNumber: [
        "",
        [Validators.required, Validators.minLength(10)],
      ],
    });

    /* Registration form initialization */
    this.registrationFormGroup = this.formBuilder.group(
      {
        phoneNumberCtrl: [
          "",
          Validators.compose([Validators.required, Validators.minLength(9)]),
        ],
        emailCtrl: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
          ]),
        ],
        passwordCtrl: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
          ]),
        ],
        confirmPasswordCtrl: [null, Validators.compose([Validators.required])],
        birthDateH: [null, [Validators.required]],
      },
      { validator: ConfirmPasswordValidator.MatchPassword }
    );

    /* Password reset form initialization */
    this.passwordResetFormGroup = this.formBuilder.group(
      {
        emailCtrl: [
          "",
          Validators.compose([
            // Validators.required,
            Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
          ]),
        ],
        phoneNumberCtrl: [
          "",
          Validators.compose([
            // Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.maxLength(4),
            Validators.minLength(4),
          ]),
        ],
        resetTokenCtrl: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
          ]),
        ],
        passwordCtrl: [
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            PasswordStrengthValidator,
          ]),
        ],
        confirmPasswordCtrl: ["", Validators.required],
      },
      { validator: ConfirmPasswordValidator.MatchPassword }
    );
  }

  /**
   * Returns the login form group controls
   * @readonly
   * @type {*}
   * @memberof AuthenticationPageComponent
   */
  get loginForm(): any {
    return this.loginFormGroup.controls;
  }

  /**
   * Returns the registration form group controls
   * @readonly
   * @type {*}
   * @memberof AuthenticationPageComponent
   */
  get identificationNumberForm(): any {
    return this.identificationNumberFormGroup.controls;
  }

  /**
   * Returns the registration form group controls
   * @readonly
   * @type {*}
   * @memberof AuthenticationPageComponent
   */
  get registrationForm(): any {
    return this.registrationFormGroup.controls;
  }

  /**
   * Returns the password reset form group controls
   * @readonly
   * @type {*}
   * @memberof AuthenticationPageComponent
   */
  get passwordResetForm(): any {
    return this.passwordResetFormGroup.controls;
  }

  /**
   * Initializes the opened authentication window side based on url or route
   * @memberof AuthenticationPageComponent
   */
  initActiveFormState(): void {
    // Get current route
    let currentRoute = this.router
      .parseUrl(this.router.url)
      .root.children["primary"]?.segments?.map((it) => it.path)
      .join("/");

    switch (currentRoute) {
      case AppRoutes.passwordReset:
        // Get url parameters
        let urlUserResetData: PasswordResetReq = {
          email: this.activedRoute.snapshot.queryParamMap.get("email"),
          lastPhoneDigits: this.activedRoute.snapshot.queryParamMap.get("bin"),
        };

        let isRedirectedFromEmail = !!(
          urlUserResetData.email && urlUserResetData.lastPhoneDigits
        ); // Indicates whether user has been redirected from email by checking the url parameters

        // Open the password reset form if the parameters exist
        if (isRedirectedFromEmail) {
          this.togglePasswordResetForm();
          this.isRequestingPasswordReset = true;
        }
        break;
      case AppRoutes.verification:
        this.toggleVerificationForm();
        this.toggleRightPanel();
        break;
      default:
        break;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                               USER INTERFACE                               */
  /* -------------------------------------------------------------------------- */
  get isRequested(): boolean {
    return this.otp.length !== 6 || this.isOtpSent;
  }

  goToLogin() {
    this.identificationNumberFormGroup.reset();
    if (this.countDown) this.countDown.unsubscribe();
    this.isRegistrationVisible = false;
    this.isLoginVisible = true;
    this.isSiginupFormVisible = false;
    this.isLoginFormVisible = true;
    this.isVerificationVisible = false;
    this.isPasswordResetVisible = false;
    this.identificationNumberVisible = false;
    this.isOtpVisible = false;
    this.isOtpVisibleLogin = false;
  }

  /**
   * Switches between the authentication window sides
   * @memberof AuthenticationPageComponent
   */
  toggleRightPanel(): void {
    // Toggle panels
    this.isRightPanelActive = !this.isRightPanelActive;
    // Reset and hide error message
    this.isErrorAlertVisible = false;
    this.errorMessage = undefined;
    // Reset and hide success message
    this.isSuccessAlertVisible = false;
    this.isSubmitting = false;
    // Reset forms
    this.resetForms();
  }

  /**
   * Switches side, then displays the password reset form instead of the registration form
   * @memberof AuthenticationPageComponent
   */
  togglePasswordResetForm(): void {
    // Switch panel
    this.toggleRightPanel();
    // start reset password first step
    this.isRequestingPasswordReset = false;
    // Hide registration form
    this.isRegistrationVisible = false;
    // Show otp form
    this.isOtpVisible = false;
    // Hide verification form
    this.isVerificationVisible = false;
    // Hide login form
    this.isLoginVisible = false;
    // Show password reset form
    this.isPasswordResetVisible = true;
    // Show identificationNumber form
    this.identificationNumberVisible = false;
  }

  /**
   * Switches side, then displays the verification form instead of the registration form
   * @memberof AuthenticationPageComponent
   */
  toggleVerificationForm(): void {
    // Reset and hide error message
    this.isErrorAlertVisible = false;
    this.errorMessage = undefined;
    this.isSubmitting = false;
    // Hide registration form
    this.isRegistrationVisible = false;
    // Show otp form
    this.isOtpVisible = false;
    // Hide password reset form
    this.isPasswordResetVisible = false;
    // Show verification form
    this.isVerificationVisible = true;
    // Hide login form
    this.isLoginVisible = false;
    // Show identificationNumber form
    this.identificationNumberVisible = false;
  }

  /**
   * Switches side, then displays the registration form instead of the verification or password reset forms
   * @memberof AuthenticationPageComponent
   */
  toggleRegistrationForm(): void {
    // Switch panel
    this.toggleRightPanel();
    // Hide password reset form
    this.isPasswordResetVisible = false;
    // Hide verification form
    this.isVerificationVisible = false;
    // Show registration form
    this.isRegistrationVisible = false;
    // Hide login form
    this.isLoginVisible = false;
    // Show otp form
    this.isOtpVisible = false;
    // Show identificationNumber form
    this.identificationNumberVisible = true;
    this.counter = 90;
  }

  /**
   * Switches side and displays the login form
   * @memberof AuthenticationPageComponent
   */
  toggleLoginForm(): void {
    // Switch panel
    this.toggleRightPanel();
    // Hide password reset form
    this.isPasswordResetVisible = false;
    // Hide login form
    this.isLoginVisible = true;
    // Hide verification form
    this.isVerificationVisible = false;
    // Show registration form
    this.isRegistrationVisible = false;
    // Show otp form
    this.isOtpVisible = false;
    // Show identificationNumber form
    this.identificationNumberVisible = false;
    this.countDown.unsubscribe();
  }

  /**
   * Toggles the password fields type and eye icon
   * @memberof AuthenticationPageComponent
   */
  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * Resets all of the authentication forms
   * @private
   * @memberof AuthenticationPageComponent
   */
  private resetForms(): void {
    this.loginFormGroup.reset();
    this.registrationFormGroup.reset();
    this.passwordResetFormGroup.reset();
    this.identificationNumberFormGroup.reset();
  }

  /**
   * Displays an alert with the retreived message(s) from an API response
   * @private
   * @param {('ErrorAlert' | 'SuccessAlert')} alertType Specifies the alert type of Success or Error
   * @param {string} message The message to be displayed on the alert
   * @memberof AuthenticationPageComponent
   */
  private displayAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.errorMessage = apiResponse.responseMessage;

        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.validationErrors = errorsArr;
        } else {
          this.validationErrors = null;
        }

        // Display alert
        this.isErrorAlertVisible = true;

        // Hide after timeout
        setTimeout(() => (this.isErrorAlertVisible = false), 12000);

        break;
      case "SuccessAlert":
        // Set the success message
        this.successMessage = apiResponse.responseMessage;

        // Display alert
        this.isSuccessAlertVisible = true;

        // Hide after timeout
        setTimeout(() => (this.isSuccessAlertVisible = false), 10000);

        break;
      default:
        break;
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                                API / SUBMIT                                */
  /* -------------------------------------------------------------------------- */

  /**
   * Authenticates user
   * @memberof AuthenticationPageComponent
   */
  login(): void {
    // Show the loader on button
    this.isLoading = true;
    // Set submission state to true (used for form validation)
    this.isSubmitting = true;
    // Validate form
    if (this.loginFormGroup.invalid) {
      this.isLoading = false;
      return;
    }
    // Construct user login payload object
    const loginData: UserLogin = {
      userName: this.loginFormGroup.get("emailCtrl").value,
      password: this.loginFormGroup.get("passwordCtrl").value,
      rememberMe: this.isRememberMeChecked,
    };
    // Send request
    this.authService.login(loginData).subscribe(
      (res: LoginResponse) => {
        // Stop the loader
        this.isLoading = false;

        if (res.isSuccess) {
          // Check the if login was opened from the quotes page after selecting a quote while not authenticated
          this.isOtpVisibleLogin = true;
          this.countDwon();
          this.isLoginVisible = false;

          localStorage.setItem(LocallyStoredItemsKeys.userId, res.userId);
          localStorage.setItem(
            LocallyStoredItemsKeys.phoneNumber,
            res.phoneNumber
          );
        } else {
          // Display error alert
          this.displayAlert("ErrorAlert", res);
        }
      },
      (err) => {
        if (err.status == StatusCodes.UnVerifiedAccount) {
          // save verfication data
          let phoneNumber = err.error.phoneNumber;
          let userId = err.error.userId;
          // Construct resend request data
          let userVerficationData: RegistrationResponse = {
            phoneNumber: phoneNumber,
            userId: userId,
            code: this.otp,
          };
          this.saveVerificationData(userVerficationData);
          // Switch panel
          this.toggleRightPanel();

          this.toggleVerificationForm();
        }
        // Get error response message
        const errMsg = err.error.responseMessage;

        // Stop the loader
        this.isLoading = false;

        // If user hasn't verified their account

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  onOtpChange(event: any) {
    this.otp = event;
  }
  /**
   * get OTP from request with the entered identity number data
   * @memberof AuthenticationPageComponent
   */
  getOTP(): void {
    // Show the loader on button
    this.isLoading = true;
    // Set submission state to true (used for form validation)
    this.isSubmitting = true;
    // Validate form
    if (this.identificationNumberFormGroup.invalid) {
      this.isLoading = false;
      return;
    }
    // Construct user registration payload object
    const getOTPData: UserIdentityNumber = {
      identificationNumber: +this.identificationNumberFormGroup.get(
        "identificationNumber"
      ).value,
    };
    this.identificationNumber = +this.identificationNumberFormGroup.get(
      "identificationNumber"
    ).value;
    // this.absherId = getOTPData.identificationNumber;

    this.authService.getOtp(getOTPData).subscribe(
      (res: any) => {
        // Stop the loader
        this.isLoading = false;
        this.inOtp = true;
        if (res.isSuccess) {
          // Show OTP form
          this.isOtpVisible = true;
          this.isLoginVisible = false;
          this.isSubmitting = false;
          this.isRegistrationVisible = false;
          this.identificationNumberVisible = false;
          this.isErrorAlertVisible = false;
          this.countDwon();
          this.identificationNumberFormGroup.reset();
        } else {
          // Show error message
          this.displayAlert("ErrorAlert", res);
          this.isRegistrationVisible = false;
        }
      },
      (err) => {
        // Stop the loader
        this.isLoading = false;
        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }
  /**
   * get OTP from request with the entered identity number data
   * @memberof AuthenticationPageComponent
   */
  verifyOTP(): void {
    // Show the loader on button
    this.isLoading = true;
    // Construct user registration payload object
    const sendOTPData: UserVerifyOtp = {
      // identificationNumber: this.absherId,
      identificationNumber: this.identificationNumber,
      absherOtpCode: +this.otp,
    };
    this.authService.verifyOtp(sendOTPData).subscribe(
      (res: any) => {
        // Stop the loader
        this.isLoading = false;
        this.inOtp = false;
        if (res.isSuccess) {
          // Show OTP form
          this.isOtpVisible = false;
          this.isRegistrationVisible = true;
          this.identificationNumberVisible = false;
          this.isErrorAlertVisible = false;
        } else {
          // Show error message
          this.displayAlert("ErrorAlert", res);
          this.isRegistrationVisible = false;
        }
      },
      (err) => {
        // Stop the loader
        this.isLoading = false;
        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  // resend and reset timer
  startTimer() {
    this.counter = 90;
    this.countDwon();
    if (this.identificationNumber) {
      const getOTPData: UserIdentityNumber = {
        identificationNumber: this.identificationNumber,
      };
      this.authService.getOtp(getOTPData).subscribe((res: any) => {
        this.isOtpSent = false;
      });
    } else {
      let userVerficationData: RegistrationResponse = {
        phoneNumber: localStorage.getItem(LocallyStoredItemsKeys.phoneNumber),
        userId: localStorage.getItem(LocallyStoredItemsKeys.userId),
      };
      this.authService
        .resendVerficationCode(userVerficationData)
        .subscribe((res: any) => {
          this.isOtpSent = false;
        });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                               verify Login OTP                           */
  /* -------------------------------------------------------------------------- */
  verifyLoginOTP() {
    // Show the loader on button
    this.isLoading = true;
    const verifyReqest: UserVerification = {
      userId: localStorage.getItem(LocallyStoredItemsKeys.userId),
      phoneNumber: localStorage.getItem(LocallyStoredItemsKeys.phoneNumber),
      code: this.otp,
    };
    if (this.counter === 0) {
      this.displayAlert("ErrorAlert", {
        isSuccess: false,
        responseMessage:
          this.lang === "ar"
            ? "لقد انتهت صلاحية كود التحقق"
            : "Your OTP has expired.",
        statusCode: 603,
      });
      this.isLoading = false;
      return;
    } else {
      this.authService.verifyLoginOTP(verifyReqest).subscribe(
        (res: any) => {
          // Stop the loader
          this.isLoading = false;
          if (res.isSuccess) {
            // Check the if login was opened from the inquire
            if (this.authService.getIsAuthModalShown().value) {
              this.router.navigate([AppRoutes.controlPanel.policies.uri]);
              this.authService.setIsAuthModalShown(false);
              this.loginFormGroup.reset();
            } else {
              this.router.navigate([AppRoutes.landing]);
            }
          } else {
            this.displayAlert("ErrorAlert", res);
          }
        },
        (err) => {
          // Stop the loader
          this.isLoading = false;
          this.displayAlert("ErrorAlert", err.error);
        }
      );
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                               init Datepicker                              */
  /* -------------------------------------------------------------------------- */
  setBirthDateValue(date: any) {
    // if (this.showgregoianDate)
    //   this.f.birthDateG.setValue(AppUtils.formatDate(date));
    if (this.showHijriDate)
      this.registrationForm.birthDateH.setValue(AppUtils.formatDate(date));
  }

  /**
   * Sends a registration request with the entered registration data
   * @memberof AuthenticationPageComponent
   */

  register(): void {
    // this.showAlert = true;

    // Show the loader on button
    this.isLoading = true;

    // Set submission state to true (used for form validation)
    this.isSubmitting = true;

    // Validate form
    if (this.registrationFormGroup.invalid) {
      this.isLoading = false;
      return;
    }

    // Construct user registration payload object
    const registrationData: UserRegistration = {
      phoneNumber: this.registrationFormGroup.get("phoneNumberCtrl").value,
      email: this.registrationFormGroup.get("emailCtrl").value,
      password: this.registrationFormGroup.get("passwordCtrl").value,
      confirmPassword: this.registrationFormGroup.get("confirmPasswordCtrl")
        .value,
      insuredBirthDate: this.registrationFormGroup.get("birthDateH").value,
      identificationNumber: this.identificationNumber,
    };

    this.authService.register(registrationData).subscribe(
      (res: RegistrationResponse) => {
        // Stop the loader
        this.isLoading = false;

        if (res.isSuccess || res.statusCode == StatusCodes.EmailNotSent) {
          this.isRegistrationVisible = false;
          this.saveVerificationData(res);
          this.authService.setIsLoggedIn(true);
          if (this.authService.getIsAuthModalShown().value) {
            this.router.navigate([AppRoutes.controlPanel.policies.uri]);
            this.authService.setIsAuthModalShown(false);
            this.registrationFormGroup.reset();
          } else {
            if (res.quoteId == null || res.quoteId == "")
              this.router.navigate([AppRoutes.landing]);
            else this.getQuoteDetails(res.quoteId);
            // this.router.navigate([AppRoutes.controlPanel.quotes]);
          }
        } else {
          // Show error message
          this.displayAlert("ErrorAlert", res);
        }
      },
      (err) => {
        // Stop the loader
        this.isLoading = false;
        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  loginAfterReg() {
    // Construct user login payload object
    const loginData: UserLogin = {
      userName: this.registrationFormGroup.get("emailCtrl").value,
      password: this.registrationFormGroup.get("passwordCtrl").value,
      rememberMe: this.isRememberMeChecked,
    };
    // Send request
    this.authService.login(loginData).subscribe((res: LoginResponse) => {
      // Stop the loader
      this.isLoading = false;

      if (res.isSuccess) {
        this.isOtpVisibleLogin = true;
        this.countDwon();
        this.isRegistrationVisible = false;

        localStorage.setItem(LocallyStoredItemsKeys.userId, res.userId);
        localStorage.setItem(
          LocallyStoredItemsKeys.phoneNumber,
          res.phoneNumber
        );
      } else {
        // Display error alert
        this.displayAlert("ErrorAlert", res);
      }
    });
  }

  sendVerificationCode(): void {
    // Set submission state to true (used for form validation)
    this.isSubmitting = true;

    if (this.verificationCode) {
      // Show the loader on button
      this.isLoading = true;

      // Send the request
      this.userService.sendVerificationCode(this.verificationCode).subscribe(
        (res: any) => {
          // Stop the loader
          this.isLoading = false;
          if (res.isSuccess) {
            // Check the if login was opened from the inquire
            if (this.authService.getIsAuthModalShown().value) {
              this.authService.setIsAuthModalShown(false);
              this.loginFormGroup.reset();
            } else {
              this.router.navigate([AppRoutes.landing]);
            }
          } else {
            this.displayAlert("ErrorAlert", res);
          }
        },
        (err) => {
          // Stop the loader
          this.isLoading = false;
          this.displayAlert("ErrorAlert", err.error);
        }
      );
    }
  }

  resendVerficationCode(): void {
    // Display loader
    this.isResending = true;
    this.isLoading = true;

    // Get phone number and user ID from local storage
    let userPhoneNumber = localStorage.getItem(
      LocallyStoredItemsKeys.VerificationPhoneNumber
    );
    let userId = localStorage.getItem(
      LocallyStoredItemsKeys.VerificationUserId
    );

    // Construct resend request data
    let userVerficationData: RegistrationResponse = {
      phoneNumber: userPhoneNumber,
      userId: userId,
    };

    // Resend the request
    this.userService.resendVerficationCode(userVerficationData).subscribe(
      (res: any) => {
        // Stop the loader
        this.isLoading = false;
        this.isResending = false;
        // Display alert
        if (res.isSuccess) this.displayAlert("SuccessAlert", res);
        else this.displayAlert("ErrorAlert", res);
      },
      (err) => {
        // Stop the loader
        this.isLoading = false;
        this.isResending = false;
        // Display alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  reissuePasswordReset(): void {
    this.isResending = true;
    this.issuePasswordReset();
  }

  issuePasswordReset(): void {
    // Get reset issuing request data from url parameters (when redirected from email message)
    let urlUserResetData: PasswordResetReq = {
      email: this.activedRoute.snapshot.queryParamMap.get("email"),
      lastPhoneDigits: this.activedRoute.snapshot.queryParamMap.get("bin"),
    };

    let isRedirectedFromEmail: boolean = !!(
      urlUserResetData.email && urlUserResetData.lastPhoneDigits
    ); // Indicates whether the user has been redirected from email (url /account/reset with or without parameters)

    // Show the loader on button
    this.isLoading = true;

    // Hide the loader of the submit button if resending (show loader on resend button only)
    if (this.isResending) this.isLoading = false;

    // Set submission state to true (used for form validation)
    this.isSubmitting = true;

    // Validate form, unless user has been redirected from email (for which the data is in the url parameters)
    if (
      !isRedirectedFromEmail &&
      this.passwordResetForm.emailCtrl.invalid &&
      this.passwordResetForm.phoneNumberCtrl.invalid
    ) {
      this.isLoading = false;
      this.isResending = false;
      return;
    }

    // Construct password reset issuing request
    let passwordResetReq = {
      email: this.passwordResetForm.emailCtrl.value,
      lastPhoneDigits: this.passwordResetForm.phoneNumberCtrl.value,
    };

    // Construct password reset issuing request from url parameters (when redirected from email message)
    if (isRedirectedFromEmail) {
      passwordResetReq = urlUserResetData;
    }

    // Send the issue request
    this.userService.requestPasswordReset(passwordResetReq).subscribe(
      (res: ApiResponse) => {
        if (res.isSuccess) {
          this.isRequestingPasswordReset = true;
          this.isSubmitting = false;
          this.isLoading = false;
          if (this.isResending) {
            this.displayAlert("SuccessAlert", res);
            this.isResending = false;
          }
        } else {
          this.displayAlert("ErrorAlert", res);
        }
      },
      (err) => {
        // Stop the loader
        this.isLoading = false;
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  resetPassword(): void {
    // Get reset issuing request data from url parameters (when redirected from email message)
    let urlUserResetParams: PasswordResetReq = {
      email: this.activedRoute.snapshot.queryParamMap.get("email"),
      lastPhoneDigits: this.activedRoute.snapshot.queryParamMap.get("bin"),
    };
    let isRedirectedFromEmail: boolean = !!(
      urlUserResetParams.email && urlUserResetParams.lastPhoneDigits
    ); // Indicates whether the user has been redirected from email (url /account/reset with or without parameters)

    // Show the loader on button
    this.isLoading = true;

    // Set submission state to true (used for form validation)
    this.isSubmitting = true;

    // this.toggleRightPanel();
    // this.isLoginVisible = true;
    // this.isRequestingPasswordReset = false;
    // this.isPasswordResetVisible = false;

    // Validate form
    if (
      (!isRedirectedFromEmail && this.passwordResetFormGroup.invalid) ||
      (isRedirectedFromEmail &&
        (this.passwordResetForm.resetTokenCtrl.invalid ||
          this.passwordResetForm.passwordCtrl.invalid))
    ) {
      this.isLoading = false;
      return;
    }

    // Construct password reset request
    let passwordResetReq = {
      email: this.passwordResetFormGroup.get("emailCtrl").value,
      code: this.passwordResetFormGroup.get("resetTokenCtrl").value,
      newPassword: this.passwordResetFormGroup.get("passwordCtrl").value,
    };

    // Construct reset request data using url parameters (when redirected from email message)
    let urlUserResetData = {
      email: urlUserResetParams.email,
      code: this.passwordResetFormGroup.get("resetTokenCtrl").value,
      newPassword: this.passwordResetFormGroup.get("passwordCtrl").value,
    };

    // Set password reset request using url parameters (when redirected from email message)
    if (urlUserResetData.email) {
      passwordResetReq = urlUserResetData;
    }

    // Send the password reset request
    this.userService.resetPassword(passwordResetReq).subscribe(
      (res: ApiResponse) => {
        if (res.isSuccess) {
          // To login
          this.toggleRightPanel();
          this.isLoginVisible = true;
          // Show success message (on login subpage)
          this.displayAlert("SuccessAlert", res);
          // Hide password reset subpage
          this.isPasswordResetVisible = false;
          this.isRequestingPasswordReset = false;
          // Reset all spinners and validations
          this.isSubmitting = false;
          this.isLoading = false;
          this.isResending = false;
          this.passwordResetFormGroup.reset();
        } else {
          this.displayAlert("ErrorAlert", res);
        }
      },
      (err) => {
        this.isLoading = false;
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  /*-------------------------------------------------------------------------- */
  /*                                MISCELLANEOUS                               */
  /* -------------------------------------------------------------------------- */

  saveVerificationData(res: RegistrationResponse): void {
    // Store token
    localStorage.setItem(LocallyStoredItemsKeys.JWT, res.accessToken);
    // Store phone number
    localStorage.setItem(
      LocallyStoredItemsKeys.phoneNumber,

      res.phoneNumber
    );
    // Store user ID
    localStorage.setItem(LocallyStoredItemsKeys.userId, res.userId);
  }

  clearVerificationData(): void {
    // Remove phone number
    localStorage.removeItem(LocallyStoredItemsKeys.VerificationPhoneNumber);
    // Remove user ID
    localStorage.removeItem(LocallyStoredItemsKeys.VerificationUserId);
  }

  checkResumedQuoteSelect(): void {
    if (this.authSource === AuthSource.InsuranceForms) {
      // Get the selected quote's PreviewQuoteRequest
      /* const previewQuoteReq: PreviewQuoteRequest = JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.PreviewQuoteRequest));

        // Send the preview quote request
        this.quoteService.setSelectedQuote(previewQuoteReq).subscribe(
           (res: PreviewQuoteResponse) => {
              // Increment step number
              this.insuranceStepService.setCurrentStepNum(7);
              // Close the modal
              this.authService.setIsAuthModalShown(false);
              // Route to checkout page
              this.router.navigate([AppRoutes.checkout]);
           },
           err => this.router.navigate([AppRoutes.error])
        ); */
    } else {
      this.router.navigate([AppRoutes.controlPanel.policies.uri]);
    }
  }

  // get quote datails by id
  getQuoteDetails(quoteId: string) {
    this.quoteService.getQuoteById(quoteId).subscribe(
      (res) => {
        // this.isLoadingUserQuoteDetails = false;
        if (res.isSuccess) {
          // Save the response on local storage
          localStorage.setItem(
            LocallyStoredItemsKeys.UserQuoteResponse,
            JSON.stringify(res)
          );
          // Route to the quotes list page
          this.router.navigate([AppRoutes.quotes]);
          // Store the retreived quotes on local storage
          localStorage.setItem(
            LocallyStoredItemsKeys.Quotes,
            JSON.stringify(res.quotes)
          );
          this.quoteService.setQuotes(res.quotes);
        }
      },
      (err) => {
        // this.isLoadingUserQuoteDetails = false;
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

@Directive({ selector: "[preventCutCopyPaste]" })
export class CopyDirective {
  constructor(el: ElementRef, renderer: Renderer2) {
    var events = "cut copy paste";
    events.split(" ").forEach((e) =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }
}
function ngOnDestroy() {
  throw new Error("Function not implemented.");
}
