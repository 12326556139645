import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileUploadModule } from "ng2-file-upload";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./../shared/shared.module";
import { PaymentStatusComponent } from "./payment-status/payment-status.component";
import { OrderReviewComponent } from "./order-review/order-review.component";
import { CheckoutRoutingModule } from "./checkout.routes";
import { CheckoutSuccessComponent } from "./checkout-success/checkout-success.component";
import { RouterModule } from "@angular/router";
import { WebcamModule } from "ngx-webcam";
import { VehicleImagesComponent } from "./vehicle-images/vehicle-images.component";
import { DirectpayComponent } from "./directpay/directpay.component";
import { ComplateCheckoutComponent } from "./complate-checkout/complate-checkout.component";
import { NgOtpInputModule } from "ng-otp-input";

@NgModule({
  declarations: [
    PaymentStatusComponent,
    OrderReviewComponent,
    CheckoutSuccessComponent,
    VehicleImagesComponent,
    DirectpayComponent,
    ComplateCheckoutComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    FileUploadModule,
    CheckoutRoutingModule,
    WebcamModule,
    NgOtpInputModule,
  ],
})
export class CheckoutModule {}
