<div class="bg-white my-2 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 inner-column mt-5">
        <div class="inner-box">
          <div class="sec-title mb-3">
            <h2 class="pb-2" style="font-weight: 700;">{{ "Footer.ChannelsTitle" | translate }}</h2>
          </div>
          <div class="row mb-4">
            <div class="col-lg-4 col-md-12 mb-2 text-md-start">
              <div class="item h-100 rounded d-flex align-items-center justify-content-center p-4">
                <div
                  class="icon d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white">
                  <i class="ion-md-mail text-primary"></i>
                </div>
                <div class="content ml-4">
                  <p class="mb-2">{{lang == "en" ? 'Email' : 'البريد الالكتروني'}}</p>
                  <h5 class="mb-0">name@domain.com</h5>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 mb-2 text-md-start">
              <div class="item h-100 rounded d-flex align-items-center justify-content-center p-4">
                <div
                  class="icon d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white">
                  <i class="ion-ios-call text-primary"></i>
                </div>
                <div class="content ml-4">
                  <p class="mb-2">{{lang == "en" ? 'Call Us Now' : 'تواصل معنا الآن'}}</p>
                  <h5 class="mb-0">+96655555555</h5>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 mb-2">
              <div class="item h-100 rounded align-items-center p-4 text-center">
                <p class="mb-2" style="color:#7c7c7c; font-size: 17px;">{{lang == "en" ? 'Follow US at' : 'تابعنا على'}}
                </p>
                <div class="social-link">
                  <a href="https://www.linkedin.com/company/wazen/about/" target="_blank">
                    <i class="ion-logo-linkedin text-primary"></i></a>
                </div>
                <div class="social-link">
                  <a href="" target="_blank">
                    <i class="ion-logo-facebook text-primary"></i></a>
                </div>
                <div class="social-link">
                  <a href="https://twitter.com/@WazenKSA" target="_blank">
                    <i class="ion-logo-twitter text-primary"></i></a>
                </div>
                <div class="social-link">
                  <a href="" target="_blank">
                    <i class="ion-logo-youtube text-primary"></i></a>
                </div>
                <!-- <div class="content ml-4">
                  <p class="mb-2">Website</p>
                  <h5 class="mb-0">wazen_domain.com</h5>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d463877.312444618!2d47.15218947228276!3d24.72545536839335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2z2KfZhNix2YrYp9i2INin2YTYs9i52YjYr9mK2Kk!5e0!3m2!1sar!2seg!4v1721116087592!5m2!1sar!2seg"
                width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>