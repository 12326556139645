import { AuthGuard } from "src/app/helpers/guards/auth.guard";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { BuyNewPolicyModule } from "./buy-new-policy/buyNewPolicy.module";

import { ProfileComponent } from "./profile/profile.component";

import {CliamsComponent} from "../user/cliams/cliams.component"
import {AddClaimComponent}  from "../user/cliams/add-claim/add-claim.component"
export const PROFILE_ROUTES = [
   {
      path: '',
      canActivate: [AuthGuard],
      component: ProfileComponent,
      children: [
         {
            path: AppRoutes.controlPanel.invoices,
            loadChildren: () => import('./invoices/invoices.module')
               .then(m => m.InvoicesModule)
         },
         {
            path: AppRoutes.controlPanel.quotes,
            loadChildren: () => import('./prev-quotes/prvQuotes.module')
               .then(m => m.PreQuoteModule)
         },
         {
            path: AppRoutes.controlPanel.policies.support,
            loadChildren: () => import('./technical-support/technicalSupport.module')
               .then(m => m.TechnicalSupportModule)
         },
         {
            path: AppRoutes.controlPanel.vehiclesList,
            loadChildren: () => import('./vehicles-list/vehicles.module')
               .then(m => m.VehiclesModule)
         },
         {
            path: AppRoutes.controlPanel.newQuotation.main,
            loadChildren: () => import('./buy-new-policy/buyNewPolicy.module')
               .then(m => m.BuyNewPolicyModule)
         },
         {
            path: AppRoutes.controlPanel.policies.main,
            loadChildren: () => import('./policies/policies.module')
               .then(m => m.PoliciesModule)
         },
         {
            path: AppRoutes.controlPanel.accountSettings.main,
            loadChildren: () => import('./account/account.module')
               .then(m => m.AccountModule)
         },
         {
            path: AppRoutes.controlPanel.claims, component: CliamsComponent,
            // children: [

            //    { path: AppRoutes.controlPanel.Addcliams, component: AddClaimComponent }]
         },
         {
            path: 'claims/Addclaims', component: AddClaimComponent
         }
      ]
   }
];