export const ApiRoutes = {
  Account: {
    Register: "account/register",
    Login: "account/login",
    VerifyPhone: "account/verify",
    PasswordResetRequest: "account/resetpassword",
    PasswordReset: "account/confirmpasswordreset",
    ResendVerfication: "account/sendcode",
    GetPaymentCode: "account/SendPaymentCode ",
    getOtp: "account/GetAbsherOtpCode",
    verifyOtp: "account/VerifyAbsherOtpCode",
  },

  MasterTable: {
    VehicleDetails: "master/VehicleDetails",
    ProductType: "master/products",
    Benefit: "master/benefits",
    Violation: "master/violations",
    DiscountTypes: "master/DiscountTypes",
    PremiumBreakdown: "master/PremiumBreakdowns",
    PromoCode: "master/PromoCodes",
    NCDFreeYear: "master/NCDFreeYears",
    IdentityType: "master/IdentityTypes",
    Gender: "master/Genders",
    EducationLevel: "master/EducationLevels",
    SocialStatus: "master/SocialStatus",
    Occupations: "master/Occupations",
    Countries: "master/Countries",
    Region: "master/Regions",
    Cities: "master/Cities",
    LicenseType: "master/LicenseTypes",
    DriverType: "master/DriverTypes",
    DrivingPercentage: "master/DrivingPercentages",
    MedicalCondition: "master/MedicalConditions",
    Mileage: "master/Mileages",
    VehicleIdType: "master/VehicleIdTypes",
    VehicleMaker: "master/VehicleMakers",
    VehicleModel: "master/VehicleModels",
    VehiclePlateType: "master/VehiclePlateTypes",
    VehiclePlateLetter: "master/VehiclePlateLetters",
    TransmissionType: "master/TransmissionTypes",
    VehicleBodyType: "master/VehicleBodyTypes",
    VehicleAxlesWeight: "master/VehicleAxlesWeights",
    VehicleColor: "master/VehicleColors",
    VehicleEngineSize: "master/VehicleEngineSizes",
    VehicleSpecifications: "master/VehicleSpecifications",
    VehicleUse: "master/VehicleUses",
    VehicleRepairMethod: "master/VehicleRepairMethods",
    ParkingLocation: "master/ParkingLocations",
    PaymentMethod: "master/PaymentMethods",
    Bank: "master/Banks",
    Attachment: "master/Attachments",
    Relation: "master/relations",
  },

  Quotation: {
    PreviewQuote: "quotation/preview",
    PreviewAttachment: "quotation/preview/attachment",
    AttachmentPreview: "quotation/attachment/preview",
    SendUploadImagesLink: "helper/sms/SendUploadImgsLink",
    RequestQuote: "quotation/request",
    ProspectClientRequest: "quotation/ProspectClientRequest",
    InquireQuote: "quotation/inquire",
    InquireDriver: "quotation/getdriverinfo",
    saveOrder: "quotation/Save",
    quoteDetails: "quotation/details/",
    quoteUrl: "quotation/generateshareurl",
  },
  LeanTec: {
    leanTech: "leantech/customer/add",
    iban: "user/iban",
    ibanVerifications: "leantech/verifications/iban",
  },
  Policy: {
    checkout: "policy/checkout",
    issue: "policy/issue",
    policyPurchase: "policy/purchase",
    applyCoupon: "policy/applycoupon",
  },

  checkout: {
    attachmentPreview: "attachment/preview",
  },

  Directpay: {
    GetSecureHash: "Request/GetSecureHash",
    PurchasePolicyRequest: "Request/PurchasePolicyRequest",
    Inquiry: "Request/Inquiry",
  },

  Profile: {
    PasswordChangeRequest: "user/changepassword",
    PasswordChange: "user/confirmpasswordchange",
    UsernameChangeRequest: "user/changeusername",
    UsernameChange: "user/confirmusernamechange",
    PhoneNumberChangeRequest: "user/changephonenumber",
    PhoneNumberChange: "user/confirmphonenumberchange",
    ChangeLanguage: "user/changelanguage",
    GetAccountinfo: "user/accountinfo",
    GetVehiclesList: "user/client/vehicles", //https://wazen-api.oasisoft.net/api/user/client/vehicles it was user/vehicles
    UserQuote: "user/ActiveQuotes",
    GetVehicles: "user/client/vehicles",
    UserQuotes: "user/Quote",
    Dashboard: "user/dashboard",
    Policy: "user/policies/",
    GetUserIdentities: "user/identities",
    Invoices: "user/invoice",
    PrintInvoice: "user/invoice/print/",
    DownloadPolicy: "user/Policy/print/",
    PoliciesCount: "user/policiescount",
  },

  Tickets: {
    ticketsList: "tickets",
    ticketsTypes: "tickets/Types",
    ticketsCreate: "tickets/create",
    ticketDetails: "tickets/Details",
    ticketFollowUp: "tickets/FollowUp",
    ticketStatus: "tickets/status",
    totalUnReadFollowup: "tickets/totalunreadmessages",
    changeStatus: "tickets/ChangeStatus",
  },
  Claims: {
    createClaim: "Claims/create",
    claimList: "Claims",
  },
};
