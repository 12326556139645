import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import AppUtils from "./helpers/utils/AppUtils";
import { LocallyStoredItemsKeys } from "./models/app/LocallyStoredItemsKeys";
import { AuthService } from "./services/auth/auth.service";
import { InvoiceService } from "./services/checkout/invoice.service";
import { InsuranceStepsService } from "./services/insurance-steps/insurance-steps.service";
import { AppService } from "./services/app/app.service";
import { OrderReviewService } from "./services/checkout/order-review.service";
import { Identity, IdentityResponse } from "./models/profile/Identity";
import { ProfileService } from "./services/profile/profile.service";
import { ApiResponse } from "./payload/responses/ApiResponse";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  isAuthModalVisible = false;
  isPrintingInvoice;
  completionPercentage = "0%";
  locale: string;
  isAtLanding: boolean;
  QuotId: string;
  identities: Identity[];
  isSuccessAlertVisible: boolean = false;
  clientId: string;
  errorMessage: string;
  successMessage;
  isErrorAlertVisible: boolean = false;
  clientIdSet: boolean = false;
  validationErrors: string[];
  /**/
  display: any;
  public timerInterval: any;
  constructor(
    private authService: AuthService,
    private invoiceService: InvoiceService,
    private translateService: TranslateService,
    private router: Router,
    private AppService: AppService,
    private orderService: OrderReviewService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.AppService.startWatching();
    setTimeout(() => this.someFunction(), 2 * 60 * 1000); // Call after 2 minutes
    setTimeout(() => this.someFunction(), 5 * 60 * 10000); // Call after 5 minutes
    setTimeout(() => this.someFunction(), 10 * 60 * 10000); // Call after 10 minutes

    this.watchLoginModalState();
    this.watchInvoicePrinting();
    this.locale = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
      }
    );
    this.getCurrentUrl();
    // this.timer(3);
  }

  getCurrentUrl() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isAtLanding = event.url === "/";
      }
    });
  }

  watchLoginModalState(): void {
    this.authService
      .getIsAuthModalShown()
      .subscribe(
        (isAuthModalVisible) => (this.isAuthModalVisible = isAuthModalVisible)
      );
  }

  watchInvoicePrinting(): void {
    this.invoiceService
      .getActiveGeneratedInvoice()
      .subscribe((invoiceGeneration) => {
        if (invoiceGeneration) {
          this.isPrintingInvoice = true;
          AppUtils.disableScrolling();
        } else {
          this.isPrintingInvoice = false;
          AppUtils.enableScrolling();
        }
      });
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      // console.log("display>>>",this.display)
      if (seconds == 0) {
        // console.log('finished');
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }
  // @HostListener("document:mousemove", ["$event"])
  // @HostListener("document:keypress", ["$event"])
  // onActivity() {
  //   this.AppService.onActivity();
  // }

  someFunction() {
    // start after login
    if (this.authService.isLoggedIn.value) {
      // Check if clientId is already set
      if (this.clientIdSet) {
        // If clientId is set, directly call InquiryCall()
        this.InquiryCall();
      } else {
        // If clientId is not set, call getIdentities() and then call InquiryCall()
        if (this.authService.getIsLoggedIn().value)
          this.getIdentities()
            .then(() => {
              this.InquiryCall();
            })
            .catch((error) => {
              console.error(error);
            });
      }
    }
  }

  getIdentities() {
    return new Promise<void>((resolve, reject) => {
      this.profileService.getIdentities().subscribe((res: IdentityResponse) => {
        this.identities = res.data;
        if (this.identities.length > 0) {
          this.clientId = this.identities[0].clientId;
          this.clientIdSet = true; // Set flag to true once clientId is set
          resolve(); // Resolve the promise when clientId is set
        } else {
          reject("No identities found"); // Reject the promise if no identities are found
        }
      });
    });
  }

  InquiryCall() {
    const Jwt = localStorage.getItem(LocallyStoredItemsKeys.JWT);
    if (Jwt) {
      let data = {
        clientId: this.clientId,
      };
      this.orderService.Inquiry(data).subscribe(
        (res) => {
          if (res.isSuccess === true) this.displayAlert("SuccessAlert", res);
        },
        (err) => {
          this.displayAlert("ErrorAlert", err.error);
        }
      );
    }
  }

  private displayAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    // Set error message
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.errorMessage = apiResponse.responseMessage;
        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.validationErrors = errorsArr;
        } else {
          this.validationErrors = null;
        }
        this.isErrorAlertVisible = true;

        // Hide after timeout
        setTimeout(() => (this.isErrorAlertVisible = false), 5000);

        break;
      case "SuccessAlert":
        // Set the success message
        this.successMessage = apiResponse.responseMessage;
        // Display alert
        this.isSuccessAlertVisible = true;

        // Hide after timeout
        setTimeout(() => (this.isSuccessAlertVisible = false), 5000);

        break;
      default:
        break;
    }
  }
}
