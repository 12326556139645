<div class="container-fluid">

    <form id="eventDate" class="mt-3" [formGroup]="claimForm" (ngSubmit)="createClaim(claimForm)" #claims>
        <ngb-alert class="alert col-6" type="success" *ngIf="successAlert" (close)="successAlertAction()"  id="Salert">{{'Claims.Alerts.Success'| translate}}
        </ngb-alert>
        <ngb-alert class="alert col-6" type="danger" *ngIf="errorAlert" (close)="errAlertAction()" id="Ealert"> {{responseMessage}} </ngb-alert>
        <div class="row">
            <div class="form-group input-group my-0 mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <input [ngClass]="{ 'is-invalid':form.AccidentDate.errors && form.AccidentDate.touched}"
                    class="form-control" formControlName="AccidentDate"
                    placeholder="{{ 'Claims.EventDate' | translate }}" [minDate]="minDate" [maxDate]="maxDate" name="dp"
                    ngbDatepicker #d="ngbDatepicker" (click)="d.open()" autocomplete="off">
                <div class="input-group-append calendar-btn-contianer">
                    <button class="btn btn-outline-secondary calendar-icon" (click)="d.open()" type="button">
                        <i class="icon ion-md-calendar"></i>
                    </button>
                </div>
            </div>


            <div class="col-12 d-flex align-items-center " *ngIf="submitted && form.AccidentDate.errors">
                <div class="v-meassage" *ngIf="form.AccidentDate.errors.required">
                    {{ 'Errors.EmptyInputField' | translate }}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group input-group  my-0  mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <input [ngClass]="{ 'is-invalid':form.cost.errors && form.cost.touched}" class="form-control"
                    formControlName="cost" type="number" placeholder="{{'Claims.Cost'|translate}}">
            </div>
            <div class="col-12 d-flex align-items-center" *ngIf="submitted && form.cost.errors">
                <div class="v-meassage" *ngIf="form.cost.errors.required">
                    {{ 'Errors.EmptyInputField' | translate }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group input-group my-0 mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <textarea formControlName="ClaimDetails" rows="2" class="form-control" type="text"
                    placeholder="{{'Claims.Details'|translate}}"
                    [ngClass]="{ 'is-invalid':form.ClaimDetails.errors && form.ClaimDetails.touched}"></textarea>
                </div>
                <div class="col-12 d-flex align-items-center" *ngIf="submitted && form.ClaimDetails.errors">
                    <div class="v-meassage" *ngIf="form.ClaimDetails.errors.required">
                        {{ 'Errors.EmptyInputField' | translate }}
                    </div>

            </div>
        </div>
        <div class="row">
           
            <!-- upload Button -->
            <div style="display: none;">
                <div class="upload-btn-wrapper">
                    <button type="button" class="btn btn-primary"> <i class="icon ion-md-add-circle-outline"></i>
                        {{'Claims.AddFile'|translate}}
                    </button>
                    <input name="ticketFile" type="file" ng2FileSelect [uploader]="uploader" #file multiple
                        (onFileSelected)="onFileSelected($event)" />
                </div>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" (click)="file.click()">
                <div ng2FileDrop [uploader]="uploader" (fileOver)="fileOverBase($event)"
                    [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" class="attachments-drop-zone">
                    <i class="icon ion-md-add-circle-outline ant-upload-drag-icon"></i>
                    <label class="drag-text">{{'Claims.AddFile'|translate}}</label>
                </div>
            </div>
        </div>
        <div class="form-group mt-0" *ngIf="!supportedFile">
           <label class="v-meassage">{{'Claims.NotSupported'|translate}} </label>
       </div>
        <div *ngIf="uploader?.queue?.length > 0" class="row col-lg-6">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{'Claims.FilesTable.Type'|translate}}</th>
                        <th scope="col">{{'Claims.FilesTable.Name'|translate}}</th>
                        <th scope="col">{{'Claims.FilesTable.Size'|translate}}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue" style="background-color: #04232f0a;
                    border-bottom: 1px solid #eee;">
                        <td>{{ item?.file?.type }}</td>
                        <td>{{ item?.file?.name }}</td>
                        <td>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                        <td><a href="javascript:void(0)" (click)="item.remove()" class="badge bg-danger text-light">
                                <i class="icon ion-md-trash"></i>
                                {{'Claims.FilesTable.Delete'|translate}}
                            </a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row col-lg-6 d-flex justify-content-end">
            <div class="form-group form-btns-wrapper mt-3 ">
                <div class="form-btns">
                    <button type="submit" class="btn btn-info submit-btn" [disabled]="isUploading">
                        <i *ngIf="!isUploading" class="icon ion-ios-send"></i>
                        {{'Claims.Add'|translate}}
                        <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-danger mx-3" (click)="cancel()" [disabled]="isUploading">
                        <i *ngIf="!isUploading" class="icon ion-ios-close"></i>
                        {{'Claims.Cancel'|translate}}
                        <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                    </button>
                    <!-- <button (click)="resetForm()" type="button" class="btn btn-danger submit-btn">Clear</button> -->
                </div>
            </div>
        </div>

      

    </form>

</div>