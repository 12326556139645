import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/services/app/app.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit {
  isCollapsed: boolean = false;
  lang: string;
  finalJson: any;
  termsCondition: any[] = [
    {
      id: "q1",
      questionAr: "عصر جديد في سرعة الحصول على تأمين للمركبات",
      questionEn: "A New Era of Insurance Agility",
      answerAr:
        "انس كل ما تعرفه عن طريقة الحصول على تأمين المركبات لأن وازن الرقمية (Wazen) تغير كل شيء. منصتنا هي طاقم البحث الرقمي الخاص بك" +
        "، وهي مجهزة بأحدث الأدوات لتعيدك إلى المسار الصحيح مع التغطية المناسبة، بشكل فوري! ",
      answerEn:
        "Forget everything you know about shopping for car insurance because Wazen is changing the game. Our platform is your digital pit crew, " +
        " equipped with state-of-the-art tools to get you back on track with the right coverage, pronto! ",
    },
    {
      id: "q2",
      questionAr: "اختر رحلتك بثقة: ",
      questionEn: " Choose Your Journey with Confidence: ",
      answerAr:
        "(1)- •	التأمين على المسؤولية تجاه الغير: قم بقيادة سيارتك عبر طرق الحياة التي لا يمكن التنبؤ بها باطمئنان. إن تغطيتنا لمسؤوليتك تجاه الغير هي ضمانك ضد الآثار المالية المترتبة على الأضرار التي حدثت للغير (المركبات الأخرى، الممتلكات، والأشخاص) بسبب مركبتك.<br>" +
        "(2)- •	التأمين الشامل: بالنسبة للأشخاص الذين يريدون للحصول على تغطية كاملة، فإن التأمين الشامل الخاص بنا يوفر أكبر قدر من راحة البال. فهو يغطي التزاماتك تجاه الغير ويغطي أيضًا الأضرار التي لحقت بسيارتك، سواء كانت ناجمة عن حادث أو كارثة طبيعية أو سرقة أو تخريب..<br>" +
        "(3)- وتقر وتتعهد بأنك تتمتع بالحق والقدرة القأنونية على استخدام موقع وازن حسب ماهو منصوص عليه فى هذه الشروط والاحكام.",
      answerEn:
        "(1)- •	Third-Party Liability Insurance: Steer through life's unpredictable roads with assurance. Our Third-Party Liability coverage is your safeguard against the financial implications of damage to other vehicles, property, " +
        " or injuries to others involved in an accident with your vehicle. <br>" +
        "(2)- •	Comprehensive Insurance: For those who seek full coverage, our Comprehensive Insurance is like an all-access pass to peace of mind. It envelops not just third-party liabilities but also takes care of damages to your own vehicle, " +
        " whether they stem from an accident, natural disaster, theft, or vandalism.",
    },
    {
      id: "q3",
      questionAr: " ميزة منصة وازن الرقمية (Wazen)",
      questionEn: "The Wazen Advantager",
      answerAr:
        "(1)- المقارنات في الوقت الفعلي: توفر لك منصتنا مجموعة من خيارات التأمين المختلفة، وتعرض لك الأسعار والتغطية الأكثر تنافسية في الوقت الفعلي.<br>" +
        "(2)-  تبسيط عملية اعرف عميلك: نحن نقدر وقتك. عملية 'اعرف عميلك' الخاصة بنا سريعة ومباشرة – بدون خطوات إضافية أو تأخيرات. <br>" +
        "(3)- بوابة دفع آمنة: بفضل التشفير المتطور، يكون دفعك آمنًا بشكل تام.<br>" +
        "(4)- السياسات المخصصة: يجب أن يكون تأمينك فريدًا مثل أسلوب قيادتك. في منصة وازن الرقمية (Wazen)، نقوم بتصميم السياسات لتناسب احتياجاتك الفردية.<br>",
      answerEn:
        "(1)- Real-Time Comparisons: Just like a dashboard GPS, our platform guides you through a landscape of insurance options, displaying the most competitive rates and coverage in real-time.<br>" +
        "(2)- Streamlined KYC Process: We value the importance of your time. Our KYC process is swift and straightforward – no detours, no delays.<br>" +
        "(3)- Secure Payment Gateway: With fortified encryption, your payment is as secure as a seatbelt on this journey.<br>" +
        "(4)- Customized Policies: Your insurance should be as unique as your driving style. At Wazen, we tailor policies to fit your individual needs like a glove.<br>",
    },
    {
      id: "q4",
      questionAr: " كيف يتم ذلك ",
      questionEn: "How It Works",
      answerAr:
        "(1)- •	ابدأ رحلتك بالدخول الى منصتنا: أدخل التفاصيل الخاصة بك ودع خوارزميتنا تقوم بالأمور الصعبة.<br>" +
        "(2)- •	قم بمقارنة السياسات: قم بالتصفية من خلال مجموعة مختارة من العروض التي تتطابق مع معاييرك. <br>" +
        "(3)- •	اختر السياسة التي تناسبك: اختر وثيقة التأمين التي تناسب احتياجاتك وميزانيتك.<br>" +
        "(4)- •	ادفع بشكل آمن: ادفع من خلال بوابتنا الموثوقة. <br>" +
        "(5)- •	احتفل بتأمينك الجديد: استمتع براحة البال عند الحصول على تغطيتك المثالية.",
      answerEn:
        "(1)- •	Start Your Engines: Enter your details and let our algorithm do the heavy lifting . <br>" +
        "(2)- •	Compare Policies: Filter through a curated selection of quotes that match your criteria.<br>" +
        "(3)- •	Select Your Champion: Choose the policy that best fits your needs and pocket.<br>" +
        "(4)- •	Secure Checkout: Pay with confidence through our secure portal. <br>" +
        "(5)- •	Victory Lap: Enjoy the peace of mind that comes with having the perfect coverage.",
    },
    {
      id: "q5",
      questionAr: " لماذا منصة وازن الرقمية (Wazen)؟",
      questionEn: "Why Wazen?",
      answerAr:
        "لماذا تختار منصة وازن الرقمية   (Wazen)؟ <br>" +
        " لأنك تستحق تجربة البحث عن التأمين بشكل سريع وقوي مثل المركبة التي تقودها" +
        " نحن المنصة الجديدة التابعة لشركة توافق الوسطاء لوساطة التأمين، الشركة العريقة في ساحة وساطة التأمين من شركة إلى شركة (B2B)، المشهورة بخدماتها المتميزة في وساطة التأمين ومستوى رضا عملائها المرتفع." +
        " مع منصة وازن الرقمية (Wazen)، أنت تستفيد من نفس الجودة، التي أعيد تصميمها الآن لتلبية الاحتياجات الفردية بنفس المستوى من الخبرة والرعاية، ولكن بشكل رقمي. ",
      answerEn:
        " Why choose Wazen? <br> " +
        "Because you deserve an insurance shopping experience that’s as dynamic and dependable as the vehicles you drive. We’re the spirited offspring of Wazen Arabia, " +
        " a powerhouse in the B2B insurance brokering arena renowned for its sterling brokerage services and unmatched customer satisfaction. With eWazen, " +
        " you're tapping into that same esteemed lineage, now reengineered to cater to individual needs with the same level of expertise and care. ",
    },
  ];
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
  }
}
