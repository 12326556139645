import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { AppLanguage } from "../../models/app/AppLanguage";
import { LoadingPageTypes } from "../../models/app/LoadingPageTypes";
import { LocallyStoredItemsKeys } from "../../models/app/LocallyStoredItemsKeys";
import { AuthService } from "../auth/auth.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AppService {
  // not active
  private timeoutId: any;
  // private readonly idleTimeout = 2 * 60 * 1000;
  private readonly idleTimeout = 4 * 60 * 1000; // 4 minutes
  appLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(
    AppLanguage.ARABIC
  );
  activeLoadingPageType: BehaviorSubject<LoadingPageTypes> =
    new BehaviorSubject<LoadingPageTypes>(undefined);
  constructor(
    public translate: TranslateService,
    private auth: AuthService,
    private ngZone: NgZone
  ) {
    // Setup app languages
    translate.addLangs(["en", "ar"]);
    translate.setDefaultLang("ar");
    // Retreive current language from local storage

    const appLang = localStorage.getItem(LocallyStoredItemsKeys.AppLanguage);
    if (appLang) {
      this.setLanguage(appLang);
    } else {
      document.body.setAttribute("dir", "rtl");
      this.setLanguage(AppLanguage.ARABIC);
    }
    this.startWatching();
    // this.resetTimer();
  }
  // logout when not active
  // private logoutUser() {
  //   this.auth.logout();
  // }

  // private resetTimer() {
  //   // if login start timer
  //   if (this.auth.isLoggedIn.value) {
  //     this.timeoutId = setTimeout(() => {
  //       this.logoutUser();
  //     }, this.inactivityTimeout);
  //   }
  // }

  // public onActivity() {
  //   this.resetTimer();
  // }

  /* -------------------------------------------------------------------------- */
  /*                            Inactivity method                               */
  /* -------------------------------------------------------------------------- */
  //#region Inactivity
  startWatching(): void {
    this.resetTimeout();
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener("mousemove", () => this.resetTimeout());
      window.addEventListener("keydown", () => this.resetTimeout());
    });
  }

  private resetTimeout(): void {
    if (this.auth.isLoggedIn.value) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.logout(), this.idleTimeout);
    }
  }

  private logout(): void {
    this.ngZone.run(() => {
      this.displayLogoutDialog();
    });
  }

  private displayLogoutDialog(): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-info",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    let isCurrentLangEn = this.appLanguage.value === "en";
    const titleMessage = isCurrentLangEn
      ? "Your session is about to expire."
      : "جلستك على وشك الانتهاء.";
    const message = isCurrentLangEn
      ? "Your organization's policy enforces automatic sign out after a period of inactivity on the applications."
      : "تفرض سياسة مؤسستك تسجيل الخروج التلقائي بعد فترة من عدم النشاط على التطبيقات.";
    const btnMessage = isCurrentLangEn
      ? "Stay signed in"
      : "البقاء مسجلا للدخول";
    swalWithBootstrapButtons
      .fire({
        title: titleMessage,
        text: message,
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: btnMessage,
        allowOutsideClick: () => !Swal.isLoading(),
        timer: 10000,
      })
      .then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          this.auth.logout();
          console.log("User has been logged out due to inactivity.");
        } else {
          this.resetTimeout();
        }
      });
  }
  //#endregion

  setAppLang(language: AppLanguage | string) {
    this.appLanguage.next(language);
    // Store current language on local storage
    localStorage.setItem(LocallyStoredItemsKeys.AppLanguage, language);
  }

  getAppLang(): BehaviorSubject<string> {
    return this.appLanguage;
  }

  setLanguage(language: AppLanguage | string) {
    if (language === AppLanguage.ARABIC) {
      document.body.setAttribute("dir", "rtl");
      this.translate.use("ar");
      const oldlink = document.getElementsByTagName("link").item(1);
      const newlink = document.createElement("link");
      document.title = "وازن لوساطة التأمين";
      newlink.setAttribute("rel", "stylesheet");
      newlink.setAttribute("type", "text/css");
      newlink.setAttribute(
        "integrity",
        "sha384-vus3nQHTD+5mpDiZ4rkEPlnkcyTP+49BhJ4wJeJunw06ZAp+wzzeBPUXr42fi8If"
      );
      newlink.setAttribute("crossorigin", "anonymous");
      newlink.setAttribute(
        "href",
        "https://cdn.rtlcss.com/bootstrap/v4.2.1/css/bootstrap.min.css"
      );
      document
        .getElementsByTagName("head")
        .item(0)
        .replaceChild(newlink, oldlink);

      this.setAppLang(AppLanguage.ARABIC);
    } else {
      document.body.setAttribute("dir", "ltr");
      document.title = "Wazan Insurance Brokerage";
      this.translate.use("en");
      const oldlink = document.getElementsByTagName("link").item(1);
      const newlink = document.createElement("link");
      newlink.setAttribute("rel", "stylesheet");
      newlink.setAttribute("type", "text/css");
      newlink.setAttribute(
        "integrity",
        "sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
      );
      newlink.setAttribute("crossorigin", "anonymous");
      newlink.setAttribute(
        "href",
        "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
      );

      document
        .getElementsByTagName("head")
        .item(0)
        .replaceChild(newlink, oldlink);

      this.setAppLang(AppLanguage.ENGLISH);
    }
  }

  setActiveLoadingPageType(loadingPageType: LoadingPageTypes): void {
    this.activeLoadingPageType.next(loadingPageType);
  }

  getActiveLoadingPageType(): BehaviorSubject<LoadingPageTypes> {
    return this.activeLoadingPageType;
  }
}
